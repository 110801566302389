<template>
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" enable-background="new 0 0 512 512"  viewBox="0 0 512 512" :width="size" :height="size">
            <path :fill="color" d="m452 48h-392c-33.084 0-60 26.916-60 60v49.495l220.191 163.781c10.709 7.965 23.259 11.948 
            35.809 11.948s25.1-3.982 35.809-11.948l220.191-163.781v-49.495c0-33.084-26.916-60-60-60zm20 
            89.396-204.063 151.784c-7.14 5.311-16.734 5.311-23.873 0l-204.064-151.784v-29.396c0-11.028 
            8.972-20 20-20h392c11.028 0 20 8.972 20 20zm0 99.703 40-29.752v196.653c0 33.084-26.916 
            60-60 60h-392c-33.084 0-60-26.916-60-60v-196.653l40 29.752v166.901c0 11.028 8.972 20 20 20h392c11.028 0 20-8.972 20-20z"/>
        </svg>
    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>