<template>
    <div class="popup">
        <div class="dark" @click="$emit('close')"></div>
        <div class="box">
            <div class="title">Appointment Issues</div>
            <div class="issues">
                <div class="each" v-for="(issue, i) in issues" :key="i">
                    {{issue.text}}
                    <div class="date">{{issue.date}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['issues']
}
</script>

<style scoped>
    .popup {
        position: fixed;
        z-index: 3;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dark {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .box {
        position: relative;
        z-index: 2;
        background-color: white;
        width: 350px;
        max-height: 70vh;
        overflow: auto;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 20px;
    }
    .title {
        font-size: 20px;
        font-weight: 500;
    }
    .issues {
        margin-top: 20px;
    }
    .each {
        margin-top: 20px;
        background-color: #edeef0;
        padding: 10px;
    }
    .date {
        font-size: 12px;
        margin-top: 5px;
    }
</style>