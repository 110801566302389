<template>
    <div>
        <div class="landmark">
            <div class="title">{{ landmark.name }}</div>
            <div class="loc">
                {{ landmark.longitude }} - {{ landmark.latitude }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['landmark']
}
</script>

<style scoped>
    .landmark {
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 5px;
    }
    .title {
        font-weight: 600;
        font-size: 14px;
    }
    .loc {
        font-size: 12px;
        color: gray;
        margin-top: 5px;
    }
</style>