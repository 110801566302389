<template>
    <div class="messages">
        
        <div class="head">
            <div class="title">Message Groups</div>
            <div class="btn" @click="show_message = true">Message</div>
        </div>

        <message v-if="show_message" @close="show_message = false"/>

    </div>
</template>

<script>
import message from '../components/admin/send_message_groups/message.vue';
export default {
    components: {
        message
    },
    data() {
        return {
            show_message: false
        }
    }
}
</script>

<style scoped>

    .messages {
        padding: 30px;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .title {
        font-size: 20px;
        font-weight: 600;
    }

    
    .btn {
        padding: 8px 30px;
        background-color: var(--main);
        color: white;
        font-weight: 500;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
    }
</style>