<template>
    <div class="l">
        <div class="box" @click="open_up()">
            <div class="name">{{city.name}}</div>

            <div class="flex">
                <div class="lab">Agents:</div>
                <div class="value">{{city.numbers}}</div>
            </div>

            <div class="icon">
                <arrow :size="'20px'"/>
            </div>
        </div>
        <div class="content" v-if="open">
            <div class="opts">
                <div class="opt" @click="show_add = true">Add Agent</div>
                <div class="opt" @click="show_send = true">Send Message</div>
            </div>
            <div class="loading" v-if="loading">
                loading...
            </div>
            <div class="numbers">
                <div class="number" v-for="(number, i) in numbers" :key="number.number">
                    <div class="n">{{number.name}}</div>
                    <div class="num">{{number.number}}</div>
                    <div class="del" @click="remove(i)">Delete</div>
                </div>
            </div>
        </div>

        <add :city="city.name" v-if="show_add" 
            @close="show_add = false" @new_person="new_person($event)"/>
        
        <message :city="city.name" v-if="show_send"
            @close="show_send = false"/>
    </div> 
</template>

<script>
import arrow from '../../../icons/arrow_down.vue'
import add from './add.vue'
import message from './message.vue'

export default {
    components: {
        arrow,
        add, message
    },
    props: ['city'],
    data() {
        return {
            open: false,
            numbers: [
            ],
            show_add: false,
            show_send: false,
            loading: false
        }
    },
    methods: {
        new_person(e) {
            this.numbers.unshift(e)
            this.show_add = false
        },
        open_up() {
            if (this.open) {
                this.open = false; return
            } 
            this.open = true
            this.loading = true

            let form = new FormData()
            form.append('city', this.city.name)

            this.$http.post('/admin/persons/get', form)
            .then(data => {
                let r = data.data 
                if (r.success) {
                    this.numbers = r.persons
                    this.loading = false
                }
            })
        },
        remove(i) {

            this.loading = true

            let form = new FormData()
            form.append('city', this.numbers[i].city)
            form.append('number', this.numbers[i].number)

            this.$http.post('/admin/persons/remove', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    this.removed()
                    this.numbers = r.persons
                    this.loading = false
                }
            })
        }
    },
    notifications: {
        removed: {
            'type': 'success',
            'title': 'Removed',
            'message': ''
        }
    }
}
</script>

<style scoped>
    
    .l {
        background-color: white;
        overflow: hidden;
        border-radius: 5px;
        margin-bottom: 20px;
    }
    .box {
        display: flex;
        padding: 20px;
        align-items: center;
        cursor: pointer;
    }
    .name {
        font-weight: 500;
        font-size: 18px;
        margin-right: 20px;
    }

    .flex {
        display: flex;
        align-items: center;
        margin-right: 10px;
        font-size: 14px;
    }
    .lab {
        font-weight: 500;
        opacity: .5;
        margin-right: 5px;
        position: relative;
        /* z-index: 1; */
    }
    .value {
        font-weight: 500;
    }
    .icon {
        margin-left: auto;
        fill: #686868;
    }


    .content {
        border-top: 1px solid #ccc;
        padding: 20px;
    }
    .opts {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
    }
    .opt {
        padding: 8px 30px;
        background-color: var(--main);
        color: white;
        font-weight: 500;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
    }


    .loading {
        text-align: center;
        padding: 40px 10px;
        font-weight: 500;
        font-size: 14px;
    }
    .numbers {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 20px;
    }
    .number {
        border: 1px solid #ddd;
        padding: 15px;
        border-radius: 5px;
    }
    .n {
        font-size: 14px;
        color: grey;
    }
    .num {
        font-weight: 500;
        margin-top: 3px;
    }
    .del {
        font-weight: 500;
        font-size: 14px;
        margin-top: 5px;
        text-align: center;
        color: var(--main);
        cursor: pointer;
    }
</style>