<template>
    <div class="page">
        <div class="title">Properties Video</div>

        <div class="loading" v-if="loading">
            <div class="spin"></div>
        </div>

        <prop_video v-for="(video, i) in videos" :key="video.prop_id" :video="video" 
            @link_added="link_added(i)"/>
    </div>
</template>

<script>
import prop_video from '../components/admin/properties_video/video.vue'

export default {
    components: {
        prop_video
    },
    data() {
        return {
            videos: [],
            loading: false
        }
    },
    mounted() {
        this.$http.get('/admin/properties/video')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.videos = r.property

                this.loading = false
            }
        })
    },
    methods: {
        link_added(i) {
            this.videos.splice(i, 1)
        }
    }
}
</script>

<style scoped>
    .page {
        padding: 30px;
    }
    .title {
        font-size: 20px;
        font-weight: 600;
    }

    
    
    .spin {
        margin: 70px auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: var(--main);
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>