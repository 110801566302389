<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">User Details</div>
            <div class="image">
                <img :src="docurl + details.pic"/>
            </div>
            <div class="item">
                <div class="label">Name</div>
                <div class="value">{{details.name}}</div>
            </div>
            <div class="item">
                <div class="label">Phone</div>
                <div class="value">{{details.phone}}</div>
            </div>
            <div class="item">
                <div class="label">Date of Brith</div>
                <div class="value">{{details.dob}}</div>
            </div>
            <div class="item">
                <div class="label">State of Origin</div>
                <div class="value">{{details.s_o_o}}</div>
            </div>
            <div class="item">
                <div class="label">Religion</div>
                <div class="value">{{details.religion}}</div>
            </div>
            <div class="item">
                <div class="label">Occupants</div>
                <div class="value">{{details.occupants}}</div>
            </div>
            <div class="item">
                <div class="label">Previous Address</div>
                <div class="value">{{details.previous_address}}</div>
            </div>
            <div class="item">
                <div class="label">Occupation</div>
                <div class="value">{{details.occupation}}</div>
            </div>
            <div class="item">
                <div class="label">Occupation Address</div>
                <div class="value">{{details.occupation_address}}</div>
            </div>
            <div class="item">
                <div class="label">Position Held</div>
                <div class="value">{{details.position_held}}</div>
            </div>
            <div class="item">
                <div class="label">Guarantor Name</div>
                <div class="value">{{details.guarantor_name}}</div>
            </div>
            <div class="item">
                <div class="label">Guarantor Address</div>
                <div class="value">{{details.guarantor_address}}</div>
            </div>
            <div class="item">
                <div class="label">Guarantor Phone</div>
                <div class="value">{{details.guarantor_phone}}</div>
            </div>
            <div class="item">
                <div class="label">Next of Kin Name</div>
                <div class="value">{{details.kin_name}}</div>
            </div>
            <div class="item">
                <div class="label">Next of Kin Address</div>
                <div class="value">{{details.kin_address}}</div>
            </div>
            <div class="item">
                <div class="label">Next of Kin Phone</div>
                <div class="value">{{details.kin_phone}}</div>
            </div>
            <div class="image">
                <img :src="docurl + details.doc"/>
            </div>

            <div class="opt">
                <div class="btn" @click="reject = !reject">Reject</div>
                <div class="btn" @click="$emit('approve')">Approve</div>
            </div>

            <div class="input" v-show="reject">
                <textarea placeholder="Reason for rejecting" v-model="text"></textarea>

                <loader v-if="loading" :height="'35px'"/>
                <div class="btn" @click="submit()" v-else>Reject</div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import loader from '../../loader.vue';

export default {
    components: {
        loader,
    },
    props: ['appt_id', 'user_id', 'details', 'user_issues'],
    data() {
        return {
            reject: false,
            text: '',
            loading: false,
            issues: []
        }
    },
    mounted() {
        this.issues = this.user_issues
    },
    computed: {
        docurl() {
            return this.$root.$data.docurl
        }
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },
        submit() {
            if (this.text == '') {
                this.empty(); return
            }

            this.loading = true

            this.issues.push({
                'from': 'admin',
                'date': moment().format('YYYY-MM-DD HH:mm'),
                'text': this.text
            })

            let form = new FormData()
            form.append('appt_id', this.appt_id)
            form.append('user_id', this.user_id)
            form.append('user_issues', JSON.stringify(this.issues))

            this.$http.post('/admin/reject_details', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.rejected()

                    this.$emit('rejected')
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Enter reason for rejecting'
        },
        rejected: {
            type: 'success',
            title: 'Rejected',
            message: 'This user details has been rejected'
        }
    }
}
</script>

<style scoped>
    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dark {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .box {
        position: relative;
        z-index: 2;
        background-color: white;
        width: 350px;
        max-height: 70vh;
        overflow: auto;
        border-radius: 5px;
        padding: 20px;
        box-sizing: border-box;
    }
    .title {
        font-size: 20px;
        font-weight: 500;
    }

    .image {
        margin-top: 20px;
    }
    img {
        width: 100%;
    }
    .item {
        margin-top: 20px;
    }
    .label {
        font-size: 12px;
    }
    .value {
        font-weight: 500;
    }


    .opt {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }
    .btn {
        background-color: var(--main);
        padding: 8px 20px;
        color: white;
        border-radius: 5px;
        font-weight: 500;
        cursor: pointer;
        font-size: 14px;
    }

    .input {
        margin-top: 20px;
    }
    textarea {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        width: 100%;
        height: 100px;
        box-sizing: border-box;
        margin-bottom: 20px;
    }
    .input .btn {
        text-align: center;
    }
</style>