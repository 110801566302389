<template>
    <div>
        <svg :width="size" :height="size" style="enable-background:new 0 0 234.928 196.992;" 
        version="1.1" viewBox="0 0 234.928 196.992" xml:space="preserve" 
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g>
            <path d="M200.836,140.735h-23.5l-0.002,41.502c0,7.866-6.299,14.273-14.115,14.478c0.445,0.172,0.926,0.276,1.433,0.276h53.695   c2.209,0,4-1.791,4-4v-54.713c0-2.209-1.791-4-4-4h-5.459C210.285,138.168,205.855,140.735,200.836,140.735z"/>
            <path d="M213.336,97.988v-0.022c-0.066,0.024-0.129,0.05-0.195,0.073L213.336,97.988z"/>
            <path d="M188.211,113.735h-25.377c-3.291,0-6.512,1.332-8.838,3.661c-2.33,2.326-3.662,5.548-3.662,8.839v56.001   c0,6.903,5.596,12.499,12.5,12.499s12.5-5.596,12.5-12.499l0.002-43.502h14.814v0.001h10.684v0.001c0.006,0,0.01-0.002,0.016-0.002   c6.896-0.008,12.484-5.6,12.484-12.499h0.002v-13.369h-25.193L188.211,113.735z"/>
            <path d="M139.314,182.236v-43.501h9.021v-12.5c0-3.822,1.549-7.558,4.25-10.253c0.879-0.881,1.877-1.625,2.945-2.247h-28.717   c-2.493,0-4.943,0.769-6.999,2.152c0.033,0.033,0.07,0.061,0.103,0.093c2.701,2.703,4.248,6.44,4.248,10.256v56   c0,4.052-1.674,7.716-4.361,10.349c1.999,1.357,4.412,2.151,7.01,2.151C133.719,194.736,139.314,189.139,139.314,182.236z"/>
            <circle cx="178.457" cy="22.5" r="22.5"/>
            <polygon points="21.144,106.659 21.144,100.867 0,100.867 0,109.867 21.144,109.867 21.144,106.659  "/>
            <rect height="9" width="153.266" x="79.234" y="100.867"/>
            <path d="M90.828,192.992v-52.257H35.645c-2.344,0-4.553-0.572-6.514-1.564v53.82c0,2.209,1.791,4,4,4h53.697   C89.037,196.992,90.828,195.201,90.828,192.992z"/>
            <path d="M57.439,0.167c-12.424,0-22.498,10.074-22.498,22.5c0,5.156,1.738,9.904,4.654,13.698h29.908   c0.457,0,0.912,0.035,1.389,0.104C71.297,36.424,71.652,36.4,72,36.4h0.166c0.94,0,1.895,0.121,2.83,0.331   c3.09-3.853,4.943-8.741,4.943-14.064C79.939,10.241,69.865,0.167,57.439,0.167z"/>
            <path d="M21.144,76.861c-1.961-1.518-2.79-2.827-3.204-3.862c-0.001-0.002-0.002-0.003-0.003-0.005   c-0.209-0.546-0.304-1.085-0.347-1.665c-0.012-0.156-0.041-0.3-0.041-0.465c-0.01-1.383,0.459-3.217,1.525-5.227   c0.963-1.859,2.43-3.795,4.07-5.514v66.111l0,0c0,6.904,5.596,12.5,12.5,12.5h61.52v43.501c0,6.902,5.596,12.5,12.5,12.5   c6.904,0,12.5-5.598,12.5-12.5v-56.001c0-3.291-1.332-6.511-3.66-8.84c-2.328-2.325-5.549-3.66-8.84-3.66h-32.43v-22.01   c3.381,3.371,7.393,4.811,10.932,5.701c4.029,0.963,7.816,1.147,10.73,1.156c2.975-0.002,5.02-0.223,5.23-0.246   c4.395-0.482,7.563-4.434,7.08-8.824c-0.484-4.393-4.438-7.563-8.83-7.078c-0.18,0.018-1.586,0.148-3.48,0.148   c-2.104,0.006-4.84-0.184-6.98-0.711c-2.199-0.486-3.445-1.4-3.449-1.521c-1.41-1.719-2.481-4.402-3.084-7.889   c-0.945-5.225-0.703-11.67-0.951-17.512c-0.183-2.973-0.328-5.801-1.654-9.139c-0.687-1.645-1.824-3.561-3.822-5.102   c-1.975-1.563-4.686-2.35-6.953-2.309c-0.389,0-0.768,0.037-1.141,0.09c-0.439-0.078-0.891-0.125-1.355-0.125H30.873   c-0.605,0-1.191,0.076-1.758,0.209c-3.078,0.422-5.555,1.594-8.113,3.002c-4.551,2.641-9.029,6.504-12.738,11.373   c-3.641,4.855-6.66,10.83-6.713,17.914c0,0.394,0.031,0.796,0.052,1.195c0.112,2.127,0.528,4.307,1.321,6.428   c0.035,0.103,0.062,0.208,0.103,0.311v-0.002c2.002,5.193,6.098,9.666,11.686,13.033c1.943,1.177,4.09,2.243,6.432,3.218   L21.144,76.861z"/>
            <path d="M124.389,99.886l2.08-2.142l1.392-1.434c1.205,1.093,2.75,1.835,4.492,2.027c0.211,0.023,2.256,0.244,5.23,0.246   c2.914-0.01,6.701-0.193,10.73-1.156c3.539-0.891,7.551-2.33,10.932-5.701v6.141h14.994l5.018-36.978   c1.297,0.486,2.662,0.74,4.025,0.739h0.002c0,0,0.004,0,0.006,0c1.363,0,2.727-0.254,4.021-0.74l3.295,24.273   c1.457-1.683,3.416-2.828,5.635-3.257c8.756-1.686,13.631-3.897,16.176-5.456c0.334-0.205,0.635-0.403,0.918-0.598v-2.939V60.124   c1.641,1.719,3.107,3.654,4.07,5.514c1.066,2.01,1.535,3.844,1.525,5.227c-0.002,0.165-0.029,0.309-0.041,0.465   c-0.043,0.58-0.139,1.118-0.348,1.665c0,0.001-0.002,0.003-0.002,0.005c-0.104,0.257-0.242,0.536-0.404,0.825   c-0.676,1.196-1.955,2.658-4.672,4.329c-3.348,2.047-8.82,4.168-16.842,5.713c-4.336,0.838-7.174,5.035-6.332,9.375   c0.738,3.824,4.09,6.479,7.844,6.479c0.506,0,1.017-0.048,1.531-0.147c9.297-1.809,16.484-4.344,22.102-7.744   c2.896-1.746,5.383-3.795,7.395-6.081c1.867-2.124,3.326-4.451,4.291-6.952v0.002c0.039-0.103,0.066-0.207,0.102-0.311   c0.795-2.121,1.211-4.301,1.322-6.428c0.021-0.399,0.053-0.801,0.051-1.195c-0.051-7.084-3.07-13.059-6.711-17.914   c-3.709-4.869-8.188-8.732-12.738-11.373c-2.559-1.408-5.035-2.58-8.113-3.002c-0.566-0.133-1.152-0.209-1.758-0.209H197.1   C192.604,43.644,185.916,47,178.457,47c-7.104,0-13.51-3.043-17.99-7.891c-1.055,0.383-2.07,0.912-2.941,1.601   c-1.998,1.541-3.135,3.457-3.822,5.102c-1.326,3.338-1.471,6.166-1.654,9.139c-0.248,5.842-0.006,12.287-0.951,17.512   c-0.604,3.486-1.674,6.17-3.084,7.889c-0.004,0.121-1.25,1.035-3.449,1.521c-0.977,0.241-2.078,0.408-3.187,0.521l2.308-2.377   l2.521-2.595l-2.869-2.787l-5.777,5.947l-1.9,1.957c-0.868-0.04-1.446-0.094-1.557-0.104c-4.393-0.484-8.346,2.685-8.83,7.078   c-0.129,1.17,0.008,2.307,0.354,3.357l-1.531,1.577l-3.053,3.143l0.284,0.276l2.585,2.511L124.389,99.886z M179.484,48.709h3.801   h3.799l1.9,7.5l-0.289,0.456c-1.564,1.306-3.484,1.96-5.412,1.962c-1.928-0.002-3.842-0.655-5.406-1.958l-0.293-0.46   L179.484,48.709z"/></g>
        </svg>
    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>