<template>
    <div class="table">
        <div class="head" @click="show = !show">
            <div class="name">{{status}}</div>
            <div class="no">{{total}}</div>
            <div class="a">
                <arrow :size="'20px'" class="arr" :class="[ show ? 'rot' : '' ]"/>
            </div>
        </div>
        <div class="main" v-if="show">
            <div class="label">
                <div class="text">Date</div>
                <div class="text">User</div>
                <div class="text">Partner</div>
                <div class="text">Agent</div>
                <div class="text">Property</div>
                <div class="text">Appt ID</div>
            </div>
            <appointment v-for="(a, i) in appointments" :key="i" :appointment="a"/>
        </div>

    </div>
</template>

<script>
import arrow from '../../../icons/arrow2.vue'
import appointment from './appointment.vue'
export default {
    components: {
        arrow, appointment
    },
    props: ['status'],
    data() {
        return {
            show: false,
            appointments: [],
            total: '-'
        }
    },
    mounted() {
        let form = new FormData()
        form.append('status', this.status)

        this.$http.post('/admin/appointments/get', form)
        .then(data => {
            let r = data.data
            if (r.success) {
                this.appointments = r.appointments
                this.total = r.total
            }
        })
    }
}
</script>

<style scoped>

    .table {
        background-color: white;
        margin-top: 30px;
        border-radius: 5px;
        overflow: hidden;
    }
    .head {
        padding: 20px;
        font-weight: 500;
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
        cursor: pointer;
        gap: 20px;
    }
    .no {
        padding: 4px 8px;
        background-color: var(--trans);
        color: var(--main);
        font-weight: 500;
        border-radius: 5px;
        font-size: 14px;
    }
    .a {
        margin-left: auto;
    }
    .arr {
        transition: .3s;
    }
    .rot {
        transform: rotate(90deg);
    }

    .main {
        border-top: 1px solid #ccc;
    }
    .label {
        display: grid;
        grid-template-columns: 20% 16% 16% 16% 16% 16%;
        padding: 0px 20px;
    }
    .text {
        padding: 10px 0px;
        font-weight: 500;
    }
</style>