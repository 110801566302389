<template>
    <div class="header" @scroll="scrolling()" :class="[bg ? 'bg' : '']">
        <router-link to="/">
            <div class="logo">
                <img src="../assets/logo.png" class="logoimg"/>
            </div>
        </router-link>
        <div class="menu">
            <router-link to="/">
                <div class="item">
                    Home
                </div>
            </router-link>
            <router-link to="/properties">
                <div class="item">
                    Properties
                </div>
            </router-link>
            
            <router-link to="/about">
                <div class="item">
                    About
                </div>
            </router-link>
            <!-- <div class="item">
                Contact
            </div> -->
            <router-link to="/dashboard">
                <div class="item" v-if="login">
                    Dashboard
                </div>
            </router-link>
            <div class="btn" v-if="login" @click="logout()">
                Logout
            </div>
            <router-link to="/login" v-else>
                <div class="btn">
                    Login / Signup
                </div>
            </router-link>
        </div>

        <div class="icon" @click="showmenu()">
            <menuIcon :size="'30px'"/>
        </div>

        <div class="pop" v-if="pop">
            <div class="dark" @click="close()"></div>
            <transition name="in">
                <div class="mobile_menu" v-if="menu">
                    <div class="close" @click="close()">
                        <close :size="'20px'"/>
                    </div>
                    <router-link to="/">
                        <div class="i first">Home</div>
                    </router-link>

                    <router-link to="/properties">
                        <div class="i">Properties</div>
                    </router-link>

                    <router-link to="/about">
                        <div class="i">About</div>
                    </router-link>

                    <router-link to="/dashboard" v-if="login">
                        <div class="i">Dashboard</div>
                    </router-link>
                    
                    <router-link to="/property_requests">
                        <div class="i">Property Requests</div>
                    </router-link>

                    <router-link to="/dashboard/agent/be" v-if="login">
                        <div class="i">Post Ad</div>
                    </router-link>


                    <div class="i" v-if="login" @click="logout()">
                        Logout
                    </div>
                    <router-link to="/login" v-else>
                        <div class="i">Login / Signup</div>
                    </router-link>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import menuIcon from '../icons/menu.vue'
import Localbase from 'localbase'
import close from '../icons/close.vue'
export default {
    components: {
        menuIcon, close
    },
    props: {
        no_bg: {
            default: false
        }
    },
    computed: {
        login() {
            return this.$root.$data.login
        }
    },
    data() {
        return {
            db: new Localbase('Bigglandlord'),
            bg: false,
            pop: false,
            menu: false,
        }
    },
    mounted() {
        if (this.no_bg) {
            this.bg = true
        }
        window.addEventListener('scroll', this.scrolling)
    },
    methods: {
        scrolling() {
            if (this.no_bg) { return }
            if (window.scrollY > 30) {
                this.bg = true
            } else {
                this.bg = false
            }
        },
        logout() {
            this.db.collection('auth').doc({ id: 1 }).delete()
            this.$root.$data.login = false
            
            this.$http.defaults.headers.common['Auth'] = ''
            localStorage.clear()
            this.$router.push('/login')
        },
        showmenu() {
            this.pop = true
            setTimeout(() => {
                this.menu = true
            }, 100);
        },
        close() {
            this.menu = false
            setTimeout(() => {
                this.pop = false
            }, 200);
        }
    }
}
</script>

<style scoped>
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        transition: .4s;
        position: relative;
    }
    .bg {
        background-color: black;
    }

    .logo {
        display: flex;
        align-items: center;
    }
    .logoimg {
        width: 150px;
    }
    .menu {
        display: flex;
        align-items: center;
    }
    .item {
        margin-right: 40px;
        font-weight: 500;
    }

    .btn {
        padding: 10px 30px;
        color: white;
        background-color: var(--main);
        border-radius: 5px;
        cursor: pointer;
        font-weight: 500;
    }
    .icon {
        fill: white;
        cursor: pointer;
        display: none;
    }

    .pop {
        position: fixed;
        top: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        display: none;
        color: white;
        fill: white;
    }
    .dark {
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
    }
    .close {
        width: fit-content;
        margin-left: auto;
        margin-right: 20px;
        margin-top: 20px;
        margin-bottom: 40px;
        cursor: pointer;
        /* opacity: .7; */
    }
    .mobile_menu {
        position: fixed;
        top: 0px;
        right: 0px;
        width: 250px;
        height: 100%;
        background-color: #191919;
        z-index: 3;
    }

    .in-enter-active {
        animation: move .3s;
    }
    .in-leave-active {
        animation: move .2s reverse;
    }

    @keyframes move {
        from {
            margin-right: -250px;
        }
        to {
            margin-right: 0px;
        }
    }
    .i {
        padding: 15px;
        border-top: 1px solid #3a3a3a;
        cursor: pointer;
        font-size: 14px;
        opacity: .7;
    }
    .first {
        border-top: none;
    }


    @media screen and (max-width: 800px) {
        .menu {
            display: none;
        }
        .icon {
            display: block;
        }
        .pop {
            display: block;
        }
    }
</style>