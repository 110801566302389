<template>
    <div class="box">
        <div class="title">Facilities</div>
        <div class="facilities">
            <div class="f" v-for="(facility, i) in f" :key="i">{{facility}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['facility'],
    mounted() {
        this.f = JSON.parse(this.facility)
    },
    data() {
        return {
            f: []
        }
    }
}
</script>

<style scoped>

    .box {
        margin-bottom: 30px;
        background-color: white;
        padding: 20px;
        border-radius: 5px;
    }
    .title {
        font-weight: 500;
        font-size: 20px;
    }

    .facilities {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-top: 10px;
    }
    .f {
        margin: 10px;
        padding: 10px 20px;
        border-radius: 5px;
        background-color: var(--trans);
        background-color: #edeef0;
        font-weight: 500;
        font-size: 14px;
    }
</style>