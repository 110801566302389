<template>
    <div class="new">
        <submit/>
    </div>
</template>

<script>
import submit from '../components/agent/submit.vue'
export default {
    components: {
        submit
    },
    created() {
        
        const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
            localStorage.removeItem('agent_page_reloaded')
            next()
            
            setTimeout(() => {
                this.$router.go()
            }, 1000);
        })

        this.$once('hook:destroyed', () => {
            unregisterRouterGuard()
        })
    },
    mounted () {
        window.scrollTo(0, 0)

        let r = localStorage.getItem('agent_page_reloaded')
        if (!r) {
            localStorage.setItem('agent_page_reloaded', true)
            this.$router.go()
        }
        
    },
}
</script>

<style scoped>

    .new {
        padding: 30px;
    }
</style>