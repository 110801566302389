<template>
    <div class="l">

        <div class="box" @click="open_up()">
            <div class="name">{{city.name}}</div>

            <div class="flex">
                <div class="lab">Client:</div>
                <div class="value">{{city.clients}}</div>
            </div>

            <div class="icon">
                <arrow :size="'15px'"/>
            </div>
        </div>
        <div class="content" v-if="open">
            <div class="menu">
                <div class="item" 
                    :class="[menu_c == 'lease' ? 'a' : '']" @click="set_menu('lease')">Lease</div>

                <div class="item" 
                    :class="[menu_c == 'let' ? 'a' : '']" @click="set_menu('let')">Let</div>

                <div class="item" 
                    :class="[menu_c == 'sale' ? 'a' : '']" @click="set_menu('sale')">Sale</div>

                <div class="item" 
                    :class="[menu_c == 'short' ? 'a' : '']" @click="set_menu('short')">Short</div>
            </div>
            <div class="grid">
                <div class="types">
                    <div class="type" v-for="m in menu" :key="m" @click="set_type(m)"
                        :class="[type_c == m ? 'type_a' : '']">{{m}}</div>
                </div>
                <div class="main">
                    <div class="opts">
                        <div class="opt" @click="show_add = true">Add Client</div>
                        <div class="opt" @click="show_msg = true">Message Clients</div>
                    </div>
                </div>
            </div>
        </div>
        
        <add :city="city.name" :category="menu_c" :type="type_c" v-if="show_add" 
            @close="show_add = false" @new_client="new_client($event)"/>
        
        <message :city="city.name" :category="menu_c" :type="type_c" v-if="show_msg"
            @close="show_msg = false"/>
    </div>
</template>

<script>
import arrow from '../../../icons/arrow_down.vue'
import add from './add.vue'
import message from './message.vue'

export default {

    components: {
        arrow,
        add, message
    },
    props: ['city'],
    data() {
        return {
            open: false,
            menu: [],
            menu_c: '',
            type_c: '',

            let: [],
            lease: [],
            sale: [],
            short: [],

            all: [],
            clients: [],

            fetching: false,

            show_add: false,
            show_msg: false
        }
    },
    mounted() {
        this.set_menu('lease')
    },
    methods: {
        
        open_up() {
            if (this.open) {
                this.open = false; return
            } 
            this.open = true
            // this.fetching = true

            // let form = new FormData()
            // form.append('city', this.city.name)

            // this.$http.post('/admin/client/get', form)
            // .then(data => {
            //     let r = data.data
            //     if (r.success) {
            //         this.all = r.clients
            //         this.fetching = false

            //         this.set_menu(this.menu_c)
            //     }
            // })
        },
        set_menu(to) {
            this.menu_c = to
            this.menu = this.city[to]
            this.type_c = this.menu[0]

            if (this.fetching) { return }

            this.let = []
            this.lease = []
            this.sale = []
            this.short = []

            let x
            for(x of this.all) {
                if (x.marked) { continue }
                if (x.category == 'let') { this.let.push(x) }
                if (x.category == 'lease') { this.lease.push(x) }
                if (x.category == 'sale') { this.sale.push(x) }
                if (x.category == 'short') { this.short.push(x) }
            }
            
            this.set_type(this.menu[0])
        },
        set_type(to) {
            this.type_c = to
            
            if (this.fetching) { return }

            this.clients = []

            let x
            for(x of this[this.menu_c]) {
                if (x.type == to) {
                    this.clients.push(x)
                }
            }
        },
        new_client(e) {

            // this[this.menu_c].unshift(e)
            
            this.clients.unshift(e)
            this.show_add = false
        }
    },
    notifications: {
        marked: {
            'type': 'success',
            'title': 'Marked',
            'message': 'This client has been marked'
        },
        removed: {
            'type': 'success',
            'title': 'Removed',
            'message': ''
        }
    }
}
</script>

<style scoped>

    .l {
        background-color: white;
        overflow: hidden;
        border-radius: 5px;
        margin-bottom: 20px;
    }
    .box {
        display: flex;
        padding: 20px;
        align-items: center;
        cursor: pointer;
        gap: 20px;
    }
    .name {
        font-weight: 500;
        font-size: 18px;
        margin-right: 20px;
    }

    .flex {
        display: flex;
        align-items: center;
        margin-right: 10px;
        font-size: 14px;
        margin-left: auto;
    }
    .lab {
        font-weight: 500;
        opacity: .5;
        margin-right: 5px;
        position: relative;
        /* z-index: 1; */
    }
    .value {
        font-weight: 500;
    }
    .icon {
        fill: #686868;
    }

    .content {
        border-top: 1px solid #ddd;
        padding: 20px;
    }
    .menu {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }
    .item {
        padding: 8px 30px;
        font-weight: 500;
        font-size: 14px;
        background-color: var(--trans);
        color: var(--main);
        border-radius: 5px;
        cursor: pointer;
    }
    .a {
        background-color: var(--main);
        color: white;
    }

    
    .grid {
        display: grid;
        grid-template-columns: max-content auto;
        margin-top: 20px;
    }
    .types {
        border-right: 1px solid #ddd;
        padding-right: 20px;
    }
    .type {
        padding: 8px 20px;
        font-weight: 500;
        font-size: 14px;
        /* background-color: var(--trans); */
        border: 1px solid #ddd;
        color: var(--main);
        border-radius: 5px;
        cursor: pointer;
        margin-bottom: 20px;
    }
    .type_a {
        border-color: white;
        background-color: var(--main);
        color: white;
    }

    .main {
        padding: 20px;
        padding-top: 0px;
    }
    .opts {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
    }
    .opt {
        padding: 8px 20px;
        border-radius: 5px;
        cursor: pointer;
        background-color: var(--main);
        color: white;
        font-weight: 500;
        font-size: 14px;
    }
    
    .clients {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 20px;
    }
    .client {
        border: 1px solid #ddd;
        padding: 15px;
        border-radius: 5px;
    }
    .c_label {
        font-size: 12px;
        color: grey;
    }
    .c_value {
        font-weight: 500;
        margin-bottom: 15px;
        font-size: 14px;
    }
    
    .clicks {
        display: flex;
        gap: 20px;
    }
    .del, .click {
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: var(--main);
        cursor: pointer;
    }


    @media screen and (max-width: 700px) {
        .grid {
            grid-template-columns: auto;
        }
        .types {
            border-right: none;
            padding-right: 0px;

            border-bottom: 1px solid #ddd;
            padding-bottom: 20px;

            border-top: 1px solid #ddd;
            padding-top: 20px;

            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            margin-bottom: 30px;
        }
        .type {
            margin: 0px;
        }
        .main {
            padding: 0px;
        }
        .opts {
            justify-content: flex-start;
        }
    }
</style>