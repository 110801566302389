<template>
    <div class="filter">
        <div class="dark" @click="$emit('close')"></div>
        <div class="box">
            <div class="title">Filter properties</div>
            
            <div class="input">
                <select v-model="category">
                    <option value="" disabled selected>Property Category</option>
                    <option value="Let">To Let</option>
                    <option value="Short Let">Short Let</option>
                    <option value="Lease">Lease</option>
                    <option value="Sale">For Sale</option>
                </select>
            </div>
            <div class="location" @click="pop = true">
                {{place}}
            </div>
            <div class="btn" @click="sort()">Filter</div>
            
            <div class="popup" v-if="pop">
                <div class="dark2" @click="pop = false"></div>
                <location @close="pop = false" @save="set_location($event)"/>
            </div>
        </div>
    </div>
</template>

<script>
import location from '../location.vue'

export default {
    components: {
        location
    },
    data() {
        return {
            category: '',
            place: 'Set Location',
            pop: false,
            location: {}
        }
    },
    methods: {
        
        set_location(e) {
            this.location = e.l
            this.place = `${e.l.city + ' ' + e.l.state + ', ' + e.l.country}`
            this.pop = false
        },
        sort() {
            this.$emit('sort', {
                'category': this.category,
                'location': this.location
            })
        }
    }
}
</script>

<style scoped>
    .filter {
        display: flex;
        align-items: center;
        justify-content: center;

        position: fixed;
        z-index: 5;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }
    .dark {
        background-color: rgba(0, 0, 0, 0.6);
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }

    .box {
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        width: 300px;

        position: relative;
        z-index: 3;
    }
    .title {
        font-weight: 500;
        margin-bottom: 20px;
    }
    
    .location {
        color: black;
        border: 1px solid #ccc;
        padding: 13px;
        border-radius: 5px;
        cursor: pointer;
        text-align: left;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .input {
        margin-bottom: 20px;
    }
    input, select {
        width: 100%;
        padding: 13px 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
        box-sizing: border-box;

    }
    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 8px 20px;
        border-radius: 5px;
        font-weight: 500;
        margin-top: 20px;
        text-align: center;
    }

    
      
    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        box-sizing: border-box;
        z-index: 5;
    }
    .dark2 {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 2;
    }
</style>