<template>
    <div class="appoint">
        <div class="title"> Appointments </div>
        <div class="menu">
            <div class="item" :class="[c == 0 ? 'a' : '']" @click="setTo(0)">Current</div>
            <div class="item" :class="[c == 1 ? 'a' : '']" @click="setTo(1)">Booked</div>
            <div class="item" :class="[c == 4 ? 'a' : '']" @click="setTo(4)">Upcoming</div>
            <div class="item" :class="[c == 2 ? 'a' : '']" @click="setTo(2)">Successful</div>
            <div class="item" :class="[c == 3 ? 'a' : '']" @click="setTo(3)">Undecided</div>
        </div>

        <!-- <div v-if="is_partner">
            <div class="title">Partner Appointment</div>
            
            <div class="spin" v-if="load"> </div>
            <partner v-else :p="partner"/>
        </div> -->

        <div v-if="c == 0">
            <div class="spin" v-if="loading"> </div>
            <div v-if="current_appointment > 0">
                <current :property="current[0]" v-if="!loading"/>
            </div>
            <div class="empty" v-if="current.length == 0 && !loading">
                You don't have any current appointment at the moment
            </div>
        </div>
        
        <div v-if="c == 1">
            <div class="spin" v-if="loading"> </div>
            <div class="grid" v-if="mine.length > 0">

                <div class="btn">Inspect Now</div>

                <property v-for="property in mine" :key="property.prop_id"
                :property="property"/>
            </div>
            <div class="empty" v-if="mine.length == 0 && !loading">
                You don't have any appointment at the moment
            </div>
        </div>

        <div class="success" v-if="c == 2">

            <div class="spin" v-if="loading"></div>
            <div class="grid" v-if="successful.length > 0">
                <successful v-for="property in successful" :key="property.prop_id" :property="property"/>
            </div>
            <div class="empty" v-if="successful.length == 0 && !loading">
                You don't have any successful appointment yet
            </div>
        </div>

        <div v-if="c == 3">
            <div class="spin" v-if="loading"></div>
            <div class="grid" v-if="undecided.length > 0">
                <undecided v-for="property in undecided" :key="property.prop_id" :property="property"/>
            </div>
            <div class="empty" v-if="undecided.length == 0 && !loading">
                You don't have any undecided appointment 
            </div>
        </div>
    </div>
</template>

<script>
import current from '../components/appointment/current.vue'
import property from '../components/appointment/property.vue'
import successful from '../components/appointment/successful.vue'
import undecided from '../components/appointment/undecided.vue'

export default {
    components: {
        current,
        property, 
        successful, undecided
    },
    data() {
        return {
            mine: [],
            current: [],
            successful: [],
            undecided: [],
            loading: true,
            load: true,

            a_load: true,

            c: 0
        }
    },
    computed: {
        current_appointment() {
            return this.$root.$data.current_appointment
        },
        is_partner() {
            return this.$root.$data.partner == '1'
        }
    },
    watch: {
        is_partner(val) {
            if (val == true) {
                this.partner_appt()
            }
        }
    },
    mounted() {
        window.scrollTo(0, 0)
        this.$http.get('/appointments')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.mine = r.mine
                this.current = r.current
                this.successful = r.successful
                this.undecided = r.undecided
                this.loading = false

                if (this.current.length == 0 && this.mine.length > 0) {
                    this.setTo(1)
                }
            }
        })

        if (this.is_partner) {
            // this.partner_appt()
        }

    },
    methods: {
        partner_appt() {
            this.load = true

            let form = new FormData()
            form.append('code', '')

            this.$http.post('/partner/appointment', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.partner = r.property
                    this.load = false
                }
            })
        },
        setTo(what) {
            this.c = what
        }
    }
}
</script>

<style scoped>

    .appoint {
        padding: 20px;
    }
    .title {
        font-weight: 500;
        font-size: 20px;
    }
    
    .menu {
        display: grid;
        grid-template-columns: 20% 20% 20% 20% 20%;
        background-color: white;
        /* border-radius: 5px; */
        overflow: hidden;
        margin: 0px -20px;
        margin-top: 20px;

        position: sticky;
        top: 0px;
        z-index: 3;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
    .item {
        text-align: center;
        font-weight: 500;
        padding: 15px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        font-size: 12px;
    }
    .a {
        border-bottom-color: var(--main);
        color: var(--main);
    }



    .spin {
        margin: 70px auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: var(--main);
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    .grid {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-gap: 30px;
    }


    .btn {
        text-align: center;
        padding: 10px;
        font-size: 14px;
        font-weight: 500;
        border-radius: 5px;
        cursor: pointer;
        color: white;
        background-color: var(--main);
    }

    .a_emp {
        margin-bottom: 20px;
    }
    .empty {
        background-color: white;
        padding: 20px;
        margin-top: 30px;
        border-radius: 5px;
    }

    .success {
        margin-top: 20px;
    }
</style>