<template>
    <div class="alert">
        <div class="title">Alerts</div>

        <div class="loading" v-if="loading">
            <div class="spin"></div>
        </div>
        <div class="grid" v-else>
            <alert v-for="(a, i) in alerts" :key="i" :a="a"/>
        </div>
    </div>
</template>

<script>
import alert from '../components/alert.vue'
export default {
    components: {
        alert
    },
    data() {
        return {
            loading: true,
            alerts: []
        }
    },
    mounted() {
        this.$http.get('/property/alerts')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.alerts = r.alerts
                this.loading = false
            }
        })
    }
}
</script>

<style scoped>

    .alert {
        padding: 30px;
    }
    .title {
        font-size: 20px;
        font-weight: 500;
    }
    .spin {
        margin: 70px auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: var(--main);
        animation: rotate 1s infinite linear;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-gap: 30px;
        margin-top: 30px;
    }
</style>