<template>
    <div class="property">
        <headerLayout class="h" :no_bg="true"/>
        
        <div v-if="loading" class="loading">
            <div class="spin"></div>
        </div>
        <div v-else>
            <div class="">
                <div class=""></div>
                <div class="swiper">
                    
                    <div class="btn" @click="popup = true" v-if="!popup">
                        <full :size="'15px'"/>
                        Full Screen
                    </div>
                    <div class="fixed_btn" @click="popup = false" v-else>
                        <full :size="'15px'"/>
                        Exit
                    </div>

                    <swiper class="swiper_conn" :class="[popup ? 'popup' : '']" ref="swiper" 
                        :options="options" >
                        
                        
                        <swiper-slide class="swiper_slide_conn" v-if="property.video != ''">
                            <!-- <youtube :id="property.video"/> -->
                            
                            <iframe :src="'https://iframe.mediadelivery.net/embed/159521/' + property.video + '?autoplay=false'" 
                                loading="lazy" style="border: none; height: 100%;  width: 100%;" 
                                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" 
                                allowfullscreen="true">
                            </iframe>
                        </swiper-slide>

                        <swiper-slide class="swiper_slide_conn" v-for="image in images" :key="image.k">
                            <div class="image" >
                                <img :src="url + image" alt="" class="img">
                                <div class="watermark">
                                    <img src="../assets/watermark.png" class="water_img">
                                </div>
                            </div>
                        </swiper-slide>
                        <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
                        <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
                    </swiper>
                </div>
            </div>
            

            <div class="main">
                <div class="head">
                    <div>
                        <div class="title">{{property.title}}</div>
                        <div class="location">
                            <location :size="'12px'" class="icon"/>
                            {{property.city}} {{property.state}}
                        </div>
                    </div>
                    <div>
                        <div class="price">
                            <vue-numeric read-only currency="₦" separator="," v-model="price.no">
                            </vue-numeric>
                            <span v-if="property.category != 'Sale'"> {{price.duration}} </span>
                        </div>
                        <div class="total">
                            Total Fee:
                            
                            <vue-numeric read-only currency="₦" separator="," v-model="total_package">
                            </vue-numeric>
                        </div>
                    </div>
                </div>
                <div class="grid">
                    <div>
                        <avaliable v-if="property.me" 
                            :prop_id="property.prop_id" :updated="property.updated" 
                            :avaliable="property.avaliable"/>
                        <special v-if="property.me" :property="property"/>

                        <detail :property="property" @total="total_package = $event"/>
                        <facility :facility="property.facility"/>
                        <description :description="property.description"/>
                        <tenant v-if="property.category != 'Sale'" :livelihood="property.livelihood" :religion="property.religion"
                                :status="property.tenant_status" :tribe="property.tribe"/>

                        <div class="m_c" v-if="property.avaliable == '1'">
                            <appointment v-if="appointment" :appointment="appointment" 
                                :category="property.category" @canceled="canceled()"/>
                            <contact :id="property.prop_id" v-else
                                @new_appointment="new_appointment($event)"/>
                        </div>
                        <div v-else>
                            <not_available/>
                        </div>
                        
                        <!-- <review/> -->
                        <div class="reviews">
                        <div class="t">Recent Reviews</div>

                            <comment v-for="rating in ratings" :key="rating.id" :comment="rating"/>
                            <div class="empty" v-if="ratings.length == 0 && !loading">
                                This property has no review
                            </div>
                        </div>
                    </div>
                    <div class="c" v-if="property.avaliable == '1'">
                        <appointment v-if="appointment" :appointment="appointment" 
                            :category="property.category" @canceled="canceled()"/>
                        <contact :id="property.prop_id" :user="property.user" v-else
                            @new_appointment="new_appointment($event)"/>
                    </div>
                    <div v-else>
                        <not_available/>
                    </div>
                </div>
            </div>
        </div>
        <register/>
        <footer-layout/>
    </div>
</template>

<script>
import headerLayout from '../components/header.vue'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

import full from '../icons/full.vue';
import youtube from '../components/youtube.vue';
import watermark from '../components/image.vue'

import location from '../icons/location.vue'

import avaliable from '../components/property/avaliable.vue'
import special from '../components/property/special.vue';
import detail from '../components/property/details.vue'
import facility from '../components/property/facility.vue'
import description from '../components/property/description.vue'
import tenant from '../components/property/tenant.vue'
import appointment from '../components/property/appointment.vue'
import contact from '../components/property/contact.vue'
import not_available from '../components/property/not_available.vue';
// import review from '../components/property/review.vue'
import comment from '../components/property/comment.vue'

import register from '../components/register.vue'
import footerLayout from '../components/footer.vue'
export default {

    components: {
        headerLayout,
        
        Swiper, SwiperSlide, full, youtube, watermark,
        location,
        avaliable, special, detail, facility, appointment, contact, not_available, description, tenant, comment,
        register, footerLayout
    },
    computed: {
        url() {
            return this.$root.$data.imgurl
        }
    },
    mounted() {
        window.scrollTo(0, 0)


        let form = new FormData()
        form.append('id', this.$route.params.id)
        form.append('user_id', this.$root.$data.user_id)

        this.$http.post('/property/details', form)
        .then(data => {
            let r = data.data
            if (r.success) {
                this.appointment = r.appointment
                this.property = r.property
                this.price = JSON.parse(this.property.price)
                this.images = JSON.parse(this.property.images)
                this.loading = false

                let vm = this
                setTimeout(() => {
                    vm.$refs.flickity.on('fullscreenChange', function(isFullscreen ) {
                        vm.full = isFullscreen
                    })
                }, 1000);
            }
        })

        this.$http.post('/property/rating', form)
        .then(data => {
            let v = data.data
            if (v.success) {
                this.ratings = v.ratings
            }
        })
    },
    data() {
        return {
            popup: false,
            options: {
                slidesPerView: 1,
                // loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                    hideOnClick: true
                },
                // autoHeight: true
            },
            full: false,
            images: [],
            property: {},
            ratings: [],
            appointment: false,
            price: {},
            total_package: '',
            loading: true
        }
    },
    methods: {
        canceled() {
            console.log('cacel');
            this.appointment = false
        },
        new_appointment(event) {
            console.log('new appoint');
            this.appointment = event
        },
        fullscreen() {
            // console.log(this.$refs.flickity);
            // this.$refs.flickity.viewFullscreen()
        }
    }
}
</script>

<style scoped>

    .property {
        background-color: #edeef0;
        position: relative;
    }
    .h {
        position: fixed;
        width: 100%;
        box-sizing: border-box;
        top: 0px;
        left: 0px;
        z-index: 5;
        color: white;
    }
    
    .loading {
        height: 100vh;
        display: flex;
        align-items: center;
    }
    .spin {
        margin: 0px auto;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 3px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }


    .main {
        max-width: 1000px;
        margin: 0 auto;
        padding: 50px 20px;
    }
    

    
    .swiper {
        position: relative;
        margin-top: 79px;
    }
    .swiper_conn {
        height: 50vh;
    }

    .btn {
        background-color: white;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

        position: absolute;
        z-index: 2;
        top: 20px;
        right: 20px;
        border-radius: 5px;
        padding: 7px 15px;
        cursor: pointer;
        
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
        font-weight: 500;
    }
    .fixed_btn {
        background-color: white;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

        position: fixed;
        z-index: 7;
        top: 20px;
        right: 20px;
        border-radius: 5px;
        padding: 7px 15px;
        cursor: pointer;
        
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
        font-weight: 500;

    }
    .image {
        height: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        
        background-color: var(--trans);
    }
    .img {
        /* height: 100%; */
        width: 100%;
        /* max-height: 100%; */
        pointer-events: none;
    }
    .watermark {
        position: absolute;
        top: 0px;
        left: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0);
        z-index: 5;
    }
    .water_img {
        width: 50%;
    }


    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        background-color: white;
        padding: 20px;
        gap: 20px;
        border-radius: 5px;
        margin-bottom: 30px;
    }
    .title {
        font-size: 25px;
        font-weight: 600;
    }
    .location {
        color: gray;
        /* font-size: 14px; */
        display: flex;
        align-items: center;
        margin-top: 5px;
        fill: #555;
    }
    .icon {
        margin-right: 5px;
    }
    .price {
        font-size: 20px;
        font-weight: 700;
    }
    .total {
        font-size: 14px;
        margin-top: 5px;
        color: gray;
        fill: #555;
    }


    .grid {
        display: grid;
        grid-template-columns: auto 290px;
        grid-gap: 30px;
    }

    .reviews {
        background-color: white;
        border-radius: 5px;
        overflow: hidden;
    }
    .t {
        /* border-bottom: 1px solid #ddd; */
        font-weight: 500;
        font-size: 20px;
        padding: 20px;
    }
    .empty {
        border-top: 1px solid #ddd;
        padding: 20px;
        font-size: 14px;
    }
    .m_c {
        display: none;
    }

    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        z-index: 5;
        background-color: rgba(0, 0, 0, 1);
    }
    .popup .image {
        background-color: unset;
    }
    .popup .img {
        width: unset;
        height: unset;

        max-height: 100%;
        max-width: 100%;
    }

    @media screen and (max-width: 800px) {
        .swiper {
            margin-top: 74px;
        }
            
        .img {
            width: unset;
            height: 100%;
        }
        .popup .img {
            width: unset;
            height: unset;

            max-height: 100%;
            max-width: 100%;
        }

        .c {
            display: none;
        }
        .m_c {
            display: block;
        }
        
        .grid {
            grid-template-columns: auto;
        }
    }
</style>