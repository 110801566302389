<template>
    <div class="details">
        <div class="title"> Details</div>
        <div class="grid">
            <div>
                <div class="flex">
                    <div class="label">Type</div>
                    <div class="value">{{property.type}}</div>
                </div>
                <div class="flex">
                    <div class="label">Kind</div>
                    <div class="value">{{property.kind}}</div>
                </div>
                <div class="flex">
                    <div class="label">Category</div>
                    <div class="value">For {{property.category}}</div>
                </div>
                <div class="flex">
                    <div class="label">Condition</div>
                    <div class="value">{{property.prop_condition}}</div>
                </div>

                <div>
                    <div class="flex" v-if="property.category != 'Sale'">
                        <div class="label">Rooms</div>
                        <div class="value">{{property.rooms}}</div>
                    </div>
                    <div class="flex" v-if="property.category != 'Sale'">
                        <div class="label">Bedrooms</div>
                        <div class="value">{{property.bedrooms}}</div>
                    </div>
                    <div class="flex" v-if="property.category != 'Sale'">
                        <div class="label">Toilets</div>
                        <div class="value">{{property.toilet}}</div>
                    </div>
                    <div class="flex" v-if="property.category != 'Sale'">
                        <div class="label">Pets Allowed</div>
                        <div class="value">{{property.pets}}</div>
                    </div>
                    <div class="flex" v-if="property.category != 'Sale'">
                        <div class="label">Parties Allowed</div>
                        <div class="value">{{property.parties}}</div>
                    </div>
                    <div class="flex" v-if="property.category != 'Sale'">
                        <div class="label">Smoking Allowed</div>
                        <div class="value">{{property.smoking}}</div>
                    </div>
                    <div class="flex" v-if="property.category != 'Sale'">
                        <div class="label">Landlord Presence</div>
                        <div class="value">{{property.landlord}}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Pay and pack in</div>
                        <div class="value">{{property.pack_in}}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Parking Space</div>
                        <div class="value">{{property.parking}}</div>
                    </div>
                </div>
            </div>
            <div>

                <!-- <div class="flex">
                    <div class="label">Price</div>
                    <div class="value">N{{price.no}} <span v-if="property.category != 'Sale'"> {{price.duration}} </span></div>
                </div>
                <div class="flex">
                    <div class="label">Type</div>
                    <div class="value">{{property.type}}</div>
                </div>
                <div class="flex">
                    <div class="label">Location</div>
                    <div class="value">{{property.city}} {{property.state}}</div>
                </div> -->

                <div>
                    <div class="flex">
                        <div class="label">Posted</div>
                        <div class="value">
                            <timeago :date="property.date"/>
                        </div>
                    </div>
                    <div class="flex" v-if="property.category != 'Sale'">
                        <div class="label">Total Appartments</div>
                        <div class="value">{{property.apartments}}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Size</div>
                        <div class="value">{{size.no}} {{size.measurement}}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Price</div>
                        <div class="value">{{price.no}} {{price.duration}}</div>
                    </div>
                    <div class="flex" v-if="property.category != 'Sale'">
                        <div class="label">Minimum Rent</div>
                        <div class="value">{{rent.no}} {{rent.duration}}</div>
                    </div>
                    <div class="flex" v-if="caution.yes">
                        <div class="label">Caution Fee</div>
                        <div class="value">{{caution.percent}}</div>
                    </div>
                    <div class="flex" v-if="agency.yes">
                        <div class="label">Agency Fee</div>
                        <div class="value">{{agency.percent}}</div>
                    </div>
                    <div class="flex" v-if="legal.yes">
                        <div class="label">Legal Fee</div>
                        <div class="value">{{legal.percent}}</div>
                    </div>
                    <div class="flex" v-if="service.yes">
                        <div class="label">Service Charge</div>
                        <div class="value">{{ service.no }}</div>
                    </div>

                    <div class="flex">
                        <div class="label">Total Package</div>
                        <div class="value">
                            <vue-numeric read-only currency="₦" separator="," v-model="total_package">
                            </vue-numeric>
                        </div>
                    </div>


                    <div class="flex" v-if="property.category != 'Sale'">
                        <div class="label">Property Flooring</div>
                        <div class="value">{{property.flooring}}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Property Ground</div>
                        <div class="value">{{property.ground}} floor</div>
                    </div>
                    <div class="flex" v-if="property.category != 'Sale'">
                        <div class="label">Painting</div>
                        <div class="value">{{property.painting}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import timeago from '../time.vue'
export default {
    components: {
        timeago
    },
    props: ['property'],
    mounted() {
        this.rent = JSON.parse(this.property.rent)
        this.price = JSON.parse(this.property.price)
        this.size = JSON.parse(this.property.size)
        this.caution = JSON.parse(this.property.caution)
        this.agency = JSON.parse(this.property.agency)
        this.legal = JSON.parse(this.property.legal)
        this.service = JSON.parse(this.property.service)

        if (this.property.category == 'Sale') {
            this.total_package = parseInt(this.price.no) + ( parseInt(this.price.no) * 0.05 )
        } else {
            this.total_package = parseInt(this.price.no) * parseInt(this.rent.no)
            if (this.agency.yes) {
                this.total_package += parseInt(this.agency.percent)
            }
            if (this.legal.yes) {
                this.total_package += parseInt(this.legal.percent)
            }
            if (this.caution.yes) {
                this.total_package += parseInt(this.caution.percent)
            }
            if (this.service.yes) {
                this.total_package += parseInt(this.service.no)
            }
        }

        this.$emit('total', this.total_package)
    },
    data() {
        return {
            rent: {},
            price: {},
            size: {},
            caution: {},
            agency: {},
            legal: {},
            service: {},

            total_package: ''
        }
    }
}
</script>

<style scoped>
    .details {
        margin-bottom: 30px;
        background-color: white;
        padding: 20px;
        border-radius: 5px;
    }
    .title {
        font-weight: 500;
        font-size: 20px;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-gap: 20px;
        margin-top: 10px;
    }
    .flex {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        gap: 10px;
        border-bottom: 1px solid #ddd;
    }
    .flex:nth-child(even) {
        /* background-color: var(--trans); */
        /* border-radius: 5px; */
    }
    .label {
        font-size: 14px;
        color: gray;
    }
    .value {
        font-size: 14px;
        font-weight: 500;
    }
</style>