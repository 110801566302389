<template>
    <div class="popup">
        <div class="dark" @click="$emit('close')"></div>
        <div class="box">
            <div class="title">Connect Whatsapp</div>
            
            <div class="loading" v-if="loading">
                <div class="spin"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: true
        }
    },
    mounted() {
        this.$http.post('/admin/whatsapp/connect')
    }
}
</script>

<style scoped>
    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .box {
        width: 500px;
        background-color: white;
        border-radius: 5px;
        padding: 20px;
        z-index: 3;
    }
    .title {
        font-weight: 500;
        margin-bottom: 20px;
    }

    

    .spin {
        margin: 70px auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: var(--main);
        border-left-color: var(--main);
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>