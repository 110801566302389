<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">Add Landmark</div>

            <div class="input">
                <div class="label">Name</div>
                <input type="text" placeholder="Enter landmark name" v-model="name">
            </div>
            <div class="input">
                <div class="label">Longitude</div>
                <input type="text" placeholder="Enter landmark Longitude" v-model="longitude">
            </div>
            <div class="input">
                <div class="label">Latitude</div>
                <input type="text" placeholder="Enter landmark latitude" v-model="latitude">
            </div>

            <loader :height="'38px'" v-if="loading"/>
            <div class="btn" v-else @click="add()">Add</div>
        </div>
    </div>
</template>

<script>
import loader from '../../../loader.vue'

export default {
    components: {
        loader
    },
    props: ['city'],
    data() {
        return {
            name: '',
            longitude: '',
            latitude: '',

            loading: false
        }
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },
        add() {
            if (this.name == '' || this.longitude == '' || this.latitude == '') {
                this.empty(); return
            }

            this.loading = true

            let form = new FormData()
            form.append('country', this.city.country)
            form.append('state', this.city.state)
            form.append('city', this.city.name)

            form.append('name', this.name)
            form.append('longitude', this.longitude)
            form.append('latitude', this.latitude)

            this.$http.post('/location/city/landmark/add', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.$emit('new_landmark', {
                        'country': this.city.country,
                        'state': this.city.state,
                        'city': this.city.name,

                        'name': this.name,
                        'longitude': this.longitude,
                        'latitude': this.latitude,
                        
                    })

                    this.success()
                }
                this.loading = false
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Make sure all field are filled'
        },
        success: {
            type: 'success',
            title: 'Success',
            message: 'Landmark added'
        }
    }
}
</script>

<style scoped>


    .popup {
        position: fixed;
        z-index: 1;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        box-sizing: border-box;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 2;
    }
    .box {
        background-color: white;
        border-radius: 5px;
        padding: 20px;
        position: relative;
        z-index: 3;
        width: 300px;
        padding: 20px;
    }
    .title {
        font-weight: 600;
        margin-bottom: 30px;
    }
    
    .input {
        margin-bottom: 30px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -10px;
        left: 10px;
        font-weight: 500;
        font-size: 14px;
        padding: 0px 5px;
        background-color: white;
        animation: .2s up;
    }
    input {
        width: 100%;
        padding: 15px;
        border-radius: 5px;
        border: 1px solid #ddd;
        box-sizing: border-box;
        outline: none;
    }
    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 10px 30px;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
    }
</style>