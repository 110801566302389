<template>
    <div class="menu">
        <router-link to="/admin/dashboard">
            <div class="item" :class="[name == 'dashboard' ? 'a' : '']">
                Dashboard
            </div>
        </router-link>
        <router-link to="/admin/banners">
            <div class="item" :class="[name == 'banners' ? 'a' : '']">
                Banners
            </div>
        </router-link>
        <router-link to="/admin/location">
            <div class="item" :class="[name == 'location' ? 'a' : '']">
                Locations
            </div>
        </router-link>
        <router-link to="/admin/properties">
            <div class="item" :class="[name == 'properties' ? 'a' : '']">
                Properties
            </div>
        </router-link>
        <router-link to="/admin/properties/video">
            <div class="item" :class="[name == 'properties_video' ? 'a' : '']">
                Properties video
            </div>
        </router-link>
        <router-link to="/admin/property">
            <div class="item" :class="[name == 'property' ? 'a' : '']">
                Property
            </div>
        </router-link>
        <router-link to="/admin/agent">
            <div class="item" :class="[name == 'agent' ? 'a' : '']">
                Agents
            </div>
        </router-link>
        <router-link to="/admin/users">
            <div class="item" :class="[name == 'users' ? 'a' : '']">
                Users
            </div>
        </router-link>
        <router-link to="/admin/appointments">
            <div class="item" :class="[name == 'appointments' ? 'a' : '']">
                Appointments
            </div>
        </router-link>

        
        <router-link to="/admin/message/groups">
            <div class="item" :class="[name == 'admin_message_groups' ? 'a' : '']">
                Message Groups
            </div>
        </router-link>

        <router-link to="/admin/message/agent">
            <div class="item" :class="[name == 'admin_message_agent' ? 'a' : '']">
                Message Agents
            </div>
        </router-link>

        <router-link to="/admin/message/client">
            <div class="item" :class="[name == 'admin_message_client' ? 'a' : '']">
                Message Clients
            </div>
        </router-link>

        <router-link to="/admin/clients">
            <div class="item" :class="[name == 'admin_clients' ? 'a' : '']">
                Marked Clients
            </div>
        </router-link>

        <router-link to="/admin/clients/unmarked">
            <div class="item" :class="[name == 'admin_clients_unmarked' ? 'a' : '']">
                Unmarked Clients
            </div>
        </router-link>

        <router-link to="/admin/whatsapp">
            <div class="item" :class="[name == 'admin_whatsapp' ? 'a' : '']">
                Whatsapp API
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    computed: {
        name() {
            return this.$route.name
        }
    }
}
</script>

<style scoped>
    .menu {
        /* padding: 20px; */
        width: 300px;
        /* border-right: 3px solid var(--main); */
        height: 100%;
        overflow: auto;
    }
    .item {
        /* margin: 20px; */
        padding: 20px;
        /* background-color: #edeef0; */
        /* color: var(--main); */
        /* border-radius: 10px; */
        cursor: pointer;
        font-weight: 500;
        /* border-bottom: 1px solid #ccc; */
        transition: .3s;
    }
    .item:hover {
        background-color: #edeef0;
    }
    .a {
        background-color: #555;
        background: linear-gradient(to right, royalblue, var(--main));
        color: white;
    }
    .a:hover {
        background-color: #555;
        color: white;
    }
</style>