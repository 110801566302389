<template>
    <div>
        <svg :width="size" :height="size" id="svg8" version="1.1" viewBox="0 0 16.933333 16.933334" 
        xmlns="http://www.w3.org/2000/svg" 
        xmlns:svg="http://www.w3.org/2000/svg">
        <defs/>
        <g>
        <path :fill="color" d="m 32,0 c 0,0 -0.309849,0.00225982 -0.523438,0.1484375 L 0.48046875,19.148438 
        C 0.18361408,19.328952 0.00170453,19.650622 0,19.998047 V 62.994141 C -0.0031883,63.54715 
        0.44307993,63.9978 0.99609375,64 H 62.980469 c 0.55607,0.0021 1.007115,-0.449796 1.003906,-1.005859 
        C 63.962832,48.660498 64,34.324772 64,19.998047 63.998401,19.650621 63.81639,19.328948 
        63.519531,19.148438 L 32.525391,0.1484375 C 32.333047,0.02717615 32.142957,8.0942365e-4 32,0 
        Z m 0,9.1757812 29.984375,18.3730468 v 34.449219 h -1.986328 v -8.001953 c 0,-1.644953 
        -1.351134,-2.99609 -2.996094,-2.996094 h -1.005859 v -1.003906 c 0.0053,-1.49811 -1.170762,-3.04401 
        -3.125,-2.998047 l 4.693359,-7.050781 c 1.37929,-0.267818 2.433594,-1.498588 2.433594,-2.951172 C 
        59.998047,35.351134 58.646913,34 57.001953,34 c -0.184743,0 -0.366956,0.01621 -0.542969,0.04883 l 
        -6.757812,-6.755859 c 0.524916,-1.114483 0.331084,-2.497073 -0.583984,-3.41211 -1.162955,-1.162944 
        -3.073376,-1.162944 -4.236329,0 l -1.419921,1.414063 c -1.778288,-0.260757 -3.319991,0.480148 
        -4.242188,1.412109 -0.507383,-0.448432 -1.074886,-0.318792 -1.410156,0 -0.393023,0.390939 
        -0.393023,1.027031 0,1.417969 0.472271,0.471964 0.934595,0.94241 1.40625,1.414062 -1.15906,1.163338 
        -1.157482,3.076634 0.0039,4.238282 1.162949,1.162945 3.081191,1.162942 4.244141,0 l 1.417968,1.417968 
        c 0.389817,0.386528 1.018394,0.386528 1.408203,0 0.393031,-0.390938 0.393031,-1.02703 0,-1.417968 
        1.151703,-1.151687 1.623336,-2.732287 1.41211,-4.236328 l 0.71289,-0.712891 6.269532,6.271484 
        c -0.427797,0.518037 -0.6875,1.179308 -0.6875,1.896485 4e-6,1.070421 0.576273,2.020051 1.43164,2.552734 
        l -4.966796,7.449219 H 49 c -1.644658,0 -3.005859,1.353393 -3.005859,2.998047 V 51 h -4.171875 
        c 0.228661,-0.62188 0.297026,-1.36066 0.164062,-2.164062 v -0.002 -0.002 -0.002 -0.002 C 
        41.558693,46.375291 40.526926,43.277026 38.767578,40.695312 37.547792,38.905377 35.923036,37.340186 
        33.904297,36.548828 35.186349,35.446189 36,33.81219 36,31.998047 c 0,-3.30176 -2.698234,-6.001953 
        -6,-6.001953 -3.301767,0 -6.001953,2.700193 -6.001953,6.001953 0,1.791877 0.795842,3.407867 
        2.050781,4.509765 -1.439117,0.507105 -2.709353,1.37122 -3.703125,2.488282 H 8.9980469 
        c -0.6846074,9.98e-4 -1.1658476,0.674152 -0.9453125,1.322265 L 11.615234,51 H 6.9980469 
        C 5.353083,51 4.0019516,52.351134 4.0019531,53.996094 v 8.001953 H 2 V 27.558594 Z M 42.914062,27.25 
        c 0.777066,0.04298 1.501711,0.378249 1.966797,0.875 1.166756,1.166749 1.187418,3.046968 0,4.234375 
        -1.415108,-1.41228 -2.832395,-2.82068 -4.246093,-4.234375 0.672631,-0.668658 1.502237,-0.917977 
        2.279296,-0.875 z M 29.947266,28 c 2.272675,0 4.054687,1.777134 4.054687,3.998047 C 34.001953,34.21896 
        32.220917,36 30,36 c -2.220918,0 -4.001953,-1.78104 -4.001953,-4.001953 0,-2.186212 1.725135,-3.943615 
        3.949219,-3.998047 z m 27.029296,8 c 0.59049,0 1.021485,0.43198 1.021485,0.996094 0,0.564109 
        -0.431977,1.003902 -0.996094,1.003906 -0.564113,-4e-6 -1.005859,-0.439797 -1.005859,-1.003906 
        0,-0.546486 0.415444,-0.970197 0.980468,-0.996094 z m -27.943359,2 h 1.962891 c 2.591304,0 
        4.579173,1.559488 6.117187,3.816406 1.536156,2.254182 2.511306,5.155104 2.894531,7.345703 
        0.132176,0.798754 -0.03952,1.216229 -0.24414,1.46875 C 39.559086,50.883407 39.27059,51 39.011719,51
         H 31.0625 C 30.518434,51 30,50.572047 30,49.998047 30,49.426756 30.514792,49 31.058594,49 H 36 c 
         0.742522,-0.0015 1.224576,-0.783101 0.892578,-1.447266 l -2,-4.001953 c -0.246274,-0.495942 
         -0.846775,-0.697341 -1.341797,-0.449219 -0.493734,0.248434 -0.69232,0.850295 -0.443359,1.34375 l 
         1.277344,2.552735 H 26.693359 L 24.09375,40.056641 C 25.351716,38.784021 27.0908,38 29.033203,38 
         Z M 18,43.998047 c 0.552269,0 1,0.447732 1,1 0,0.552268 -0.447731,1 -1,1 -0.552269,0 -1,-0.447732 
         -1,-1 0,-0.552268 0.447731,-1 1,-1 z M 6.9980469,53 H 57.001953 c 0.564117,0 0.996094,0.431988 
         0.996094,0.996094 v 8.001953 H 6.0019531 V 53.996094 C 6.0019527,53.431988 6.4339361,53 
         6.9980469,53 Z" 
         style="opacity:1;fill-opacity:1;stroke:none;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" transform="scale(0.26458334)"/>
        </g>
        </svg>
    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>