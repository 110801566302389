<template>
    <div>
        <div class="spin" v-if="getting">
        </div>
        <div class="box" v-else>
            <div class="flex">
                <div class="b">Avaliable ({{avaliable}})</div>
                <div class="btn" @click="avaliable = 'Yes'" :class="[avaliable == 'Yes' ? 'a': '']">Yes</div>
                <div class="btn" @click="avaliable = 'No'" :class="[avaliable == 'No' ? 'a': '']">No</div>
            </div>

            <div class="input">
                <div class="label">Phone Number</div>
                <input type="text" placeholder="Enter your phone number" v-model="phone"/>
            </div>

            <div class="b up">Location</div>
            <div class="input">
                <div class="label" v-if="country != ''">Country</div>
                <select v-model="country">
                    <option value="">Country</option>
                    <option v-for="c in countries" :value="c.name" :key="c.name">{{c.name}}</option>
                </select>
            </div>
            <div class="input">
                <div class="label" v-if="state != ''">State</div>
                <select v-model="state">
                    <option value="">State</option>
                    <option v-for="c in s" :value="c.name" :key="c.name">{{c.name}}</option>
                </select>
            </div>
            <div class="input">
                <div class="label" v-if="city">City</div>
                <select v-model="city">
                    <option value="">City</option>
                    <option v-for="c in c" :value="c.name" :key="c.name">{{c.name}}</option>
                </select>
            </div>

            <div class="b up">Working Hours</div>
            <div class="input">
                <div class="label">Open Time</div>
                <input type="time" v-model="opening">
            </div>
            <div class="input">
                <div class="label">Closing Time</div>
                <input type="time" v-model="closing">
            </div>

            <div v-if="earnings == '0'">
                <div class="b up">Bank Details</div>
                <div class="note">This details will not be editable after your recieve your first payment</div>
                <div class="input">
                    <div class="label">Bank Name</div>
                    <input type="text" placeholder="Enter your bank name" v-model="bank">
                </div>
                <div class="input">
                    <div class="label">Account Name</div>
                    <input type="text" placeholder="Enter your account name" v-model="acct_name">
                </div>
                <div class="input">
                    <div class="label">Account Number</div>
                    <input type="text" placeholder="Enter your account number" v-model="acct_no">
                </div>
            </div>

            <loader class="up" v-if="loading" :height="'37px'"/>
            <div class="btn a" @click="save()" v-else>Save</div>
        </div>
    </div>
</template>

<script>
import loader from '../loader.vue'
export default {
    components: {
        loader
    },
    data() {
        return {
            avaliable: '',
            country: '',
            state: '',
            city: '',
            opening: '',
            closing: '',
            phone: '',
            getting: true,
            countries: [],
            states: [],
            cities: [],
            s: [],
            c: [],
            earnings: '10',
            bank: '',
            acct_name: '',
            acct_no: '',
            loading: false
        }
    },
    watch: {
        country() {
            this.set_states()
        },
        state() {
            this.set_cities()
        }
    },
    mounted() {
        this.$http.get('/partner')
        .then( data => {
            let r = data.data
            if (r.success) {
                this.opening = r.partner.opening
                this.closing = r.partner.closing
                this.avaliable = r.partner.avaliable
                this.country = r.partner.country
                this.phone = r.partner.phone
                this.earnings = r.partner.earnings

                this.bank = r.partner.bank
                this.acct_name = r.partner.acct_name
                this.acct_no = r.partner.acct_no

                setTimeout(() => {
                    this.state = r.partner.state
                }, 1000);
                setTimeout(() => {
                    this.city = r.partner.city
                }, 1500);

                this.$http.get('/properties/locations')
                .then( data => {
                    let r = data.data
                    if (r.success) {
                        this.countries = r.countries
                        this.states = r.states
                        this.cities = r.cities

                        this.set_states()

                        this.getting = false
                    }
                })
            }
        })
    },
    methods: {
        save() {
            this.loading = true

            let form = new FormData()
            form.append('avaliable', this.avaliable)
            form.append('phone', this.phone)
            form.append('country', this.country)
            form.append('state', this.state)
            form.append('city', this.city)
            form.append('opening', this.opening)
            form.append('closing', this.closing)

            form.append('bank', this.bank)
            form.append('acct_name', this.acct_name)
            form.append('acct_no', this.acct_no)

            this.$http.post('/settings/partner', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.saved()
                    this.loading = false
                }
            })
        },
        set_states() {
            this.s = []
            let x
            for (x of this.states) {
                if (x.country == this.country) {
                    this.s.push(x)
                    this.state = ''
                    this.city = ''
                }
            }
        },
        set_cities() {
            this.c = []
            let x
            for (x of this.cities) {
                if (x.state == this.state) {
                    this.c.push(x)
                }
            }
        }
    },
    notifications: {
        saved: {
            type: 'success',
            title: 'Saved',
            message: 'Your chnages has been saved'
        }
    }
}
</script>

<style scoped>

    .spin {
        margin: 70px auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: var(--main);
        animation: rotate 1s infinite linear;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .box {
        background-color: white;
        border-radius: 10px;
        padding: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .btn {
        background-color: var(--trans);
        color: var(--main);
        cursor: pointer;
        padding: 8px 30px;
        border-radius: 5px;
        font-weight: 500;
        margin-top: 15px;
        text-align: center;
    }
    .a {
        background-color: var(--main);
        color: white;
    }

    .up {
        margin-top: 20px;
    }
    .b {
        font-weight: 500;
    }
    .note {
        font-size: 12px;
    }

    
    .input {
        margin-top: 20px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -10px;
        left: 10px;
        font-weight: 500;
        font-size: 14px;
        animation: .2s up;
        background-color: white;
        padding: 0px 5px;
    }
    @keyframes up {
        from {
            top: 0px;
        }
        to {
            top: -20px;
        }
    }
    input, select {
        width: 100%;
        padding: 12px;
        border-radius: 5px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        outline: none;
    }
</style>