<template>
    <div class="looking">
        <div class="title">
            What Are You Looking For?
        </div>
        <div class="grid">
            <div class="box">
                <div class="icon">
                    <appartment :size="'30px'"/>
                </div>
                <div class="t">Apartment</div>
                <div class="text">
                    Find properties for rent in your location
                </div>
            </div>
            <div class="box">
                <div class="icon">
                    <house :size="'40px'"/>
                </div>
                <div class="t">Family House</div>
                <div class="text">
                    Find properties for sale in your location
                </div>
            </div>
            <div class="box">
                <div class="icon">
                    <office :size="'30px'"/>
                </div>
                <div class="t">Office</div>
                <div class="text">
                    Find offices for hire in your location
                </div>
            </div>
            <div class="box">
                <div class="icon">
                    <shop :size="'30px'"/>
                </div>
                <div class="t">Store / Shop</div>
                <div class="text">
                    Find store or shop available for rent in your location
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appartment from '../icons/appartment.vue'
import house from '../icons/house.vue'
import office from '../icons/office.vue'
import shop from '../icons/shop.vue'

export default {
    components: {
        appartment, house, office, shop
    }
}
</script>

<style scoped>
    .looking {
        padding: 150px 30px;
    }
    .title {
        text-align: center;
        font-size: 25px;
        font-weight: 500;
        margin-bottom: 30px;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-gap: 30px;
        max-width: 1000px;
        margin: 0 auto;
        margin-top: 50px;
        
    }
    .box {
        text-align: center;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 20px;
        cursor: pointer;
        transition: .3s;
    }
    .box:hover {
        border-color: transparent;
        box-shadow: 0px 0px 20px #ccc;
    }
    .box:hover .icon {
        background-color: var(--main);
        fill: white;
    }
    .box:hover .t {
        color: var(--main);
    }
    .icon {
        background-color: var(--trans);
        fill: var(--main);
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        transition: .5s;
    }

    .t {
        font-weight: 500;
        margin: 10px auto;
        transition: .5s;
    }
    .text {
        font-size: 12px;
        font-weight: 500;
        opacity: .7;
    }
</style>