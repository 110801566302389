<template>
    <div class="city">

        <div class="pop">
            <div class="close" @click="$emit('close')">
                <close :size="'30px'"/>
            </div>
            <div class="title">
                Edit City
            </div>
            <div class="image">
                <img :src="image"/>
                <div class="change" @click="upload()" >Change Photo</div>
            </div>
            <div class="input">
                <input placeholder="Enter State Name" v-model="input"/>
            </div>
            <div class="flex">
                <div class="t">
                    Home Page
                </div>
                <div class="opt">
                    <div class="o" :class="[home ? 'o_a' : '']" @click="home = true">Yes</div>
                    <div class="o" :class="[home ? '' : 'o_a']" @click="home = false">No</div>
                </div>
            </div>
            <loader :width="'93px'" :height="'37px'" class="l" v-if="loading"/>
            <div class="btn" v-else @click="save()">Save</div>
        </div>
        
        <input type="file" accept="image/png, image/gif, image/jpeg, image/jpg" 
            ref="image" class="hidden" @change="handleupload($event)">
    </div>
</template>

<script>
import close from '../../../icons/remove.vue'
import loader from '../../loader.vue'
export default {
    components: {
        close, loader
    },
    props: ['city'],
    data() {
        return {
            input: '',
            loading: false,
            image: '',
            is_image: false,
            home: false
        }
    },
    mounted() {
        this.image = this.$root.$data.imgurl + this.city.image
        this.input = this.city.name
        this.home = this.city.home_page == '0' ? false : true
    },
    methods: {
        upload() {
            this.$refs.image.click()
        },
        handleupload(event) {
            let mediaType = event.target.files[0].type
            if (mediaType.includes('image')) {
                const picture = event.target.files[0]
                const reader = new FileReader()
                reader.readAsDataURL(picture)
                reader.onload = event => {
                    this.image = event.target.result
                    this.is_image = true
                }
            }
        },
        save() {
            if (this.input == '') { this.empty(); return }

            this.loading = false

            let form = new FormData()
            form.append('name', this.city.name)
            form.append('new_name', this.input)
            form.append('home_page', this.home)
            form.append('new_image', this.is_image)
            form.append('image', this.city.image)
            if (this.is_image) {
                form.append('image', this.$refs.image.files[0])
            }
            this.$http.post('/location/city/edit', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()
                    this.city.name = this.input
                    this.city.home_page = this.home
                    this.city.image = r.image

                    this.$emit('close')
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: 'Empty',
            message: 'City name cannot be empty'
        },
        success: {
            type: 'success',
            title: 'Edited',
            message: 'This city has been edited'
        }
    }
}
</script>

<style scoped>

    .pop {
        background-color: white;
        position: relative;
        z-index: 4;
        width: 300px;
        padding: 20px;
        border-radius: 10px;
        animation: pop .3s;
        
    }
    @keyframes pop {
        from {
            transform: scale(.7);
        }
        to {
            transform: scale(1);
        }
    }
    .close {
        position: absolute;
        right: -10px;
        top: -10px;
        fill: var(--main);
        cursor: pointer;
    }

    .title {
        font-weight: 500;
    }
    
    .image {
        height: 150px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        position: relative;
    }
    img {
        height: 150px;
    }
    .change {
        background-color: var(--main);
        color: white;
        padding: 8px 20px;
        font-weight: 500;
        position: absolute;
        left: 0px;
        bottom: 0px;
        cursor: pointer;
    }

    input {
        margin-top: 20px;
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 5px;
    }

    .flex {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }
    .t {
        font-weight: 500;
    }
    .opt {
        display: flex;
    }
    .o {
        margin-left: 20px;
        padding: 5px 10px;
        color: var(--main);
        background-color: var(--trans);
        cursor: pointer;
        border-radius: 5px;
        font-weight: 500;
    }
    .o_a {
        background-color: var(--main);
        color: white;
    }


    .btn {
        margin-top: 20px;
        background-color: var(--main);
        padding: 8px 30px;
        border-radius: 5px;
        margin-left: auto;
        width: fit-content;
        color: white;
        font-weight: 500;
        cursor: pointer;
    }
    .l {
        margin-top: 20px;
        margin-left: auto;
    }
    .hidden {
        display: none;
    }
</style>