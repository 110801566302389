<template>
    <div class="tenant">
        <div class="title">Prospective Tenant</div>

        <div class="main">
            <div class="flex">
                <div class="label">Status:</div>
                <div class="values" v-for="item in s" :key="item">
                    <div class="value">{{item}}</div>
                </div>
            </div>
            <div class="flex">
                <div class="label">Livelihood:</div>
                <div class="values" v-for="item in l" :key="item">
                    <div class="value">{{item}}</div>
                </div>
            </div>
            <div class="flex">
                <div class="label">Tribe:</div>
                <div class="values" v-for="item in t" :key="item">
                    <div class="value">{{item}}</div>
                </div>
            </div>
            <div class="flex">
                <div class="label">Religion:</div>
                <div class="values" v-for="item in r" :key="item">
                    <div class="value">{{item}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['status', 'livelihood', 'tribe', 'religion'],
    mounted() {
        this.s = JSON.parse(this.status)
        this.l = JSON.parse(this.livelihood)
        this.t = JSON.parse(this.tribe)
        this.r = JSON.parse(this.religion)
    },
    data() {
        return {
            s: [],
            l: [],
            t: [],
            r: []
        }
    }
}
</script>

<style scoped>

    .tenant {
        margin-bottom: 30px;
        background-color: white;
        padding: 20px;
        border-radius: 5px;
    }
    .title {
        font-weight: 500;
        font-size: 20px;
    }

    .main {
        padding-top: 10px;
    }
    
    .flex {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 15px;
        font-size: 14px;
    }
    .label {
        color: gray;
    }
    .values {
        display: flex;
        gap: 10px;
    }
    .value {
        font-weight: 500;
        padding: 5px 10px;
        border-radius: 5px;
        background-color: #edeef0;
        /* font-size: 12px; */
    }
</style>