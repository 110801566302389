<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>

        <div class="box">
            <div class="title">Add Tenant</div>
            <div class="input">
                <div class="label">Name</div>
                <input type="text" placeholder="Enter tenant name" v-model="name">
            </div>
            <div class="input">
                <div class="label">Phone</div>
                <input type="text" placeholder="Enter tenant phone number" v-model="phone">
            </div>
            <div class="input">
                <div class="label">Whatsapp</div>
                <input type="text" placeholder="Enter tenant whatsapp number" v-model="whatsapp">
            </div>
            <div class="input">
                <div class="label">Email</div>
                <input type="text" placeholder="Enter tenant email address" v-model="email">
            </div>
            <div class="input">
                <div class="label">Office Address</div>
                <input type="text" placeholder="Enter tenant office address" v-model="office_address">
            </div>
            <div class="input">
                <div class="label">Property Type</div>
                <input type="text" placeholder="Enter tenant property type" v-model="property_type">
            </div>


            <div class="input">
                <div class="label">Guarantor Name</div>
                <input type="text" placeholder="Enter tenant guarantor name" v-model="guarantor_name">
            </div>
            <div class="input">
                <div class="label">Guarantor Address</div>
                <input type="text" placeholder="Enter tenant guarantor address" v-model="guarantor_address">
            </div>
            <div class="input">
                <div class="label">Guarantor Phone</div>
                <input type="text" placeholder="Enter tenant guarantor phone" v-model="guarantor_phone">
            </div>

            <div class="input">
                <div class="label">Next of Kin Name</div>
                <input type="text" placeholder="Enter tenant next of kin name" v-model="next_of_kin_name">
            </div>
            <div class="input">
                <div class="label">Next of Kin Address</div>
                <input type="text" placeholder="Enter tenant next of kin address" v-model="next_of_kin_address">
            </div>
            <div class="input">
                <div class="label">Next of Kin Phone</div>
                <input type="text" placeholder="Enter tenant next of kin phone" v-model="next_of_kin_phone">
            </div>

            
            <div class="input">
                <div class="label">Rent Start</div>
                <input type="date" placeholder="Choose when tenant rent start" v-model="rent_start">
            </div>
            <div class="input">
                <div class="label">Rent End</div>
                <input type="date" placeholder="Chose when tenant rent end" v-model="rent_end">
            </div>
            <div class="input">
                <div class="label">Rent Price</div>
                <input type="number" placeholder="Enter rent price" v-model="rent_price">
            </div>
            <div class="input">
                <div class="label">Rent Duration</div>
                <div class="flex">
                    <input type="number" placeholder="No" v-model="rent_duration_no">
                    <select v-model="rent_duration">
                        <option value="" selected disabled>Duration</option>
                        <option value="Day">Day</option>
                        <option value="Week">Week</option>
                        <option value="Month">Month</option>
                        <option value="Year">Year</option>
                    </select>
                </div>
            </div>

            <loader :height="'41px'" v-if="loading"/>
            <div class="btn" v-else @click="add()">Add</div>
            
        </div>
    </div>
</template>

<script>

import loader from '../loader.vue'

export default {
    components: {
        loader
    },
    props: ['house_id'],
    data() {
        return {
            loading: false,

            name: '',
            phone: '',
            whatsapp: '',
            email: '',
            office_address: '',
            property_type: '',

            guarantor_name: '',
            guarantor_address: '',
            guarantor_phone: '',

            next_of_kin_name: '',
            next_of_kin_address: '',
            next_of_kin_phone: '',
            
            rent_start: '',
            rent_end: '',
            rent_price: '',
            rent_duration_no: '',
            rent_duration: '',

            loading: false
        }
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },

        add() {
            if (this.name == '' || this.phone == '' || this.email == '' || this.office_address == '' ||
            this.property_type == '' || this.guarantor_name == '' || this.guarantor_address == '' ||
            this.guarantor_phone == '' || this.next_of_kin_name == '' || this.next_of_kin_address == '' ||
            this.next_of_kin_phone == '' || this.rent_start == '' || this.rent_end == '' || this.rent_price == '' ||
            this.rent_duration_no == '' || this.rent_duration == '') {
                this.empty(); return
            }

            this.loading = true

            let form = new FormData()

            form.append('house_id', this.house_id)
            
            form.append('name', this.name)
            form.append('phone', this.phone)
            form.append('whatsapp', this.whatsapp)
            form.append('email', this.email)
            form.append('office_address', this.office_address)
            form.append('property_type', this.property_type)

            form.append('guarantor_name', this.guarantor_name)
            form.append('guarantor_address', this.guarantor_address)
            form.append('guarantor_phone', this.guarantor_phone)

            form.append('next_of_kin_name', this.next_of_kin_name)
            form.append('next_of_kin_address', this.next_of_kin_address)
            form.append('next_of_kin_phone', this.next_of_kin_phone)

            form.append('rent_start', this.rent_start)
            form.append('rent_end', this.rent_end)
            form.append('rent_price', this.rent_price)
            form.append('rent_duration_no', this.rent_duration_no)
            form.append('rent_duration', this.rent_duration)

            this.$http.post('/manager/tenant/add', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.added()

                    this.loading = false
                    this.$emit('new_tenant', {
                        'tenant_id': r.tenant_id,
                        'name': this.name,
                        'phone': this.phone,
                        'whatsapp': this.whatsapp,
                        'email': this.email,
                        'office_address': this.office_address,
                        'property_type': this.property_type,

                        'guarantor_name': this.guarantor_name,
                        'guarantor_address': this.guarantor_address,
                        'guarantor_phone': this.guarantor_phone,

                        'next_of_kin_name': this.next_of_kin_name,
                        'next_of_kin_address': this.next_of_kin_address,
                        'next_of_kin_phone': this.next_of_kin_phone
                    })
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Make sure all field are filled'
        },
        added: {
            type: 'success',
            title: 'Success',
            message: 'Tenant as been added'
        }
    }
}
</script>

<style scoped>

    .popup {
        position: fixed;
        z-index: 5;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
    }
    .box {
        background-color: white;
        border-radius: 5px;
        padding: 20px;
        z-index: 5;
        width: 300px;
        height: calc(100vh - 150px);
        overflow: auto;
    }
    .title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    
    
    .input {
        margin-bottom: 25px;
        position: relative;
    }
    .label {
        position: absolute;
        background-color: white;
        padding: 0px 5px;
        top: -10px;
        left: 10px;
        transition: .2s;
        font-weight: 500;
        font-size: 14px;
    }
    input, select {
        padding: 15px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
    }
    .flex {
        display: flex;
        gap: 10px;
    }
    
    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: 500;
        text-align: center;
    }
</style>