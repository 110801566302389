import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Properties from '../views/Properties.vue'
import Property from '../views/Property.vue'
import Login from '../views/Login.vue'
import Signup from '../views/Signup.vue'
import Verify from '../views/verify.vue'
import dashboard from '../views/dashboard.vue'

import dash from '../sub_views/dashboard.vue'

import agent from '../sub_views/agent.vue'
import new_property from '../sub_views/new_property.vue'
import agent_property from '../sub_views/properties.vue'
import agent_appointment from '../sub_views/agent_appointment.vue'
import alerts from '../sub_views/alerts.vue'

import partner_dashboard from '../sub_views/partner/dashboard.vue'
import partner_appointments from '../sub_views/partner/appointments.vue'
import partner_settings from '../sub_views/partner/settings.vue'

import appointments from '../sub_views/appointments.vue'

import tenants from '../sub_views/tenants.vue'
import settings from '../sub_views/settings.vue'


import admin from '../views/admin.vue'
import admin_login from '../views/admin_login.vue'
import admin_dashboard from '../sub_views/admin_dashboard.vue'
import admin_banners from '../sub_views/admin_banners.vue'
import admin_location from '../sub_views/admin_location.vue'
import admin_properties from '../sub_views/admin_properties.vue'
import admin_properties_videos from '../sub_views/admin_properties_video.vue'
import admin_property from '../sub_views/admin_property.vue'
import admin_agent from '../sub_views/admin_agent.vue'
import admin_users from '../sub_views/admin_users.vue'
import admin_appoinments from '../sub_views/admin_appointments.vue'
import admin_message_groups from '../sub_views/admin_message_groups.vue'
import admin_message_agent from '../sub_views/admin_message_agent.vue'
import admin_message_client from '../sub_views/admin_message_client.vue'
import admin_clients from '../sub_views/admin_clients'
import admin_clients_unmarked from '../sub_views/admin_clients_unmarked.vue'
import admin_whatsapp from '../sub_views/admin_whatsapp.vue'


import employee_login from '../views/employee/login.vue'
import employee from '../views/employee/employee.vue'
import employee_dashboard from '../views/employee/dashboard.vue'
import message_agent from '../views/employee/message_agent.vue'
import message_client from '../views/employee/message_client.vue'
import properties_video from '../views/employee/properties_video.vue'
import agents_employee from '../views/employee/agents.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/terms',
        name: 'Terms',
        component: () => import(/* webpackChunkName: "terms" */ '../views/terms.vue')
    },
    {
        path: '/properties',
        name: 'Properties',
        component: () => import(/* webpackChunkName: "properties" */ '../views/Properties.vue')
    },
    {
        path: '/property_requests',
        name: 'Request',
        component: () => import('../views/requests.vue')
    },
    {
        path: '/agent_registration',
        name: 'agent_registration',
        component: () => import('../views/agent_registration.vue')
    },
    {
        path: '/property/:id',
        name: 'Property',
        component: () => import(/* webpackChunkName: "property" */ '../views/Property.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
    },
    {
        path: '/forgot_password',
        name: 'Forgot',
        component: () => import(/* webpackChunkName: "forgot" */ '../views/forgot.vue')
    },
    {
        path: '/signup',
        name: 'Signup',
        component: () => import(/* webpackChunkName: "signup" */ '../views/Signup.vue')
    },
    {
        path: '/verify',
        name: 'Verify',
        component: () => import(/* webpackChunkName: "verify" */ '../views/verify.vue')
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard.vue'),
        children: [
            {
                path: '',
                name: 'dashboard',
                component: dash
            },

            {
                path: 'agent/be',
                name: 'agent',
                component: agent
            },
            {
                path: 'agent/submit',
                name: 'submit',
                component: new_property
            },
            {
                path: 'agent/properties',
                name: 'properties',
                component: agent_property
            },
            {
                path: 'agent/appointments',
                name: 'a_appointment',
                component: agent_appointment
            },
            {
                path: 'agent/alerts',
                name: 'alerts',
                component: alerts
            },


            {
                path: 'partner/dashboard',
                name: 'p_dashboard',
                component: partner_dashboard
            },
            {
                path: 'partner/appointments',
                name: 'p_appointments',
                component: partner_appointments
            },
            {
                path: 'partner/settings',
                name: 'p_settings',
                component: partner_settings
            },

            
            {
                path: 'appointments',
                name: 'appointments',
                component: appointments
            },
            {
                path: 'tenants',
                name: 'tenants',
                component: tenants
            },
            {
                path: 'settings',
                name: 'settings',
                component: settings
            }
        ]
    },
    {
        path: '/admin/login',
        name: 'admin_login',
        component: admin_login
    },
    {
        path: '/admin',
        name: 'admin',
        component: admin,
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: admin_dashboard
            },
            {
                path: 'banners',
                name: 'banners',
                component: admin_banners
            },
            {
                path: 'location',
                name: 'location',
                component: admin_location
            },
            {
                path: 'properties',
                name: 'properties',
                component: admin_properties
            },
            {
                path: 'properties/video',
                name: 'properties_video',
                component: admin_properties_videos
            },
            {
                path: 'property',
                name: 'property',
                component: admin_property
            },
            {
                path: 'agent',
                name: 'agent',
                component: admin_agent
            },
            {
                path: 'users',
                name: 'users',
                component: admin_users
            },
            {
                path: 'appointments',
                name: 'appointments',
                component: admin_appoinments
            },
            {
                path: 'message/groups',
                name: 'admin_message_groups',
                component: admin_message_groups
            },
            {
                path: 'message/agent',
                name: 'admin_message_agent',
                component: admin_message_agent
            },
            {
                path: 'message/client',
                name: 'admin_message_client',
                component: admin_message_client
            },
            {
                path: 'clients',
                name: 'admin_clients',
                component: admin_clients
            },
            {
                path: 'clients/unmarked',
                name: 'admin_clients_unmarked',
                component: admin_clients_unmarked
            },
            {
                path: 'whatsapp',
                name: 'admin_whatsapp',
                component: admin_whatsapp
            }
        ]
    },

    {
        path: '/employee/login',
        name: 'employee_login',
        component: employee_login
    },
    {
        path: '/employee',
        name: 'employee',
        component: employee,
        children: [
            {
                path: '',
                name: 'employee_dashboard',
                component: employee_dashboard
            },
            {
                path: 'message/agent',
                name: 'message_agent',
                component: message_agent
            },
            {
                path: 'message/client',
                name: 'message_client',
                component: message_client
            },
            {
                path: 'properties/video',
                name: 'properties_video',
                component: properties_video
            },
            {
                path: 'agents',
                name: 'agents',
                component: agents_employee
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
