<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="add">
            <div class="title">Add Client</div>
            <div class="main">
                <div class="input">
                    <div class="label">Name</div>
                    <input type="text" placeholder="Enter client name" v-model="name">
                </div>
                <div class="input">
                    <div class="label">Number</div>
                    <input type="text" placeholder="Enter client number" v-model="number">
                </div>
                <loader :height="'37px'" v-if="loading"/>
                <div class="btn" @click="add()" v-else>Add</div>
            </div>
        </div>
    </div>
</template>

<script>
import loader from '../../loader.vue'

export default {
    components: {
        loader
    },
    props: ['city', 'category', 'type'],
    data() {
        return {
            loading: false,
            name: '',
            number: ''
        }
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },
        add() {
            if (this.name == '' || this.number == '') {
                this.empty(); return
            }
            if (this.number.length != 11) { this.invalid_no(); return }

            this.loading = true

            let form = new FormData()
            form.append('name', this.name)
            form.append('number', this.number)
            form.append('city', this.city)
            form.append('category', this.category)
            form.append('type', this.type)

            this.$http.post('/employee/client/add', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.loading = false

                    // this.$emit('new_client', {
                    //     'name': this.name,
                    //     'number': this.number,
                    //     'city': this.city,
                    //     'category': this.category,
                    //     'id': r.id
                    // })
                    this.$emit('close')

                    this.added()
                } else {
                    this.loading = false
                    this.duplicate()
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: 'Empty',
            message: 'Enter client name and phone number'
        },
        invalid_no: {
            type: 'warn',
            title: 'Invalid number',
            message: 'The number nust be eleven digit'
        },
        duplicate: {
            type: 'warn',
            title: 'Duplicates detected',
            message: 'This number has been added to this category'
        },
        added: {
            type: 'success',
            title: 'Added',
            message: 'Client has been added'
        },
    }
}
</script>

<style scoped>
    .popup {
        z-index: 10;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
    }

    .add {
        background-color: white;
        width: 300px;
        position: relative;
        z-index: 20;
        border-radius: 5px;
    }
    .title {
        padding: 15px;
        font-weight: 500;
    }

    .main {
        padding: 20px;
        border-top: 1px solid #ddd;
    }

    .input {
        position: relative;
        margin-bottom: 20px;
    }
    .label {
        position: absolute;
        top: -10px;
        left: 10px;
        background-color: white;
        font-weight: 500;
        font-size: 14px;
        padding: 0px 5px;
    }
    input {
        padding: 15px;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #ddd;
        border-radius: 5px;
    }
    .btn {
        background-color: var(--main);
        color: white;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
        padding: 8px;
        font-weight: 500;
    }
</style>