<template>
    <div class="pop">
        <div class="close" @click="$emit('close')">
            <close :size="'30px'"/>
        </div>
        <div class="title">
            Add a New Property Type
        </div>
        <div class="input">
            <input placeholder="Enter Property Type" v-model="input"/>
        </div>
        <loader :width="'90px'" :height="'37px'" class="l" v-if="loading"/>
        <div class="btn" v-else @click="add()">Add</div>
    </div>  
</template>

<script>
import close from '../../../icons/remove.vue'
import loader from '../../loader.vue'
export default {
    components: {
        close, loader
    },
    props: ['properties', 'Let', 'Short', 'Lease', 'Sale', 'current', 'country'],
    data() {
        return {
            input: '',
            loading: false
        }
    },
    methods: {
        add() {
            if (this.input == '') { this.empty(); return }
            this.loading = true

            this[this.current].push(this.input)

            let form = new FormData()
            form.append('country', this.country)
            form.append('where', this.current)
            form.append('type', JSON.stringify(this[this.current]))

            this.$http.post('/location/property_type', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()
                    this.$emit('new_property', this.input)
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: 'Empty',
            message: 'Property type cannot be empty'
        },
        success: {
            type: 'success', 
            title: 'Sucess',
            message: 'New property type has been added'
        }
    }
}
</script>

<style scoped>
    .pop {
        background-color: white;
        position: relative;
        z-index: 4;
        width: 300px;
        padding: 20px;
        border-radius: 10px;
        animation: pop .3s;
        
    }
    @keyframes pop {
        from {
            transform: scale(.7);
        }
        to {
            transform: scale(1);
        }
    }
    .close {
        position: absolute;
        right: -10px;
        top: -10px;
        fill: var(--main);
        cursor: pointer;
    }

    .title {
        font-weight: 500;
    }
    input {
        margin-top: 20px;
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 5px;
    }
    .btn {
        margin-top: 20px;
        background-color: var(--main);
        padding: 8px 30px;
        border-radius: 5px;
        margin-left: auto;
        width: fit-content;
        color: white;
        font-weight: 500;
        cursor: pointer;
    }
    .l {
        margin-top: 20px;
        margin-left: auto;
    }
</style>