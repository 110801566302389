<template>
    <div class="property">
        <div class="main">
            <div class="swiper">
                <swiper class="swiper_conn" ref="swiper" :options="options" @slide-change="change_slide()" 
                    @slider-move="change_slide()" >
                    <swiper-slide class="swiper_slide_conn" v-for="image in display" :key="image.k">
                        <div class="image">
                            <img :src="url + image.img" alt="" @error="err()" @load="loaded_img()" class="img">
                            <div class="watermark">
                                <img src="../assets/watermark.png" class="water_img">
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide class="swiper_slide_conn" v-if="property.video != ''">
                        
                        <iframe :src="'https://iframe.mediadelivery.net/embed/159521/' + property.video + '?autoplay=false'" 
                            loading="lazy" style="border: none; height: 153px;  width: 100%;" 
                            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" 
                            allowfullscreen="true">
                        </iframe>
                    </swiper-slide>
                    <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
                    <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
                </swiper>
                <div class="tag">For {{property.category}}</div>
            </div>
            <div class="content">
                <router-link :to="'/property/' + property.prop_id">
                    <div class="name">{{property.title}}</div>
                
                    <div class="flex">
                        <location :size="'10px'" class="location"/>
                        {{property.city}} {{property.state}}
                    </div>

                    <div class="flex">
                        <house :size="'10px'" class="location"/>
                        {{ property.type }}
                    </div>
                
                    <div class="price">
                        <vue-numeric read-only currency="₦" separator="," v-model="price.no">
                        </vue-numeric>
                        <span v-if="property.category != 'Sale'"> {{price.duration}} </span>
                    </div>
                </router-link>
                
                <share :property="property"/>
            </div>
        </div>
        <div class="grid grid_sale" v-if="property.category == 'Sale'">
            <div class="g_item">
                <div class="icon">
                    <size :size="'15px'" :style="{'width': '17px'}"/>
                </div>
                <div class="g_flex">
                    <div class="label">Prop size</div>
                    <div class="no">
                        {{size.no}} {{size.measurement == 'Square Meter' ? 'Sq M' : size.measurement}}
                    </div>
                </div>
            </div>
            <div class="g_item">
                <div class="icon">
                    <condition :size="'15px'" :style="{'width': '17px'}"/>
                </div>
                <div class="g_flex">
                    <div class="label">Condition</div>
                    <div class="no">{{property.prop_condition}}</div>
                </div>
            </div>
            <div class="g_item">
                <div class="icon">
                    <car :size="'15px'" :style="{'width': '17px'}"/>
                </div>
                <div class="g_flex">
                    <div class="label">Parking</div>
                    <div class="no">{{property.parking}}</div>
                </div>
            </div>
        </div>

        <div class="grid" v-else>
            <div class="g_item">
                <div class="icon">
                    <bedroom :size="'15px'" :style="{'width': '17px'}"/>
                </div>
                <div class="g_flex">
                    <div class="no">{{property.bedrooms}}</div>
                    <div class="label">Bedrooms</div>
                </div>
            </div>
            <div class="g_item">
                <div class="icon">
                    <bath :size="'15px'" :style="{'width': '17px'}"/>
                </div>
                <div class="g_flex">
                    <div class="no">{{property.bathrooms}}</div>
                    <div class="label">Bathrooms</div>
                </div>
            </div>
            <div class="g_item">
                <div class="icon">
                    <toilet :size="'15px'" :style="{'width': '15px'}"/>
                </div>
                <div class="g_flex">
                    <div class="no">{{property.toilet}}</div>
                    <div class="label">Toilets</div>
                </div>
            </div>
            <div class="g_item">
                <div class="icon">
                    <car :size="'15px'" :style="{'width': '17px'}"/>
                </div>
                <div class="g_flex">
                    <div class="no">{{property.parking}}</div>
                    <div class="label">Parking</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import youtube from './youtube.vue'

import share from '../components/share.vue'

import location from '../icons/location.vue'
import house from '../icons/house.vue'
import bedroom from '../icons/bedroom.vue'
import bath from '../icons/bath.vue'
import toilet from '../icons/toilet.vue'
import car from '../icons/car.vue'

import condition from '../icons/condition.vue'
import size from '../icons/size.vue'

export default {
    components: {
        Swiper, SwiperSlide,
        // youtube,
        share,
        location, house,
        bedroom, bath, toilet, car,
        size, condition
    },
    props: ['property'],
    computed: {
        url() {
            return this.$root.$data.imgurl
        }
    },
    mounted() {

        this.price = JSON.parse(this.property.price)

        this.images = JSON.parse(this.property.images)
        let i
        for (i = 0; i < this.images.length; i++) {
            this.display.push({'img': '', 'k': Math.random().toString()})
        }
        this.display[0].img = this.images[0]

        this.size = JSON.parse(this.property.size)

    },
    data() {
        return {
            options: {
                slidesPerView: 1,
                // loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                    hideOnClick: true
                },
                // autoHeight: true
            },
            // images: [
            //     'bg1.jpg', 'bg2.jpg', 'osun.jpg', 'ogun.jpg'
            // ]
            images: [],
            display: [],
            price: {},
            size: {}
        }
    },
    methods: {
        change_slide() {
            let a = this.$refs.swiper.$swiper.activeIndex

            this.display[a].img = this.images[a]
            // this.redraw()
        },
        err() {
            console.log('err');
        },
        loaded_img() {
            console.log('load');
            setTimeout(() => {
                // this.redraw()
                console.log('redraw');
            }, 500);
            setTimeout(() => {
                this.$refs.swiper.$swiper.updateSize()
            }, 1000);
        },
        redraw() {
            setTimeout(() => {
                this.$redrawVueMasonry()
            }, 500);
        }
    },
    activated() {
        // this.redraw()
    }
}
</script>

<style scoped>
    .property {
        /* width: 300px; */
        border-radius: 5px;
        overflow: hidden;
        background-color: white;
        /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */

    }
    .main {

        display: grid;
        grid-template-columns: 40% 60%;
    }
    .swiper {
        position: relative;
    }
    .swiper_conn {
        height: 153px;
    }


    .tag {
        position: absolute;
        z-index: 2;
        top: 0px;
        left: 0px;
        background-color: var(--main);
        color: white;
        padding: 5px 10px;
        /* border-radius: 5px; */
        font-weight: 500;
        font-size: 10px;
    }
    .image {
        height: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: rgb(0, 0, 0);
    }
    .img {
        /* height: 250px; */
        width: 100%;
        pointer-events: none;
    }
    .watermark {
        position: absolute;
        top: 0px;
        left: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0);
        z-index: 5;
    }
    .water_img {
        width: 50%;
    }

    .content {
        padding: 15px;

    }
    
    .price {
        background-color: #edeef0;
        padding: 5px 10px;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 15px;
        
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .name {
        /* color: var(--main); */
        font-weight: 500;
        
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .grid {
        display: grid;
        grid-template-columns: auto auto auto auto;
        /* background-color: #edeef0; */
        /* background-color: var(--trans); */
        /* background-color: var(--main); */
        /* margin: 10px -15px; */
        padding: 10px 10px;
        border-top: 1px solid #ddd;
        /* border-radius: 5px; */
    }
    .grid_sale {
        grid-template-columns: auto auto auto;
    }
    .g_item {
        text-align: center;
        font-size: 10px;
        fill: var(--main);
        stroke: var(--main);
        /* display: flex; */
        /* gap: 5px; */
    }
    .g_item .no {
        /* color: var(--main); */
        /* font-size: 14px; */
        font-weight: 600;
    }
    .icon {
        width: fit-content;
        margin: 0 auto;
    }
    .g_flex {
        display: flex;
        justify-content: center;
        gap: 3px;
        margin-top: 5px;
    }


    .flex {
        display: flex;
        align-items: center;
        margin-top: 5px;
        font-size: 10px;
        font-weight: 500;
        color: gray;
        
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .location {
        margin-right: 5px;
        fill: gray;
    }

    .opt {
        display: flex;
        margin-right: 10px;
    }
    .no {
        /* margin-left: 5px; */
        font-weight: 500;
    }

    .btn {
        background-color: var(--trans);
        color: var(--main);
        cursor: pointer;
        padding: 8px 20px;
        border-radius: 5px;
        font-weight: 500;
        margin-top: 15px;
        text-align: center;
    }



    
    .swiper-button-prev, .swiper-button-next {
        color: var(--main);
        background-color: rgba(0, 0, 0, 0.651);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .swiper-button-prev:after, .swiper-button-next:after {
        font-size: 10px;
        font-weight: 700;
    }
</style>