<template>
    <div class="banner">
        <div class="head">
            <div class="title">Banners</div>
            <div class="btn" @click="show_add = true">Add Banner</div>
        </div>

        <div class="grid">
            <div class="item" v-for="banner in banners" :key="banner.id">
                <img :src="banurl + banner.image"/>

                <loader class="del" :height="'37px'" v-if="load == banner.id"/>
                <div class="btn del" @click="remove(banner.id)" v-else>Delete</div>
            </div>
        </div>

        <add v-if="show_add" @close="show_add = false" @new_banner="new_banner($event)"/>
    </div>
</template>

<script>
import add from '../components/admin/banner/add.vue';
import loader from '../components/loader.vue';

export default {
    components: {
        add, loader
    },
    data() {
        return {
            banurl: this.$root.$data.banurl,
            show_add: false,
            banners: [],
            load: ''
        }
    },
    mounted() {
        this.$http.post('/admin/banners')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.banners = r.banners
            }
        })
    },
    methods: {
        new_banner(e) {
            this.show_add = false
            this.banners.push(e)
        },
        remove(id) {
            this.load = id

            let form = new FormData()
            form.append('id', id)

            this.$http.post('/admin/banner/remove', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.$router.go()
                }
            })
        }
    }
}
</script>

<style scoped>
    .banner {
        padding: 20px;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .title {
        font-weight: 500;
        font-size: 20px;
    }
    .btn {
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        padding: 8px 20px;
        cursor: pointer;
        font-weight: 500;
    }


    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
    }
    .item {
        padding: 20px;
        background-color: white;
        border-radius: 5px;
    }
    img {
        width: 100%;
    }
    .del {
        text-align: center;
        margin-top: 20px;
    }
</style>