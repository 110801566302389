<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">
                Send SMS to {{ name }}
            </div>
            <div class="main">
                <textarea placeholder="Enter your message" v-model="text"></textarea>

                <loader :height="'35px'" v-if="loading"/>
                <div class="btn" @click="send()" v-else>Send</div>
            </div>
        </div>
    </div>

</template>

<script>
import loader from '../../loader.vue'
export default {
    components: {
        loader
    },

    props: ['name', 'phone'],
    data() {
        return {
            text: '',
            loading: false
        }
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },
        send() {
            if (this.text == '') {
                return;
            }
            this.loading = true

            let form = new FormData()
            form.append('phone', this.phone)
            form.append('message', this.text)

            this.$http.post('/admin/client/sms_unmarked', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    alert(r.res.SMSMessageData.Message)
                    this.loading = false
                    this.$emit('close')
                }
            })
        }
    }
}
</script>

<style scoped>

    .popup {
        z-index: 10;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
    }

    .box {
        background-color: white;
        width: 500px;
        position: relative;
        z-index: 20;
        border-radius: 5px;
    }
    .title {
        padding: 15px 20px;
        font-weight: 500;
    }
    .main {
        padding: 20px;
        border-top: 1px solid #ddd;
    }
    textarea {
        padding: 15px;
        border-radius: 5px;
        border: 1px solid #ddd;
        width: 100%;
        box-sizing: border-box;
        height: 100px;
        margin-bottom: 20px;
    }
    .btn {
        padding: 8px;
        border-radius: 5px;
        cursor: pointer;
        background-color: var(--main);
        color: white;
        text-align: center;
        font-weight: 500;
    }
</style>