<template>
    <div class="messages">
        <div class="head">
            <div class="title">Unmarked Clients</div>
        </div>

        <div class="clients">
            <div class="client" v-for="client in clients" :key="client.id">
                <div class="c_label">Name</div>
                <div class="c_value">{{client.name}}</div>

                <div class="c_label">Number</div>
                <div class="c_value">{{client.number}}</div>

                <div class="c_label">City</div>
                <div class="c_value">{{ client.city }}</div>

                <div class="c_label">Added</div>
                <div class="c_value">{{ moment(client.added).fromNow() }}</div>

                <div class="c_label">Type</div>
                <div class="c_value">{{ client.category }} - {{ client.type }}</div>

                <div class="clicks">
                    <div class="click" @click="message(client.name, client.number)">Whatsapp</div>
                    <div class="click" @click="sms(client.name, client.number)">SMS</div>
                    <div class="del" @click="remove(client.id, client.city)">Delete</div>
                </div>
            </div>
        </div>

        <message_unmarked :name="name" :phone="phone" v-if="show_message" 
            @close="show_message = false"/>

        <sms :name="name" :phone="phone" v-if="show_sms" 
            @close="show_sms = false"/>

    </div>
</template>

<script>
import moment from 'moment'
import message_unmarked from '../components/admin/clients/message_unmarked.vue'
import sms from '../components/admin/clients/sms.vue'

export default {
    components: {
        message_unmarked, sms
    },
    data() {
        return {
            clients: [],

            show_message: false,
            show_sms: false,
            name: '',
            phone: ''
        }
    },
    mounted() {
        this.$http.post('/admin/client/get_unmarked')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.clients = r.clients
            }
        })
    },
    methods: {
        message(name, phone) {
            this.name = name
            this.phone = phone

            this.show_message = true
        },
        sms(name, phone) {
            this.name = name
            this.phone = phone

            this.show_sms = true
        },
        remove(id, city) {

            let form = new FormData()
            form.append('id', id)
            form.append('city', city)

            this.$http.post('/admin/client/delete', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    this.removed()
                    this.$router.go()
                }
            })
        }
    },
    notifications: {
        
        removed: {
            'type': 'success',
            'title': 'Removed',
            'message': 'Client has been removed'
        }
    }
}
</script>

<style scoped>

    .messages {
        padding: 30px;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .title {
        font-size: 20px;
        font-weight: 600;
    }
    .btn {
        padding: 8px 30px;
        border-radius: 5px;
        background-color: var(--main);
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        color: white;
    }


    
    
    .clients {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 20px;
    }
    .client {
        padding: 15px;
        border-radius: 5px;
        background-color: white;
    }
    .c_label {
        font-size: 12px;
        color: grey;
    }
    .c_value {
        font-weight: 500;
        margin-bottom: 15px;
        font-size: 14px;
    }
    
    .clicks {
        display: flex;
        gap: 20px;
    }
    .del, .click {
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: var(--main);
        cursor: pointer;
    }
</style>