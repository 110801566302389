<template>
    <div class="image" :style="{'height': height}" @contextmenu="context" @click="$emit('clicked')">
        <img :src="imgurl + image" class="main_img" :class="[full ? 'full_img' : '']"/>
        <div class="watermark">
            <img src="../assets/watermark.png" class="watermark_img">
        </div>
    </div>
</template>

<script>
export default {
    props: ['image', 'height', 'full'],
    computed: {
        imgurl() {
            return this.$root.$data.imgurl
        }
    },
    methods: {
        context() {
            return
        }
    }
}
</script>

<style scoped>
    .image {
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .main_img {
        width: 100%;
    }

    .watermark {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        /* background-color: green; */
        z-index: 5;
    }
    .watermark_img {
        width: 300px;
    }

    
    .full_img {
        max-height: 100%;
        width: unset;
        max-width: 100%;
    }
</style>