<template>
    <div class="">
        
        <div class="pop">
            <div class="close" @click="$emit('close')">
                <close :size="'30px'"/>
            </div>
            <div class="title">
                Choose Location
            </div>
            <div class="input">
                <select v-model="country">
                    <option value="" disabled selected>Country</option>
                    <option v-for="c in countries" :value="c.name" :key="c.name">{{c.name}}</option>
                </select>
            </div>
            <div class="input">
                <select v-model="state" :disabled="country == ''">
                    <option value="" disabled selected>State</option>
                    <option v-for="c in s" :value="c.name" :key="c.name">{{c.name}}</option>
                </select>
            </div>
            <div class="input up">
                <v-select label="name" :options="c" :reduce="(c) => c.name" 
                    v-model="city"></v-select>
                <!-- <select v-model="city" :disabled="state == ''">
                    <option value="" disabled selected>City</option>
                    <option v-for="c in c" :value="c.name" :key="c.name">{{c.name}}</option>
                </select> -->
            </div>
            <div class="btn" @click="save()">Save</div>
        </div>
    </div>
</template>

<script>
import close from '../icons/remove.vue'
export default {
    components: {
        close
    },
    data() {
        return {
            country: '',
            state: '',
            city: '',
            countries: [],
            states: [],
            s: [],
            cities: [],
            c: []
        }
    },
    mounted() {
        this.$http.get('/properties/locations')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.countries = r.countries
                this.states = r.states
                this.cities = r.cities
            }
        })
    },
    watch: {
        country() {
            this.set_states()
        },
        state() {
            this.set_cities()
        }
    },
    methods: {
        save() {
            let i = this.countries.findIndex(country => country.name == this.country)
            console.log(i);
            if (this.country != ''  && this.state != '' && this.city != '') {
                this.$emit('save', {
                    l: {
                        country: this.country,
                        state: this.state,
                        city: this.city
                    },
                    p: this.countries[i]
                })
            }
        },
        set_states() {
            this.s = []
            let x
            for (x of this.states) {
                if (x.country == this.country) {
                    this.s.push(x)
                    this.state = ''
                    this.city = ''
                }
            }
        },
        set_cities() {
            this.city = ''
            this.c = []
            let x
            for (x of this.cities) {
                if (x.state == this.state) {
                    this.c.push(x)
                }
            }
        }
    }
}
</script>

<style scoped>

    .pop {
        background-color: white;
        position: relative;
        z-index: 4;
        width: 300px;
        padding: 20px;
        border-radius: 10px;
        animation: pop .3s;
        
    }
    @keyframes pop {
        from {
            transform: scale(.7);
        }
        to {
            transform: scale(1);
        }
    }
    .close {
        position: absolute;
        right: -10px;
        top: -10px;
        fill: var(--main);
        cursor: pointer;
    }

    .title {
        font-weight: 500;
    }

    
    select {
        margin-top: 20px;
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        background-color: white;
        box-sizing: border-box;
        border-radius: 5px;
    }
    .up {
        margin-top: 20px;
    }
    .btn {
        margin-top: 20px;
        background-color: var(--main);
        padding: 8px 30px;
        border-radius: 5px;
        margin-left: auto;
        width: fit-content;
        color: white;
        font-weight: 500;
        cursor: pointer;
    }
</style>