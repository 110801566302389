<template>
    <div class="seetings">
        <div class="title">Settings</div>
        <user/>
    </div>
</template>

<script>
import user from '../components/settings/user.vue'
export default {
    components: {
        user
    },
    mounted () {
        window.scrollTo(0, 0)
    },
}
</script>

<style scoped>

    .seetings {
        padding: 20px;
    }
    .title {
        font-weight: 500;
        font-size: 20px;
    }

</style>