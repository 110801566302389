<template>
    <div class="verify">
        <headerLayout class="h" :no_bg="true"/>

        <div class="main">
            <div class="title">Verify Email Address</div>
            <div v-if="current == 0">
                <div class="text">
                    In order to start using Bigglandlord, you need to verify your email addresss {{email}}
                </div>
                <div class="btn" @click="verify_email()">Verify Email</div>
            </div>
            <div v-if="current == 1">

                <div class="small">
                    A verification code has been sent to {{email}} enter the code below to verify your account
                </div>
                
                <div class="input">
                    <div class="label" v-if="code != ''">Verification code:</div>
                    <input type="text" placeholder="Enter verification code" v-model="code">
                    <div class="err" v-if="c">the code you entered is inccorect</div>
                </div>
                
                <loader :height="'37px'" v-if="loading"/>
                <div class="btn" v-else @click="verify()">Verify</div>

                <div class="text2">
                    Didn't get a code? <span @click="resend_code()">Resend Code</span> 
                    or verify via <span @click="show_whatsapp = true">Whatsapp</span>
                </div>
                
            </div>
        </div>

        <whatsapp v-show="show_whatsapp" @close="show_whatsapp = false"/>
    </div>
</template>

<script>
import moment from 'moment'

import headerLayout from '../components/header.vue'
import loader from '../components/loader.vue'

import whatsapp from '../components/verify/whatsapp.vue'

export default {

    components: {
        headerLayout, loader,
        whatsapp
    },
    data () {
        return {
            last_email: '',
            email: 'chri@gmail.com',
            code: '',
            loading: false,
            c: false,

            show_whatsapp: false,

            current: 0
        }
    },
    mounted () {
        window.scrollTo(0, 0)

        let email_sent = localStorage.getItem('email_sent')
        if (email_sent) {
            this.current = 1
        }

        let last_email = localStorage.getItem('email_sent_time')
        if (last_email) {
            this.last_email = last_email
        }

        let email = localStorage.getItem('email')

        if (email == null) { 
            this.$router.push('/')
        } else {
            this.email = email
        }
    },
    methods: {
        verify_email() {
            this.current = 1
            this.send_otp()
            this.otp_sent()
        },
        send_otp() {

            let time = new Date()

            this.last_email = time

            localStorage.setItem('email_sent_time', time)
            localStorage.setItem('email_sent', true)

            this.$http.post('/send_otp')
            this.otp_sent()
        },
        resend_code() {
            if (this.last_email != '') {
                let last_moment = moment(this.last_email) 
                let diff = moment().diff(last_moment, 'minutes', true)

                if (diff < 1) {
                    this.wait(); return
                }
            }

            this.send_otp()
        },
        verify() {
            this.c = false
            this.loading = true

            let form = new FormData()
            form.append('code', this.code)

            this.$http.post('verify_email', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    localStorage.removeItem('email')
                    this.$router.push('/dashboard')
                } else {
                    this.c = true
                }
                this.loading = false
            })
        }
    },
    notifications: {
        otp_sent: {
            type: 'success',
            title: 'Code sent',
            message: 'verification code has been sent'
        },
        wait: {
            type: 'info',
            title: '',
            message: 'Please wait at least 1 min before resending code'
        }
    }
}
</script>

<style scoped>

    .main {
        max-width: 350px;
        margin: 150px auto;
        margin-top: 230px;
        padding: 30px;
        background-color: #edeef0;
        border-radius: 10px;
    }
    .h {
        position: fixed;
        width: 100%;
        box-sizing: border-box;
        top: 0px;
        left: 0px;
        z-index: 5;
        color: white;
    }
    .title {
        font-weight: 800;
        font-size: 20px;
    }
    .text {
        margin-top: 20px;
        font-size: 14px;
        line-height: 22px;
    }
    .small {
        font-size: 12px;
        font-weight: 500;
        opacity: .7;
        margin-bottom: 30px;
        margin-top: 5px;
    }
    .input {
        margin-bottom: 30px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -20px;
        left: 10px;
        font-weight: 500;
        font-size: 14px;
        animation: .2s up;
    }
    @keyframes up {
        from {
            top: 0px;
        }
        to {
            top: -20px;
        }
    }
    input {
        width: 100%;
        padding: 12px;
        border-radius: 5px;
        /* border: 1px solid #ccc; */
        border: none;
        box-sizing: border-box;
        outline: none;
    }
    .err {
        position: absolute;
        right: 0px;
        bottom: -13px;
        font-size: 12px;
        color: red;
        font-weight: 500;
    }
    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 10px 30px;
        border-radius: 5px;
        font-weight: 500;
        margin-top: 20px;
        text-align: center;
    }

    .text2 {
        font-size: 14px;
        margin-top: 20px;
    }
    .text2 span {
        color: var(--main);
        font-weight: 600;
        cursor: pointer;
    }
</style>