<template>
    <div class="dashboard">
        <div class="header">
            <div class="menuicon" @click="show_menu = true">
                <menuicon :size="'20px'"/>
            </div>
            <div class="logo">Bigglandlord Employee</div>
            <div class="logout" @click="logout()">Logout</div>
        </div>
        <div class="grid">
            <menulayout class="web_menu"/>
            <div class="main" v-if="ready">
                <router-view></router-view>
            </div>
        </div>

        <div class="popup" v-if="show_menu">
            <div class="dark" @click="show_menu = false"></div>
            <menulayout @clicked="show_menu = false"/>
        </div>
    </div>
</template>

<script>
import Localbase from 'localbase'
import menulayout from '../../components/employee/menu.vue';

import menuicon from '../../icons/menu.vue'

export default {
    components: {
        menulayout, menuicon
    },
    mounted() {
        this.db.collection('auth').doc({ id: 2 }).get().then( auth => {
            if ( auth == undefined ) {
                this.$router.push('/employee/login')
            } else {
                this.$http.defaults.headers.common['Auth'] = auth.key
                setTimeout(() => {
                    this.ready = true
                }, 1000)
            }
        })
    },
    data() {
        return {
            db: new Localbase('Bigglandlord'),
            ready: false,

            show_menu: false
        }
    },
    methods: {
        logout() {
            this.db.collection('auth').doc({ id: 2 }).delete()
            this.$router.push('/admin/login')
        }
    }
}
</script>

<style scoped>

    .header {
        display: flex;
        align-items: center;
        gap: 20px;
        /* justify-content: space-between; */
        padding: 20px;
        box-sizing: border-box;
        border-bottom: 2px solid #edeef0;
    }
    .menuicon {
        display: none;
        cursor: pointer;
        fill: var(--main);
    }
    .logo {
        color: var(--main);
        font-weight: 500;
        font-size: 18px;
    }
    .logout {
        padding: 8px 20px;
        box-shadow: 0px 0px 10px #ddd;
        /* border: 1px solid #ddd; */
        border-radius: 10px;
        font-weight: 500;
        cursor: pointer;
        margin-left: auto;
    }

    .grid {
        display: grid;
        grid-template-columns: max-content auto;
        height: calc(100vh - 79px);
    }
    .main {
        background-color: #edeef0;
        height: 100%;
        overflow: auto;
    }

    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: none;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    @media screen and (max-width: 800px) {
        .menuicon {
            display: block;
        }
        .grid {
            grid-template-columns: auto;
        }
        .web_menu {
            display: none;
        }
        .popup {
            display: block;
        }
        .menu {
            width: 70vw;
            background-color: white;
            height: 100vh;
            z-index: 3;
            position: relative;
        }
    }
</style>