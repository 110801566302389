<template>
    <div class="register">
        <div class="form">
            <div class="title">Register</div>
            <div class="small">Fill in your details to register as a property manager or landlord</div>
        
            <div class="input">
                <div class="label">Full Name</div>
                <input type="text" placeholder="Enter your full name" v-model="name">
            </div>
            <div class="input">
                <div class="label">Phone Number</div>
                <input type="text" placeholder="Enter your phone number" v-model="phone">
            </div>
            <div class="input">
                <div class="label">Whatsapp Number</div>
                <input type="text" placeholder="Enter your whatsapp number" v-model="whatsapp">
            </div>
            <div class="input">
                <div class="label">Email Address</div>
                <input type="text" placeholder="Enter your email address" v-model="email">
            </div>
            <div class="input">
                <div class="label">Company Name</div>
                <input type="text" placeholder="Enter your company name" v-model="company">
            </div>
            <div class="input">
                <div class="label">Address</div>
                <input type="text" placeholder="Enter your address" v-model="address">
            </div>

            <loader :height="'41px'" v-if="loading"/>
            <div class="btn" @click="register()" v-else>Create</div>
        </div>
    </div>
</template>

<script>
import loader from '../loader.vue'
export default {
    components: {
        loader
    },
    data() {
        return {
            name: '',
            phone: '',
            whatsapp: '',
            email: '',
            company: '',
            address: '',

            loading: false
        }
    },
    methods: {
        register() {
            if (this.name == '' || this.phone == '' || this.whatsapp == '' || this.email == '' ||
                this.company == '' || this.address == '') {
                    this.empty(); return
                }
            
            this.loading = true
            
            let form = new FormData()
            form.append('name', this.name)
            form.append('phone', this.phone)
            form.append('whatsapp', this.whatsapp)
            form.append('email', this.email)
            form.append('company', this.company)
            form.append('address', this.address)
            
            this.$http.post('/manager/register', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    this.$root.$data.is_manager = 'ok'
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Make sure all field are filled'
        }
    }
}
</script>

<style scoped>

    .form {
        padding: 20px;
        background-color: white;
        border-radius: 5px;
        max-width: 400px;
        margin: 0 auto;
        margin-top: 30px;
    }
    .title {
        font-size: 20px;
        font-weight: 500;
    }
    .small {
        font-size: 12px;
        font-weight: 500;
        color: gray;
        margin-bottom: 30px;
    }
    

    .input {
        margin-bottom: 25px;
        position: relative;
    }
    .label {
        position: absolute;
        background-color: white;
        padding: 0px 5px;
        top: -10px;
        left: 10px;
        transition: .2s;
        font-weight: 500;
    }
    input, select {
        padding: 15px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
    }
    
    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: 500;
        text-align: center;
    }
</style>