<template>
    <div class="current">
        <property :property="property"/>
        <div class="main">
            <div class="flex">
                <div class="label">Partner</div>
                <div class="value">{{ property.name }}</div>
            </div>
            <div class="flex">
                <div class="label">Partner Id</div>
                <div class="value">{{ property.partner_id }}</div>
            </div>
            <div class="flex">
                <div class="label">User</div>
                <div class="value">{{ property.firstname }} {{ property.lastname }}</div>
            </div>
            <div class="flex last">
                <div class="label">User id</div>
                <div class="value">{{ property.user_id }}</div>
            </div>
            <div class="foot">
                <loader :height="'35px'" v-if="loading"/>
                <div class="btn" v-else @click="checked()">Checked</div>
            </div>
        </div>
    </div>
</template>

<script>
import property from '../../../property.vue';
import loader from '../../../loader.vue';

export default {
    components: {
        property, loader
    },
    props: ['property'],
    data() {
        return {
            loading: false
        }
    },
    methods: {
        checked() {
            this.loading = true

            let form = new FormData()
            form.append('appt_id', this.property.appt_id)
            form.append('agent', this.property.agent)


            this.$http.post('/employee/agent/appointment/checked', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.$router.go()
                }
            })
        }
    }
}
</script>

<style scoped>

    .current {
        background-color: white;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #ddd;
    }

    .main {
        
        padding-top: 20px;
        border-top: 1px solid #ddd;
    }
    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
        font-size: 14px;
        padding: 5px 20px;
        border-bottom: 1px solid #ddd;
    }
    .value {
        font-weight: 500;
    }
    .foot {
        padding: 20px;
    }
    .btn {
        padding: 8px;
        background-color: var(--main);
        color: white;
        font-weight: 500;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
    }
</style>