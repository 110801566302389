<template>
    <div class="table">
        <div class="head">
            <div class="title">{{title}}</div>
            <div class="search">
                <input type="text" placeholder="search">
            </div>
        </div>

        <div class="label">
            <div class="text">Name</div>
            <div class="text">Phone Number</div>
            <div class="text">City</div>
            <div class="text">Agent - id</div>
        </div>

        <div class="loader" v-if="loading">
            <div class="spin"></div>
        </div>
        <div class="empty" v-if="!loading && data.length == 0">
            There are currently no agents here at the moment
        </div>

        <agent v-for="(agent, i) in data" :key="agent.user_id" :info="agent" :index="i"
            @move="$emit('move', $event)"/>
    </div>
</template>

<script>
import agent from './agent.vue'
export default {
    components: {
        agent
    },
    props: ['title', 'data', 'loading']
}
</script>

<style scoped>
    .table {
        margin-top: 30px;
        border-radius: 5px;
        overflow: hidden;
        background-color: white;
    }
    .head {
        /* background-color: var(--main); */
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        border-bottom: 1px solid #ddd;
    }
    .title {
        font-weight: 500;
        font-size: 20px;
        /* color: white; */
    }
    input {
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ddd;
    }
    .label {
        font-weight: 500;
        display: grid;
        grid-template-columns: calc(40% - 10px) 20% 20% 20%;
        padding-left: 20px;
    }
    .text {
        padding: 10px 0px;
    }

    .empty {
        border-top: 1px solid #ccc;
        padding: 20px;
    }
    .spin {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: var(--main);
        animation: rotate 1s infinite linear;
        margin: 20px auto;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>