<template>
    <div class="appointments">
        <div class="title"> Appointments </div>
        <div class="menu">
            <div class="item" :class="[c == 0 ? 'a' : '']" @click="setTo(0)">Current</div>
            <div class="item" :class="[c == 1 ? 'a' : '']" @click="setTo(1)">All</div>
        </div>

        <div class="loading" v-if="load">
            <div class="spin"></div>
        </div>

        <div class="main" v-else>
            <div v-if="c == 0">
                <div class="no" v-if="!is_current && !load">
                    <appointmenticon :size="'30px'"/>
                    <div>You don't have any appointment at the moment</div>
                </div>
                <partner :p="partner" v-if="is_current"/>
            </div>
            <div v-if="c == 1">
                <div class="grid">
                    <appointment v-for="p in property" :key="p.appt_id" :property="p"/>
                </div>
                
                <div class="no" v-if="property.length == 0 && !load">
                    <appointmenticon :size="'30px'"/>
                    <div>You don't have any appointment at the moment</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import partner from '../../components/appointment/partner_appointment.vue'
import appointmenticon from '../../icons/appointment.vue'

import appointment from '../../components/partner/appointment.vue'

export default {
    components: {
        partner,
        appointmenticon,
        appointment
    },
    data() {
        return {
            c: 0,
            load: true,
            partner: {},
            property: [],
            is_current: true
        }
    },
    mounted() {
        this.fetchCurrent()
    },
    methods: {
        setTo(c) {
            this.c = c
            this.load = true

            if (c == 0) { this.fetchCurrent() }
            if (c == 1) { this.fetchAll() }
        },
        fetchCurrent() {
            
            let form = new FormData()
            form.append('code', '')

            this.is_current = true

            this.$http.post('/partner/appointment', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.partner = r.property
                    if (!r.property) {
                        this.is_current = false
                    }
                    this.load = false
                }
            })
        },
        fetchAll() {
            this.$http.get('/partner/appointments')
            .then(data => {
                let r = data.data

                if (r.success) {
                    this.property = r.property

                    this.load = false
                }
            })
        }
    }
}
</script>

<style scoped>

    .appointments {
        padding: 20px;
        position: relative;
    }
    .title {
        font-weight: 500;
        font-size: 20px;
    }

    .menu {
        display: grid;
        grid-template-columns: 50% 50%;
        background-color: white;
        /* border-radius: 5px; */
        overflow: hidden;

        margin: 0px -20px;
        margin-top: 20px;
        position: sticky;
        top: 0px;
        z-index: 3;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .item {
        text-align: center;
        font-weight: 500;
        padding: 15px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        font-size: 14px;
    }
    .a {
        border-bottom-color: var(--main);
        color: var(--main);
    }

    
    .spin {
        margin: 70px auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid transparent;
        border-top-color: var(--main);
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .no {
        padding-top: 80px;
        fill: gray;
        text-align: center;
        font-size: 14px;
    }
    
    .grid {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-gap: 20px;
    }
</style>