<template>
    <div class="appointment">
        <div class="label">Appointments</div>
        <div class="no">{{appointments}}</div>
        <div class="t">Current Appointment</div>
        <div class="flex" v-if="appointment.length > 0">
            <div class="time" v-if="date != ''">
                <countdown :end="appointment[0].date"/>
            </div>
            
            <router-link :to="'/property/' + appointment[0].property">
            <div class="btn">View</div>
            </router-link>
        </div>
        <div v-else class="em">
            You don't have any appointment currently
        </div>

        <router-link to="/dashboard/appointments">
            <div class="view">View All Appointments</div>
        </router-link>
    </div>
</template>

<script>
import countdown from '../countdown.vue'
export default {
    components: {
        countdown
    },
    props: ['appointment', 'appointments'],
    data() {
        return {
            date: ''
        }
    },
    mounted() {
        var dateOffset = (24*60*60*1000) * 5
        
        var d = new Date();
        d.setTime(d.getTime() + dateOffset);

        this.date = d.toString()
        console.log('r', this.date);
    }
}
</script>

<style scoped>

    .appointment {
        background-color: white;
        padding: 20px 0px ;
        border-radius: 10px;
        overflow: hidden;
    }
    .label {
        background-color: var(--main);
        padding: 10px 20px;
        color: white;
        font-weight: 500;
        width: fit-content;
    }
    .no {
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        padding: 30px 0px;
    }
    .t {
        margin-left: 20px;
        font-weight: 500;
    }

    .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #edeef0;
        padding: 10px 20px;
        margin-top: 5px;
    }

    .em {
        padding: 15px 20px;
        margin-top: 10px;
        background-color: #edeef0;
    }



    .btn {
        background-color: white;
        padding: 5px 30px;
        color: var(--main);
        font-weight: 500;
        border-radius: 5px;
        cursor: pointer;
    }
    .view {
        background-color: var(--trans);
        color: var(--main);
        padding: 8px;
        text-align: center;
        cursor: pointer;
        margin: 0px 20px;
        margin-top: 20px;
        font-weight: 500;
        border-radius: 5px;
    }
</style>