<template>
    <div>
        <svg enable-background="new 0 0 24 24" :width="size" version="1.1" 
        viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" 
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <g><g>
            <path d="M19.1,23.5c-2.4,0-4.3-1.9-4.4-4.3l-7.3-3.7c-0.8,0.6-1.6,0.8-2.6,0.8c-2.4,0-4.4-2-4.4-4.4c0-2.4,2-4.4,4.4-4.4    
            c0.9,0,1.8,0.3,2.6,0.8l7.3-3.7c0-2.4,2-4.3,4.4-4.3c2.4,0,4.4,2,4.4,4.4c0,2.4-2,4.4-4.4,4.4c-0.9,0-1.8-0.3-2.6-0.8L9.4,12    
            l7.2,3.6c0.7-0.5,1.6-0.8,2.6-0.8c2.4,0,4.4,2,4.4,4.4C23.5,21.5,21.5,23.5,19.1,23.5z"/>
        </g></g>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>