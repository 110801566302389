<template>
    <div class="popup">
        <div class="dark" @click="$emit('close')"></div>

        <div class="box">
            <div class="title">Add Property</div>
            <div class="input">
                <div class="label">Property Name</div>
                <input type="text" placeholder="Enter property name for identification purpose" v-model="name">
            </div>
            <div class="input">
                <div class="label">Property Address</div>
                <input type="text" placeholder="Enter property address" v-model="address">
            </div>
            
            <loader :height="'41px'" v-if="loading"/>
            <div class="btn" @click="add()" v-else>Add</div>
        </div>
    </div>
</template>

<script>
import loader from '../loader.vue'

export default {
    components: {
        loader
    },
    data() {
        return {
            name: '',
            address: '',
            loading: false,
        }
    },
    methods: {
        add() {
            if (this.name == '' || this.address == '') {
                this.empty(); return
            }

            this.loading = true

            let form = new FormData()
            form.append('name', this.name)
            form.append('address', this.address)

            this.$http.post('/manager/house/add', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.$emit('new_house', {
                        'name': this.name,
                        'address': this.address,
                        'house_id': r.id,
                        'tenants': 0,
                        'date': r.date
                    })
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Make sure all field are filled'
        }
    }
}
</script>

<style scoped>
    .popup {
        position: fixed;
        z-index: 2;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
    }
    .box {
        background-color: white;
        border-radius: 5px;
        padding: 20px;
        z-index: 3;
        width: 300px;
    }
    .title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    
    .input {
        margin-bottom: 25px;
        position: relative;
    }
    .label {
        position: absolute;
        background-color: white;
        padding: 0px 5px;
        top: -10px;
        left: 10px;
        transition: .2s;
        font-weight: 500;
        font-size: 14px;
    }
    input, select {
        padding: 15px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
    }
    
    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: 500;
        text-align: center;
    }
</style>