<template>
    <div class="processing">
        <div class="box">
            <processing :size="'50px'"/>
            <div class="title">Processing</div>
            <div class="text">
                Your registration to be an agent is currently under review, we will get back to you shortly
            </div>
        </div>
    </div>
</template>

<script>
import processing from '../../icons/processing.vue'
export default {
    components: {
        processing
    }
}
</script>

<style scoped>
    .processing {
        height: calc(100vh - 198px);
        /* height: 100%; */
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .box {
        background-color: white;
        width: fit-content;
        padding: 30px;
        border-radius: 20px;
        text-align: center;
        max-width: 300px;
    }
    .title {
        font-weight: 500;
        font-size: 25px;
        margin: 20px;
    }
</style>