<template>
    <div class="be">
        <div class="title"> Become An Agent On Bigglandlord </div>
        <div class="small">
            Register as an agent now and get the privilege to post properties on Bigglandlord
        </div>
        <div class="form">
            <div class="t">Registration form</div>
            
            <div class="input">
                <div class="label" v-if="name != ''">Official Name</div>
                <input type="text" placeholder="Enter Official Name" v-model="name">
            </div>
            <div class="input">
                <div class="label" v-if="business_name">Business Name (Optional)</div>
                <input type="text" placeholder="Enter Business Name (Optional)" v-model="business_name">
            </div>
            <div class="input">
                <div class="label" v-if="address != ''">Office Address</div>
                <input type="text" placeholder="Enter Office Address" v-model="address">
            </div>

            <div class="input under">
                <div class="label" v-if="country != ''">Country</div>
                <select v-model="country">
                    <option value="" disabled selected>Country</option>
                    <option v-for="c in countries" :key="c.name" :value="c">{{c.name}}</option>
                </select>
            </div>
            <div class="input under">
                <div class="label" v-if="state != ''">State</div>
                <select v-model="state">
                    <option value="" disabled selected>State</option>
                    <option v-for="c in s" :value="c.name" :key="c.name">{{c.name}}</option>
                </select>
            </div>
            <div class="input under">
                <div class="label" v-if="city != ''">City</div>
                <select v-model="city">
                    <option value="" disabled selected>City</option>
                    <option v-for="c in c" :value="c.name" :key="c.name">{{c.name}}</option>
                </select>
            </div>

            <div class="input">
                <div class="label" v-if="phone != ''">Phone Number</div>
                <input type="number" placeholder="Enter Phone Number" v-model="phone">
            </div>
            <div class="input">
                <div class="label" v-if="alter_no != ''">Alternate Number</div>
                <input type="number" placeholder="Enter Alternate Number" v-model="alter_no">
            </div>
            <div class="input">
                <div class="label" v-if="whatsapp != ''">Whatsapp Number</div>
                <input type="number" placeholder="Enter Whatsapp Number" v-model="whatsapp">
            </div>
            <div class="input">
                <div class="label" v-if="facebook != ''">Personal Facebook Profile Link</div>
                <input type="text" placeholder="Enter Personal Facebook Profile Link" v-model="facebook">
            </div>
            <div class="input">
                <div class="label" v-if="business_facebook != ''">Business Facebook Page (Optional)</div>
                <input type="text" placeholder="Enter Business Facebook Profile Link (Optional)" v-model="business_facebook">
            </div>
            <div class="input">
                <div class="label" v-if="guarantor_name != ''">Guarantor Name</div>
                <input type="text" placeholder="Enter Guarantor Name" v-model="guarantor_name">
            </div>
            <div class="input">
                <div class="label" v-if="guarantor_phone != ''">Guarantor Phone Number</div>
                <input type="number" placeholder="Enter Guarantor Phone Number" v-model="guarantor_phone">
            </div>
            <div class="input">
                <div class="label" v-if="guarantor_address != ''">Guarantor Address</div>
                <input type="text" placeholder="Enter Guarantor Address" v-model="guarantor_address">
            </div>
            <div class="input">
                <div class="label" v-if="next_of_kin_name != ''">Next of Kin Name</div>
                <input type="text" placeholder="Enter Next of Kin Name" v-model="next_of_kin_name">
            </div>
            <div class="input">
                <div class="label" v-if="next_of_kin_address != ''">Next of Kin Address</div>
                <input type="text" placeholder="Enter Next of Kin Address" v-model="next_of_kin_address">
            </div>
            <div class="input">
                <div class="label" v-if="next_of_kin_phone != ''">Next of Kin Phone</div>
                <input type="number" placeholder="Enter Next of Kin Phone" v-model="next_of_kin_phone">
            </div>
            <div class="input">
                <div class="label" v-if="next_of_kin_facebook != ''">Next of Kin Facebook Profile Link</div>
                <input type="text" placeholder="Enter Next of Kin Facebook Profile Link" v-model="next_of_kin_facebook">
            </div>

            <div class="t">Means of Verification</div>

            <div class="flex">
                <div class="upload" @click="upload('id')" v-if="id == null">Upload your ID</div>
                <div class="image" v-else>
                    <img :src="id_display"/>
                </div>

                <div class="info">
                    <div> <b> Allowed Documents </b> </div>
                    <div>Driver License</div>
                    <div>International Passport</div>
                    <div>NIN</div>
                    <div>Any Govt. Approved ID</div>
                </div>
            </div>
            <div class="flex">
                <div class="upload" @click="upload('utility')" v-if="utility == null">
                    Upload Proof of Address
                </div>
                <div class="image" v-else>
                    <img :src="utility_display"/>
                </div>

                <div class="info">
                    <div> <b> Allowed Documents </b> </div>
                    <div>Any Govt. Approved Utility Bill</div>
                    <div>eg. electricity bill, waste bill</div>
                </div>
            </div>
            <div class="flex">
                <div class="upload" @click="upload('image')" v-if="image == null">Upload Your Picture</div>
                <div class="image" v-else>
                    <img :src="image_display"/>
                </div>

                <div class="info">
                    <div> <b> Allowed Picture </b> </div>
                    <div>A very clear selfie of your self</div>
                </div>
            </div>

            <loader :height="'41px'" v-if="loading"/>
            <div class="btn" v-else @click="send()">Register</div>
        </div>
        
        <input type="file" accept="image/*" class="hidden" ref="id" @change="handleDp($event, 'id', 'id_display')"/>
        <input type="file" accept="image/*" class="hidden" ref="utility" @change="handleDp($event, 'utility', 'utility_display')"/>
        <input type="file" accept="image/*" class="hidden" ref="image" @change="handleDp($event, 'image', 'image_display')"/>
    </div>
</template>

<script>
import loader from '../loader.vue'
import * as imageConversion from 'image-conversion';
export default {
    components: {
        loader
    },
    mounted() {
        window.scrollTo(0, 0)

        this.$http.get('/properties/locations')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.countries = r.countries
                this.states = r.states
                this.cities = r.cities
            }
        })
    },
    watch: {
        country() {
            this.set_states()
        },
        state() {
            this.set_cities()
        }
    },
    data() {
        return {
            countries: [],
            states: [],
            cities: [],

            s: [],
            c: [],

            name: '',
            business_name: '',
            address: '',
            country: '',
            state: '',
            city: '',
            phone: '',
            alter_no: '',
            whatsapp: '',
            facebook: '',
            business_facebook: '',
            guarantor_name: '',
            guarantor_phone: '',
            guarantor_address: '',
            next_of_kin_name: '',
            next_of_kin_address: '',
            next_of_kin_phone: '',
            next_of_kin_facebook: '',

            id: null,
            utility: null,
            image: null,

            id_display: '',
            utility_display: '',
            image_display: '',

            uploading: false,
            loading: false,
        }
    },
    methods: {
        upload(what) {
            if (this.uploading) {
                this.up(); return
            }
            this.$refs[what].click()
        },
        handleDp(event, handle, display) {
            this.uploading = true
            let mediaType = event.target.files[0].type
            if (mediaType.includes('image')) {
                const picture = event.target.files[0]
                
                this.compress(picture, handle)
                const reader = new FileReader()
                reader.readAsDataURL(picture)
                reader.onload = event => {
                    this[display] = event.target.result
                }
            }
        },
        compress(file, handle) {
            let name = file.name
            imageConversion.compressAccurately(file, 500).then(res=>{
                // this.is_image = true
                
                this[handle] = new File([res], name)
                this.uploading = false
            })
        },
        set_states() {
            this.s = []
            this.c = []
            let x
            for (x of this.states) {
                if (x.country == this.country.name) {
                    this.s.push(x)
                    this.state = ''
                    this.city = ''
                }
            }
            // this.set_types()
        },
        set_cities() {
            this.c = []
            let x
            for (x of this.cities) {
                if (x.state == this.state) {
                    this.c.push(x)
                }
            }
        },
        send() {
            if (
                this.name == '' || this.address == '' || this.phone == '' || this.alter_no == '' ||
                this.whatsapp == '' || this.facebook == '' || this.guarantor_name == '' ||
                this.guarantor_phone == '' || this.guarantor_address == '' || this.next_of_kin_name == '' ||
                this.next_of_kin_address == '' || this.next_of_kin_phone == '' || this.next_of_kin_facebook == '' ||
                this.state == '' || this.city == ''
            ) {
                this.empty(); return
            }
            if (this.whatsapp.length != 11 || this.phone.length != 11 || this.alter_no.length != 11) {
                this.no(); return
            }
            if (this.id == null || this.utility == null || this.image == null) {
                this.doc(); return
            }

            this.loading = true
            let form = new FormData()

            form.append('name', this.name)
            form.append('business_name', this.business_name)
            form.append('address', this.address)
            form.append('country', this.country.name)
            form.append('state', this.state)
            form.append('city', this.city)

            form.append('phone', this.phone)
            form.append('alter_no', this.alter_no)
            form.append('whatsapp', this.whatsapp)
            form.append('facebook', this.facebook)
            form.append('business_facebook', this.business_facebook)
            form.append('guarantor_name', this.guarantor_name)
            form.append('guarantor_phone', this.guarantor_phone)
            form.append('guarantor_address', this.guarantor_address)
            form.append('next_of_kin_name', this.next_of_kin_name)
            form.append('next_of_kin_address', this.next_of_kin_address)
            form.append('next_of_kin_phone', this.next_of_kin_phone)
            form.append('next_of_kin_facebook', this.next_of_kin_facebook)

            form.append('id', this.id)
            form.append('utility', this.utility)
            form.append('image', this.image)

            this.$http.post('/agent/register', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()
                    this.$root.$data.agent = 'processing'
                } else {
                    this.wrong()
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: 'Empty',
            message: 'Some fields are empty'
        },
        no: {
            type: 'warn',
            title: 'Invalid',
            message: 'your number must be 11 digit'
        },
        doc: {
            type: 'warn',
            title: 'Document',
            message: 'Make sure all document are uploaded'
        },
        success: {
            type: 'success',
            title: 'Success',
            message: 'Your details has been submitted'
        },
        wrong: {
            type: 'warn',
            title: 'Error',
            message: 'Something went wrong, try again later'
        },
        up: {
            type: 'warn',
            title: 'Uploading',
            message: 'we are uploading your document, kindly wait 2 seconds'
        }
    }
}
</script>

<style scoped>
    .title {
        font-weight: 500;
        font-size: 25px;
    }
    .small {
        font-size: 14px;
    }

    .input {
        margin-bottom: 30px;
        position: relative;
    }
    .label {
        position: absolute;
        background-color: white;
        padding: 0px 5px;
        top: -10px;
        left: 10px;
        transition: .2s;
        font-weight: 500;
    }
    input, select {
        padding: 15px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
    }

    .form {
        max-width: 500px;
        background-color: white;
        padding: 30px;
        border-radius: 20px;
        margin-top: 30px;
    }
    .t {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 30px;
    }

    .flex {
        display: flex;
        margin-bottom: 30px;
    }
    .upload {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 120px;
        text-align: center;
        background-color: var(--trans);
        border: 1px dotted var(--main);
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
    }
    .image {
        width: 120px;
        overflow: hidden;
    }
    img {
        width: 100%;
    }
    .info {
        font-size: 12px;
        /* font-weight: 500; */
        margin-left: 10px;
    }
    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: 500;
        margin-top: 15px;
        text-align: center;
    }

    
    input[type=file] {
        visibility: hidden;
        display: none;
    }
</style>