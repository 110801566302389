<template>
    <div class="whatsapp">
        <div class="head">
            <div class="title">Whatsapp API</div>
        </div>

        <div class="main">
            <div class="grid">
                <div class="box">
                    <div class="t"> Whatsapp Logs</div>
                </div>
                <div class="box">
                    <div class="t">Whatsapp API</div>
                    <div class="qr">
                        <QrcodeVue :value="qr.qr" :size="300" level="H"/>
                        <div class="flexx">
                            <div class="flex">
                                <div class="label">Generated:</div>
                                <div class="value">{{ qr.date }}</div>
                            </div>
                            <div class="flex">
                                <div class="label">Status:</div>
                                <div class="value">{{ qr.status }}</div>
                            </div>
                        </div>
                        <div class="up">
                            <loader :height="'34px'" v-if="loading"/>
                            <div class="btn" @click="connect_whatsapp()" v-else>Start</div>
                        </div>
                        <div class="up">
                            <loader :height="'34px'" v-if="loggingout"/>
                            <div class="btn" @click="logout_whatsapp()" v-else>Logout</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <connect v-if="show" @close="show = false"/>
    </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import connect from '../components/admin/whatsapp/connect.vue';
import loader from '../components/loader.vue';

export default {
    components: {
        QrcodeVue,
        connect, loader
    },
    data() {
        return {
            show: false,
            status: '',
            qr: {
                qr: 'whatsapp',
                status: ''
            },
            interval: null,

            loading: false,
            loggingout: false
        }
    },
    destroyed() {
        clearInterval(this.interval)
    },
    mounted() {
        this.interval = setInterval(() => {

            this.$http.post('/admin/whatsapp/info')
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.qr = r.qr

                    if (r.qr.status == 'READY') {
                        clearInterval(this.interval)
                    }
                }
            })
        }, 5000)

    },
    methods: {
        logout_whatsapp() {
            this.loggingout = true

            this.$http.post('/admin/whatsapp/logout')
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.loggingout = false
                }
            })
        },
        connect_whatsapp() {
            this.loading = true

            this.$http.post('/admin/whatsapp/connect')
            .then(data => {
                let r = data.data
                if (r.success) {
                    
                    this.loading = false
                }
            })
        }
    }
}
</script>

<style scoped>
    .whatsapp {
        padding: 30px;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        gap: 20px;
    }
    .title {
        font-weight: 500;
        font-size: 18px;
    }
    .btn {
        padding: 8px 30px;
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
    }

    .grid {
        display: grid;
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        gap: 20px;
    }

    .box {
        background-color: white;
        padding: 20px;
        border-radius: 5px;
    }
    .qr {
        margin-top: 10px;
    }

    .up {
        margin-top: 20px;
    }
    .flex {
        display: flex;
        gap: 20px;
        font-size: 14px;
        margin-top: 5px;
    }
    .label {
        color: gray;
    }
    .value {
        font-weight: 500;
    }
</style>