<template>
    <div class="agent">
        <div class="title">
            Agents
        </div>
        <agent_table :title="'New Agents'" :data="pending" :loading="loading"
            @move="move($event.index, 'pending', $event.to)"/>

        <agent_table :title="'Verified Agents'" :data="verified" :loading="loading"
            @move="move($event.index, 'verified', $event.to)"/>

        <agent_table :title="'Rejected Agents'" :data="rejected" :loading="loading"
            @move="move($event.index, 'rejected', $event.to)"/>

        <agent_table :title="'Blocked Agents'" :data="blocked" :loading="loading"
            @move="move($event.index, 'blocked', $event.to)"/>
    </div>
</template>

<script>
import agent_table from '../components/admin/agent/table.vue'
export default {
    components: {
        agent_table
    },
    data() {
        return {
            pending: [],
            verified: [],
            rejected: [],
            blocked: [],
            loading: true
        }
    },
    created() {
        
        const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
            localStorage.removeItem('agent_page_reloaded')
            next()
            
            setTimeout(() => {
                this.$router.go()
            }, 1000);
        })

        this.$once('hook:destroyed', () => {
            unregisterRouterGuard()
        })
    },
    mounted() {
        window.scrollTo(0, 0)

        let r = localStorage.getItem('agent_page_reloaded')
        if (!r) {
            localStorage.setItem('agent_page_reloaded', true)
            this.$router.go()
        }

        
        this.$http.post('/agents/get')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.pending = r.pending
                this.verified = r.new
                this.rejected = r.rejected
                this.blocked = r.blocked
                
                this.loading = false
            }
        })
    },
    methods: {
        move(index, from, to) {
            let f = this[from][index]

            this[to].push(f)

            this[from].splice(index, 1)
        }
    }
}
</script>

<style scoped>
    .agent {
        padding: 30px;
    }
    .title {
        font-size: 20px;
        font-weight: 600;
    }
</style>