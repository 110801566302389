<template>
    <div class="pop">
        <div class="close" @click="$emit('close')">
            <close :size="'30px'"/>
        </div>
        <div class="title">
            Delete {{city.name}} city
        </div>
        <div class="t">
            Deleting this city will remove it from the search page and also from the agent dashboard
        </div>
        <loader :width="'90px'" :height="'37px'" class="l" v-if="loading"/>
        <div class="btn" v-else @click="del()">Delete</div>
    </div>  
</template>

<script>
import close from '../../../icons/remove.vue'
import loader from '../../loader.vue'
export default {
    components: {
        close, loader
    },
    props: ['city'],
    data() {
        return {
            loading: false
        }
    },
    methods: {
        del() {
            this.loading = true

            let form = new FormData()
            form.append('country', this.city.country)
            form.append('state', this.city.state)
            form.append('name', this.city.name)
            this.$http.post('/location/city/delete', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()
                    this.$emit('deleted')
                    this.$router.go()
                }
            })
        }
    },
    notifications: {
        success: {
            type: 'success', 
            title: 'Sucess',
            message: 'This city has been deleted page will now refresh'
        }
    }
}
</script>

<style scoped>
    .pop {
        background-color: white;
        position: relative;
        z-index: 4;
        width: 300px;
        padding: 20px;
        border-radius: 10px;
        animation: pop .3s;
        
    }
    @keyframes pop {
        from {
            transform: scale(.7);
        }
        to {
            transform: scale(1);
        }
    }
    .close {
        position: absolute;
        right: -10px;
        top: -10px;
        fill: var(--main);
        cursor: pointer;
    }

    .title {
        font-weight: 500;
    }
    .t {
        margin-top: 20px;
        font-weight: 500;
        font-size: 14px;
    }
    input {
        margin-top: 20px;
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 5px;
    }
    .btn {
        margin-top: 20px;
        background-color: var(--main);
        padding: 8px 30px;
        border-radius: 5px;
        margin-left: auto;
        width: fit-content;
        color: white;
        font-weight: 500;
        cursor: pointer;
    }
    .l {
        margin-top: 20px;
        margin-left: auto;
    }
</style>