<template>
    <div class="special">

        <div class="main_title"> Special Details</div>
        <div class="note">Note: this details is only visible to you as this property poster</div>

        <div class="main">
            
            <div class="flex">
                <div class="label">Property Address</div>
                <div class="value">{{property.address}}</div>
            </div>
            <div class="flex">
                <div class="label">Manadate No</div>
                <div class="icon" @click="send_whatsapp()">
                    <whatsapp :size="'20px'"/>
                </div>
                <div class="value">{{property.mandate_no}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import whatsapp from '../../icons/whatsapp.vue'

export default {
    components: {
        whatsapp
    },
    props: ['property'],
    methods: {
        send_whatsapp() {

            if (this.property.mandate_no == '') {
                return
            }

            let no = '234' + this.property.mandate_no.slice(1, this.property.mandate_no.length)
            let text = 'https://bigglandlord.com/property/' + this.property.prop_id
            
            let link = `https://wa.me/${no}?text=${text}`
        
            window.open(link, '_blank');
        }
    }
}
</script>

<style scoped>

    .special {
        margin-bottom: 30px;
        background-color: white;
        padding: 20px;
        border-radius: 5px;
    }
    .main_title {
        font-weight: 500;
        font-size: 20px;
    }
    .note {
        /* font-weight: 500; */
        font-size: 12px;
        color: gray;
    }

    .main {
        margin-top: 20px;
    }    
    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 10px;
        gap: 10px;
        border-bottom: 1px solid #ddd;
    }
    .label {
        font-size: 14px;
        color: gray;
    }
    
    .icon {
        cursor: pointer;
        fill: #25d366;
    }
    .value {
        font-size: 14px;
        font-weight: 500;
    }
</style>