<template>
    <div>
        
        <div class="box">
            <div class="image">
                <img :src="dp"/>

                <div class="click" @click="upload()"> Change </div>
            </div>
            <div class="input">
                <div class="label">Firstname</div>
                <input type="text" placeholder="Enter your firstname" v-model="firstname">
            </div>
            <div class="input">
                <div class="label">Lastname</div>
                <input type="text" placeholder="Enter your lastname" v-model="lastname">
            </div>
            <div class="input">
                <div class="label">Phone</div>
                <input type="text" placeholder="Enter your phone number" v-model="phone">
            </div>
            <div class="input">
                <div class="label">Whatsapp Number</div>
                <input type="text" placeholder="Enter your whatsapp number" v-model="whatsapp">
            </div>
            <div class="input">
                <div class="label">Email</div>
                <div class="in">{{email}}</div>
            </div>

            <loader :height="'38px'" v-if="loading"/>
            <div class="btn" v-else @click="save()">Save</div>

            <div class="btn btn2">Change Password</div>

            <input type="file" accept="image/*" class="hidden" 
                ref="image" @change="handleDp($event)"/>
        </div>
    </div>
</template>

<script>
import Localbase from 'localbase'

import imageCompression from 'browser-image-compression';

import loader from '../loader.vue'

export default {
    components: {
        loader
    },
    computed: {
        dpurl() {
            return this.$root.$data.dpurl
        }
    },
    mounted() {
        this.firstname = this.$root.$data.firstname
        this.lastname = this.$root.$data.lastname
        this.phone = this.$root.$data.phone
        this.whatsapp = this.$root.$data.whatsapp
        this.email = this.$root.$data.email

        this.dp = this.$root.$data.dp == '' ? this.dp + 'default.png' : this.dp + this.$root.$data.dp
    },
    data() {
        return {
            db: new Localbase('Bigglandlord'),
            
            is_dp: '0',
            dp: this.$root.$data.dpurl,
            img_file: null,

            firstname: '',
            lastname: '',
            phone: '',
            whatsapp: '',
            email: '',
            loading: false
        }
    },
    methods: {
        upload() {
            this.$refs.image.click()
        },
        
        handleDp(event) {
            this.uploading = true
            for (let i = 0; i < event.target.files.length; i++) {
                let mediaType = event.target.files[i].type
                if (mediaType.includes('image')) {
                    const picture = event.target.files[i]
                    
                    const options = {
                        maxSizeMB: 0.03,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true
                    }
                    imageCompression(picture, options).then( res => {
                        this.img_file = new File([res], picture.name)
                        this.is_dp = '1'
                        
                        const reader = new FileReader()
                        reader.readAsDataURL(picture)
                        reader.onload = event => {
                            this.dp = event.target.result

                            this.$refs['image'].value = ''
                        }
                    })

                }
            }
            this.uploading = false
        },

        save() {
            if (this.firstname == '' || this.lastname == '' || this.phone == '') {
                this.empty(); return
            }
            if (this.whatsapp != '') {
                if (this.whatsapp.length != 11) {
                    this.no(); return
                }
            }

            this.loading = true

            let form = new FormData()

            form.append('is_dp', this.is_dp)
            if (this.is_dp == '1') {
                form.append('dp', this.img_file)
            }
            form.append('firstname', this.firstname)
            form.append('lastname', this.lastname)
            form.append('phone', this.phone)
            form.append('whatsapp', this.whatsapp)

            this.$http.post('/settings/user', form)
            .then(data => {
                let r = data.data
                if (r.success) {

                    this.$root.$data.firstname = this.firstname
                    this.$root.$data.lastname = this.lastname
                    this.$root.$data.phone = this.phone
                    this.$root.$data.whatsapp = this.whatsapp
                    this.$root.$data.dp = this.dp
                    
                    this.db.collection('auth').doc({ id: 1 }).update({
                        firstname: this.firstname,
                        lastname: this.lastname,
                        phone: this.phone,
                        whatsapp: this.whatsapp,
                        dp: r.dp
                    }).then(() => {
                        this.saved()
                        this.loading = false
                    })
                    
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: 'Empty',
            message: 'Please provide all your details'
        },
        no: {
            type: 'warn',
            title: 'Invalid',
            message: 'your number must be 11 digit'
        },
        saved: {
            type: 'success',
            title: 'Saved',
            message: 'Your details has been updated'
        }
    }
}
</script>

<style scoped>

    .box {
        background-color: white;
        border-radius: 10px;
        padding: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .image {
        width: 120px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto;
        margin-bottom: 30px;

        position: relative;
    }
    img {
        width: 120px;
    }
    .click {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 120px;
        padding: 10px 0px;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        cursor: pointer;
        font-size: 14px;
        text-align: center;
        font-weight: 600;
    }
    
    .input {
        margin-bottom: 30px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -10px;
        left: 10px;
        font-weight: 500;
        font-size: 14px;
        animation: .2s up;
        background-color: white;
        padding: 0px 5px;
    }
    @keyframes up {
        from {
            top: 0px;
        }
        to {
            top: -20px;
        }
    }
    input, .in {
        width: 100%;
        padding: 15px;
        border-radius: 5px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        outline: none;
    }
    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 10px 30px;
        border-radius: 5px;
        font-weight: 600;
        font-size: 14px;
        /* margin-top: 20px; */
        text-align: center;
    }

    .btn2 {
        background-color: var(--trans);
        color: var(--main);
        margin-top: 25px;
    }

    
    
    input[type=file] {
        visibility: hidden;
        display: none;
    }
</style>