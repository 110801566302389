<template>
    <div class="table">
        <div class="head">
            <div class="title">{{title}}</div>
            <div class="search">
                <input type="text" placeholder="search">
            </div>
        </div>

        <div class="label">
            <div class="text">Name</div>
            <div class="text">Email</div>
            <div class="text">UserId</div>
            <div class="text">Agt</div>
            <div class="text">Patnr</div>
        </div>

        <div class="loader" v-if="loading">
            <div class="spin"></div>
        </div>
        <div class="empty" v-if="!loading && data.length == 0">
            There are currently no users here at the moment
        </div>

        <user v-for="(user, i) in data" :key="user.user_id" :index="i" :info="user"/>

    </div>
</template>

<script>
import user from './user.vue'
export default {
    props: ['title', 'data', 'loading'],
    components: {
        user
    }
}
</script>

<style scoped>

    .table {
        margin-top: 30px;
        border-radius: 10px;
        overflow: hidden;
        background-color: white;
    }
    .head {
        /* background-color: var(--main); */
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
    }
    .title {
        font-weight: 500;
        font-size: 20px;
        /* color: white; */
    }
    input {
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
    }
    
    .label {
        font-weight: 500;
        display: grid;
        grid-template-columns: calc( 40% - 20px ) 30% 20% 5% 5%;
        padding-left: 20px;
        border-top: 1px solid #ccc;
    }
    .text {
        padding: 10px 0px;
    }

    .empty {
        border-top: 1px solid #ccc;
        padding: 20px;
    }

    .loader {
        border-top: 1px solid #ccc;

    }
    .spin {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: var(--main);
        animation: rotate 1s infinite linear;
        margin: 20px auto;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>