<template>
    <div class="menu">
        <router-link to="/employee">
            <div class="item" :class="[name == 'employee_dashboard' ? 'a' : '']" @click="$emit('clicked')">
                Dashboard
            </div>
        </router-link>

        <router-link to="/employee/message/agent">
            <div class="item" :class="[name == 'message_agent' ? 'a' : '']" @click="$emit('clicked')">
                Message Agents
            </div>
        </router-link>

        <router-link to="/employee/message/client">
            <div class="item" :class="[name == 'message_client' ? 'a' : '']" @click="$emit('clicked')">
                Message Clients
            </div>
        </router-link>

        <router-link to="/employee/properties/video">
            <div class="item" :class="[name == 'properties_video' ? 'a' : '']" @click="$emit('clicked')">
                Properties Video
            </div>
        </router-link>

        <router-link to="/employee/agents">
            <div class="item" :class="[name == 'agents' ? 'a' : '']" @click="$emit('clicked')">
                Agents
            </div>
        </router-link>
    </div>
</template>

<script>
export default {

    computed: {
        name() {
            return this.$route.name
        }
    }
}
</script>

<style scoped>

    .menu {
        /* padding: 20px; */
        width: 300px;
        /* border-right: 3px solid var(--main); */
        height: 100%;
        overflow: auto;
    }
    .item {
        /* margin: 20px; */
        padding: 20px;
        /* background-color: #edeef0; */
        /* color: var(--main); */
        /* border-radius: 10px; */
        cursor: pointer;
        font-weight: 500;
        /* border-bottom: 1px solid #ccc; */
        transition: .3s;
    }
    .item:hover {
        background-color: #edeef0;
    }
    .a {
        background-color: #555;
        background: linear-gradient(to right, royalblue, var(--main));
        color: white;
    }
    .a:hover {
        background-color: #555;
        color: white;
    }
</style>