<template>
    <div class="submit">
        <div class="title"> Submit a Property </div>
        <div class="small">
            Fill the form below to submit a property on Bigglandlord
        </div>

        <div class="form">
            <div class="t">New Property Form</div>

            <div class="input">
                <div class="label" v-if="title != ''">Property Title</div>
                <input type="text" placeholder="Enter Property Title" v-model="title">
            </div>
            

            <div class="text">Property Video <small>(optional)</small></div>
            <div class="s">
                Add a short video of the property. 1 minute video is preferable, video size
                should not exceed 200 MB. Please note, your video will be reviewed before it will be 
                added to this property.
            </div>
            <div class="video_container">
                <div v-if="is_video" class="video">
                    <div class="remove" @click="remove_video()">
                        <remove :size="'20px'"/>
                    </div>
                    <video controls>
                        <source :src="videosrc"/>
                    </video>
                </div>
                <div class="upload" v-else @click="upload_video()">
                    <div v-if="video_upload">
                        loading video - {{ loading_percent }}
                    </div>
                    <div v-else>
                        Click here to select video
                    </div>
                </div>
            </div>


            <div class="input">
                <div class="label" v-if="post_as != ''">Post As</div>
                <select v-model="post_as">
                    <option value="" disabled selected class="dis">Post As</option>
                    <option value="Owner">Owner</option>
                    <option value="Mandate">Mandate</option>
                    <option value="Semi-Mandate">Semi-Mandate</option>
                </select>
            </div>
            <div class="input under" v-if="post_as == 'Semi-Mandate'">
                <div class="label" v-if="mandate_no != ''">Mandate Phone Number</div>
                <input type="number" placeholder="Enter Mandate Phone Number" v-model="mandate_no">
            </div>
            
            <div class="input">
                <div class="label" v-if="kind != ''">Property Kind</div>
                <select v-model="kind">
                    <option value="" disabled selected>Property Kind</option>
                    <option value="Mixed-Use">Mixed-Use</option>
                    <option value="Residential">Residential</option>
                    <option value="Commercial">Commercial</option>
                </select>
            </div>

            <div class="input">
                <div class="label" v-if="category != ''">Property Category</div>
                <select v-model="category">
                    <option value="" disabled selected>Property Category</option>
                    <option value="Let">To Let</option>
                    <option value="Short Let">Short Let</option>
                    <option v-if="kind == 'Commercial' || kind== 'Mixed-Use'" value="Lease">Lease</option>
                    <option value="Sale">For Sale</option>
                </select>
            </div>

            <div class="input">
                <div class="label" v-if="address != ''">Property Address</div>
                <input type="text" placeholder="Enter Property Address" v-model="address">
            </div>
            <div class="input under">
                <div class="label" v-if="country != ''">Country</div>
                <select v-model="country">
                    <option value="" disabled selected>Country</option>
                    <option v-for="c in countries" :key="c.name" :value="c">{{c.name}}</option>
                </select>
            </div>
            <div class="input under">
                <div class="label" v-if="state != ''">State</div>
                <select v-model="state">
                    <option value="" disabled selected>State</option>
                    <option v-for="c in s" :value="c.name" :key="c.name">{{c.name}}</option>
                </select>
            </div>
            <div class="input under">
                <div class="label" v-if="city != ''">City</div>
                <select v-model="city">
                    <option value="" disabled selected>City</option>
                    <option v-for="c in c" :value="c.name" :key="c.name">{{c.name}}</option>
                </select>
            </div>

            <div class="input">
                <div class="label" v-if="type != ''">Property Type</div>
                <select v-model="type">
                    <option value="" selected>Property Type</option>
                    <option v-for="c in types" :value="c" :key="c">{{c}}</option>
                </select>
            </div>

            <div class="input">
                <div class="label" v-if="parking != ''">Parking Space</div>
                <select v-model="parking">
                    <option value="" selected>Parking Space</option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                </select>
            </div>
            
            <div class="grid">
                <div class="text">Property Size</div>
                <div class="input">
                    <div class="label" v-if="size.no != ''">Property Size</div>
                    <input type="number" placeholder="Enter Property Size" v-model="size.no">
                </div>
                <div class="input">
                    <div class="label" v-if="size.measurement != ''">Measurement</div>
                    <select v-model="size.measurement">
                        <option value="" disabled selected>Measurement</option>
                        <option value="Square Meter">Square Meter</option>
                        <option value="Plot">Plot</option>
                    </select>
                </div>
            </div>

            <div class="input">
                <div class="label" v-if="prop_condition != ''">Condition</div>
                <select v-model="prop_condition">
                    <option value="" disabled selected>Condition</option>
                    <!-- <option value="New">New</option> -->
                    <option value="Newly Built">Newly Built</option>
                    <option value="Fairly Used">Fairly Used</option>
                    <option value="Old">Old</option>
                    <option value="Renovated">Renovated</option>
                </select>
            </div>

            <div class="input">
                <div class="text fac">Facilities</div>
                <div class="grid" v-if="category == 'Sale'">
                    <div v-for="f in sale_f" :key="f" class="flex">
                        <input type="checkbox" :value="f" v-model="facility">
                        <div class="l">{{f}}</div>
                    </div>
                </div>
                <div class="grid" v-else>
                    <div v-for="f in rent_f" :key="f" class="flex">
                        <input type="checkbox" :value="f" v-model="facility">
                        <div class="l">{{f}}</div>
                    </div>
                </div>
            </div>
            <div class="input" v-if="category != 'Sale'">
                <div class="label" v-if="rooms != ''">Total Rooms</div>
                <select v-model="rooms">
                    <option value="" disabled selected>Total Rooms</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                </select>
            </div>
            <div class="input" v-if="category != 'Sale'">
                <div class="label" v-if="bedrooms != ''">Bedrooms</div>
                <select v-model="bedrooms">
                    <option value="" disabled selected>Bedrooms</option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                </select>
            </div>
            <div class="input" v-if="category != 'Sale'">
                <div class="label" v-if="bathrooms != ''">Bathrooms</div>
                <select v-model="bathrooms">
                    <option value="" disabled selected>Bathrooms</option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                </select>
            </div>
            <div class="input" v-if="category != 'Sale'">
                <div class="label" v-if="toilet != ''">Toilet</div>
                <select v-model="toilet">
                    <option value="" disabled selected>Toilet</option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                </select>
            </div>
            <div class="input" v-if="category != 'Sale'">
                <div class="label" v-if="pets != ''">Pets Allowed</div>
                <select v-model="pets">
                    <option value="" disabled selected>Pets Allowed</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>

            
            <div class="grid" v-if="category != 'Sale'">
                <div class="text">Minimum Rent</div>
                <div class="input">
                    <div class="label" v-if="rent.no != ''">Minimum Rent</div>
                    <select  v-model="rent.no">
                        <option value="" disabled selected>Select Minimum Rent </option>
                        <option v-for="i in 30" :value="i" :key="i"> {{ i }}</option>
                    </select>
                    <!-- <input type="number" placeholder="Enter Minimum Rent" v-model="rent.no"> -->
                </div>
                <div class="input">
                    <div class="label" v-if="rent.duration != ''">Duration</div>
                    <select v-model="rent.duration">
                        <option value="" disabled selected>Duration</option>
                        <option v-for="l in durations" :key="l" :value="l">{{l}}</option>
                    </select>
                </div>
            </div>
            
            <div class="grid" v-if="category != 'Sale'">
                <div class="text">Property Price</div>
                <div class="input">
                    <div class="label" v-if="price.no != ''">Price</div>
                    <input type="number" placeholder="Enter Price" v-model="price.no">
                </div>
                <div class="input">
                    <div class="label" v-if="price.duration != ''">Duration</div>
                    <select v-model="price.duration">
                        <option value="" disabled selected>Duration</option>
                        <option v-for="l in locale" :key="l" :value="l">{{l}}</option>
                    </select>
                </div>
            </div>

            <div class="grid service" v-if="category != 'Sale'">
                <div class="text">Service Charge</div>
                <input type="checkbox" checked v-model="service.yes" />
                <div class="input">
                    <div class="label" v-if="service.no != ''">Amount</div>
                    <input type="number" placeholder="Amount" v-model="service.no">
                </div>
                <div class="input">
                    <div class="label" v-if="service.duration != ''">Duration</div>
                    <select v-model="service.duration">
                        <option value="" disabled selected>Duration</option>
                        <option v-for="l in locale" :key="l" :value="l">{{l}}</option>
                    </select>
                </div>
            </div>

            <div class="grid" v-if="category == 'Sale'">
                <div class="text">Property Price</div>
                <div class="input">
                    <div class="label" v-if="price.no != ''">Amount</div>
                    <input type="number" placeholder="Amount" v-model="price.no" 
                        @input="sale_amount_input()">
                </div>
                <div class="flex">
                    <input type="checkbox" checked v-model="price.negotiable" />
                    <div>Negotiable</div>
                </div>
            </div>



            <div class="grid" v-if="category == 'Sale'">
                <div class="text">Agency Fee (Commission)</div>
                <input type="checkbox" disabled checked v-model="agency.yes" />
                <div class="input">
                    <div class="label" v-if="agency.percent != ''">Fee</div>
                    <input type="number" disabled placeholder="Enter Fee" 
                        v-model="agency.percent">
                </div>
            </div>
            <div class="grid" v-else>
                <div class="text">Agency Fee (Commission)</div>
                <input type="checkbox" checked v-model="agency.yes" />
                <div class="input">
                    <div class="label" v-if="agency.percent != ''">Fee</div>
                    <input type="number" placeholder="Enter Fee" v-model="agency.percent">
                </div>
            </div>

            <div class="grid" v-if="category != 'Sale'">
                <div class="text">Legal Fee (Agreement)</div>
                <input type="checkbox" checked v-model="legal.yes" />
                <div class="input">
                    <div class="label" v-if="legal.percent != ''">Fee</div>
                    <input type="number" placeholder="Enter Fee" v-model="legal.percent">
                </div>
            </div>
            <div class="grid" v-if="category != 'Sale'">
                <div class="text">Caution Fee (Damages)</div>
                <input type="checkbox" checked v-model="caution.yes" />
                <div class="input">
                    <div class="label" v-if="caution.percent != ''">Enter Amount</div>
                    <input type="number" placeholder="Enter Amount" v-model="caution.percent">
                </div>
            </div>



            <!-- <div class="text package" v-if="category == 'Sale'">
                Total Package: {{ price.no + + price.no * 0.05 }}
            </div>
            <div class="text package" v-else>
                Total Package: {{ 
                (rent.no * rent.duration) + agency.percent + legal.percent + caution.percent + service.no
                }}
            </div> -->



            <div class="input" v-if="category != 'Sale'">
                <div class="label" v-if="apartments != ''">Total Apartments</div>
                <input type="number" placeholder="Enter Total Number of Apartments" v-model="apartments">
            </div>

            <div class="input" v-if="category != 'Sale'">
                <div class="label" v-if="flooring != ''">Property Flooring</div>
                <select v-model="flooring">
                    <option value="" disabled selected>Property Flooring</option>
                    <option value="Fully">Fully</option>
                    <option value="Half">Half</option>
                    <option value="None">None</option>
                </select>
            </div>

            <div class="input" v-if="category != 'Sale'">
                <div class="label" v-if="roofing != ''">Property Roofing</div>
                <select v-model="roofing">
                    <option value="" disabled selected>Property Roofing</option>
                    <option value="Abestors">Asbestos</option>
                    <option value="Gerrard">Gerrard</option>
                    <option value="Aluminium">Aluminium</option>
                    <option value="Zinc">Zinc</option>
                </select>
            </div>
            <div class="input" v-if="category != 'Sale'">
                <div class="label" v-if="painting != ''">Property Painting</div>
                <select v-model="painting">
                    <option value="" disabled selected>Property Painting</option>
                    <option value="New">New</option>
                    <option value="Fairly New">Fairly New</option>
                    <option value="Old">Old</option>
                    <option value="None">None</option>
                </select>
            </div>
            <div class="input" v-if="category != 'Sale'">
                <div class="label" v-if="ground != ''">Property Floor</div>
                <select v-model="ground">
                    <option value="" disabled selected>Property Floor</option>
                    <option value="Bungalow">Bungalow</option>
                    <option value="Duplex">Duplex</option>
                    <option value="Ground">Ground</option>
                    <option value="1st">1st</option>
                    <option value="2nd">2nd</option>
                    <option value="3rd">3rd</option>
                </select>
            </div>
            <div class="input" v-if="category != 'Sale'">
                <div class="label" v-if="smoking != ''">Smoking Allowed</div>
                <select v-model="smoking">
                    <option value="" disabled selected>Smoking Allowed</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>
            <div class="input" v-if="category != 'Sale'">
                <div class="label" v-if="parties != ''">Parties Allowed</div>
                <select v-model="parties">
                    <option value="" disabled selected>Parties Allowed</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>
            <div class="input" v-if="category != 'Sale'">
                <div class="label" v-if="landlord != ''">Landlord Presence</div>
                <select v-model="landlord">
                    <option value="" disabled selected>Landlord Presence</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>
            <div class="input">
                <div class="label" v-if="pack_in != ''">Pay & Pack in</div>
                <select v-model="pack_in">
                    <option value="" disabled selected>Pay & Pack in</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>
            
            <div class="input">
                <div class="label" v-if="description != ''">Description</div>
                <textarea 
                    placeholder="Property Description" v-model="description">
                </textarea>
            </div>
            <div class="input">
                <div class="label" v-if="keywords != ''">Keywords</div>
                <textarea 
                    placeholder="Enter Keywords, seperate each keywords by comma" v-model="keywords">
                </textarea>
            </div>

            <div v-if="category != 'Sale'">
                <div class="t fac">Propective Tenant Requirement</div>
                <div class="text">Tribe <small> (select at least 1)</small></div>
                <div class="grid">
                    <div v-for="f in tribes" :key="f" class="flex">
                        <input type="checkbox" :value="f" v-model="tribe">
                        <div class="l">{{f}}</div>
                    </div>
                </div>
                
                <div class="text">Livelihood <small> (select at least 1)</small></div>
                <div class="grid">
                    <div v-for="f in livelihoods" :key="f" class="flex">
                        <input type="checkbox" :value="f" v-model="livelihood">
                        <div class="l">{{f}}</div>
                    </div>
                </div>

                <div class="text">Status <small> (select at least 1)</small></div>
                <div class="grid">
                    <div v-for="f in statuss" :key="f" class="flex">
                        <input type="checkbox" :value="f" v-model="status">
                        <div class="l">{{f}}</div>
                    </div>
                </div>

                <div class="text">Religion <small>(select at least 1)</small></div>
                <div class="grid">
                    <div v-for="f in religions" :key="f" class="flex">
                        <input type="checkbox" :value="f" v-model="religion">
                        <div class="l">{{f}}</div>
                    </div>
                </div>
                
                <!-- <div class="input">
                    <div class="label" v-if="pet_allowed != ''">Pets Allowed</div>
                    <select v-model="pet_allowed">
                        <option value="" disabled selected>Pets Allowed</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div> -->
            </div>

            <div class="text">Property Images</div>
            <div class="s">
                Select property images, minimum of four and maximum of 10 images 
                are allowed, the image selected first will be used as this property display picture. 
                Landscape pictures are preferable.
            </div>

            <div class="u">
                <div class="upload" @click="upload()">
                    Click here to select a picture
                </div>
                <div class="image" v-for="(image, i) in images" :key="i">
                    <img :src="image">
                    <div class="remove" @click="remove_img(i)">
                        <remove :size="'20px'"/>
                    </div>
                </div>
            </div>

            <div class="agree">
                <input type="checkbox" v-model="agree1">
                <div class="agree_text">
                    I agree that all information wrongly submitted should affect my earnings
                </div>
            </div>

            <div class="agree">
                <input type="checkbox" v-model="agree2">
                <div class="agree_text">
                    I agree to receive all payment for this property via 
                    Bigglandlord; Bank Name: UBA, Account Number: 1020153237,
                    Name: Foresure Authentication Services Limited
                </div>
            </div>

            <loader :height="'41px'" v-if="loading"/>
            <div class="btn" @click="submit()" v-else>Submit</div>

            <input type="file" accept="video/*" ref="video_input" @change="handleVideo($event)"/>
            <input type="file" accept="image/*" multiple class="hidden" 
                ref="image" @change="handleDp($event)"/>
        </div>
    </div>
</template>

<script>
// import * as imageConversion from 'image-conversion';

import * as tus from 'tus-js-client'

import { createFFmpeg } from "@ffmpeg/ffmpeg/dist/ffmpeg.min.js";

import imageCompression from 'browser-image-compression';
import remove from '../../icons/remove.vue'

import loader from '../loader.vue'
export default {
    components: {
        remove, loader
    },
    data() {
        return {
            title: '',
            post_as: '',
            mandate_no: '',
            kind: '',
            category: '',
            rent: {
                no: '',
                duration: ''
            },
            price: {
                negotiable: false,
                no: '',
                duration: ''
            },
            address: '',
            countries: [],
            country: '',
            states: [],
            s: [],
            state: '',
            cities: [],
            c: [],
            city: '',
            type: '',
            types: [],
            parking: '',
            size: {
                no: '',
                measurement: ''
            },
            prop_condition: '',
            facility: [],
            rent_f: [
                '24hrs electricity', 'Air conditioning', 'Balcony', 'Chandelier', 'Dining area',
                'Dish washer', 'Hot water', 'Kitchen shelf', 'Microwave', 'POP ceiling', 'Prepaid meter',
                'Refridgerator', 'Tiled floor', 'TV', 'Wardrobe', 'WIFI'
            ],
            sale_f: ['Car parking', 'Domestic sewage', 'Electricity supply', 'Gas supply', 'Rain water drainage', 'Water supply'],
            rooms: '',
            bedrooms: '',
            bathrooms: '',
            toilet: '',
            pets: '',
            service: {
                yes: false,
                no: '',
                duration: ''
            },
            agency: {
                yes: false,
                percent: ''
            },
            legal: {
                yes: false,
                percent: ''
            },
            caution: {
                yes: false,
                percent: ''
            },
            apartments: '',
            flooring: '',
            roofing: '',
            painting: '',
            ground: '',
            smoking: '',
            parties: '',
            landlord: '',
            pack_in: '',
            description: '',
            keywords: '',

            durations: ['Hour', 'Day', 'Week', 'Month', 'Year'],
            locale: ['Hourly', 'Daily', 'Weekly', 'Monthly', 'Yearly'],

            tribe: [],
            tribes: ['Any', 'Yoruba', 'Igbo', 'Hausa', 'Others'],

            livelihood: [],
            livelihoods: ['Any', 'Self Employed', 'Company Worker', 'Others'],

            status: [],
            statuss: [
                'Any', 'Young Couple', 'Single Male', 'Single Female', 
                'Divorce/Seperated', 'Elderly Couple'
            ],

            religion: [],
            religions: ['Any', 'Muslim', 'Christian'],

            pet_allowed: '',

            uploading: false,
            images: [],
            files: [],
            uploads: [],

            videosrc: '',
            video_file: null,
            video_upload: false,
            is_video: false,
            loading_percent: '',
            compressed_video: null,

            hash: '',
            expiration_time: '',
            video_result: {},

            agree1: false,
            agree2: false,

            loading: false,


            video_uploading: false,
            property_uploading: false,

            tus_upload: null,


            ffmpeg: null
        }
    },
    mounted() {

        this.ffmpeg = createFFmpeg({
            log: false,
        });

        this.$http.get('/properties/locations')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.countries = r.countries
                this.states = r.states
                this.cities = r.cities
            }
        })
    },
    destroyed() {
        if (this.videosrc != '') {
            URL.revokeObjectURL(this.videosrc)
        }
    },
    watch: {
        country() {
            this.set_states()
        },
        state() {
            this.set_cities()
        },
        category() {
            this.set_types()
        }
    },
    methods: {
        sale_amount_input() {
            this.agency.percent = this.price.no * 0.05
        },
        upload() {
            if (this.uploading) {
                this.up(); return
            }
            this.$refs['image'].click()
        },
        upload_video() {
            if (this.video_upload) {
                this.video_uploading_err(); return
            }
            this.$refs['video_input'].click()
        },
        async handleVideo(event) {
            
            let mediaType = event.target.files[0].type
            if (mediaType.includes('video')) {
                
                this.video_upload = true
                
                const video_uploaded = event.target.files[0]
                let size = video_uploaded.size / 1000000

                console.log(video_uploaded);

                if (size > 230) {
                    this.video_err(); 
                    this.video_upload = false
                    return 
                }
                
                // this.video_file = video_uploaded

                if (this.ffmpeg.isLoaded() == false) {
                    await this.ffmpeg.load()
                }
                this.ffmpeg.setProgress(e => {
                    
                    this.loading_percent = (e.ratio * 100).toFixed(2) + ' %'
                })

                const inputBytes = await video_uploaded.arrayBuffer();
                this.ffmpeg.FS('writeFile', video_uploaded.name, new Uint8Array(inputBytes))

                // await this.ffmpeg.run(
                //     '-i', video_uploaded.name, 
                //     "-c:v", "libx264",
                //     "-preset", "ultrafast",
                //     "-crf", "28",
                //     "-c:a", "aac",
                //     "-b:a", "128k",
                //     // "-s", "640x480",
                //     "-map", "0:v",
                //     "-map", "0:a:0",
                //     "-map_metadata", "-1",
                //     "copy", "output.mp4")

                await this.ffmpeg.run(
                    '-i', video_uploaded.name, 
                    '-r', '10',
                    '-b:v', '600k',
                    '-c:v', 'libx264',
                    '-tune', 'fastdecode',
                    '-preset', 'ultrafast',
                    '-c:a', 'libmp3lame',
                    '-b:a', '128k', 'video.mp4')
                
                // await this.ffmpeg.run("-i", video_uploaded.name, "test.mp4");

                const outputBytes = this.ffmpeg.FS('readFile', 'video.mp4'); // Read compressed video from the file system
                const compressed_blob = new Blob([outputBytes], { type: video_uploaded.type });
                const compressed_video = new File([compressed_blob], video_uploaded.name, { type: compressed_blob.type })

                console.log(compressed_video);

                this.ffmpeg.FS('unlink', video_uploaded.name)
                this.ffmpeg.exit()

                this.$http.post('/property/video')
                .then(data => {
                    let r = data.data
                    if (r.success) {
                        this.hash = r.hash
                        this.expiration_time = r.expiration_time
                        this.video_result = r.results

                        this.compressed_video = compressed_video
                        
                        this.videosrc = URL.createObjectURL(
                            new Blob([outputBytes.buffer], { type: "video/mp4" })
                        )
                        this.is_video = true
                        
                        this.$refs['video_input'].value = ''
                        this.video_upload = false

                        this.loading_percent = ''
                    }
                })
                
                            
                // }
            }
        },
        remove_video() {
            this.is_video = false
            this.video_file = null
            this.compressed_video = null
            
            if (this.videosrc != '') {
                URL.revokeObjectURL(this.videosrc)
                this.videosrc = ''
            }
        },
        handleDp(event) {
            this.uploading = true
            for (let i = 0; i < event.target.files.length; i++) {
                let mediaType = event.target.files[i].type
                if (mediaType.includes('image')) {
                    const picture = event.target.files[i]
                    
                    this.compress(picture)
                    const reader = new FileReader()
                    reader.readAsDataURL(picture)
                    reader.onload = event => {
                        this.images.push(event.target.result)

                        this.$refs['image'].value = ''
                    }
                }
            }
            this.uploading = false
        },
        compress(file) {
            let name = file.name
            
            const options = {
                maxSizeMB: 0.03,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            imageCompression(file, options).then( res => {
                this.files.push(new File([res], name))
                this.uploads.push(this.files.length.toString())
                // this.uploading = false
            })
        },
        set_states() {
            this.s = []
            this.c = []
            let x
            for (x of this.states) {
                if (x.country == this.country.name) {
                    this.s.push(x)
                    this.state = ''
                    this.city = ''
                }
            }
            this.set_types()
        },
        set_cities() {
            this.c = []
            let x
            for (x of this.cities) {
                if (x.state == this.state) {
                    this.c.push(x)
                }
            }
        },
        set_types() {
            this.agency.percent = ''
            if (this.category == 'Sale') {
                this.agency.yes = true
            }

            this.type = ''
            if (this.country == '') { return }
            this.types = JSON.parse(this.country[this.category])
        },
        remove_img(from) {
            this.files.splice(from, 1)
            this.uploads.splice(from, 1)
            this.images.splice(from, 1)
        },
        submit() {
            if (this.video_upload) {
                this.video_upload_err(); return
            }
            if (this.title == '' || this.post_as == '' || this.kind == '' || this.category == '' ||
                this.price.no == '' || this.address == '' || this.country == '' || 
                this.state == '' ||  this.city == '' ||  this.type == '' || this.parking == '' || 
                this.size.no == '' || this.size.measurement == '' || this.prop_condition == '' || 
                this.pack_in == '' || this.description == '') 
                {
                    console.log('first error');
                    this.empty(); return
            }
            if (this.post_as == 'Semi-Mandate' && this.mandate_no == '') { 
                console.log('Mandate error');
                this.empty(); return }

            if (this.service.yes && (this.service.no == '' || this.service.duration == '')) { 
                console.log('service error');
                this.empty(); return 
            }

            if (this.agency.yes && this.agency.percent == '') { 
                
                console.log('agency error');
                this.empty(); return }

            if (this.legal.yes && this.legal.percent == '') { 
                console.log('lagal error');
                this.empty(); return }

            if (this.caution.yes && this.caution.percent == '') { 
                console.log('caution error');
                this.empty(); return }
            
            if (!this.agree1 && !this.agree2) {
                console.log('agree error');
                this.agree(); return }


            if (this.category != 'Sale') {
                if ( this.price.duration == '' ||
                    this.rooms == '' || this.bedrooms == '' || this.bathrooms == '' || 
                    this.toilet == '' || this.pets == '' || this.rent.no == '' || this.rent.duration == '' ||
                    this.apartments == '' || this.flooring == '' || this.roofing == '' ||
                    this.painting == '' || this.ground == '' || this.smoking == '' || this.parties == '' ||
                    this.landlord == '' || this.tribe.length == 0 || this.livelihood.length == 0 || 
                    this.status.length == 0 || this.religion.length == 0
                ) {

                    console.log('sale error');
                    this.empty(); return
                }
            }

            if (this.files.length < 4 || this.files.length > 10) {
                this.image_err(); return
            }

            this.loading = true

            let form = new FormData()
            form.append('title', this.title)
            form.append('post_as', this.post_as)
            form.append('mandate_no', this.mandate_no)
            form.append('kind', this.kind)
            form.append('category', this.category)
            form.append('rent', JSON.stringify(this.rent))
            form.append('price', JSON.stringify(this.price))
            form.append('amount', this.price.no)
            form.append('address', this.address)
            form.append('country', this.country.name)
            form.append('state', this.state)
            form.append('city', this.city)
            form.append('type', this.type)
            form.append('parking', this.parking)
            form.append('size', JSON.stringify(this.size))
            form.append('prop_condition', this.prop_condition)
            form.append('facility', JSON.stringify(this.facility))
            form.append('rooms', this.rooms)
            form.append('bedrooms', this.bedrooms)
            form.append('bathrooms', this.bathrooms)
            form.append('toilet', this.toilet)
            form.append('pets', this.pets)
            form.append('service', JSON.stringify(this.service))
            form.append('agency', JSON.stringify(this.agency))
            form.append('legal', JSON.stringify(this.legal))
            form.append('caution', JSON.stringify(this.caution))
            form.append('apartments', this.apartments)
            form.append('flooring', this.flooring)
            form.append('roofing', this.roofing)
            form.append('painting', this.painting)
            form.append('ground', this.ground)
            form.append('smoking', this.smoking)
            form.append('parties', this.parties)
            form.append('landlord', this.landlord)
            form.append('pack_in', this.pack_in)
            form.append('description', this.description)
            form.append('keywords', this.keywords)

            form.append('tribe', JSON.stringify(this.tribe))
            form.append('livelihood', JSON.stringify(this.livelihood))
            form.append('tenant_status', JSON.stringify(this.status))
            form.append('religion', JSON.stringify(this.religion))

            form.append('uploads', JSON.stringify(this.uploads))

            let i
            for (i = 0; i < this.uploads.length; i++) {
                form.append(this.uploads[i], this.files[i])
            }

            form.append('is_video', this.is_video ? '1' : '0')
            if (this.is_video) {
                form.append('video', this.video_result.guid)
            }

            this.video_uploading = this.is_video ? false : true
            this.property_uploading = false

            this.$http.post('/property/add', form)
            .then(data => {
                let r = data.data
                console.log(r);
                if (r.success) {
                    this.property_uploading = true
                    if (this.video_uploading) {
                        this.success()
                        this.loading = false

                        this.$router.push('/property/' + r.prop_id)
                    }
                }
            })
            
            var vm = this

            if (this.is_video) {
                this.tus_upload = new tus.Upload(this.compressed_video, {
                    endpoint: "https://video.bunnycdn.com/tusupload",
                    retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
                    headers: {
                        AuthorizationSignature: this.hash, // SHA256 signature (library_id + api_key + expiration_time + video_id)
                        AuthorizationExpire: this.expiration_time, // Expiration time as in the signature,
                        VideoId: this.video_result.guid, // The guid of a previously created video object through the Create Video API call
                        LibraryId: this.video_result.videoLibraryId,
                    },
                    metadata: {
                        filetype: this.compressed_video.type,
                        title: this.video_result.title,
                        collection: this.video_result.collectionId
                    },
                    onError: function (error) { },
                    onProgress: function (bytesUploaded, bytesTotal) { },
                    onSuccess: function () {
                        console.log('tus success');
                        vm.video_uploading = true
                        if (vm.property_uploading) {
                            vm.success()
                            vm.loading = false

                            vm.$router.push('/property/' + r.prop_id)
                        }
                    }
                })
            
                this.tus_upload.start()
            }


        }
    },
    notifications: {
        
        up: {
            type: 'warn',
            title: 'Uploading',
            message: 'we are uploading your document, kindly wait 2 seconds'
        },
        video_upload_err: {
            type: 'warn',
            title: 'Video',
            message: 'Video upload in progress, please wait...'
        },
        empty: {
            type: 'warn',
            title: 'Empty',
            message: 'Some fields are empty'
        },
        image_err: {
            type: 'warn',
            title: 'Images',
            message: 'Add the required number of images'
        },
        video_uploading_err: {
            type: 'warn',
            title: 'Video loading',
            message: 'Your video is uploading'
        },
        video_err: {
            type: 'warn',
            title: 'Too large',
            message: 'Vidoe should not be more than 200 MB'
        },
        agree: {
            type: 'warn',
            title: 'Agree',
            message: 'Please agree to Bigglandlord terms'
        },
        success: {
            type: 'success',
            title: 'Success',
            message: 'Your property has been listed'
        }
    }
}
</script>

<style scoped>

    .title {
        font-weight: 500;
        font-size: 25px;
    }
    .small {
        font-size: 14px;
    }

    .flex {
        display: flex;
        align-items: center;
        font-size: 14px;
    }

    .form {
        max-width: 500px;
        background-color: white;
        padding: 30px;
        border-radius: 20px;
        margin-top: 30px;
    }
    .t {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 30px;
    }
    .under {
        margin-left: 20px;
    }
    .input {
        margin-bottom: 30px;
        position: relative;
    }
    .dis {
        color: #686868;
    }
    .label {
        position: absolute;
        background-color: white;
        padding: 0px 5px;
        top: -10px;
        left: 10px;
        transition: .2s;
        font-weight: 500;
    }
    input, select, textarea {
        padding: 15px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
        /* appearance:none; */
    }
    select {
        padding-right: 10px;
    }
    
    input[type=checkbox] {
        accent-color: var(--main);
        color: white;
        width: 20px;
        height: 20px;
        padding: 0px;
    }

    .fac {
        margin-bottom: 10px;
    }

    .grid {
        display: grid;
        grid-template-columns: max-content max-content auto;
        grid-gap: 15px;
        align-items: center;
        margin-bottom: 30px;
    }
    .grid > .input {
        margin-bottom: 0px;
    }
    .service {
        grid-template-columns: max-content max-content auto auto;
    }
    .text {
        font-weight: 500;
    }
    .package {
        margin-bottom: 30px;
    }

    .s {
        font-size: 12px;
    }

    .u {
        margin: 30px 0px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        grid-gap: 15px;
        height: fit-content;
    }
    .upload {
        background-color: var(--trans);
        padding: 10px;
        border: 1px dotted var(--main);
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 100px;
    }
    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        overflow: hidden;
        position: relative;
    }
    img {
        width: 100px;
    }
    .remove {
        position: absolute;
        top: 0px;
        right: 0px;
        fill: var(--main);
        cursor: pointer;
    }


    .agree {
        display: grid;
        grid-template-columns: max-content auto;
        gap: 20px;
        font-size: 14px;
        align-items: center;
        margin-bottom: 20px;
    }

    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: 500;
        /* margin-top: 15px; */
        text-align: center;
    }

    
    input[type=file] {
        visibility: hidden;
        display: none;
    }

    .video_container {
        margin: 30px 0px;
    }
    .video {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
    }
    .video .remove {
        z-index: 3;
    }
    video {
        width: 100%;
    }

    @media screen and (max-width: 900px) {
        .grid {
            grid-template-columns: auto;
        }
    }
</style>