<template>
    <div class="login">
        <div class="main">
            <div class="title">Admin Login</div>
            <div class="input">
                <div class="label" v-if="username != ''">Username:</div>
                <input type="text" placeholder="username" v-model="username">
                <div class="err" v-if="u">username not found</div>
            </div>
            <div class="input">
                <div class="label" v-if="password != ''">Password:</div>
                <input type="password" placeholder="Password" v-model="password">
                <div class="err" v-if="p">Incorrect Password</div>
            </div>
            <loader :height="'37px'" :width="'100%'" v-if="loading"/>
            <div class="btn" @click="login()" v-else>Log in</div>
        </div>
    </div>
</template>

<script>
import Localbase from 'localbase'
import loader from '../components/loader.vue'
export default {
    components: {
        loader
    },
    data() {
        return {
            db: new Localbase('Bigglandlord'),
            u: '',
            p: '',
            username: '',
            password: '',
            loading: false
        }
    },
    mounted() {
        this.db.collection('auth').doc({ id: 1 }).get().then( auth => {
            if ( auth == undefined ) {
                console.log('ll');
            } else {
                let user = auth.key.split('-')[0]
                if (user == 'admin') {
                    this.$http.defaults.headers.common['Auth'] = auth.key
                    setTimeout(() => {
                        this.$router.push('/admin/dashboard')
                    }, 1000)
                } else {
                    this.$router.push('/')
                }
            }
        })
    },
    methods: {
        login() {
            if (this.username == '' ) { this.e_username(); return }
            if (this.password == '' ) { this.e_pass(); return }

            this.loading = true
            this.u = false
            this.p = false

            let form = new FormData()
            form.append('username', this.username)
            form.append('password', this.password)

            this.$http.post('/admin/login', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.db.collection('auth').add({
                        'id': 1,
                        'key': r.session
                    }).then( () => {
                        this.$router.push('/admin')
                        this.loading = false
                    })
                } else {
                    if (r.username) {
                        this.u = true
                    }
                    if (r.password) {
                        this.p = true
                    }
                    this.loading = false
                }
            })
        }
    },
    notifications: {
        e_username: {
            type: 'warn',
            title: 'Empty',
            message: 'Username cannot be empty'
        },
        e_pass: {
            type: 'warn',
            title: 'Empty',
            message: 'password cannot be empty'
        }
    }
}
</script>

<style scoped>

    .main {
        max-width: 350px;
        margin: 150px auto;
        margin-top: 230px;
        padding: 30px;
        background-color: #edeef0;
        border-radius: 10px;
    }
    .title {
        font-weight: 500;
        font-size: 25px;
        margin-bottom: 30px;
    }
    .input {
        margin-bottom: 30px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -20px;
        left: 10px;
        font-weight: 500;
        font-size: 14px;
    }
    input {
        width: 100%;
        padding: 12px;
        border-radius: 5px;
        border: none;
        box-sizing: border-box;
        outline: none;
    }
    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 8px 30px;
        border-radius: 5px;
        font-weight: 500;
        margin-top: 20px;
        text-align: center;
    }
    .err {
        position: absolute;
        color: red;
        font-weight: 500;
        font-size: 12px;
        right: 0px;
        bottom: -14px;
    }
</style>