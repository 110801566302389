<template>
    <div class="box">
        
        <div class="title">
            This property is no longer available
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

    .box {
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        /* width: 250px; */
        height: fit-content;
        margin-bottom: 30px;
    }
    .small {
        font-size: 14px;
        font-weight: 500;
        opacity: .5;
    }
    .title {
        font-weight: 500;
        /* text-align: center; */
        /* font-size: 20px; */
    }
</style>