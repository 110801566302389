<template>
    <div class="properties">
        <div class="title">Properties Video</div>
        
        <div class="loading" v-if="loading">
            <div class="spin"></div>
        </div>

        <div class="grid">
                
            <prop_video  v-for="prop in videos" :key="prop.prop_id" :prop="prop"/>
            
        </div>
    </div>
</template>

<script>
import prop_video from '../../components/employee/prop_video.vue'

export default {
    components: {
        prop_video
    },
    data() {
        return {
            videos: [],
            loading: true
        }
    },
    mounted() {
        this.$http.get('/employee/property/video/get')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.videos = r.property

                this.loading = false
            }
        })
    },
}
</script>

<style scoped>

    .properties {
        padding: 20px;
    }
    .title {
        font-weight: 500;
        margin-bottom: 20px;
    }
    
    
    
    .spin {
        margin: 70px auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: var(--main);
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .grid {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 20px;
    }
</style>