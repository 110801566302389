import { render, staticRenderFns } from "./check.vue?vue&type=template&id=159b9689&scoped=true&"
import script from "./check.vue?vue&type=script&lang=js&"
export * from "./check.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "159b9689",
  null
  
)

export default component.exports