<template>
    <div class="prop_video">

        <div class="label">Date</div>
        <div class="date">
            {{ prop.date }}
        </div>

        <iframe :src="'https://iframe.mediadelivery.net/embed/159521/' + prop.video_data + '?autoplay=false'" 
            loading="lazy" style="border: none;  width: 100%;" 
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" 
            allowfullscreen="true">
        </iframe>
        <!-- <iframe src="https://iframe.mediadelivery.net/embed/159521/74b881dd-818f-4b6d-ab0e-edc10a5850f9?autoplay=false" 
            loading="lazy" style="border: none;  height: 100%; width: 100%;" 
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" 
            allowfullscreen="true">
        </iframe> -->


        <div class="click">
            <router-link :to="'/property/' + prop.prop_id">View Property</router-link>
        </div>
        <div class="input">
            <input type="text" placeholder="Enter property title" v-model="title">

            <div>
                <textarea placeholder="Enter property description" v-model="description"></textarea>
            </div>


            <loader :height="'35px'" :width="'89.38px'" v-if="loading"/>
            <div class="btn" @click="save()" v-else>Save</div>
        </div>
    </div>
</template>

<script>
import loader from '../loader.vue';

export default {
    components: {
        loader
    },
    props: ['prop'],
    data() {
        return {
            title: '',
            description: '',
            loading: false
        }
    },
    mounted() {
        this.title = this.prop.title
        this.description = this.prop.description
    },
    computed: {
        tmpurl() {
            return this.$root.$data.tmpurl
        }
    },
    methods: {
        save() {
            if (this.title == '' || this.description == '') {
                this.empty(); return
            }
            this.loading = true

            let form = new FormData()
            form.append('prop_id', this.prop.prop_id)
            form.append('video_data', this.prop.video_data)
            form.append('title', this.title)
            form.append('description', this.description)

            this.$http.post('/employee/property/video/set', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()
                    this.loading = false
                    
                    this.$router.go()
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Please enter title and description'
        },
        success: {
            type: 'success',
            title: 'Added',
            message: 'Youtube link has been added'
        }
    }
}
</script>

<style scoped>
    .prop_video {
        background-color: white;
        padding: 15px;
        border-radius: 5px;
        font-size: 14px;
    }

    .label {
        font-weight: 600;
    }
    .click {
        margin-top: 15px;
        font-weight: 600;
        color: var(--main);
    }

    .input {
        margin-top: 15px;
    }
    input, textarea {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        margin-bottom: 15px;
        width: 100%;
        box-sizing: border-box;
    }
    .btn {
        padding: 8px;
        text-align: center;
        background-color: var(--main);
        border-radius: 5px;
        color: white;
        font-weight: 500;
        cursor: pointer;
    }
</style>