<template>
    <div class="partner">
        <div class="label">Partner</div>
        <div class="bold">You are already a partner on Bigglandlord</div>

        <div class="f">
            <router-link to="/dashboard/partner/settings">
                <div class="btn btn2">Settings</div>
            </router-link>
            <router-link to="/dashboard/partner/appointments">
                <div class="btn btn2 up">View appointments</div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

    .partner {
        background-color: white;
        padding: 20px 0px ;
        border-radius: 10px;
        overflow: hidden;
    }
    .label {
        background-color: var(--main);
        padding: 10px 20px;
        color: white;
        font-weight: 500;
        width: fit-content;
    }
    .bold {
        margin: 50px 0px;
        text-align: center;
        font-weight: 500;
        font-size: 20px;
    }

    
    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px;
    }
    .text {
        font-size: 14px;
    }
    
    .btn {
        background-color: var(--trans);
        color: var(--main);
        padding: 8px 20px;
        text-align: center;
        cursor: pointer;
        font-weight: 500;
        border-radius: 5px;
        margin-left: 15px;
        box-sizing: border-box;
    }
    .btn2 {
        margin: 0px 20px;
    }
    .up {
        margin-top: 20px;
    }

</style>