<template>
    <div class="appointments">
        <div class="head">
            <div class="title">Agent Appointments</div>
        </div>
        <div class="menu">
            <div class="item" :class="[c == 0 ? 'a' : '']" @click="available()">Current</div>
            <div class="item" :class="[c == 1 ? 'a' : '']" @click="all()">Successful</div>
            <div class="item" :class="[c == 2 ? 'a' : '']" @click="all()">Undecided</div>
        </div>

        <div class="loader" v-if="loading">
            <div class="spin"></div>
        </div>
        <div v-else>
            <div v-if="c == 0" class="main">
                <div v-if="current.length == 0" class="empty">
                    This agent does not have any current appointments
                </div>
                <div class="grid" v-else>
                    <current v-for="property in current" :property="property" :key="property.prop_id"/>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import current from './appointment/current.vue'

export default {
    components: {
        current
    },
    props: ['user_id'],
    data() {
        return {
            c: 0,
            loading: true,
            current: []
        }
    },
    mounted() {
        this.available()
    },
    methods: {
        available() {
            this.c = 0

            this.loading = true
            let form = new FormData()
            form.append('user_id', this.user_id)
            form.append('how', 'current')
            
            this.$http.post('/admin/agent/appointments', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.current = r.properties
                    this.loading = false
                }
            })
        },
        all() {

        }
    }
}
</script>

<style scoped>

    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
    }
    .title {
        font-size: 18px;
        font-weight: 500;
    }


    .menu {
        display: flex;
        padding: 0px 20px;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
    }
    .item {
        padding: 12px 30px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        border-bottom: 2px solid transparent;
    }
    .a {
        border-bottom-color: var(--main);
        color: var(--main);
    }


    .main {
        padding: 20px;
    }
    .empty {
        text-align: center;
        font-size: 14px;
    }


    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-gap: 20px;
    }


    
    
    .spin {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 2px solid var(--main) ;
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
        margin: 50px auto;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>