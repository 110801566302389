<template>
    <div class="properties">
        <headerLayout class="h" :no_bg="true"/>
        <div class="main">
            <headLayout class="he" :text="'Properties'">
                <template v-slot:left>
                    <div class="filter" @click="filter = true">
                        <filteri :size="'15px'" class="icon"/>
                        Search
                    </div>
                </template>
            </headLayout>
            <div class="flex">
                <search class="s"/>
                <div class="right">
                    <div class="top">
                        <div class="t">
                            Showing Result {{current}} - {{total}}
                        </div>
                    </div>

                    <div class="search_notice" v-if="!query">
                        <filteri :size="'20px'"/>
                        <div class="s_title">Search</div>
                        <div class="s_text">
                            Use the search option to find your prefferred property
                        </div>
                    </div>

                    <div class="loading" v-if="loading">
                        <div class="spin"></div>
                    </div>
                    
                    <div class="grid" v-if="!loading && properties.length > 0">
                        <property v-for="property in properties" 
                            :key="property.prop_id" :property="property"/>
                    </div>

                    <div class="empty" v-if="!loading && properties.length == 0 && query">
                        <div class="empty_title">No Result</div>
                        <div class="empty_text">There are currently no matching property for your search</div>
                        
                        <div class="empty_title empty_up">Create An Alert</div>
                        <div class="empty_text">
                            By creating an alert, Bigglandlord will notify you by email or whatsapp when a property
                            matching your search has been posted
                        </div> 
                        <div class="small_title">Your Search</div>
                        <div class="empty_flex">
                            <div class="label">Location:</div>
                            <div class="value">{{this.city}} {{this.state}}</div>
                        </div>
                        <div class="empty_flex">
                            <div class="label">Category:</div>
                            <div class="value">{{this.category}}</div>
                        </div>
                        <div class="empty_flex em_f_last">
                            <div class="label">Property Type:</div>
                            <div class="value">{{this.type}}</div>
                        </div>
                        <div class="empty_flex em_f_last">
                            <div class="label">Min Price:</div>
                            <div class="value">₦{{this.min}}</div>
                        </div>
                        <div class="empty_flex em_f_last">
                            <div class="label">Max Price:</div>
                            <div class="value">₦{{this.max}}</div>
                        </div>


                        <div class="submitted" v-if="sent">
                            Your alert has been submitted, we will notify you by email
                        </div>
                        <div v-else>
                            <loader :height="'37px'" v-if="sending"/>
                            <div class="btn" v-else @click="alert()">Create Alert</div>
                        </div>
                    </div>


                    <div class="pagination" v-if="results.length > 0">
                        <div class="no" v-for="i in total" :key="i" @click="showNext(i)"
                            :class="[i == current ? 'a' : '']">{{i}}</div>
                    </div>
                </div>
            </div>
        </div>
        <register/>
        <footer-layout/>
        <div class="popup" v-if="filter">
            <div class="dark" @click="filter = false"></div>
            <search class="searchpop"/>
        </div>
    </div>
</template>

<script>
import headerLayout from '../components/header.vue'
import headLayout from '../components/head.vue'
import filteri from '../icons/search.vue'
import search from '../components/property/search.vue'
import register from '../components/register.vue'
import footerLayout from '../components/footer.vue'
import property from '../components/property.vue'

import loader from '../components/loader.vue'
export default {
    components: {
        headerLayout, headLayout, filteri, search,
        property,
        register, footerLayout, loader
    },
    data() {
        return {
            loading: false,
            filter: false,
            query: true,
            results: [],
            properties: [],
            city: '',
            state: '',
            country: '',
            category: '',
            type: '',
            min: 0,
            max: 100000000,
            current: 0,
            total: 0,

            sending: false,
            sent: false
        }
    },
    computed: {
        path() {
            return this.$route.fullPath
        }
    },
    watch: {
        path() {
            this.results = []
            this.properties = []
            this.current = 0
            this.total = 0
            this.init()
        }
    },
    mounted () {
        window.scrollTo(0, 0)

        this.init()
    },
    methods: {
        init() {

            let q = this.$route.query

            if (q.hasOwnProperty('city') && q.hasOwnProperty('state') && q.hasOwnProperty('country')
                && q.hasOwnProperty('category') && q.hasOwnProperty('type') && q.hasOwnProperty('min')
                && q.hasOwnProperty('max')) {
                    this.city = q.city
                    this.state = q.state
                    this.country = q.country
                    this.category = q.category
                    this.type = q.type
                    this.min = q.min
                    this.max = q.max

                    this.loading = true
                    this.query = true

                    let form = new FormData()
                    form.append('city', this.city)
                    form.append('state', this.state)
                    form.append('country', this.country)
                    form.append('category', this.category)
                    form.append('type', this.type)
                    form.append('min', this.min)
                    form.append('max', this.max)

                    this.$http.post('/property/search', form)
                    .then(data => {
                        let r = data.data
                        if (r.success) {
                            this.results = r.properties
                            
                            if (this.results.length > 0) {
                                this.properties = this.results.slice(0, 5)
                                this.current = 1
                                this.total = Math.ceil(this.results.length / 5)
                            }
                            
                            this.loading = false
                        }
                    })
                }
            else {
                this.query = false
            }
        },
        showNext(i) {
            this.loading = true
            this.current = i

            let len = this.properties.length * (i - 1)

            console.log(len);
            this.properties = this.results.slice(len, len + 5)

            this.loading = false

            window.scrollTo(0, 0)
        },
        alert() {
            if (!this.$root.$data.login) {
                this.login()
                return
            }
            this.sending = true
            let form = new FormData()
            form.append('country', this.country)
            form.append('state', this.state)
            form.append('city', this.city)
            form.append('category', this.category)
            form.append('type', this.type)
            form.append('min', this.min)
            form.append('max', this.max)

            this.$http.post('/property/alert', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.submitted()
                    this.sent = true
                }
            })
        }
    },
    notifications: {
        login: {
            type: 'warn',
            title: 'Login/Signup',
            message: 'This action require you to be logged in'
        },
        submitted: {
            type: 'success',
            title: 'Submitted',
            message: 'Your alert has been submitted'
        }
    }
}
</script>

<style scoped>
    .properties {
        background-color: #edeef0;
        position: relative;
    }
    .h {
        position: fixed;
        width: 100%;
        box-sizing: border-box;
        top: 0px;
        left: 0px;
        z-index: 5;
        color: white;
    }
    .main {
        max-width: 1000px;
        margin: 0 auto;
        padding: 0px 20px;
        padding-bottom: 70px;
        padding-top: 100px;
    }
    .he {
        margin: 40px 0px;
        margin-top: 20px;
    }
    .filter {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 8px 20px;
        border-radius: 5px;
        font-weight: 500;
        margin-top: 15px;
        text-align: center;
        display: flex;
        fill: white;
        align-items: center;
        display: none;
    }
    .icon {
        margin-right: 10px;
    }
    .flex {
        display: grid;
        grid-template-columns: max-content auto;
    }
    .right {
        margin-left: 30px;
    }

    .top {
        background-color: white;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 500;
        border-radius: 5px;
    }
    .t {
        opacity: .5;
    }


    .search_notice {
        background-color: white;
        padding: 20px;
        text-align: center;
        border-radius: 5px;
        fill: var(--main);
        margin-top: 30px;
    }
    .s_title{
        font-size: 20px;
        font-weight: 500;
        margin: 10px 0px;
    }
    
    .spin {
        margin: 70px auto;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 3px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .grid {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-gap: 30px;
    }

    .empty {
        background-color: white;
        margin-top: 20px;
        padding: 20px;
        border-radius: 5px;
        font-size: 12px;
    }
    .empty_title {
        font-size: 18px;
        font-weight: 500;
    }
    .empty_up {
        margin-top: 20px;
    }

    .small_title {
        font-weight: 500;
        margin-top: 20px;
        font-size: 18px;
    }

    .empty_flex {
        display: flex;
        margin-top: 5px;
        font-size: 14px;
    }
    .em_f_last {
        margin-bottom: 20px;
    }
    .label {
        width: 100px;
        color: gray;
    }
    .value {
        font-weight: 500;
    }

    .submitted {
        background-color: #edeef0;
        padding: 10px;
        text-align: center;
        font-weight: 500;
    }

    .btn {
        text-align: center;
        padding: 9px;
        font-weight: 500;
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
    }

    .pagination {
        background-color: white;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border-radius: 5px;
    }
    .no {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #edeef0;
        font-weight: 500;
        margin: 0px 10px;
        cursor: pointer;
        line-height: 12px;
    }
    .a {
        background-color: var(--main);
        color: white;
    }

    

    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: none;
        align-items: center;
        justify-content: center;
        z-index: 3;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.9);
    }
    .searchpop {
        z-index: 3;
        animation: .5s pop;
    }
    @keyframes pop {
        from {
            transform: scale(.5);
        }
        to {
            transform: scale(1);
        }
    }

    @media screen and (max-width: 700px) {
        .popup {
            display: flex;
        }
        .filter {
            display: flex;
        }
        .flex {
            display: block;
        }
        .s {
            display: none;
        }
        .right {
            margin-left: 0px;
        }
    }
</style>