<template>
    <div class="popup">
        <div class="dark" @click="$emit('close')"></div>
        <div class="box">
            <div class="title">Add Banner</div>
            
            <croppa v-model="myCroppa" 
                :width="400"
                :height="300"
                :accept="'image/*'"
                :placeholder="'Add Image'" 
                :placeholder-font-size="14"
                :zoom-speed="10"
                :disable-rotation="true"
                :prevent-white-space="true"
                :show-remove-button="true"
                :remove-button-color="'rgb(255, 128, 0)'"
                :remove-button-size="25">
                
            </croppa>

            <loader :height="'37px'" class="l" v-if="loading"/>
            <div class="btn" @click="add()" v-else>Add</div>
        </div>
    </div>
</template>

<script>
import imageCompression from 'browser-image-compression';

import loader from '../../loader.vue'
export default {
    components: {
        loader
    },
    data() {
        return {
            loading: false,
            myCroppa: null
        }
    },
    methods: {
        add() {
            if (!this.myCroppa.hasImage()) { return }
            
            this.loading = true,

            this.myCroppa.generateBlob(blob => {
                let name = 'image.' + blob.type.split('/')[1]
                let file = new File([blob], name)
                
                // const options = {
                //     maxSizeMB: 0.05,
                //     maxWidthOrHeight: 1920,
                //     useWebWorker: true
                // }
                // imageCompression(file, options).then( res => {

                    // let compressed = new File([res], name)
                    
                    let form = new FormData()
                    form.append('image', file)

                    this.$http.post('/admin/banner', form)
                    .then(data => {
                        let r = data.data
                        if (r.success) {
                            this.$emit('new_banner', {
                                'image': r.image,
                                'id': r.id
                            })
                        }
                    })

                // })
                    
            },
                'image/jpeg',
                0.7
            )
        }
    }
}
</script>

<style scoped>
    .popup {
        display: flex;
        align-items: center;
        justify-content: center;

        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }
    .dark {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }
    .box {
        background-color: white;
        width: 400px;
        padding: 20px;
        position: relative;
        z-index: 2;
        border-radius: 5px;
    }
    .title {
        font-weight: 500;
        margin-bottom: 20px;
    }

    .l {
        margin-top: 20px;
    }
    .btn {
        padding: 8px;
        text-align: center;
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        font-weight: 500;
        margin-top: 20px;
        cursor: pointer;
    }
</style>