<template>
    <div class="current">
        <property :property="property"/>

        <div class="content">
            <div v-if="property.status == 'waiting'">
                <div v-if="property.avaliable == '1'">
                    <div class="text" v-if="property.cancel == '0'">
                        you have booked an appointment with one of our partner <b> {{property.name}} </b> to inspect 
                        and <b>{{property.category}}</b> this property. 
                        <b>{{property.name}}</b> contact details will be reveal once <b> {{property.name}} </b>
                        respond to this appointment.
                    </div>

                    <div class="cancel" v-else>
                        <b>{{property.name}}</b> has canceled the appointment due to unavailability, 
                        please kindly click the change partner button and select another partner
                    </div>
                </div>
                <div v-else class="text">
                    <b>
                        This property is not longer available, please kindly cancel this appointment 
                        and book another property for inspection.
                    </b>
                </div>
            </div>


            <div class="details" v-if="property.status == 'running'">
                <div class="title">Partner Details</div>
                <div class="f">
                    <div class="label">Name:</div>
                    <div class="value">{{property.name}}</div>
                </div>
                <div class="f">
                    <div class="label">Phone Number:</div>
                    <div class="value">{{property.phone}}</div>
                </div>

                <div class="text">
                    <b>{{property.name}}</b> will get in touch with you soon
                </div>
                <div class="box">
                    <div class="label">Appointment Code</div>
                    <div class="code">{{property.code}}</div>
                    <div class="note">
                        <div>
                            Never disclose this code over the phone to anyone.
                            If a fraud is committed in your absence, YOU WILL BE HELD RESPONSIBLE.
                        </div>
                    </div>
                </div>

                <div class="payment">
                    <div class="title">Payment Details</div>
                    <div class="pay_text">
                        Payment for this property should be made into Bigglandlord account 
                    </div>
                    <div class="f">
                        <div class="label">Bank:</div>
                        <div class="value">UBA</div>
                    </div>
                    <div class="f">
                        <div class="label">Account Number:</div>
                        <div class="value">1020153237</div>
                    </div>
                    <div class="f">
                        <div class="label">Account Name:</div>
                        <div class="value">Foresure Authentication Services Limited</div>
                    </div>
                    <div class="note">
                        <b>Note:</b> Do not pay to any agent or partner
                    </div>
                </div>
            </div>

            
            <user v-if="property.status == 'checked'" 
                :appt_id="property.appt_id" :partner="property.partner"/>


            <div class="opt" v-if="property.status == 'waiting'">
                <div class="btn" @click="refresh()">refresh</div>

                <loader :height="'37px'" v-if="loading"/>
                <div class="btn" v-else @click="change()">
                    {{property.avaliable == '1' ? 'Change Partner' : 'Cancel Appointment'}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import property from '../property.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import location from '../../icons/location.vue'
import user from './rating/user.vue'
import loader from '../loader.vue'
export default {

    components: {
        property,
        Swiper, SwiperSlide,
        user,
        location, loader
    },
    props: ['property'],
    computed: {
        url() {
            return this.$root.$data.imgurl
        }
    },
    mounted() {

        this.price = JSON.parse(this.property.price)

        this.images = JSON.parse(this.property.images)
    },
    data() {
        return {
            options: {
                slidesPerView: 1,
                // loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                    hideOnClick: true
                },
            },
            price: {},
            images: {},
            loading: false,
        }
    },
    methods: {
        refresh() {
            this.$router.go()
        },
        change() {
            this.loading = true

            let form = new FormData()
            form.append('appt_id', this.property.appt_id)
            this.$http.post('/partner/unselect', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.succ()
                    this.$router.go()
                }
            })
        }
    },
    notifications: {
        succ: {
            type: 'success',
            title: 'Unselected',
            message: 'you have unselect this partner'
        }
    }
}
</script>

<style scoped>
    .current {
        background-color: white;
        border-radius: 5px;
        margin-top: 20px;
        margin-bottom: 20px;
        overflow: hidden;
    }


    
    .content {
        padding: 15px;
        border-top: 1px solid #ddd;
    }

    .opt {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 15px;
        margin-top: 15px;
    }

    .btn {
        background-color: var(--trans);
        color: var(--main);
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
    }

    .text {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .cancel {
        margin-top: 15px;
        font-size: 14px;
        margin-bottom: 15px;
    }

    .details {
        font-size: 14px;
    }
    .details .text {
        margin-top: 15px;
    }
    .title {
        margin: 0px -15px;
        /* margin-top: 15px; */
        font-weight: 500;
        background-color: var(--main);
        color: white;
        padding: 10px 15px;
    }
    .f {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;
    }
    .label {
        font-size: 12px;
    }
    .value {
        font-weight: 500;
        text-align: right;
    }
    .code {
        text-align: center;
        font-weight: 500;
        font-size: 20px;
        margin: 10px 0px;
    }


    .payment {
        margin-top: 20px;
        /* font-size: 16px; */
    }
    .payment .title {
        margin-bottom: 15px;
    }
    .payment .note {
        margin-top: 10px;
    }
</style>