<template>
    <div class="details">
        <div class="head">
            Partner Dashboard
        </div>
        <div class="loading" v-if="loading">
            <div class="spin"></div>
        </div>
        <div v-else v-masonry transition-duration="0.3s" 
                item-selector=".man_item" 
                horizontal-order="true"
                gutter="20"
                class="items">
            <detailsvue v-masonry-tile class="man_item" :details="details"/>
            <earnings v-masonry-tile class="man_item" :earning="details.earnings"/>
            <statistics v-masonry-tile class="man_item" :d="details"/>
        </div>
    </div>
</template>

<script>
import detailsvue from '../../components/partner/details.vue'
import earnings from '../../components/partner/earnings.vue'
import statistics from '../../components/partner/statistics.vue'

export default {
    components: {
        detailsvue, earnings, statistics
    },
    data() {
        return {
            loading: true,
            details: []
        }
    },
    mounted() {
        this.$http.get('/partner/dashboard')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.details = r.details

                this.loading = false
            }
        })
    }
}
</script>

<style scoped>
    .details {
        padding: 20px;
    }
    .head {
        font-size: 20px;
        font-weight: 500;
    }

    .spin {
        margin: 70px auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: var(--main);
        animation: rotate 1s infinite linear;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }


    .items {
        margin-top: 20px;
        margin-bottom: 40px;
        width: 100%;
    }
    .man_item {
        width: calc(33.33% - 20px);
        margin-bottom: 20px;
    }

    @media screen and (max-width: 850px) {
        .man_item {
            width: calc(50% - 20px);
        }
    }
    @media screen and (max-width: 650px) {
        .man_item {
            width: calc(100%);
        }
    }
</style>