<template>
    <div class="message">
        <div class="title">Message Clients</div>

        <location v-for="city in cities" :key="city.name" :city="city"/>
    </div>
</template>

<script>
import location from '../components/admin/send_message_client/location.vue'
export default {
    components: {
        location
    },
    mounted() {

        this.$http.get('/properties/locations')
        .then(data => {
            let r = data.data
            if (r.success) {
                let i
                for (i = 0; i < r.cities.length; i++) {
                    let x;
                    for (x of r.countries) {
                        if (x.name == r.cities[i].country) {
                            r.cities[i]['lease'] = JSON.parse(x.Lease)
                            r.cities[i]['let'] = JSON.parse(x.Let)
                            r.cities[i]['sale'] = JSON.parse(x.Sale)
                            r.cities[i]['short'] = JSON.parse(x.Short)
                        }
                    }
                }
                this.cities = r.cities
            }
        })
    },
    data() {
        return {
            cities: []
        }
    }
}
</script>

<style scoped>

    .message {
        padding: 30px;
    }
    .title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
    }
</style>