<template>
    <div class="comment">
        <div class="head">
            <div>
                <div class="name">{{comment.firstname}} {{comment.lastname}}</div>
                <div class="time">
                    <timeago :date="comment.date"/>
                </div>
            </div>
            <div class="star">
                <star :size="'20px'" class="icon" v-for="i in s" :key="i"/>
            </div>
        </div>
        <div class="text">
            {{comment.text}}
        </div>
    </div>
</template>

<script>
import star from '../../icons/stared.vue'
import timeago from '../time.vue'
export default {
    props: ['comment'],
    components: {
        star, timeago
    },
    mounted() {
        this.s = parseInt(this.comment.star)
    },
    data() {
        return {
            s: 0
        }
    }
}
</script>

<style scoped>

    .comment {
        /* margin-bottom: 30px; */
        background-color: white;
        padding: 20px;
        border-top: 1px solid #ddd;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .name {
        font-weight: 500;
    }
    .time {
        font-weight: 500;
        font-size: 12px;
        opacity: .7;
    }
    .star {
        display: flex;
        fill: var(--main);
    }
    .icon {
        margin-left: 10px;
    }
    .text {
        opacity: .7;
        margin-top: 20px;
        font-size: 14px;
    }
</style>