<template>
    <div>
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size">
            <g>
                <path d="M436.6,139.59l-42.49-11.13-59.64,90.44a27.852,27.852,0,0,1-22.05,12.53l23.73,151.48a28.1085,28.1085,0,0,1-7.84,24.15l-6.4394,6.44H466V177.67A39.2564,39.2564,0,0,0,436.6,139.59Z"/>
                <path d="M199.6505,231.43A27.852,27.852,0,0,1,177.6,218.9l-59.64-90.44-42.49,11.13A39.32,39.32,0,0,0,46,177.67V413.5H190.1306l-6.4394-6.44a28.1091,28.1091,0,0,1-7.8409-24.15Z"/>
                <polygon points="284 214 256 176.34 228 214.07 229.75 220.09 203.5 387.25 256 439.75 308.5 387.25 282.25 220.09 284 214"/>
                <polygon points="357.283 72.25 256.033 129.437 311.111 203.5 374.158 107.875 357.283 72.25"/>
                <polygon points="137.908 107.875 200.955 203.5 256.033 129.437 154.784 72.25 137.908 107.875"/>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>