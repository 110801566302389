<template>
    <div class="dashboard">
        <div class="title">Employee Dashboard</div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .dashboard {
        padding: 20px;
    }
    .title {
        font-weight: 500;
    }
</style>