<template>
    <div class="login">
        <headerLayout class="h" :no_bg="true"/>
        <div class="main">
            <div class="title">
                Login
            </div>
            <div class="small">Enter your email and password to signin into your account</div>
            <div class="input">
                <div class="label" v-if="email != ''">Email:</div>
                <input type="text" placeholder="Email" v-model="email">
                <div class="err" v-if="e">Email has not been registered</div>
            </div>
            <div class="input">
                <div class="label" v-if="password != ''">Password:</div>
                <div class="flex">
                    <input :type="show ? 'text' : 'password'" placeholder="Password" v-model="password">
                    <div class="view" @click="show = !show">
                        <eye :size="'20px'"/>
                    </div>
                </div>
                <div class="err" v-if="p">Incorrect Password</div>
            </div>

            <div class="incorrect" v-if="show_incorrect">
                <div v-if="login_attempt < 5">
                    You've entered incorrect password {{login_attempt}} of 5 times
                </div>
                <div v-else>
                    Your account has been temporarily locked, please use the forgot password below
                    to unlock your account
                </div>
            </div>

            <loader :height="'39px'" v-if="loading"/>
            <div class="btn" v-else @click="login()">Log in</div>

            <div class="text">
                <span><router-link to="/forgot_password"> Forgot Password? </router-link></span>
            </div>
            <div class="text">Don't have an account?
                <span> <router-link to="/signup">Register</router-link></span>
            </div>
        </div>
        <register/>
        <footerLayout/>
    </div>
</template>

<script>
import headerLayout from '../components/header.vue'

import eye from '../icons/view.vue'

import loader from '../components/loader.vue'
import register from '../components/register.vue'
import footerLayout from '../components/footer.vue'

import Localbase from 'localbase'
export default {
    components: {
        headerLayout,
        eye,
        loader,
        register, footerLayout
    },
    data() {
        return {
            db: new Localbase('Bigglandlord'),
            loading: false,
            email: '',
            password: '',
            e: false,
            p: false,
            show: false,
            show_incorrect: false,
            login_attempt: 0
        }
    },
    mounted () {
        window.scrollTo(0, 0)

        this.db.collection('auth').doc({ id: 1 }).get().then(document => {
            if (document == undefined) {
                this.$root.$data.login = false
            } else {
                this.$router.push('/')
            }
        })
    },
    methods: {
        login() {
            if (this.email == '' && this.password == '') {
                this.empty(); return
            }
            this.loading = true
            this.e = false
            this.p = false

            let form = new FormData()
            form.append('email', this.email)
            form.append('password', this.password)

            this.$http.post('/login', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()
                    this.db.collection('auth').add({
                        'id': 1,
                        'key': r.session,
                        'email': this.email,
                        'firstname': r.firstname,
                        'lastname': r.lastname,
                        'phone': r.phone,
                        'whatsapp': r.whatsapp,
                        'user_id': r.user_id,
                        'dp': r.dp
                    }).then( () => {
                        this.$root.$data.login = true
                        this.$root.$data.firstname = r.firstname
                        this.$root.$data.lastname = r.lastname
                        this.$root.$data.phone = r.phone
                        this.$root.$data.whatsapp = r.whatsapp
                        this.$root.$data.user_id = r.user_id
                        this.$root.$data.dp = r.dp
                        this.$root.$data.email = this.email
                        this.$http.defaults.headers.common['Auth'] = r.session

                        if (r.email_verified != '0') {
                            setTimeout(() => {
                                localStorage.removeItem('email')
                                this.$router.push('/dashboard')
                            }, 1000);
                        } else {
                            localStorage.setItem('email', this.email)
                            setTimeout(() => {
                                this.$router.push('/verify')
                            }, 1000);
                        }
                    })
                } else {
                    if (r.email) {
                        this.e = true
                    }
                    if (r.password) {
                        this.p = true
                        this.login_attempt = r.login_attempt
                        this.show_incorrect = true
                    }
                }
                this.loading = false
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: 'Empty',
            message: 'Input your email and password'
        },
        success: {
            type: 'success',
            title: 'Success',
            message: 'You are now logged in'
        }
    }
}
</script>

<style scoped>

    .main {
        max-width: 350px;
        margin: 150px auto;
        margin-top: 230px;
        padding: 30px;
        background-color: #edeef0;
        border-radius: 10px;
    }
    .h {
        position: fixed;
        width: 100%;
        box-sizing: border-box;
        top: 0px;
        left: 0px;
        z-index: 5;
        color: white;
    }
    .title {
        font-weight: 500;
        font-size: 25px;
    }
    .small {
        font-size: 12px;
        font-weight: 500;
        opacity: .7;
        margin-bottom: 30px;
        margin-top: 5px;
    }
    .flex {
        display: flex;
        align-items: center;
        background-color: white;
        border-radius: 5px;
    }
    .view {
        padding: 0px 10px;
        fill: var(--main);
        cursor: pointer;
    }
    .input {
        margin-bottom: 30px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -20px;
        left: 10px;
        font-weight: 500;
        font-size: 14px;
        animation: .2s up;
    }
    @keyframes up {
        from {
            top: 0px;
        }
        to {
            top: -20px;
        }
    }
    input {
        width: 100%;
        padding: 12px;
        border-radius: 5px;
        border: none;
        box-sizing: border-box;
        outline: none;
        background-color: white;
    }
    input:-webkit-autofill { 
        -webkit-background-clip: text;
    }
    .err {
        position: absolute;
        right: 0px;
        bottom: -13px;
        font-size: 12px;
        color: red;
        font-weight: 500;
    }
    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 10px 30px;
        border-radius: 5px;
        font-weight: 500;
        margin-top: 20px;
        text-align: center;
    }
    .text {
        text-align: center;
        margin-top: 20px;
    }
    span {
        font-weight: 500;
        color: var(--main);
    }


    .incorrect {
        font-weight: 500;
        font-size: 12px;
        text-align: center;
        margin-bottom: 30px;
    }
</style>