<template>
    <div class="users">
        <div class="title">
            Users
        </div>
        <partners :title="'Partners'" :data="partners" :loading="fetching"/>
        
        <users_table :title="'Users'" :data="users" :loading="loading"/>
    </div>
</template>

<script>
import partners from '../components/admin/users/partners.vue'
import users_table from '../components/admin/users/table.vue'
export default {
    components: {
        partners,
        users_table
    },
    data() {
        return {
            users: [],
            partners: [],
            loading: false,
            fetching: false
        }
    },
    mounted() {
        this.loading = true
        this.$http.get('/users/get')
        .then(data => {
            let r = data.data
            this.users = r.users
            this.loading = false
        })

        this.fetching = true
        this.$http.get('/users/partners')
        .then(data => {
            let r = data.data
            this.partners = r.partners
            this.fetching = false

        })
    }
}
</script>

<style scoped>
    .users {
        padding: 30px;
    }
    .title {
        font-size: 20px;
        font-weight: 600;
    }
</style>