<template>
    <div class="contact">
        <div class="title">
            Do you like this property? 
        </div>
        <div class="small">Book an appointment for a physical tour</div>

        <div class="input">
            <div class="label">Set appointment date</div>
            <input type="datetime-local" v-model="date" :min="min">
        </div>

        <loader :height="'37px'" v-if="loading"/>
        <div v-else>
            <div class="btn" v-if="login" @click="book()">Book Appointment</div>
            <router-link to="/signup" v-else>
                <div class="btn">Login / Signup</div>
            </router-link>
        </div>
    </div>
</template>

<script>
import loader from '../loader.vue'
export default {
    props: ['id', 'user'],
    components: {
        loader
    },
    computed: {
        login() {
            return this.$root.$data.login
        }
    },
    mounted() {
        this.min = new Date().toISOString().slice(0,new Date().toISOString().lastIndexOf(":"))
    },
    data() {
        return {
            min: '',
            date: '',
            loading: false
        }
    },
    methods: {
        book() {
            if (this.date == '') {
                this.date_err(); return
            }
            if (this.user == this.$root.$data.user_id) {
                this.owner(); return
            }

            this.loading = true
            let form = new FormData()
            form.append('id', this.id)
            form.append('user_id', this.$root.$data.user_id)
            form.append('date', this.date)

            this.$http.post('/appointment/new', form)
            .then( data => {
                let r = data.data

                if (r.success) {
                    this.loading = false
                    this.success()

                    let d = new Date(this.date)
                    let y = d.getFullYear()
                    let m = d.getMonth() + 1
                    let day = d.getDate()

                    let h = d.getHours()
                    let min = d.getMinutes()

                    this.$emit('new_appointment', {
                        'appt_id': r.appt_id,
                        'date': `${y}-${m}-${day} ${h}:${min}`
                    })
                }
            })
        }
    },
    notifications: {
        date_err: {
            type: 'warn',
            title: 'Date',
            message: 'Add date and time to your appointment'
        },
        success: {
            type: 'success',
            title: 'Success',
            message: 'Your appointment has been booked'
        },
        owner: {
            type: 'warn',
            title: 'Owner',
            message: "You can't book an appointment for your own property"
        }
    }
}
</script>

<style scoped>
    .contact {
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        /* width: 250px; */
        height: fit-content;
        margin-bottom: 30px;
    }
    .small {
        font-size: 14px;
        font-weight: 500;
        opacity: .5;
    }
    .title {
        font-weight: 500;
        font-size: 20px;
    }

    
    .input {
        margin: 50px 0px;
        position: relative;
    }
    .label {
        position: absolute;
        background-color: white;
        padding: 0px 5px;
        top: -10px;
        left: 10px;
        transition: .2s;
        font-weight: 500;
    }
    input, select, textarea {
        padding: 15px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
    }
    
    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 8px 20px;
        border-radius: 5px;
        font-weight: 500;
        /* margin-top: 20px; */
        text-align: center;
    }
</style>