<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">Submit Your Details</div>
            <div class="small">
                Please fill the form below has required by the property owner and Bigglandlord
            </div>

            <div class="input">
                <div class="image" v-if="pic.added">
                    <div class="remove" @click="remove('pic')">
                        <remove :size="'20px'"/>
                    </div>
                    <img :src="pic.url"/>
                </div>
                <div class="upload" v-else @click="upload('pic')">
                    Upload a picture of yourself
                </div>
            </div>
            <div class="input">
                <div class="label">Legal Full Name</div>
                <input type="text" placeholder="Enter your legal full name" v-model="name">
            </div>
            <div class="input">
                <div class="label">Phone Number</div>
                <input type="text" placeholder="Enter your phone number" v-model="phone">
            </div>
            <div class="input">
                <div class="label">Date of Birth</div>
                <input type="date" placeholder="Enter your date of birth" v-model="date">
            </div>
            <div class="input">
                <div class="label">State Of Origin</div>
                <input type="text" placeholder="Enter your state of origin" v-model="s_o_o">
            </div>
            <div class="input">
                <div class="label">Religion</div>
                <input type="text" placeholder="Enter your religion" v-model="religion">
            </div>
            <div class="input">
                <div class="label">Occupants</div>
                <input type="number" placeholder="Enter number of occupants" v-model="occupants">
            </div>
            <div class="input">
                <div class="label">Previous Residential Address</div>
                <input type="text" placeholder="Enter your previous residential address" v-model="previous_address">
            </div>
            <div class="input">
                <div class="label">Occupation</div>
                <input type="text" placeholder="Enter your occupation" v-model="occupation">
            </div>
            <div class="input">
                <div class="label">Occupation Address</div>
                <input type="text" placeholder="Enter your occupation location" v-model="occupation_address">
            </div>


            <div class="input">
                <div class="label">Position Held</div>
                <input type="text" placeholder="Enter postion held" v-model="position_held">
            </div>
            <div class="input">
                <div class="label">Guarantor Name</div>
                <input type="text" placeholder="Enter guarantor name" v-model="guarantor_name">
            </div>
            <div class="input">
                <div class="label">Guarantor Address</div>
                <input type="text" placeholder="Enter guarantor address" v-model="guarantor_address">
            </div>
            <div class="input">
                <div class="label">Guarantor Phone Number</div>
                <input type="text" placeholder="Enter guarantor phone number" v-model="guarantor_phone">
            </div>
            <div class="input">
                <div class="label">Next Of Kin Name</div>
                <input type="text" placeholder="Enter next of kin name" v-model="kin_name">
            </div>
            <div class="input">
                <div class="label">Next Of Kin Address</div>
                <input type="text" placeholder="Enter next of kin address" v-model="kin_address">
            </div>
            <div class="input">
                <div class="label">Next Of Kin Phone Number</div>
                <input type="text" placeholder="Enter next of kin phone number" v-model="kin_phone">
            </div>

            <div class="input">
                <div class="image" v-if="doc.added">
                    <div class="remove" @click="remove('doc')">
                        <remove :size="'20px'"/>
                    </div>
                    <img :src="doc.url"/>
                </div>
                <div class="upload" v-else @click="upload('doc')">
                    Upload a valid ID card (NIN, Voter's Card, Driver Licence, Int Passport)
                </div>
            </div>

            <loader :height="'41px'" v-if="loading"/>
            <div class="btn" v-else @click="submit()">Submit</div>

            <input type="file" accept="image/*" class="hidden" ref="pic" @change="handleUpload($event, 'pic')"/>
            <input type="file" accept="image/*" class="hidden" ref="doc" @change="handleUpload($event, 'doc')"/>
        
        </div>
    </div>
</template>

<script>
import imageCompression from 'browser-image-compression';

import remove from '../../icons/remove.vue';

import loader from '../loader.vue'

export default {
    components: {
        remove,
        loader
    },
    props: ['appt_id'],
    data() {
        return {
            pic: {
                added: false,
                url: '',
                file: false
            },
            name: '',
            phone: '',
            date: '',
            s_o_o: '',
            religion: '',
            occupants: '',
            previous_address: '',
            occupation: '',
            occupation_address: '',

            position_held: '',
            guarantor_name: '',
            guarantor_address: '',
            guarantor_phone: '',
            kin_name: '',
            kin_address: '',
            kin_phone: '',
            doc: {
                added: false,
                url: '',
                file: null
            },
            loading: false
        }
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },
        upload(what) {
            this.$refs[what].click()
        },
        remove(what) {
            URL.revokeObjectURL(this[what].url)

            this[what] = {
                added: false,
                url: '',
                file: null
            }
        },
        handleUpload(event, put) {

            if (event.target.files[0] == undefined) {
                return
            }

            let mediaType = event.target.files[0].type
            if (mediaType.includes('image')) {
                
                const imageFile = event.target.files[0];

                this.compress(imageFile, put)
            }
        },
        compress(file, put) {
            let name = file.name
            
            const options = {
                maxSizeMB: 0.03,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            imageCompression(file, options).then( res => {
                
                let url = URL.createObjectURL(res)
                this[put] = {
                    'added': true,
                    'url': url,
                    'file': new File([res], name)
                }
                // this.put.file = new File([res], name)
                
            })
        },
        submit() {
            if (this.pic.added == false || this.doc.added == false || this.name == '' || this.phone == '' || 
                this.date == '' || this.s_o_o == '' || this.religion == '' || this.occupants == '' || 
                this.previous_address == '' || this.occupation == '' || this.occupation_address == '' || 
                this.position_held == '' || this.guarantor_name == '' || this.guarantor_address == '' ||
                this.guarantor_phone == '' || this.kin_name == '' || this.kin_address == '' ||
                this.kin_phone == ''
                ) {
                    this.empty(); return
                }

            this.loading = true
            
            let form = new FormData()
            form.append('appt_id', this.appt_id)
            form.append('name', this.name)
            form.append('phone', this.phone)
            form.append('dob', this.date)
            form.append('s_o_o', this.s_o_o)
            form.append('religion', this.religion)
            form.append('occupants', this.occupants)
            form.append('previous_address', this.previous_address)
            form.append('occupation', this.occupation)
            form.append('occupation_address', this.occupation_address)

            form.append('position_held', this.position_held)
            form.append('guarantor_name', this.guarantor_name)
            form.append('guarantor_address', this.guarantor_address)
            form.append('guarantor_phone', this.guarantor_phone)
            form.append('kin_name', this.kin_name)
            form.append('kin_address', this.kin_address)
            form.append('kin_phone', this.kin_phone)
            form.append('pic', this.pic.file)
            form.append('doc', this.doc.file)

            this.$http.post('/user/sumbit_details', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    this.success()
                    this.$emit('success')
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Please provide all your information'
        },
        success: {
            type: 'success',
            title: 'Success',
            message: 'Your details has been saved and submitted'
        }
    }
}
</script>

<style scoped>
    .popup {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 7;
    }
    .dark {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background-color: rgba(0, 0, 0, 0.5);
    }
    .box {
        width: 350px;
        max-width: 80vw;
        max-height: 80vh;
        overflow: auto;
        background-color: white;
        border-radius: 5px;
        padding: 20px;
        position: relative;
        z-index: 7;
    }

    .title {
        font-size: 20px;
        font-weight: 500;
    }
    .small {
        font-size: 12px;
        margin-bottom: 20px;
    }
    .upload {
        height: 100px;
        background-color: var(--trans);
        border: 1px dotted var(--main);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        cursor: pointer;
        font-size: 14px;
        text-align: center;
        border-radius: 5px;
        padding: 20px;
        box-sizing: border-box;
    }
    
    .input {
        margin-bottom: 25px;
        position: relative;
    }
    .label {
        position: absolute;
        background-color: white;
        padding: 0px 5px;
        top: -10px;
        left: 10px;
        transition: .2s;
        font-weight: 500;
        font-size: 14px;
    }
    input, select {
        padding: 15px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
    }
    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: 500;
        margin-top: 15px;
        text-align: center;
    }

    
    input[type=file] {
        visibility: hidden;
        display: none;
    }

    .image {
        
        height: 100px;
        overflow: hidden;
        border-radius: 5px;
        overflow: hidden;
        background-color: #edeef0;
        position: relative;
        display: flex;
        justify-content: center;
    }
    .remove {
        fill: var(--main);
        cursor: pointer;
        position: absolute;
        top: 0px;
        left: 0px;
    }
    .image img {
        height: 100px;
    }
</style>