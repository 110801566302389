<template>
    <div class="city">
        <div class="image">
            <img :src="url + banner.image" alt="">
        </div>
    </div>
</template>

<script>
export default {
    props: ['banner'],
    computed: {
        url() {
            return this.$root.$data.banurl
        }
    }
}
</script>

<style scoped>
    .city {
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        /* width: 400px; */
    }
    .city:hover img {
        /* transform: scale(1.5); */
    }
    .content {
        position: absolute;
        bottom: 0px;
        color: white;
        width: 100%;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.8);
        padding: 10px;
        box-sizing: border-box;
    }
    .name {
        font-weight: 500;
        color: var(--main);
    }
    
    .image {
        max-height: 250px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    img{
        max-height: 250px;
        /* transform: scale(1.2); */
        transition: .8s;
        /* max-width: 500px; */
    }

</style>