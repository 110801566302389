<template>
    <div class="login">
        <headerLayout class="h" :no_bg="true"/>
        <div class="main">
            <div class="title">
                Signup
            </div>
            <div class="small">Create an account to buy, sell or rent properties</div>
            <div class="input">
                <div class="label" v-if="firstname != ''">Firstname:</div>
                <input type="text" placeholder="Firstname" v-model="firstname">
                <div class="err" v-if="f">firstname cannot be empty</div>
            </div>
            <div class="input">
                <div class="label" v-if="lastname != ''">Lastname:</div>
                <input type="text" placeholder="Lastname" v-model="lastname">
                <div class="err" v-if="l">lastname cannot be empty</div>
            </div>
            <div class="input">
                <div class="label" v-if="email != ''">Email:</div>
                <input type="text" placeholder="Email" v-model="email">
                <div class="err" v-if="e_t">Email cannot be empty</div>
                <div class="err" v-if="e">This email has already been used</div>
            </div>
            <div class="input">
                <div class="label" v-if="password != ''">Password:</div>
                <div class="flex">
                    <input :type="show ? 'text' : 'password'" placeholder="Password" v-model="password">
                    <div class="view" @click="show = !show">
                        <eye :size="'20px'"/>
                    </div>
                </div>
                <div class="err" v-if="p">Password must be greater than 8 characters</div>
            </div>
            <div class="input">
                <div class="label" v-if="password2 != ''">Repeat Password:</div>
                <div class="flex">
                    <input :type="show ? 'text' : 'password'" placeholder="Repeat Password" v-model="password2">
                    <div class="view" @click="show = !show">
                        <eye :size="'20px'"/>
                    </div>
                </div>
                <div class="err" v-if="p2">Password Mismatch</div>
            </div>
            <loader :height="'39px'" v-if="loading"/>
            <div class="btn" v-else @click="signup()">Sign up</div>

            <div class="agree">
                By signing up, you agree to Bigglandlord 
                <router-link to="/terms" tag="a"> Terms and Conditions </router-link>
            </div>

            <div class="text">already have an account?
                <span> <router-link to="/login">Login</router-link></span>
                </div>
        </div>
        <register/>
        <footerLayout/>
    </div>
</template>

<script>
import headerLayout from '../components/header.vue'

import eye from '../icons/view.vue'

import loader from '../components/loader.vue'
import register from '../components/register.vue'
import footerLayout from '../components/footer.vue'
import Localbase from 'localbase'
export default {
    components: {
        headerLayout,
        eye,
        loader,
        register, footerLayout
    },
    data() {
        return {
            db: new Localbase('Bigglandlord'),
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            password2: '',
            f: false,
            l: false,
            e_t: false,
            e: false,
            p: false,
            p2: false,
            loading: false,

            show: false
        }
    },
    mounted () {
        window.scrollTo(0, 0)

        this.db.collection('auth').doc({ id: 1 }).get().then(document => {
            if (document == undefined) {
                this.$root.$data.login = false
            } else {
                this.$router.push('/')
            }
        })
    },
    methods: {
        signup() {
            let err = false
            if (this.firstname == '') { this.f = true; err = true}
            if (this.lastname == '') { this.l = true; err = true}
            if (this.email == '') { this.e_t = true; err = true}
            if (this.password.length < 8) { this.p = true; err = true}
            if (this.password2 != this.password) { this.p2 = true; err = true}

            if (err) { return }
            this.f = false
            this.l = false
            this.e_t = false
            this.e = false
            this.p = false
            this.p2 = false

            this.loading = true

            let form = new FormData()
            form.append('firstname', this.firstname)
            form.append('lastname', this.lastname)
            form.append('email', this.email)
            form.append('password', this.password)

            this.$http.post('/signup', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()
                    this.db.collection('auth').add({
                        'id': 1,
                        'key': r.session,
                        'email': this.email,
                        'firstname': this.firstname,
                        'lastname': this.lastname,
                        'user_id': r.user_id
                    }).then( () => {
                        this.$root.$data.login = true
                        this.$root.$data.firstname = this.firstname
                        this.$root.$data.lastname = this.lastname
                        this.$root.$data.user_id = r.user_id
                        this.$http.defaults.headers.common['Auth'] = r.session
                        localStorage.setItem('email', this.email)
                        setTimeout(() => {
                            this.$router.push('/verify')
                        }, 1000);
                    })
                } else {
                    if (r.email) {
                        this.e = true
                    }
                }
                this.loading = false
            })
        }
    },
    notifications: {
        success: {
            type: 'success',
            title: 'Success',
            message: 'Your account has been successfully created'
        }
    }
}
</script>

<style scoped>

    .main {
        max-width: 350px;
        margin: 150px auto;
        margin-top: 230px;
        padding: 30px;
        background-color: #edeef0;
        border-radius: 10px;
    }
    .h {
        position: fixed;
        width: 100%;
        box-sizing: border-box;
        top: 0px;
        left: 0px;
        z-index: 5;
        color: white;
    }
    .title {
        font-weight: 500;
        font-size: 25px;
    }
    .small {
        font-size: 12px;
        font-weight: 500;
        opacity: .7;
        margin-bottom: 30px;
        margin-top: 5px;
    }
    .flex {
        display: flex;
        align-items: center;
        background-color: white;
        border-radius: 5px;
    }
    .view {
        padding: 0px 10px;
        fill: var(--main);
        cursor: pointer;
    }
    .input {
        margin-bottom: 30px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -20px;
        left: 10px;
        font-weight: 500;
        font-size: 14px;
        animation: .2s up;
    }
    @keyframes up {
        from {
            top: 0px;
        }
        to {
            top: -20px;
        }
    }
    input {
        width: 100%;
        padding: 12px;
        border-radius: 5px;
        /* border: 1px solid #ccc; */
        border: none;
        box-sizing: border-box;
        outline: none;
    }
    .err {
        position: absolute;
        right: 0px;
        bottom: -13px;
        font-size: 12px;
        color: red;
        font-weight: 500;
    }
    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 10px 30px;
        border-radius: 5px;
        font-weight: 500;
        margin-top: 20px;
        text-align: center;
    }

    .agree {
        font-size: 12px;
        margin-top: 5px;
    }
    a {
        color: var(--main);
        font-weight: 500;
    }
    .text {
        text-align: center;
        margin-top: 20px;
    }
    span {
        font-weight: 500;
        color: var(--main);
    }
</style>