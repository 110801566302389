<template>
    <div class="search">
        <div class="title">
            Search
        </div>
        <div class="text">
            Use our search tool to quickly find what you are looking for
        </div>

        <div class="location" @click="pop2 = true">
            {{price}}
        </div>
        
        <div class="input">
            <select v-model="category">
                <option value="" disabled selected>Property Category</option>
                <option value="Let">To Let</option>
                <option value="Short">Short Let</option>
                <option value="Lease">Lease</option>
                <option value="Sale">For Sale</option>
            </select>
        </div>
        
        <div class="location" @click="pop = true">
            {{place}}
        </div>
        <div class="input">
            <select v-model="type">
                <option value="" disabled selected>Property Type</option>
                <option v-for="t in types" :key="t" :value="t">{{t}}</option>
            </select>
        </div>
        
        <div class="btn" @click="search()">Search</div>
        
        <div class="popup" v-if="pop">
            <div class="dark2" @click="pop = false"></div>
            <location @close="pop = false" @save="set_location($event)"/>
        </div>

        <div class="popup" v-if="pop2">
            <div class="dark2" @click="pop2 = false"></div>
            <prices @close="pop2 = false" @save="set_price($event)"/>
        </div>
    </div>
</template>

<script>
import location from '../location.vue'
import prices from '../prices.vue'
export default {
    components: {
        // dropdown 
        location, prices
    },
    data() {
        return {
            price: 'Set price range',
            place: 'Set Location',
            min: 0,
            max: 1000000000000000,
            location: [],
            properties: [],
            types: [],
            type: '',
            category: '',
            pop: false,
            pop2: false
        }
    },
    watch: {
        category() {
            this.types = JSON.parse(this.properties[this.category])
            this.type = ''
        }
    },
    methods: {
        set_price(e) {
            this.price = `${e.min} - ${e.max}`

            this.min = e.min
            this.max = e.max

            this.pop2 = false
        },
        set_location(e) {
            
            this.location = e.l
            this.place = `${e.l.city + ' ' + e.l.state + ', ' + e.l.country}`
            this.pop = false

            this.properties = e.p
            
            this.types = JSON.parse(this.properties[this.category])

            this.type = ''
        },
        search() {
            if (this.place == 'Set Location') { this.loc_err(); return }
            if (this.type == '') { this.type_err(); return }

            this.$router.push({
                path: '/properties',
                query: {
                    'min': this.min,
                    'max': this.max,
                    'city': this.location.city,
                    'state': this.location.state,
                    'country': this.location.country,
                    'category': this.category,
                    'type': this.type
                }
            })
        }
    },
    notifications: {
        loc_err: {
            type: 'warn',
            title: 'Location',
            message: 'Please select a location'
        },
        type_err: {
            type: 'warn',
            title: 'Type',
            message: 'please choose a property type'
        }
    }
}
</script>

<style scoped>
    .search {
        width: 250px;
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        height: fit-content;
    }
    .title {
        font-weight: 500;
        font-size: 18px;
    }
    .text {
        margin-bottom: 20px;
        font-size: 12px;
    }
    .location {
        color: black;
        border: 1px solid #ccc;
        padding: 13px;
        border-radius: 5px;
        cursor: pointer;
        text-align: left;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .input {
        margin-bottom: 20px;
    }
    input, select {
        width: 100%;
        padding: 13px 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
        box-sizing: border-box;

    }
    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 8px 20px;
        border-radius: 5px;
        font-weight: 500;
        margin-top: 20px;
        text-align: center;
    }
    
      
    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        box-sizing: border-box;
        z-index: 5;
    }
    .dark2 {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 2;
    }
</style>