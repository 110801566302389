<template>
    <div class="rating">
        <div class="label">My Rating</div>
        <div class="star">
            <star :size="'30px'" v-for="i in s" :key="i"/>
        </div>
        <div class="no">
            {{r}}
        </div>
        <div class="info">
            <div>
                <div class="l">Total Rating</div>
                <div class="value">{{rating.rating}}</div>
            </div>
            <div>
                <div class="l">Total Users</div>
                <div class="value">{{rating.users}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import star from '../../icons/stared.vue'
export default {
    components: {
        star
    },
    props: ['rating'],
    data() {
        return {
            r : 0,
            s: 0
        }
    },
    mounted() {
        let r = this.rating
        if (r.rating > 0) {
            var number = r.rating / r.users
            this.r = Math.round(number * 10) / 10
            this.s = Math.round(this.r)
        } else {
            this.r = 0.0
            this.s = 5
        }
    }
}
</script>

<style scoped>
    .rating {
        background-color: white;
        padding: 20px 0px ;
        border-radius: 10px;
        overflow: hidden;
    }
    .label {
        background-color: var(--main);
        padding: 10px 20px;
        color: white;
        font-weight: 500;
        width: fit-content;
    }

    .star {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        fill: var(--main);
    }
    .no {
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 40px;
    }
    .info {
        display: grid;
        grid-template-columns: 50% 50%;
        background-color: #edeef0;
        margin: 0px 20px;
    }
    .info > div {
        padding: 20px;
    }

    .l {
        font-weight: 500;
        opacity: .5;
        font-size: 12px;
    }
    .value {
        font-size: 18px;
        font-weight: 500;
    }
</style>