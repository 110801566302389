<template>
    <div>
        <svg :width="size" :height="size" style="enable-background:new 0 0 128 128;" version="1.1" viewBox="0 0 128 128" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path :fill="color" d="M117.4,115V29.9l-5.7-5.7H89.3V115h-5V7.3h-68L10.6,13v102H4.9v5.7h118.2V115H117.4z M98.3,38.8h11.3v11.3H98.3  V38.8z M98.3,55.8h11.3v11.3H98.3V55.8z M98.3,72.8h11.3v11.3H98.3V72.8z M98.3,90.1h11.3v11.3H98.3V90.1z M44.6,114.4V86.1h22.6  v28.3C67.2,114.4,44.6,114.4,44.6,114.4z M63.2,17.2h11.3v11.3H63.2V17.2z M63.2,34.1h11.3v11.3H63.2V34.1z M63.2,51.2h11.3v11.3  H63.2V51.2z M63.2,68.5h11.3v11.3H63.2V68.5z M40.6,17.2h11.3v11.3H40.6V17.2z M40.6,34.1h11.3v11.3H40.6V34.1z M40.6,51.2h11.3  v11.3H40.6V51.2z M40.6,68.5h11.3v11.3H40.6V68.5z M17.9,17.2h11.3v11.3H17.9V17.2z M17.9,34.1h11.3v11.3H17.9V34.1z M17.9,51.2  h11.3v11.3H17.9V51.2z M17.9,68.5h11.3v11.3H17.9V68.5z"/>
        </svg>
    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>