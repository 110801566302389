<template>
    <div class="popup">
        <div class="dark" @click="$emit('close')"></div>

        <div class="box">
            <div class="title">Tenant Information</div>

            <div class="each">
                <div class="label">Name</div>
                <div class="value">{{ tenant.name }}</div>
            </div>
            <div class="each">
                <div class="label">Phone Number</div>
                <div class="value">{{ tenant.phone }}</div>
            </div>
            <div class="each">
                <div class="label">Whatsapp Number</div>
                <div class="value">{{ tenant.whatsapp }}</div>
            </div>
            <div class="each">
                <div class="label">Email</div>
                <div class="value">{{ tenant.email }}</div>
            </div>
            <div class="each">
                <div class="label">Office Address</div>
                <div class="value">{{ tenant.office_address }}</div>
            </div>
            <div class="each">
                <div class="label">Property Type</div>
                <div class="value">{{ tenant.property_type }}</div>
            </div>

            <div class="each">
                <div class="label">Guarantor Name</div>
                <div class="value">{{ tenant.guarantor_name }}</div>
            </div>
            <div class="each">
                <div class="label">Guarantor Address</div>
                <div class="value">{{ tenant.guarantor_address }}</div>
            </div>
            <div class="each">
                <div class="label">Guarantor Phone</div>
                <div class="value">{{ tenant.guarantor_phone }}</div>
            </div>
            
            <div class="each">
                <div class="label">Next of Kin Name</div>
                <div class="value">{{ tenant.next_of_kin_name }}</div>
            </div>
            <div class="each">
                <div class="label">Next of Kin Address</div>
                <div class="value">{{ tenant.next_of_kin_address }}</div>
            </div>
            <div class="each">
                <div class="label">Next of Kin Phone</div>
                <div class="value">{{ tenant.next_of_kin_phone }}</div>
            </div>

            <div class="flex">
                <div class="t">Payment History</div>
                <div class="btn" @click="show_add = true">Add Payment</div>
            </div>

            <div class="loading" v-if="loading">
                <div class="spin"></div>
            </div>
            <div class="grid" v-else>
                <div class="payment" v-for="payment in payments" :key="payment.id">
                    <div class="l">Rent Price</div>
                    <div class="v">{{ payment.rent_price }}</div>
                    
                    <div class="l">Rent Duration</div>
                    <div class="v">{{ payment.rent_duration_no }} {{ payment.rent_duration }}</div>

                    <div class="l">Rent Start</div>
                    <div class="v">{{ payment.rent_start }}</div>

                    <div class="l">Rent End</div>
                    <div class="v">{{ payment.rent_end }}</div>
                </div>
            </div>
        </div>

        <add_payment v-if="show_add" :tenant_id="tenant.tenant_id" 
            @new_payment="new_payment($event)" @close="show_add = false"/>
    </div>
</template>

<script>
import moment from 'moment'

import add_payment from './add_payment.vue'

export default {
    components: {
        add_payment
    },
    props: ['tenant'],
    methods: {
        close() {
            this.$emit('close')
        }
    },
    data() {
        return {
            show_add: false,
            loading: true,
            payments: []
        }
    },
    mounted() {
        let form = new FormData()
        form.append('tenant_id', this.tenant.tenant_id)

        this.$http.post('/manager/tenant/payments', form)
        .then(data => {
            let r = data.data
            if (r.success) {
                for (let x of r.payments) {
                    x.rent_start = moment(x.rent_start).format('Do MMMM YYYY')
                    x.rent_end = moment(x.rent_end).format('Do MMMM YYYY')

                    this.payments.push(x)
                }

                this.loading = false
            }
        })
    },
    methods: {
        new_payment(e) {
            this.payments.unshift(e)

            this.show_add = false
        }
    }
}
</script>

<style scoped>

    .popup {
        position: fixed;
        z-index: 5;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
    }
    .box {
        background-color: white;
        border-radius: 5px;
        z-index: 5;
        width: 400px;
        height: calc(100vh - 150px);
        overflow: auto;
    }


    .title {
        font-size: 18px;
        font-weight: 500;
        padding: 20px;
    }
    .each {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        gap: 20px;
        flex-wrap: wrap;
    }
    .each:nth-child(even) {
        background-color: #edeef0;
    }
    .label {
        font-size: 12px;
        font-weight: 500;
        color: gray;
    }
    .value {
        font-weight: 500;
        font-size: 14px;
    }

    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0px;
        padding: 10px 20px;
        background-color: var(--trans);
    }
    .t {
        font-weight: 500;
    }
    .btn {
        padding: 7px 20px;
        border-radius: 5px;
        background-color: var(--main);
        color: white;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }

    
    
    .spin {
        margin: 70px auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: var(--main);
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }


    .grid {
        display: grid;
        grid-template-columns: auto auto;
        gap: 20px;
        padding: 20px;
        padding-top: 0px;
    }
    .payment {
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 10px;
        padding-bottom: 0px;
    }
    .l {
        font-size: 12px;
        font-weight: 500;
        color: gray;
    }
    .v {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 10px;
    }
</style>