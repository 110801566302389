<template>
    <div class="rejected">
        <div class="box">
            <sad :size="'50px'"/>
            <div class="title">Rejeted</div>
            <div class="text">
                Your application to become an agent on Bigglandlord was rejected due to {{note}}
            </div>
            <div class="spin" v-if="getting"></div>
            <loader :height="'37px'" v-if="loading"/>
            <div class="btn" v-else @click="reapply()">Re Apply</div>
        </div>
    </div>
</template>

<script>
import sad from '../../icons/sad.vue'
import loader from '../loader.vue'
export default {
    components: {
        sad, loader
    },
    data() {
        return {
            getting: true,
            loading: false,
            note: ''
        }
    },
    mounted() {
        this.$http.get('/agent/me')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.note = r.agent[0].note
                this.getting = false
            }
        })
    },
    methods: {
        reapply() {
            this.loading = true

            this.$http.post('/agent/reapply')
            .then(data => {
                let r = data.data 
                if (r.success) {
                    this.$root.$data.agent = ''
                }
            })
        }
    }   
}
</script>

<style scoped>

    .rejected {
        height: calc(100vh - 198px);
        /* height: 100%; */
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .box {
        background-color: white;
        width: fit-content;
        padding: 20px;
        border-radius: 20px;
        text-align: center;
        max-width: 300px;
    }
    .title {
        font-weight: 500;
        font-size: 25px;
        margin: 20px;
    }
    .text {
        margin-bottom: 20px;
    }

    
    .spin {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: var(--main);
        animation: rotate 1s infinite linear;
        margin: 20px auto;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }


    .btn {
        background-color: var(--main);
        color: white;
        padding: 8px 20px;
        text-align: center;
        cursor: pointer;
        font-weight: 500;
        border-radius: 5px;
    }
</style>