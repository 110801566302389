<template>
    <div class="messages">
        <div class="head">
            <div class="title">Message Agents</div>
            <div class="total">Total: {{ total }}</div>
        </div>

        <location v-for="city in cities" :key="city.name" :city="city"/>
    </div>
</template>

<script>
import location from '../components/admin/send_message_agent/location.vue'

export default {
    components: {
        location
    },
    mounted() {

        this.$http.get('/properties/locations')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.cities = r.cities

                for (let x of this.cities) {
                    this.total += parseInt(x.numbers)
                }
            }
        })
    },
    data() {
        return {
            cities: [],
            total: 0
        }
    }
}
</script>

<style scoped>

    .messages {
        padding: 30px;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .title {
        font-size: 20px;
        font-weight: 600;
    }
</style>