<template>
    <div class="appointments">
        <div class="title">
            Appointments
        </div>
        <appointmenttable v-for="s in status" :key="s" :status="s"/>
    </div>
</template>

<script>
import appointmenttable from '../components/admin/appointments/table.vue'

export default {
    components: {
        appointmenttable
    },
    data() {
        return {
            status: ['ok', 'waiting', 'running', 'checked']
        }
    }
}
</script>

<style scoped>

    .appointments {
        padding: 30px;
    }
    .title {
        font-size: 20px;
        font-weight: 600;
    }
</style>