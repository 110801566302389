<template>
    <div class="box">
        <div class="title">My Info</div>
        <div class="item">
            <div class="label">Name:</div>
            <div class="value">{{$root.$data.lastname}} {{$root.$data.firstname}}</div>
        </div>
        <div class="item">
            <div class="label">Phone:</div>
            <div class="value">{{details.phone}}</div>
        </div>
        <div class="item">
            <div class="label">Avaliable:</div>
            <div class="value">{{details.avaliable}}</div>
        </div>
        <div class="item">
            <div class="label">Country:</div>
            <div class="value">{{details.country}}</div>
        </div>
        <div class="item">
            <div class="label">State:</div>
            <div class="value">{{details.state}}</div>
        </div>
        <div class="item">
            <div class="label">City:</div>
            <div class="value">{{details.city}}</div>
        </div>
        <div class="item">
            <div class="label">Opening:</div>
            <div class="value">{{details.opening}}</div>
        </div>
        <div class="item">
            <div class="label">Closing</div>
            <div class="value">{{details.closing}}</div>
        </div>
        <div class="item">
            <div class="label">Bank</div>
            <div class="value">{{details.bank}}</div>
        </div>
        <div class="item">
            <div class="label">Account Name</div>
            <div class="value">{{details.acct_name}}</div>
        </div>
        <div class="item">
            <div class="label">Account Number</div>
            <div class="value">{{details.acct_no}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['details']
}
</script>

<style scoped>

    .box {
        background-color: white;
        border-radius: 10px;
        box-sizing: border-box;
        overflow: hidden;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        
    }
    .title {
        font-size: 18px;
        font-weight: 500;
        padding: 20px;
    }
    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        padding: 10px 20px;
    }
    .item:nth-child(odd) {
        background-color: var(--trans);
    }
    .label {
        font-size: 14px;
        color: gray;
    }
    .value {
        font-weight: 500;
    }
</style>