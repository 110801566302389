<template>
    <div class="agent">
        <property :property="property"/>
        
        
        <div class="content">

            <div class="text">
                Our partner <b>{{property.name}}</b> is coming with a client 
                <b> {{property.lastname}} {{property.firstname}}</b> to inspect / {{property.category}}
                this property. Once the inspection is done, kindly click on the checked button below.
            </div>

            <loader v-if="loading" :height="'38px'"/>
            <div class="btn" @click="checked()" v-else>
                Checked
            </div>
        </div>
    </div>
</template>

<script>
import property from '../property.vue'
import loader from '../loader.vue'

export default {

    components: {
        property,
        loader
    },
    props: ['property'],

    data() {
        return {
            loading: false,
        }
    },
    methods: {
        checked() {
            this.loading = true

            let form = new FormData()
            form.append('appt_id', this.property.appt_id)

            this.$http.post('/agent/appoinment/checked', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.loading = false
                    this.$router.go()
                }
            })
        }
    }
}
</script>

<style scoped>
    .agent {
        background-color: white;
        border-radius: 5px;
        margin-bottom: 20px;
        overflow: hidden;
    }

    .content {
        padding: 15px;
        border-top: 1px solid #ddd;
    }


    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
    }

    .text {
        font-size: 14px;
        margin-bottom: 15px;
    }
</style>