<template>
    <div class="box">
        <div class="title">My Earnings</div>
        <div class="no">
            <vue-numeric read-only currency="₦" separator="," v-model="earning">
            </vue-numeric>
        </div>
        <div class="label">Earning History</div>

        <div class="loading" v-if="loading">
            <div class="spin"></div>
        </div>
        <div class="empty" v-if="!loading && earnings.length == 0">
            <money_bag :size="'40px'"/>
            You don't have any earning History on Bigglandlord
        </div>

        <div class="earn" v-for="(e, i) in earnings" :key="i">
            <div class="amount">{{e.paid == '0' ? '+' : ''}}{{e.amount}}</div>
            <div class="date">{{e.date}}</div>
        </div>
    </div>
</template>

<script>
import money_bag from '../../icons/money_bag.vue';

export default {
    components: {
        money_bag
    },
    props: ['earning'],
    data() {
        return {
            no: '10000000',
            earnings: [],
            loading: true
        }
    },
    mounted() {
        this.$http.get('/partner/earnings')
        .then(data => {
            let r = data.data

            if (r.success) {
                this.earnings = r.earnings
                this.loading = false

                setTimeout(() => {
                    this.$redrawVueMasonry()
                }, 1000);
            }
        })
    }
}
</script>

<style scoped>

    .box {
        background-color: white;
        border-radius: 10px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .title {
        font-size: 18px;
        font-weight: 500;
        padding: 20px;
    }
    .no {
        text-align: center;
        font-size: 25px;
        font-weight: 500;
        padding: 30px 0px;
    }
    .label {
        font-weight: 500;
        padding: 8px 20px;
        /* background-color: #edeef0; */
        background-color: var(--trans);
        font-weight: 500;
    }

    .spin {
        margin: 70px auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: var(--main);
        animation: rotate 1s infinite linear;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }


    .empty {
        text-align: center;
        fill: gray;
        font-size: 12px;
        padding: 40px 20px;
    }

    .earn {
        padding: 10px 15px;
        background-color: var(--trans);
        background-color: #edeef0;
        margin: 10px;
    }
    .amount {
        font-weight: 500;
        font-size: 18px;
    }
    .date {
        font-size: 12px;
        /* text-align: right; */
    }
</style>