<template>
    <div class="menu">
        <router-link to="/dashboard">
            <div class="item_d" :class="[view == 'dashboard' ? 'a' : '']">
                <dashboard :size="'15px'" class="icon" :style="{'height': '15px'}"/>
                Dashboard
            </div>
        </router-link>
        <div class="dropdown">
            <div class="f" @click="agent_opt = !agent_opt" 
                :class="[
                    view == 'agent' || view == 'submit' || view == 'properties' || 
                    view == 'a_appointment' || view == 'alerts' ? 'a' : '']"
                >
                <agent :size="'15px'" class="icon" :style="{'height': '15px'}"/>
                Agent
                <arrow :size="'15px'" class="arr" :class="[agent_opt ? '' : 'rot']"/>
            </div>
            <div v-if="agent_opt">
                <div v-if="agent == 'block'">
                    <router-link to="/dashboard/agent/properties">
                        <div class="opt" :class="[view == 'properties' ? 'a' : '']">
                            <house :size="'18px'" class="icon" :style="{'height': '20px', 'width': '15px'}"/>
                            View Properties
                        </div>
                    </router-link>

                    <router-link to="/dashboard/agent/appointments">
                        <div class="opt" :class="[view == 'a_appointment' ? 'a' : '']">
                            <appointment :size="'15px'" class="icon" :style="{'height': '20px'}"/>
                            Appointments
                        </div>
                    </router-link>
                </div>
                <div v-else>
                    <router-link to="/dashboard/agent/be" v-if="agent != 'ok'">
                        <div class="opt" :class="[view == 'agent' ? 'a' : '']">
                            <register :size="'15px'" class="icon" :style="{'height': '20px'}"/>
                            Become An Agent
                        </div>
                    </router-link>

                    <router-link to="/dashboard/agent/submit" v-if="agent == 'ok'">
                        <div class="opt" :class="[view == 'submit' ? 'a' : '']">
                            <plus :size="'15px'" class="icon" :style="{'height': '20px'}"/>
                            Submit A Property
                        </div>
                    </router-link>

                    <router-link to="/dashboard/agent/properties" v-if="agent == 'ok'">
                        <div class="opt" :class="[view == 'properties' ? 'a' : '']">
                            <house :size="'18px'" class="icon" :style="{'height': '20px', 'width': '15px'}"/>
                            View Properties
                        </div>
                    </router-link>

                    <router-link to="/dashboard/agent/appointments" v-if="agent == 'ok'">
                        <div class="opt" :class="[view == 'a_appointment' ? 'a' : '']">
                            <appointment :size="'15px'" class="icon" :style="{'height': '20px'}"/>
                            Appointments
                        </div>
                    </router-link>
                    
                    <router-link to="/dashboard/agent/alerts" v-if="agent == 'ok'">
                        <div class="opt" :class="[view == 'alerts' ? 'a' : '']">
                            <notification :size="'15px'" class="icon" :style="{'height': '20px'}"/>
                            Alerts
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

        <div class="dropdown" v-if="is_partner">
            <div class="f" @click="partner_opt = !partner_opt" 
                :class="[
                    view == 'p_dashboard' || view == 'p_appointments' || view == 'p_settings' ? 'a' : '']"
                >
                <partner :size="'15px'" class="icon" :style="{'height': '15px'}"/>
                Partner
                <arrow :size="'15px'" class="arr" :class="[partner_opt ? '' : 'rot']"/>
            </div>
            <div v-if="partner_opt">
                <router-link to="/dashboard/partner/dashboard">
                    <div class="opt" :class="[view == 'p_dashboard' ? 'a' : '']">
                        <dashboard :size="'15px'" class="icon" :style="{'height': '20px'}"/>
                        Dashboard
                    </div>
                </router-link>

                <router-link to="/dashboard/partner/appointments">
                    <div class="opt" :class="[view == 'p_appointments' ? 'a' : '']">
                        <appointment :size="'15px'" class="icon" :style="{'height': '20px'}"/>
                        Appointments
                    </div>
                </router-link>
                
                <router-link to="/dashboard/partner/settings">
                    <div class="opt" :class="[view == 'p_settings' ? 'a' : '']">
                        <settings :size="'15px'" class="icon" :style="{'height': '15px'}"/>
                        Settings
                    </div>
                </router-link>
            </div>
        </div>


        <router-link to="/dashboard/appointments">
            <div class="item_d" :class="[view == 'appointments' ? 'a' : '']">
                <appointment :size="'15px'" class="icon" :style="{'height': '15px'}"/>
                Appointments
            </div>
        </router-link>

        <router-link to="/dashboard/tenants">
            <div class="item_d" :class="[view == 'tenants' ? 'a' : '']">
                <house :size="'18px'" class="icon" :style="{'height': '15px'}"/>
                My Tenants
            </div>
        </router-link>
        
        <router-link to="/dashboard/settings">
            <div class="item_d" :class="[view == 'settings' ? 'a' : '']">
                <settings :size="'15px'" class="icon" :style="{'height': '15px'}"/>
                Settings
            </div>
        </router-link>
    </div>
</template>

<script>
import dashboard from '../icons/dashboard.vue'
import agent from '../icons/agent.vue'
import arrow from '../icons/arrow_down.vue'
import register from '../icons/register.vue'
import house from '../icons/house.vue'
import notification from '../icons/notification.vue'
import plus from '../icons/plus.vue'

import partner from '../icons/partner.vue'
import user from '../icons/user.vue'

import appointment from '../icons/appointment.vue'
import settings from '../icons/settings.vue'

export default {
    components: {
        dashboard, agent, arrow, register, house, notification, plus, 
        partner, user, appointment, settings
    },
    data() {
        return {
            agent_opt: false,
            partner_opt: false,
        }
    },
    computed: {
        view() {
            return this.$route.name
        },
        agent() {
            return this.$root.$data.agent
        },
        partner() {
            return this.$root.$data.partner
        },
        is_partner() {
            return this.$root.$data.is_partner == 'ok'
        }
    }
}
</script>

<style scoped>

    .menu {
        /* padding: 20px; */
        width: 300px;
        border-right: 1px solid var(--main);
        height: 100%;
        overflow: auto;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .item_d {
        margin: 20px;
        padding: 20px;
        /* background-color: #edeef0; */
        border-top: 1px solid #edeef0;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 10px;
        cursor: pointer;
        font-weight: 500;
        display: flex;
        align-items: center;
        fill: #686868;
        transition: .2s;
    }
    .item_d:hover {
        color: black;
        fill: black;
    }
    .dropdown {
        margin: 20px;
        /* background-color: #edeef0; */
        border-top: 1px solid #edeef0;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        fill: #686868;
    }
    .f {
        padding: 20px;
        display: flex;
        align-items: center;
        font-weight: 500;
    }
    .opt {
        padding: 10px 20px;
        border-top: 1px solid #edeef0;
        display: flex;
        align-items: center;
        fill: #686868;
        transition: .2s;
    }
    .opt:hover {
        background-color: #edeef0;
    }
    .icon {
        margin-right: 10px;
    }
    .arr {
        margin-left: auto;
        transition: .2s;
    }
    .rot {
        transform: rotate(-90deg);
    }
    .a, .a:hover {
        background-color: var(--main);
        color: white;
        fill: white
    }
</style>