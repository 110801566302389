<template>
    <div class="agents">
        <div class="title">Agents</div>

        <div class="loading" v-if="loading">
            <div class="spin"></div>
        </div>
        
        <agent v-for="agent in agents" :key="agent.user_id" :agent="agent"/>
    </div>
</template>

<script>
import agent from '../../components/employee/agents/agent.vue'
export default {
    components: {
        agent
    },
    data() {
        return {
            loading: true,
            agents: []
        }
    },
    created() {
        
        const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
            localStorage.removeItem('agent_page_reloaded')
            next()
            
            setTimeout(() => {
                this.$router.go()
            }, 1000);
        })

        this.$once('hook:destroyed', () => {
            unregisterRouterGuard()
        })
    },
    mounted() {
        window.scrollTo(0, 0)
        
        let r = localStorage.getItem('agent_page_reloaded')
        if (!r) {
            localStorage.setItem('agent_page_reloaded', true)
            this.$router.go()
        }

        this.$http.post('/employee/agents')
        .then(data => {
            let r = data.data

            if (r.success) {
                this.loading = false
                this.agents = r.agents
            }
        })
    }
}
</script>

<style scoped>

    .agents {
        padding: 20px;
    }
    .title {
        font-weight: 500;
        margin-bottom: 20px;
    }
    
    .spin {
        margin: 70px auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: var(--main);
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .grid {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 20px;
    }
</style>