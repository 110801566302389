<template>
    <div class="properties">
        <div class="head">
            <div class="title">My Properties</div>
            <div class="btn" @click="pop = true" v-if="!loading">
                <filtericon :size="'15px'"/>
                Filter
            </div>
        </div>

        <div class="grid">
            <property v-for="property in properties" :key="property.prop_id"
            :property="property"/>
        </div>

        <div class="loading" v-if="loading">
            <div class="spin"></div>
        </div>

        <div class="empty" v-if="!loading && properties.length == 0">
            You don't have any property on Bigglandlord at the moment
        </div>

        <filterpop @close="pop = false" v-if="pop" @sort="sort($event)"/>
    </div>
</template>

<script>
import property from '../components/agent/property.vue'

import filtericon from '../icons/filter.vue'

import filterpop from '../components/agent/filter.vue'

export default {
    components: {
        property,
        filtericon,
        filterpop
    },
    data() {
        return {
            all: [],
            properties: [],
            loading: true,

            pop: false
        }
    },
    mounted() {
        window.scrollTo(0, 0)
        this.$http.get('/property/me')
        .then(data => {
            let r = data.data

            this.all = r.properties
            this.properties = r.properties

            this.loading = false
        })
    },
    methods: {
        sort(e) {
            let properties = this.all

            if (e.location['country'] ) {
                let by_states = []
                for (let x of this.all) {
                    if (x.state == e.location.state) {
                        by_states.push(x)
                    }
                }

                let by_cities = []
                for (let x of by_states) {
                    if (x.city == e.location.city) {
                        by_cities.push(x)
                    }
                }

                properties = by_cities
                this.properties = by_cities
            }

            if (e.category != '') {
                let by_category = []
                for (let x of properties) {
                    if (x.category == e.category) {
                        by_category.push(x)
                    }
                }
                this.properties = by_category
            }
            
            this.pop = false
        }
    }
}
</script>

<style scoped>
    .properties {
        padding: 30px;
    }

    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .title {
        font-size: 20px;
        font-weight: 500;
    }
    .btn {
        display: flex;
        align-items: center;
        gap: 10px;
        background-color: var(--main);
        color: white;
        fill: white;
        border-radius: 5px;
        padding: 5px 20px;
        font-weight: 500;
        cursor: pointer;
    }

    .grid {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-gap: 30px;
    }

    .spin {
        margin: 70px auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }


    .empty {
        background-color: white;
        padding: 20px;
        /* font-size: 14px; */
        /* font-weight: 500; */
    }
</style>