<template>
    <div class="undecided">

        <property :property="property"/>

        <div class="content">
            <div class="text">
                Are you still interested in this property
            </div>
            <div class="btn">Yes</div>
        </div>
    </div>
</template>

<script>
import property from '../property.vue'

export default {

    components: {
        property,
    },
    props: ['property'],
    computed: {
        url() {
            return this.$root.$data.imgurl
        }
    },
    mounted() {

        this.price = JSON.parse(this.property.price)

        this.images = JSON.parse(this.property.images)
    },
    
    data() {
        return {
            options: {
                slidesPerView: 1,
                // loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                    hideOnClick: true
                },
            },
            price: {},
            images: {},
            loading: false,
        }
    }
}
</script>

<style scoped>
    .undecided {
        background-color: white;
        border-radius: 5px;
        overflow: hidden;
    }

    .content {
        padding: 15px;
        border-top: 1px solid #ddd;
    }

    .text {
        font-size: 14px;
        margin-bottom: 10px;
    }
    .btn {
        background-color: var(--trans);
        color: var(--main);
        cursor: pointer;
        padding: 8px 20px;
        border-radius: 5px;
        font-weight: 500;
        text-align: center;
    }
</style>