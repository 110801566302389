<template>
    <div class="upcoming">

        <property :property="property"/>
        <div class="content">
            <div class="title">Appointment Date</div>
            <div class="date">{{ property.appt_date }}</div>

            <div class="agree" v-if="!property.agent_ok">
                <loader v-if="agree" :height="'38px'"/>
                <div class="btn" v-else @click="appt_agree()">Agree</div>
            </div>

            <div v-if="property.status == 'ok'">

                <div class="btn" @click="show = true" v-if="!show">Reschedule</div>

                <div class="opt" v-else>
                    <div class="input">
                        <div class="label">Set new date and time</div>
                        <input type="datetime-local" :min="min" v-model="date">
                    </div>

                    <loader v-if="loading" :height="'38px'"/>
                    <div class="btn" v-else @click="save()">Save</div>
                </div>
            </div>

            <div class="text" v-if="property.status == 'waiting'">
                Our client has selected a partner, we will soon get in touch with you
            </div>
        </div>
    </div>
</template>

<script>
import property from '../property.vue'
import loader from '../loader.vue'

export default {

    components: {
        property,
        loader
    },
    props: ['property'],

    mounted() {
        this.min = new Date().toISOString().slice(0,new Date().toISOString().lastIndexOf(":"))
    },

    data() {
        return {
            min: '',
            date: '',

            agree: false,
            show: false,
            loading: false,
        }
    },

    methods: {
        appt_agree() {
            this.agree = true

            let form = new FormData()
            form.append('appt_id', this.property.appt_id)

            this.$http.post('/agent/appointment/agree', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.agreed()

                    this.property.agent_ok = true
                    this.agree = false
                }
            })
        },
        save() {
            if (this.date == '') {
                this.empty(); return
            }

            this.loading = true

            let form = new FormData()
            form.append('appt_id', this.property.appt_id)
            form.append('date', this.date)

            this.$http.post('/agent/appointments/reschedule', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.$router.go()
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: 'Pick Date',
            message: 'Choose a new date for the appointment'
        },
        agreed: {
            type: 'success',
            title: 'Success',
            message: 'Our client will now proceed with the appointment'
        }
    }
}
</script>

<style scoped>

    .upcoming {
        background-color: white;
        border-radius: 5px;
        margin-bottom: 20px;
        overflow: hidden;
    }

    .content {
        padding: 15px;
        border-top: 1px solid #ddd;
    }
    .title {
        /* font-weight: 500; */
        font-size: 14px;
        color: gray;
    }
    .date {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 20px;
    }


    .agree {
        margin-bottom: 20px;
    }

    
    .input {
        margin-bottom: 20px;
        position: relative;
    }
    .label {
        /* font-weight: 500; */
        font-size: 14px;
        color: gray;
    }
    input, select, textarea {
        padding: 10px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
    }


    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
    }

    .text {
        font-size: 14px;
        background-color: #edeef0;
        padding: 10px;
        border-radius: 5px;
    }
</style>