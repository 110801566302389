<template>
    <div class="settings">
        <div class="title">Partner Settings</div>
        <partner/>
    </div>
</template>

<script>
import partner from '../../components/settings/partner.vue';

export default {
    components: {
        partner
    }
}
</script>

<style scoped>

    .settings {
        padding: 20px;
    }
    .title {
        font-weight: 500;
        font-size: 20px;
    }

</style>