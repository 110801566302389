<template>
    <div class="rate">
        <div class="text">
            The agent has marked that this property has been checked
        </div>

        <div class="label">Rate Property:</div>
        <star @stared="prop_star = $event"/>
        <textarea placeholder="Enter Your Review" v-model="prop_text"></textarea>

        <div class="label">Rate Client:</div>
        <star @stared="client_star = $event"/>
        <textarea placeholder="Enter Your Review" v-model="client_text"></textarea>

        <div class="label">Rate Agent:</div>
        <star @stared="agent_star = $event"/>
        <textarea placeholder="Enter your review" v-model="agent_text"></textarea>

        <div> Was this appoinment succesful </div>
        <div class="flex">
            <div class="btn l trans" :class="[s == 'yes' ? 'a' : '']" @click="set('yes')">Yes</div>
            <div class="btn trans" :class="[s == 'undecided' ? 'a' : '']" @click="set('undecided')">Undecided</div>
        </div>


        <div class="tab" v-if="s == 'yes'">
            <div class="tab_item" :class="[tab == 'full' ? 'tab_a' : '']" @click="tab = 'full'">
                Full Payment
            </div>
            <div class="tab_item" :class="[tab == 'part' ? 'tab_a' : '']" @click="tab = 'part'">
                Part Payment
            </div>
        </div>
        <div class="form" v-if="s == 'yes'">
            <div v-if="tab == 'full'">
                <div class="input">
                    <div class="label">Bank Account Name</div>
                    <input placeholder="Enter sender bank account name"
                        type="text" v-model="acct_name">
                </div>
                <div class="input">
                    <div class="label">Amount</div>
                    <input placeholder="Enter the total amount paid"
                        type="number" v-model="amount"/>
                </div>
                <div class="input">
                    <div class="label">Commission</div>
                    <input placeholder="Enter the commission paid"
                        type="number" v-model="commission"/>
                </div>
            </div>

            <div v-if="tab == 'part'">
                <div class="input">
                    <div class="label">Bank Account Name</div>
                    <input placeholder="Enter sender bank account name"
                        type="text" v-model="acct_name">
                </div>
                <div class="input">
                    <div class="label">Amount to be paid</div>
                    <input placeholder="Enter the total amount to be paid" 
                        type="number" v-model="total_amount">
                </div>
                <div class="input">
                    <div class="label">Amount paid</div>
                    <input placeholder="Enter the amount paid" 
                        type="number" v-model="amount_paid">
                </div>
                <div class="input">
                    <div class="label">Balance</div>
                    <input type="text" disabled :value="total_amount - amount_paid">
                </div>
            </div>
        </div>

        <loader :height="'38px'" v-if="loading"/>
        <div class="btn" v-else @click="rate()">Submit</div>

        <!-- <div class="label">Rate Property</div>
        <star/>
        <textarea placeholder="Enter Your Review"></textarea> -->
    </div>
</template>

<script>
import star from './star.vue'
import loader from '../../loader.vue'

export default {
    components: {
        star, loader
    },
    props: ['appt_id', 'user'],
    data() {
        return {
            prop_star: 0,
            prop_text: '',

            client_star: 0,
            client_text: '',

            agent_star: 0,
            agent_text: '',

            s: '',
            acct_name: '',
            commission: '',
            amount: '',

            tab: 'full',

            total_amount: '',
            amount_paid: '',
            loading: false
        }
    },
    methods: {
        rate() {
            if ( this.prop_star == 0 || this.prop_text == '' || 
                this.client_star == 0 || this.client_text == '' || 
                this.agent_star == 0 || this.agent_text == '' ||
                this.s == '' ) {
                    this.emp();  return
                }
            
            if (this.s == 'yes') {
                if (this.tab == 'full') {
                    if (this.acct_name == '' || this.commission == '' || this.amount == '') {
                        this.emp(); return
                    }
                }
                if (this.tab == 'part') {
                    if (this.acct_name == '' || this.total_amount == '' || this.amount_paid) {
                        this.emp(); return
                    }
                }
            }

            this.loading = true
            let form = new FormData()

            form.append('appt_id', this.appt_id)
            form.append('user', this.user)

            form.append('prop_star', this.prop_star)
            form.append('prop_text', this.prop_text)

            form.append('client_star', this.client_star)
            form.append('client_text', this.client_text)

            form.append('agent_star', this.agent_star)
            form.append('agent_text', this.agent_text)

            form.append('success', this.s)
            form.append('payment_type', this.tab)
            
            form.append('acct_name', this.acct_name)

            form.append('commission', this.commission)
            form.append('amount', this.amount)

            form.append('total_amount', this.total_amount)
            form.append('amount_paid', this.amount_paid)

            this.$http.post('/partner/submit_rating', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.loading = false
                    this.suc() 
                    setTimeout(() => {
                        this.$router.go()
                    }, 1000);
                }
            })
        },
        set(w) {
            this.s = w
        }
    },
    notifications: {
        emp: {
            type: 'warn',
            title: 'Check',
            message: 'Please make sure all field are filled including the star rating'
        },
        suc: {
            type: 'success',
            title: 'Success',
            message: 'Your ratings and reviews has been submitted'
        }
    }
}
</script>

<style scoped>
    .rate {
        /* margin-top: 15px; */
    }

    .text {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .label {
        /* margin-top: 10px; */
        font-weight: 500;
    }

    textarea, input {
        margin-bottom: 15px;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

    .flex {
        display: flex;
        margin-bottom: 20px;
        margin-top: 5px;
    }
    .l {
        margin-right: 20px;
    }
    
    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
    }
    .trans {
        background-color: var(--trans);
        color: var(--main);
    }
    .a {
        background-color: var(--main);
        color: white;
    }


    

    .tab {
        display: flex;
        gap: 15px;
        margin-left: 15px;
        position: relative;
    }
    .tab_item {
        padding: 8px 15px;
        padding-bottom: 9px;
        font-size: 14px;
        cursor: pointer;
    }
    .tab_a {
        border-radius: 5px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border: 1px solid #ccc;
        border-bottom: none;
        z-index: 2;
        background-color: white;
        font-weight: 500;
        color: var(--main);
    }
    .form {
        padding: 15px;
        padding-bottom: 0px;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-bottom: 20px;
        margin-top: -1px;
    }
</style>