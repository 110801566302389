<template>
    <div class="property">
        <div class="box-con" v-if="!show">
            <div class="box">
                <div class="box_title">View a property</div>
                <input type="text" placeholder="Enter property id" v-model="id">

                <loader :height="'37px'" v-if="loading"/>
                <div class="btn" v-else @click="search()">Search</div>
            </div>
        </div>
        <div class="details" v-else>
            <div class="info">
            <div class="title">Property Details ({{property.title}}) </div>
                <div class="flex">
                    <div class="t">Property ID</div>
                    <div class="value">{{ id }}</div>
                </div>
                <div class="flex">
                    <div class="t">Type</div>
                    <div class="value">{{property.type}}</div>
                </div>
                <div class="flex">
                    <div class="t">Kind</div>
                    <div class="value">{{property.kind}}</div>
                </div>
                <div class="flex">
                    <div class="t">Category</div>
                    <div class="value">{{property.category}}</div>
                </div>
                <div class="flex">
                    <div class="t">Address</div>
                    <div class="value">{{property.address}}</div>
                </div>
                <div class="flex">
                    <div class="t">City</div>
                    <div class="value">{{property.city}}</div>
                </div>
                <div class="flex">
                    <div class="t">Country</div>
                    <div class="value">{{property.country}}</div>
                </div>
                <div class="flex">
                    <div class="t">Agent</div>
                    <div class="value">{{property.user}}</div>
                </div>
                <div class="flex">
                    <div class="t">Posted</div>
                    <div class="value">{{property.date}}</div>
                </div>
                <div class="flex">
                    <div class="t">Updated</div>
                    <div class="value">{{property.updated}}</div>
                </div>
                <div class="flex">
                    <div class="t">Post As</div>
                    <div class="value">{{property.post_as}}</div>
                </div>
                <div class="flex">
                    <div class="t">Mandate No</div>
                    <div class="value">{{property.mandate_no}}</div>
                </div>
                <div class="appt">
                    <div class="t">Appointments</div>
                    <div class="ids" v-for="(appt, i) in appts" :key="i"> {{ appt.appt_id }}</div>
                </div>
                <div class="btn" @click="show = false">close</div>
            </div>
        </div>
    </div>
</template>

<script>
import loader from '../components/loader.vue'
export default {
    components: {
        loader
    },
    data() {
        return {
            id: '',
            loading: false,
            show: false,
            property: {},
            appts: []
        }
    },
    methods: {
        search() {
            if (this.id == '') { this.empty(); return }

            this.loading = true

            let form = new FormData()
            form.append('id', this.id)

            this.$http.post('/admin/property/info', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    if (r.property.length == 0) { 
                        this.not_found() 
                        this.loading = false
                        return
                    }

                    this.property = r.property[0]
                    this.appts = r.appointments
                    this.show = true
                }
                this.loading = false
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: 'ID',
            message: 'Enter the property id'
        },
        not_found: {
            type: 'warn',
            title: 'Not Found',
            message: 'Property not found'
        }
    }
}
</script>

<style scoped>
    .property {
        /* padding: 20px; */
    }

    .box-con {
        width: 100%;
        height: calc(100vh - 79px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .box {
        background-color: white;
        padding: 20px;
        width: 350px;
        border-radius: 5px;
    }
    .box_title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
    }
    input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 20px;
    }
    .btn {
        padding: 8px;
        text-align: center;
        color: white;
        background-color: var(--main);
        font-weight: 500;
        cursor: pointer;
        border-radius: 5px;
    }

    .details {
        padding: 30px;
    }
    .title {
        font-size: 20px;
        font-weight: 500;
    }

    .info {
        /* display: grid; */
        /* grid-template-columns: calc(50% - 15px) calc(50% - 15px); */
        /* gap: 30px; */
        background-color: white;
        border-radius: 5px;
        padding: 20px;
        width: 350px;
    }
    .info .btn {
        margin-top: 20px;
    }
    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #edeef0;
        padding: 20px 20px;
        border-radius: 5px;
        flex-wrap: wrap;
        margin-top: 20px;
    }
    .value {
        font-weight: 500;
    }


    .appt {
        background-color: #edeef0;
        padding: 20px;
        margin-top: 20px;
        border-radius: 5px;
    }
    .ids {
        margin-top: 10px;
        font-weight: 500;
        font-size: 14px;
    }
</style>