<template>
    <div class="successful">
        <property :property="property"/>
        
        
        <div class="content">

            <div class="title">Appointment Summary</div>
            <div class="f">
                <div class="label">Appointment Id</div>
                <div class="value">{{property.appt_id}}</div>
            </div>
            <div class="f">
                <div class="label">Property Id</div>
                <div class="value">{{property.prop_id}}</div>
            </div>
            <div class="f">
                <div class="label">Partner Name</div>
                <div class="value">{{ property.name }}</div>
            </div>
            <div class="f">
                <div class="label">Partner Phone</div>
                <div class="value">{{ property.partner_phone }}</div>
            </div>


            <div>
                <div class="t">Your Payment</div>
                <div class="f">
                    <div class="label">Payment Type</div>
                    <div class="value">{{user_summary.payment_type}}</div>
                </div>
                <div v-if="user_summary.payment_type == 'full'">
                    <div class="f">
                        <div class="label">Account Name</div>
                        <div class="value">{{ user_summary.full_payment.acct_name }}</div>
                    </div>
                    <div class="f">
                        <div class="label">Amount Paid</div>
                        <div class="value">{{user_summary.full_payment.amount}}</div>
                    </div>
                    <div class="f">
                        <div class="label">Commission Paid</div>
                        <div class="value">{{user_summary.full_payment.commission}}</div>
                    </div>
                </div>
                <div v-if="user_summary.payment_type == 'part'">
                    <div class="f">
                        <div class="label">Account Name</div>
                        <div class="value">{{ user_summary.part_payment[0].acct_name }}</div>
                    </div>
                    <div class="f">
                        <div class="label">Total Amount</div>
                        <div class="value">{{ user_summary.part_payment[0].total_amount }}</div>
                    </div>
                    <div class="f">
                        <div class="label">Amount Paid</div>
                        <div class="value">{{ user_summary.part_payment[0].amount_paid }}</div>
                    </div>

                    <div v-if="user_summary.part_payment.length == 2">
                        <div class="f">
                            <div class="value">Your Second Payment</div>
                        </div>
                        <div class="f">
                            <div class="label">Account Name</div>
                            <div class="value">{{ user_summary.part_payment[1].acct_name }}</div>
                        </div>
                        <div class="f">
                            <div class="label">Balance Paid</div>
                            <div class="value">{{ user_summary.part_payment[1].balance }}</div>
                        </div>
                        <div class="f">
                            <div class="label">Commission Paid</div>
                            <div class="value">{{ user_summary.part_payment[1].commission }}</div>
                        </div>
                    </div>

                    <div v-if="user_summary.part_payment.length == 1">

                        <div class="btn up" @click="show_pay = true" v-if="!show_pay">
                            Pay Balance ({{user_summary.part_payment[0].total_amount - 
                                user_summary.part_payment[0].amount_paid}})
                        </div>
                        
                        <div v-if="show_pay">
                            <div class="payment">
                                <div class="title">Payment Details</div>
                                <div class="pay_text">
                                    Payment for this property should be made into Bigglandlord account 
                                </div>
                                <div class="f">
                                    <div class="label">Bank:</div>
                                    <div class="value">UBA</div>
                                </div>
                                <div class="f">
                                    <div class="label">Account Number:</div>
                                    <div class="value">1020153237</div>
                                </div>
                                <div class="f">
                                    <div class="label">Account Name:</div>
                                    <div class="value">Foresure Authentication Services Limited</div>
                                </div>
                                <div class="note">
                                    <b>Note:</b> Do not pay to any agent or partner
                                </div>
                            </div>
                            <div class="form">
                                <div class="input">
                                    <div class="label">Bank Account Name</div>
                                    <input placeholder="Enter sender bank account name"
                                        type="text" v-model="acct_name">
                                </div>
                                <div class="input">
                                    <div class="label">Balance to be paid</div>
                                    <input type="number" disabled 
                                        :value="user_summary.part_payment[0].total_amount - 
                                                user_summary.part_payment[0].amount_paid">
                                </div>
                                <div class="input">
                                    <div class="label">Commission paid</div>
                                    <input placeholder="Enter the commission you paid" 
                                        type="number" v-model="commission">
                                </div>
                                <loader :height="'37px'" v-if="paying"/>
                                <div class="btn bold_btn" v-else @click="pay_balance()">Submit</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div>
                <div class="t">Our Partner Summary</div>
                <div class="f">
                    <div class="label">Success</div>
                    <div class="value">{{partner_summary.success}}</div>
                </div>
                <div class="f">
                    <div class="label">Payment Type</div>
                    <div class="value">{{partner_summary.payment_type}}</div>
                </div>
                <div v-if="partner_summary.payment_type == 'full'">
                    <div class="f">
                        <div class="label">Account Name</div>
                        <div class="value">{{ partner_summary.full_payment.acct_name }}</div>
                    </div>
                    <div class="f">
                        <div class="label">Amount Paid</div>
                        <div class="value">{{partner_summary.full_payment.amount}}</div>
                    </div>
                    <div class="f">
                        <div class="label">Commission Paid</div>
                        <div class="value">{{partner_summary.full_payment.commission}}</div>
                    </div>
                </div>
                
                <div v-if="partner_summary.payment_type == 'part'">
                    <div class="f">
                        <div class="label">Account Name</div>
                        <div class="value">{{ partner_summary.part_payment[0].acct_name }}</div>
                    </div>
                    <div class="f">
                        <div class="label">Total Amount</div>
                        <div class="value">{{ partner_summary.part_payment[0].total_amount }}</div>
                    </div>
                    <div class="f">
                        <div class="label">Amount Paid</div>
                        <div class="value">{{ partner_summary.part_payment[0].amount_paid }}</div>
                    </div>
                </div>
            </div>

            <div class="timeline">
                <div class="each" :class="[property.payment_confirmed ? 'a' : '']">
                    <checked :size="'15px'" :style="{'height': '15px'}" v-if="property.payment_confirmed"/>
                    <loading :size="'15px'" class="spin" :style="{'height': '15px'}" v-else/>
                    <div>Payment recieved and confirmed</div>
                </div>

                <div class="btn" @click="is_submit = true" 
                    v-if="property.payment_confirmed && !property.details_submitted">
                    Submit Details
                </div>

                <div class="btn" @click="is_submit = true" v-if="property.details_rejected">
                    Resubmit Details
                </div>

                <div class="each" :class="[property.details_confirmed ? 'a' : '']">
                    <checked :size="'15px'" :style="{'height': '15px'}" v-if="property.details_confirmed"/>
                    <loading :size="'15px'" class="spin" :style="{'height': '15px'}" v-else/>
                    <div>Your details submitted and confirmed</div>
                </div>

                <div class="each" :class="[property.payment_forwarded ? 'a' : '']">
                    <checked :size="'15px'" :style="{'height': '15px'}" v-if="property.payment_forwarded"/>
                    <loading :size="'15px'" class="spin" :style="{'height': '15px'}" v-else/>
                    <div>Your payment and details forwarded to the agent</div>
                </div>
            </div>

            <div class="btn issues" v-if="issues.length > 0" @click="is_issues = true">
                Appointment issues ({{issues.length}})
            </div>

            <div class="message">
                <div class="btn" @click="show = true">Message Bigglandlord</div>

                <div v-if="show">
                    <textarea placeholder="Enter you message" v-model="text"></textarea>

                    <div class="sent" v-if="sent">
                        Your message has been sent, Bigglandlord will respond to you via email or whatsapp
                    </div>

                    <loader :height="'37px'" v-if="loading"/>
                    <div class="btn click" v-else @click="message()">Send</div>
                </div>

            </div>
        </div>

        <submit_details v-if="is_submit" @close="is_submit = false" @success="submitted()" 
            :appt_id="property.appt_id"/>
            
        <issues v-if="is_issues" :issues="issues" @close="is_issues=false"/>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

import submit_details from './sumbit_details.vue'
import issues from './issues.vue'
import loader from '../loader.vue'

import location from '../../icons/location.vue'
import loading from '../../icons/loading.vue'
import checked from '../../icons/check.vue'

import property from '../property.vue'
export default {
    components: {
        property,
        Swiper, SwiperSlide,
        submit_details, issues, loader,
        location, loading, checked
    },
    props: ['property'],
    computed: {
        url() {
            return this.$root.$data.imgurl
        }
    },
    mounted() {

        this.price = JSON.parse(this.property.price)
        this.images = JSON.parse(this.property.images)

        this.user_summary = JSON.parse(this.property.user_summary)

        console.log(this.user_summary);

        if ( this.property.partner_summary != '' ) {
            this.partner_summary = JSON.parse(this.property.partner_summary)
        }

        this.issues = this.property.user_issues == '' ? [] : JSON.parse(this.property.user_issues)
    },
    data() {
        return {
            options: {
                slidesPerView: 1,
                // loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                    hideOnClick: true
                },
            },
            price: {},
            images: {},
            user_summary: {},
            partner_summary: {},

            show_pay: false,
            acct_name: '',
            commission: '',
            paying: false,

            show: false,
            sent: false,
            loading: false,
            text: '',

            is_submit: false,
            is_issues: false,
            issues: []
        }
    },
    methods: {
        pay_balance() {
            if (this.acct_name == '' || this.commission == '') {
                this.emp(); return
            }

            this.paying = true

            let form = new FormData()
            form.append('appt_id', this.property.appt_id)
            form.append('balance', this.user_summary.part_payment[0].total_amount - 
                                    this.user_summary.part_payment[0].amount_paid)
            form.append('acct_name', this.acct_name)
            form.append('commission', this.commission)

            this.$http.post('/user/pay_balance', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.$router.go()
                }
            })
        },
        message() {
            if (this.text == '') { this.empty(); return }

            this.loading = true
            this.sent = false

            let form = new FormData()
            form.append('text', this.text)
            form.append('appt_id', this.property.appt_id)
            form.append('from', 'user')

            this.$http.post('/user/message', form)
            .then( data => {
                let r = data.data
                if (r.success) {
                    this.loading = false
                    this.sent = true
                    this.text = ''
                    this.sent_noti()
                }
            })
        },
        submitted() {
            this.property.details_submitted = true
            this.property.details_rejected = false
            this.is_submit = false
        }
    },
    notifications: {
        emp: {
            type: 'warn',
            title: 'Check',
            message: 'Please make sure all field are filled'
        },
        empty: {
            type: 'warn',
            title: 'Empty',
            message: 'Please type a message'
        },
        sent_noti: {
            type: 'success',
            title: 'Sent',
            message: 'Your message has been sent, We will get in touch with you soon'
        }
    }
}
</script>

<style scoped>
    .successful {
        background-color: white;
        border-radius: 5px;
        overflow: hidden;
    }
    .swiper {
        position: relative;
    }
    .price {
        position: absolute;
        z-index: 2;
        bottom: 0px;
        left: 0px;
        padding: 10px 15px;
        color: white;
        background-color: rgba(0, 0, 0, 0.4);
        width: 100%;
    }
    .tag {
        position: absolute;
        z-index: 2;
        top: 10px;
        right: 10px;
        background-color: black;
        color: white;
        padding: 5px 10px;
        /* border-radius: 5px; */
        font-weight: 500;
        font-size: 12px;
    }
    .image {
        height: 200px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    img {
        height: 250px;
        pointer-events: none;
    }

    .content {
        padding: 15px;
        border-top: 1px solid #ddd;
    }


    .btn {
        background-color: var(--trans);
        color: var(--main);
        cursor: pointer;
        padding: 8px 20px;
        border-radius: 5px;
        font-weight: 500;
        text-align: center;
    }

    .title {
        text-align: center;
        margin-bottom: 10px;
        font-weight: 500;
    }
    .t {
        font-size: 14px;
        font-weight: 500;
        background-color: var(--main);
        padding: 10px 15px;
        color: white;
        font-weight: 500;
        margin: 0px -15px;
        margin-top: 20px;
    }
    .f {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;
    }
    .label {
        font-size: 12px;
    }
    .value {
        font-weight: 500;
        text-align: right;
        font-size: 14px;
    }


    .timeline {
        /* display: flex; */
    }
    .each {
        /* text-align: center; */
        font-size: 12px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 15px;
        background-color: #edeef0;
        margin-top: 15px;
        padding: 15px;
        border-radius: 5px;
    }
    .timeline .btn {
        margin-top: 15px;
    }
    .a {
        fill: var(--main);
        background-color: var(--trans);
        color: var(--main);
    }
    .spin {
        width: fit-content;
        /* margin: 0 auto; */
        animation: spin 2s linear infinite;
        fill: var(--main);
    }
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .issues {
        margin-top: 15px;
        background-color: var(--main);
        color: white;
    }

    .message {
        margin-top: 15px;
    }
    textarea {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-top: 15px;
        margin-bottom: 15px;
        box-sizing: border-box;
        width: 100%;
        height: 150px;
    }
    .click {
        background-color: var(--main);
        color: white;
    }
    .sent {
        background-color: #edeef0;
        font-weight: 500;
        font-size: 14px;
        padding: 15px;
        margin: 15px -15px;
        margin-top: 0px;
    }

    .up {
        margin-top: 20px;
    }

    
    .form {
        margin-top: 15px;
    }
    .input .label {
        font-weight: 500;
        font-size: 14px;
    }
    input {
        margin-bottom: 20px;
        width: 100%;
        padding: 12px;
        box-sizing: border-box;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
    .bold_btn {
        background-color: var(--main);
        color: white;
    }
    .note {
        font-size: 14px;
        margin-top: 15px;
    }
</style>