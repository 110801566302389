<template>
    <div class="price">

        <div class="pop">
            <div class="close" @click="$emit('close')">
                <close :size="'30px'"/>
            </div>
            <div class="title">
                Set Price Range
            </div>
            <div class="input">
                <select v-model="min">
                    <option value="" disabled selected>Minimum Price</option>
                    <option value="0">No Min</option>
                    <option v-for="c in prices" :value="c.value" :key="c.d">{{c.d}}</option>
                </select>
            </div>
            <div class="input">
                <select v-model="max">
                    <option value="" disabled selected>Maximum Price</option>
                    <option value="1000000000000">No Max</option>
                    <option v-for="c in prices2" :value="c.value" :key="c.d">{{c.d}}</option>
                </select>
            </div>
            <div class="btn" @click="save()">Save</div>
        </div>
    </div>
</template>

<script>
import close from '../icons/remove.vue'

export default {
    components: {
        close
    },
    data() {
        return {
            prices: [
                {'value': 100000, 'd': '₦100,000'},
                {'value': 200000, 'd': '₦200,000'},
                {'value': 300000, 'd': '₦300,000'},
                {'value': 500000, 'd': '₦500,000'},
                {'value': 1000000, 'd': '₦1,000,000'},
                {'value': 2000000, 'd': '₦2,000,000'},
                {'value': 3000000, 'd': '₦3,000,000'},
                {'value': 5000000, 'd': '₦5,000,000'},
                {'value': 10000000, 'd': '₦10,000,000'},
                {'value': 20000000, 'd': '₦20,000,000'},
                {'value': 30000000, 'd': '₦30,000,000'},
                {'value': 50000000, 'd': '₦50,000,000'},
                {'value': 100000000, 'd': '₦100,000,000'},
                {'value': 200000000, 'd': '₦200,000,000'},
                {'value': 500000000, 'd': '₦500,000,000'},
                {'value': 1000000000, 'd': '₦1,000,000,000'},
                {'value': 2000000000, 'd': '₦2,000,000,000'},
                {'value': 5000000000, 'd': '₦5,000,000,000'},
                {'value': 10000000000, 'd': '₦10,000,000,000'},
                {'value': 20000000000, 'd': '₦20,000,000,000'},
                {'value': 50000000000, 'd': '₦50,000,000,000'},
                {'value': 100000000000, 'd': '₦100,000,000,000'},
            ],
            prices2: [],
            min: '',
            max: ''
        }
    },
    watch: {
        min(val) {
            this.max = ''
            this.prices2 = []
            let x
            for (x of this.prices) {
                if (x.value > val) {
                    this.prices2.push(x)
                }
            }
        }
    },
    methods: {
        save() {
            this.$emit('save', {
                'min': this.min,
                'max': this.max
            })
        }
    }
}
</script>

<style scoped>

    .pop {
        background-color: white;
        position: relative;
        z-index: 4;
        width: 300px;
        padding: 20px;
        border-radius: 10px;
        animation: pop .3s;
        
    }
    @keyframes pop {
        from {
            transform: scale(.7);
        }
        to {
            transform: scale(1);
        }
    }
    .close {
        position: absolute;
        right: -10px;
        top: -10px;
        fill: var(--main);
        cursor: pointer;
    }

    .title {
        font-weight: 500;
    }
    select {
        margin-top: 20px;
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        background-color: white;
        box-sizing: border-box;
        border-radius: 5px;
    }
    .btn {
        margin-top: 20px;
        background-color: var(--main);
        padding: 8px 30px;
        border-radius: 5px;
        margin-left: auto;
        width: fit-content;
        color: white;
        font-weight: 500;
        cursor: pointer;
    }
</style>