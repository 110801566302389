<template>
    <div class="location">
        <div class="head">
            <div class="title">Locations</div>
            <div class="btn" @click="pop = true">Add New Country</div>
        </div>
        <location v-for="country in countries" :key="country.name" :country="country"/>

        <div class="popup" v-if="pop">
            <div class="dark"></div>
            <country @close="pop = false" @new="add($event)"/>
        </div>
    </div>
</template>

<script>
import location from '../components/admin/location.vue'
import country from '../components/admin/location/country.vue'
export default {
    components: {
        location, country
    },
    data() {
        return {
            pop: false,
            countries: []
        }
    },
    mounted() {
        this.$http.post('/location/country/get')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.countries = r.countries
            }
        })
    },
    methods: {
        add(event) {
            this.countries.unshift({
                'name': event,
                'cities': 0,
                'states': 0,
                'properties': 0,
                'property_type': '[]'
            })
            this.pop = false
        }
    }
}
</script>

<style scoped>
    .location {
        padding: 20px;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .title {
        font-size: 20px;
        font-weight: 500;
    }
    .btn {
        background-color: var(--main);
        color: white;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
    }
    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        box-sizing: border-box;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 2;
    }
</style>