<template>
    <div class="d">
        <div class="head">Agent Details</div>
        <div class="info">
            <div class="details">
                <div class="flex">
                    <div class="label">Official Name:</div>
                    <div class="value">{{info.name}}</div>
                </div>
                <div class="flex">
                    <div class="label">Business Name:</div>
                    <div class="value">{{ info.business_name }}</div>
                </div>
                <div class="flex">
                    <div class="label">Office Address:</div>
                    <div class="value">{{info.address}}</div>
                </div>
                <div class="flex">
                    <div class="label">Country:</div>
                    <div class="value">{{info.country}}</div>
                </div>
                <div class="flex">
                    <div class="label">State:</div>
                    <div class="value">{{info.state}}</div>
                </div>
                <div class="flex">
                    <div class="label">City:</div>
                    <div class="value">{{info.city}}</div>
                </div>
                <div class="flex">
                    <div class="label">Properties:</div>
                    <div class="value">{{info.properties}}</div>
                </div>
                <div class="flex">
                    <div class="label">Phone Number:</div>
                    <div class="value">{{info.phone}}</div>
                </div>
                <div class="flex">
                    <div class="label">Alternate Number:</div>
                    <div class="value">{{info.alter_no}}</div>
                </div>
                <div class="flex">
                    <div class="label">Whatsapp Number:</div>
                    <div class="value">{{info.whatsapp}}</div>
                </div>
                <div class="flex">
                    <div class="label">Facebook profile link:</div>
                    <div class="value">{{info.facebook}}</div>
                </div>
                <div class="flex">
                    <div class="label">Facebook business page:</div>
                    <div class="value">{{ info.business_facebook }}</div>
                </div>
                <div class="flex">
                    <div class="label">Guarantor's Name:</div>
                    <div class="value">{{info.guarantor_name}}</div>
                </div>
                <div class="flex">
                    <div class="label">Guarantor's Phone :</div>
                    <div class="value">{{info.guarantor_phone}}</div>
                </div>
                <div class="flex">
                    <div class="label">Guarantor Address:</div>
                    <div class="value">{{info.guarantor_address}}</div>
                </div>
                <div class="flex">
                    <div class="label">Next of kin name:</div>
                    <div class="value">{{info.next_of_kin_name}}</div>
                </div>
                <div class="flex">
                    <div class="label">Next of kin Address:</div>
                    <div class="value">{{info.next_of_kin_address}}</div>
                </div>
                <div class="flex">
                    <div class="label">Next of kin phone:</div>
                    <div class="value">{{info.next_of_kin_phone}}</div>
                </div>
                <div class="flex">
                    <div class="label">Completed Appointments</div>
                    <div class="value">{{ info.completed_appointments }}</div>
                </div>
                <div class="flex">
                    <div class="label">Successful Appointments</div>
                    <div class="value">{{ info.successful_appointments }}</div>
                </div>
                <div class="flex">
                    <div class="label"> Rating </div>
                    <div class="value">{{ info.rating / info.rating_users }}</div>
                    <div class="click">
                        Reviews
                    </div>
                </div>
                <div class="flex">
                    <div class="label">Employee allowed</div>
                    <div class="value">{{ info.allow_employee == '1' ? 'Yes' : 'No' }}</div>
                    <div class="click" @click="allow_employee()">{{ a_text }}</div>
                </div>
            </div>
            <div class="images">
                <div class="title">Attachments</div>
                <div class="t">ID card</div>
                <div class="image">
                    <img :src="imgurl + info.id_card" class="img"/>
                </div>

                
                <div class="t">Proof of address</div>
                <div class="image">
                    <img :src="imgurl + info.utility" class="img"/>
                </div>


                <div class="t">Selfie</div>
                <div class="image">
                    <img :src="imgurl + info.image" class="img"/>
                </div>

                <div v-if="info.status == 'processing'">
                    <loader :height="'35px'" v-if="v_loading"/>
                    <div class="btn" @click="verify()" v-else>Verify Agent</div>

                    <div v-if="info.status == 'processing'">
                        <div class="text">
                            Your application to become an agent on Bigglandlord was rejected due to
                        </div>
                        <textarea placeholder="Enter reasons" v-model="note"></textarea>
                        <loader :height="'35px'" v-if="r_loading"/>
                        <div class="btn" @click="reject()" v-else>Reject Application</div>
                    </div>

                </div>
                
                <div class="text" v-if="info.status == 'rejected'">
                    This agent application was rejected
                </div>

                <div v-if="info.status == 'ok'">
                    <loader :height="'34px'" v-if="b_loading"/>
                    <div class="btn" v-else @click="block()">Block Agent</div>
                </div>

                <div v-if="info.status == 'block'">
                    <loader :height="'34px'" v-if="u_loading"/>
                    <div class="btn" v-else @click="unblock()">Unblock Agent</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import loader from '../../loader.vue'

export default {
    components: {
        loader
    },
    props: ['info', 'index'],
    data() {
        return {
            
            note: '',
            r_loading: false,
            v_loading: false,

            a_text: '',

            b_loading: false,
            u_loading: false
        }
    },
    mounted() {
        if (this.info.allow_employee == '1') {
            this.a_text = 'Prevent'
        } else {
            this.a_text = 'Allow'
        }
    },
    computed: {
        imgurl() {
            return this.$root.$data.docurl
        }
    },
    methods: {
        reject() {
            this.r_loading = true

            let form = new FormData()
            form.append('user_id', this.info.user_id)
            form.append('note', this.note)

            this.$http.post('/agents/reject', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.r_loading = false
                    this.rejected()
                    this.info.status == 'rejected'

                    this.$emit('move', {
                        index: this.index,
                        to: 'rejected'
                    })
                }
            })
        },
        verify() {
            this.v_loading = true

            let form = new FormData()
            form.append('user_id', this.info.user_id)

            this.$http.post('/agents/verify', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.v_loading = false
                    this.verified()
                    this.info.status == 'ok'
                    
                    this.$emit('move', {
                        index: this.index,
                        to: 'verified'
                    })
                }
            })
        },
        block() {
            this.b_loading = true

            let form = new FormData()
            form.append('user_id', this.info.user_id)

            this.$http.post('/admin/agent/block', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.b_loading = false
                    this.blocked()
                    this.info.status = 'block'
                    
                    this.$emit('move', {
                        index: this.index,
                        to: 'blocked'
                    })
                }
            })
        },
        unblock() {
            this.u_loading = true

            let form = new FormData()
            form.append('user_id', this.info.user_id)

            this.$http.post('/admin/agent/unblock', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.u_loading = false
                    this.unblocked()
                    this.info.status = 'ok'

                    this.$emit('move', {
                        index: this.index,
                        to: 'verified'
                    })
                }
            })
        },
        allow_employee() {
            let s = ''
            let new_text = ''
            if (this.info.allow_employee == '1') {
                this.a_text = 'Preventing'
                new_text = 'Allow'
                s = '0'
            } else {
                this.a_text = 'Allowing'
                new_text = 'Prevent'
                s = '1'
            }

            let form = new FormData()
            form.append('agent', this.info.user_id)
            form.append('state', s)

            this.$http.post('/admin/agent/employee_allowed', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.info.allow_employee = s
                    this.a_text = new_text
                }
            })
        }
    },
    notifications: {
        rejected: {
            type: 'success',
            title: 'Rejected',
            message: 'This application has been rejected'
        },
        verified: {
            type: 'success',
            title: 'Verified',
            message: 'This agent has been verified'
        },
        blocked: {
            type: 'success',
            title: 'Blocked',
            message: 'This agent has been blocked'
        },
        unblocked: {
            type: 'success',
            title: 'Unblocked',
            message: 'This agent has been unblocked'
        }
    }
}
</script>

<style scoped>

    .d {
        padding: 20px;
    }
    .head {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
    }
    .info {
        /* padding: 20px; */
        display: grid;
        grid-template-columns: 50% calc(50% - 20px);
        grid-gap: 20px;
    }
    .details {
        /* max-width: 400px; */
        position: relative;
    }
    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid #ddd;
    }
    .flex:nth-child(even) {
        /* background-color: var(--trans); */
        /* border-radius: 5px; */
    }
    .label {
        margin-right: 10px;
        font-size: 14px;
    }
    .value {
        font-weight: 500;
    }
    .click {
        color: var(--main);
        font-weight: 500;
        cursor: pointer;
    }

    .title {
        font-weight: 500;
        font-size: 18px;
        text-align: center;
    }
    .t {
        font-weight: 500;
    }
    .image {
        max-height: 100px;
        overflow: hidden;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .img {
        max-height: 100px;
        /* max-width: 100%; */
    }
    
    .btn {
        background-color: var(--main);
        color: white;
        padding: 8px 20px;
        border-radius: 5px;
        font-weight: 500;
        cursor: pointer;
        font-size: 14px;
        text-align: center;
    }
    .text {
        margin-top: 10px;
        font-size: 16px;
    }
    textarea {
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #ddd;
        border-radius: 5px;
        margin-bottom: 10px;
    }
</style>