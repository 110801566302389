<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">
                Send message to groups
            </div>
            <div class="main">

                <div class="image" v-if="is_image">
                    <img :src="image"/>
                </div>
                <div class="btn" v-else @click="upload()">Add Image</div>

                <textarea placeholder="Enter your message" v-model="text"></textarea>
                <div class="btn" @click="send()">Send</div>
            </div>
        </div>
        
        <input type="file" accept="image/*" class="hidden" 
                ref="image" @change="handleImageUpload($event)"/>

    </div>
</template>

<script>

import imageCompression from 'browser-image-compression';

export default {
    props: ['city'],
    data() {
        return {
            link: '',
            text: '',

            image: '',
            img_file: null,
            is_image: '',

            loading: false
        }
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },

        
        upload() {
            this.$refs.image.click()
        },
        
        handleImageUpload(event) {
            for (let i = 0; i < event.target.files.length; i++) {
                let mediaType = event.target.files[i].type
                if (mediaType.includes('image')) {
                    const picture = event.target.files[i]
                    
                    const options = {
                        maxSizeMB: 0.03,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true
                    }
                    imageCompression(picture, options).then( res => {
                        this.img_file = new File([res], picture.name)
                        this.is_image = '1'
                        
                        const reader = new FileReader()
                        reader.readAsDataURL(picture)
                        reader.onload = event => {
                            this.image = event.target.result

                            this.$refs['image'].value = ''
                        }
                    })

                }
            }
        },
        

        send() {
            if (this.text == '') { this.empty(); return }

            let form = new FormData()
            form.append('message', this.text)

            if (this.is_image == '1') {
                form.append('is_image', '1')
                form.append('image', this.img_file)
            }

            this.$http.post('/admin/groups/send', form)
            this.success()

            this.close()
        }
    },
    notifications: {
        empty: {
            'type': 'warn',
            'title': 'Empty message',
            'message': ''
        },
        success: {
            'type': 'success',
            'title': 'Sent',
            'message': 'Message sent, check whatsapp for delivery'
        }
    }
}
</script>

<style scoped>

    .popup {
        z-index: 10;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
    }

    .box {
        background-color: white;
        width: 500px;
        position: relative;
        z-index: 20;
        border-radius: 5px;
    }
    .title {
        padding: 15px 20px;
        font-weight: 500;
    }
    .main {
        padding: 20px;
        border-top: 1px solid #ddd;

        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .image img {
        width: 50%;
    }
    input, textarea {
        padding: 15px;
        border-radius: 5px;
        border: 1px solid #ddd;
        width: 100%;
        box-sizing: border-box;
        /* margin-bottom: 20px; */
    }
    textarea {
        height: 100px;

    }
    .btn {
        padding: 8px;
        border-radius: 5px;
        cursor: pointer;
        background-color: var(--main);
        color: white;
        text-align: center;
        font-weight: 500;
    }

    
    
    input[type=file] {
        visibility: hidden;
        display: none;
    }
</style>