<template>
    <div class="partner">
        <div class="image">
            <img :src="imgurl + '/avatar.svg'"/>
        </div>
        <div class="name">{{partner.firstname}} {{partner.lastname}}</div>

        <div class="info">
            <div class="flex">
                <stared :size="'15px'"/>
                <div class="no">{{star}}</div>
            </div>
            <div class="flex">
                <div>Score</div>
                <div class="no">{{partner.score}}/10</div>
            </div>
        </div>

        <!-- <loader v-if="loading" :height="'31px'"/> -->
        <div class="btn" @click="show = true">View</div>

        <info v-if="show" :partner="partner" :appt_id="appt_id" :location="location" @close="show = false"/>
    </div>
</template>

<script>
import star from '../../icons/star.vue'
import stared from '../../icons/stared.vue'
import info from './info.vue'

export default {
    components: {
        star, stared, info
    },
    props: ['partner', 'appt_id', 'location'],
    computed: {
        imgurl() {
            return this.$root.$data.dpurl
        }
    },
    mounted() {
        let s
        if (parseInt(this.partner.rating) > 0) {
            s = parseInt(this.partner.rating) / parseInt(this.partner.rating_users)
        } else {
            s = 0
        }
        
        console.log(s);
        // this.star = Math.round(s * 10) / 10
        this.star = s == NaN ? 0 : s.toFixed(1)
    },
    data() {
        return {
            star: 0,
            loading: false,
            show: false
        }
    },
    methods: {
        select() {
            this.loading = true
        }
    },
    notifications: {
        selected: {
            type: 'success',
            title: 'Success',
            message: 'This partner has been selected'
        }
    }
}
</script>

<style scoped>
    .partner {
        border: 1px solid #ddd;
        /* background-color: var(--trans); */
        /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
        border-radius: 5px;
        padding: 10px;
    }

    .image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    img {
        width: 50px;
    }

    .name {
        font-weight: 500;
        text-align: center;
        margin: 5px 0px;
        font-size: 12px;
    }

    .info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .flex {
        display: flex;
        fill: var(--main);
        font-size: 12px;
    }
    .no {
        font-weight: 500;
        margin-left: 5px;
    }
    
    .btn {
        background-color: var(--trans);
        color: var(--main);
        cursor: pointer;
        padding: 8px ;
        border-radius: 5px;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
    }
</style>