<template>
    <div class="city">
        <!-- <div class="image">
            <img :src="image"/>
        </div> -->
        <div class="info">
            <div class="icon" @click="toggle_main()">
                <arrow2 :size="'20px'" :style="{'height': '20px'}" 
                    :class="[show_main ? 'rot' : '']" class="arr"/>
            </div>
            <div class="name">{{city.name}}</div>
            <div class="flex">
                <div class="label">Properties:</div>
                <div class="value">{{city.properties}}</div>
            </div>
            <div class="opt">
                <div class="btn" @click="$emit('edit')">Edit</div>
                <div class="btn r" @click="$emit('delete')">Delete</div>
            </div>
        </div>
        <div class="main" v-if="show_main">
            <div class="menu">
                <div class="item" @click="c = 0" :class="[c == 0 ? 'item_a' : '']">Details</div>
                <div class="item" @click="c = 1" :class="[c == 1 ? 'item_a' : '']">Landmarks</div>
                <div class="item">Properties</div>
                <div class="item">Agents</div>
                <div class="item">Partners</div>
            </div>

            <div class="body" v-if="c == 0">
                <div class="head">
                    <div class="title">Details</div>
                    <div class="btn btn_big" @click="show_edit = true">Edit Details</div>
                </div>
                <div>
                    <div class="f">
                        <div class="l">Properties:</div>
                        <div class="v">{{ city.properties }}</div>
                    </div>
                    <div class="f">
                        <div class="l">Landmarks:</div>
                        <div class="v">{{ city.landmarks }}</div>
                    </div>
                    <div class="f">
                        <div class="l">Clients:</div>
                        <div class="v">{{ city.clients }}</div>
                    </div>
                    <div class="f">
                        <div class="l">Agents:</div>
                        <div class="v">{{ city.numbers }}</div>
                    </div>
                    <div class="f">
                        <div class="l">Longitude:</div>
                        <div class="v">{{ city.longitude }}</div>
                    </div>
                    <div class="f">
                        <div class="l">Latitude:</div>
                        <div class="v">{{ city.latitude }}</div>
                    </div>
                    <div class="f">
                        <div class="l">Radius:</div>
                        <div class="v">{{ city.radius }}</div>
                    </div>
                </div>
            </div>
            <div class="body" v-if="c == 1">
                <div class="head">
                    <div class="title">Landmarks ({{ city.landmarks }})</div>
                    <div class="btn btn_big" @click="show_add = true">Add Landmarks</div>
                </div>

                <div>
                    <div class="loading" v-if="loading">
                        <div class="spin"></div>
                    </div>
                    <div class="landmark_grid" v-else>
                        <landmark :landmark="landmark" v-for="landmark in landmarks" :key="landmark.id"/>
                    </div>
                </div>
            </div>
        </div>

        <add :city="city" v-if="show_add" 
            @close="show_add = false" @new_landmark="new_landmark($event)"/>

        <edit_city_point v-if="show_edit" :city="city"
            @close="show_edit = false" @updated="updated($event)"/>
    </div>
</template>

<script>
import arrow2 from '../../icons/arrow2.vue';

import landmark from './location/landmark/landmark.vue';

import add from './location/landmark/add.vue';
import edit_city_point from './location/edit_city_point.vue';

export default {
    components: {
        arrow2,
        landmark,
        add,
        edit_city_point
    },
    props: ['city'],
    data() {
        return {
            image: '',
            show_main: false,
            show_add: false,
            show_edit: false,

            c: 0,

            loading: false,
            landmarks: []
        }
    },
    mounted() {
        this.image = this.$root.$data.imgurl + this.city.image
    },
    methods: {
        toggle_main() {
            if (!this.show_main) {

                this.loading = true

                let form = new FormData()
                form.append('city', this.city.name)
                form.append('state', this.city.state)
                form.append('country', this.city.country)

                this.$http.post('/location/city/landmark/get', form)
                .then(data => {
                    let r = data.data
                    if (r.success) {
                        this.landmarks = r.landmarks
                        this.loading = false
                    }
                })
            }

            this.show_main = !this.show_main
        },
        new_landmark(e) {
            this.city.landmarks++
            this.landmarks.unshift(e)
            this.show_add = false
        },
        updated(e) {
            this.city.radius = e.radius
            this.city.longitude = e.longitude
            this.city.latitude = e.latitude

            this.show_edit = false
        }
    }
}
</script>

<style scoped>
    .city {
        /* display: flex;
        align-items: center; */
        border-radius: 5px;
        overflow: hidden;
        background-color: white;

        margin-top: 20px;
    }
    

    .info {
        padding: 15px 20px;
        width: 100%;
        box-sizing: border-box;

        display: grid;
        grid-template-columns: max-content max-content max-content auto;
        align-items: center;
        gap: 20px;
    }
    
    .icon {
        width: 30px;
        height: 30px;
        border-radius: 3px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        /* border: 1px solid #ccc; */
        background-color: var(--main);
        cursor: pointer;
        fill: white;
    }
    .arr {
        transition: .2s;
    }
    .rot {
        transform: rotate(90deg);
    }


    .name {
        font-weight: 500;
    }
    .flex {
        display: flex;
        align-items: center;
        gap: 10px;
        /* margin-right: 10px; */
        font-size: 14px;
    }
    .label {
        font-weight: 500;
        opacity: .5;
        /* margin-right: 5px; */
    }
    .value {
        font-weight: 500;
    }

    .opt {
        display: flex;
        /* justify-content: space-between; */
        gap: 20px;
        margin-left: auto;
        /* width: 100%; */
        /* margin-top: 10px; */
    }
    .btn {
        padding: 5px 20px;
        color: var(--main);
        background-color: var(--trans);
        cursor: pointer;
        border-radius: 5px;
        font-weight: 500;
        font-size: 12px;
    }
    .r {
        background-color: rgba(255, 99, 71, 0.2);
        color: tomato;
    }


    .main {
        border-top: 1px solid #ddd;
    }
    .menu {
        padding: 0px 20px;
        display: flex;
        border-bottom: 1px solid #ddd;
    }
    .item {
        font-size: 14px;
        font-weight: 500;
        padding: 15px 20px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
    }
    .item_a {
        border-bottom-color: var(--main);
        color: var(--main);
    }

    .body {
        padding: 20px;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .title {
        font-weight: 500;
        font-size: 18px;
    }
    .btn_big {
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 500;
    }

    .f {
        display: flex;
        align-items: center;
        padding: 15px;
        border-radius: 5px;
        font-size: 14px;
    }
    .f:nth-child(even) {
        background-color: #edeef0;
    }
    .l {
        color: gray;
        width: 150px;
    }
    .v {
        font-weight: 500;
    }

    
    .loading {
        padding: 50px 0px;
    }
    .spin {
        margin: 0 auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: var(--main);
        animation: rotate 1s infinite linear;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .landmark_grid {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 20px;
    }
</style>