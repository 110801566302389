<template>
    <div class="message">
        <div class="title">Message Agent</div>
        
        <location v-for="city in cities" :key="city.name" :city="city"/>
    </div>
</template>

<script>
import location from '../../components/employee/msg_agent/location.vue'
export default {

    components: {
        location
    },
    mounted() {

        this.$http.get('/properties/locations')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.cities = r.cities
            }
        })
    },
    data() {
        return {
            cities: []
        }
    }
}
</script>

<style scoped>
    .message {
        padding: 20px;
    }
    .title {
        font-weight: 500;
        margin-bottom: 20px;
    }
</style>