<template>
    <div class="youtube">
        <iframe
            :width="width"
            :src="'https://www.youtube.com/embed/' + id + '?autoplay=0&controls=1&showinfo=0&rel=0&modestbranding=1'"
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowfullscreen>
        </iframe>
    </div>
</template>

<script>
export default {
    props: ['id'],
    mounted() {
        // this.width = window.innerWidth + 'px'
    },
    data() {
        return {
            // width: ''
        }
    }
}
</script>

<style scoped>

iframe {
    width: 100%;
    min-height: 100%;
    border: none;
}
</style>