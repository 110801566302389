import { render, staticRenderFns } from "./undecided.vue?vue&type=template&id=189c3e66&scoped=true&"
import script from "./undecided.vue?vue&type=script&lang=js&"
export * from "./undecided.vue?vue&type=script&lang=js&"
import style0 from "./undecided.vue?vue&type=style&index=0&id=189c3e66&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "189c3e66",
  null
  
)

export default component.exports