<template>
    <div class="locations">
        <div class="head" @click="show = !show">
            <div class="name">{{city.name}} ({{city.properties}})</div>
            <div>
                <arrow :size="'20px'" class="arr" :class="[ show ? 'rot' : '' ]"/>
            </div>
        </div>
        <div class="main" v-if="show">
            Coming soon
        </div>
    </div>
</template>

<script>
import arrow from '../../../icons/arrow2.vue'
export default {
    components: {
        arrow
    },
    props: ['city'],
    data() {
        return {
            show: false,
            properties: []
        }
    },
    mounted() {
        
    }
}
</script>

<style scoped>
    .locations {
        background-color: white;
        margin-top: 30px;
        border-radius: 5px;
        overflow: hidden;
    }
    .head {
        padding: 20px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    .arr {
        transition: .3s;
    }
    .rot {
        transform: rotate(90deg);
    }

    .main {
        padding: 20px;
        border-top: 1px solid #ccc;
    }
</style>