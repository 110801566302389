<template>
    <div class="menu">
        <router-link to="/dashboard">
            <div class="item" :class="[view == 'dashboard' ? 'a' : '']">
                <dashboard :size="'15px'" class="icon" :style="{'height': '20px'}"/>
                Dashboard
            </div>
        </router-link>

        <div class="pos">
            <div class="item" @click="agent_clicked()"
                :class="[view == 'agent' || view == 'submit' || view == 'properties' || 
                view == 'a_appointment' || view == 'alerts' ? 'a' : '']">
                <agent :size="'15px'" class="icon" :style="{'height': '20px'}"/>
                Agent
            </div>
            <div class="popup" v-if="show">
                <!-- <router-link to="/dashboard/agent" v-if="agent != 'ok'">
                    <div class="flex">
                        <register :size="'15px'" class="icon ic2" :style="{'height': '20px'}"/>
                        Become An Agent
                    </div>
                </router-link> -->
                <div v-if="agent == 'block'">
                    <router-link to="/dashboard/agent/properties">
                        <div class="flex" @click="show = false" :class="[view == 'properties' ? 'a' : '']">
                            <house :size="'15px'" class="icon ic2" :style="{'height': '15px'}"/>
                            View Properties
                        </div>
                    </router-link>
                    
                    <router-link to="/dashboard/agent/appointments">
                        <div class="flex" @click="show = false" :class="[view == 'a_appointment' ? 'a' : '']">
                            <appointment :size="'15px'" class="icon ic2" :style="{'height': '15px'}"/>
                            Appointments
                        </div>
                    </router-link>
                </div>

                <div v-else>

                    <router-link to="/dashboard/agent/submit" v-if="agent == 'ok'">
                        <div class="flex" @click="show = false" :class="[view == 'submit' ? 'a' : '']">
                            <plus :size="'15px'" class="icon ic2" :style="{'height': '15px'}"/>
                            Submit a Property
                        </div>
                    </router-link>
                    
                    <router-link to="/dashboard/agent/properties" v-if="agent == 'ok'">
                        <div class="flex" @click="show = false" :class="[view == 'properties' ? 'a' : '']">
                            <house :size="'15px'" class="icon ic2" :style="{'height': '15px'}"/>
                            View Properties
                        </div>
                    </router-link>
                    
                    <router-link to="/dashboard/agent/appointments">
                        <div class="flex" @click="show = false" :class="[view == 'a_appointment' ? 'a' : '']">
                            <appointment :size="'15px'" class="icon ic2" :style="{'height': '15px'}"/>
                            Appointments
                        </div>
                    </router-link>
                    
                    <router-link to="/dashboard/agent/alerts" v-if="agent == 'ok'">
                        <div class="flex" @click="show = false" :class="[view == 'alerts' ? 'a' : '']">
                            <notification :size="'15px'" class="icon ic2" :style="{'height': '15px'}"/>
                            Alerts
                        </div>
                    </router-link>
                
                </div>
            </div>
        </div>

        <router-link to="/dashboard/appointments">
            <div class="item" :class="[view == 'appointments' ? 'a' : '']">
                <appointment :size="'15px'" class="icon" :style="{'height': '20px'}"/>
                Appointments
            </div>
        </router-link>

        <div class="pos" v-if="is_partner">
            <div class="item" @click="partner_opt = !partner_opt" 
                :class="[
                    view == 'p_dashboard' || view == 'p_appointments' || view == 'p_settings' ? 'a' : '']">
                <partner :size="'15px'" class="icon" :style="{'height': '20px'}"/>
                Partner
            </div>
            <div class="popup" v-if="partner_opt">

                <router-link to="/dashboard/partner/dashboard">
                    <div class="flex" @click="partner_opt = false" :class="[view == 'p_dashboard' ? 'a' : '']">
                        <dashboard :size="'15px'" class="icon ic2" :style="{'height': '20px'}"/>
                        Dashboard
                    </div>
                </router-link>

                <router-link to="/dashboard/partner/appointments">
                    <div class="flex" @click="partner_opt = false" :class="[view == 'p_appointments' ? 'a' : '']">
                        <appointment :size="'15px'" class="icon ic2" :style="{'height': '20px'}"/>
                        Appointments
                    </div>
                </router-link>

                <router-link to="/dashboard/partner/settings">
                    <div class="flex" @click="partner_opt = false" :class="[view == 'p_settings' ? 'a' : '']">
                        <settings :size="'15px'" class="icon ic2" :style="{'height': '15px'}"/>
                        Settings
                    </div>
                </router-link>
            </div>
        </div>

        <div class="pos">
            <div class="item" @click="show_more = !show_more"
            :class="[ view == 'tenants' || view == 'settings' ? 'a' : '']">
                <more :size="'15px'" class="icon"/>
                More
            </div>
            <div class="popup last_pop" v-if="show_more">
                <router-link to="/dashboard/tenants">
                    <div class="flex" @click="show_more = false" :class="[view == 'tenants' ? 'a' : '']">
                        <house :size="'15px'" class="icon ic2" :style="{'height': '15px'}"/>
                        My Tenants
                    </div>
                </router-link>
                <router-link to="/dashboard/settings">
                    <div class="flex" @click="show_more = false" :class="[view == 'settings' ? 'a' : '']">
                        <settings :size="'15px'" class="icon ic2" :style="{'height': '15px'}"/>
                        Settings
                    </div>
                </router-link>

            </div>
        </div>

    </div>
</template>

<script>
import dashboard from '../icons/dashboard.vue'
import agent from '../icons/agent.vue'
import arrow from '../icons/arrow_down.vue'
import register from '../icons/register.vue'
import house from '../icons/house.vue'
import notification from '../icons/notification.vue'
import plus from '../icons/plus.vue'
import appointment from '../icons/appointment.vue'
import partner from '../icons/partner.vue'
import user from '../icons/user.vue'

import more from '../icons/more.vue'
import settings from '../icons/settings.vue'


export default {

    components: {
        dashboard, agent, arrow, register, house, notification, plus, appointment, 
        partner, user, more, settings
    },
    computed: {
        view() {
            return this.$route.name
        },
        agent() {
            return this.$root.$data.agent
        },
        is_partner() {
            return this.$root.$data.is_partner == 'ok'
        }
    },
    data() {
        return {
            // agent: 'ok',
            show: false,
            partner_opt: false,
            show_more: false
        }
    },
    methods: {
        agent_clicked() {
            if (this.agent == 'block') {
                this.show = !this.show
                return
            }
            
            if (this.agent == 'ok') {
                this.show = !this.show
            } else {
                this.$router.push('/dashboard/agent/be')
            }
        }
    }
}
</script>

<style scoped>

    .menu {
        display: flex;
        justify-content: space-around;
        padding: 10px 0px;
        box-sizing: border-box;
        border-top: 1px solid #ccc;
    }
    .item {
        text-align: center;
        font-size: 12px;
        fill: #686868;
        font-weight: 500;
    }

    .pos {
        position: relative;
    }
    .popup {
        position: absolute;
        bottom: 40px;
        left: -60px;
        background-color: white;
        width: 160px;
        box-shadow: 0px 0px 10px #ccc;
        border-radius: 10px;
        overflow: hidden;
        /* background-color: green; */
        /* z-index: 2; */
    }
    .popup:after {
        content: ' ';
        width: 0;
        height: 0;
        position: absolute;
        bottom: -20px;
        left: calc(50% - 10px);
        border: 10px solid;
        border-color: var(--main) transparent transparent transparent;
    }
    .flex {
        display: flex;
        align-items: center;
        padding: 10px;
        font-size: 14px;
        fill: #686868;
    }
    .a {
        color: var(--main);
        fill: var(--main);
    }
    .ic2 {
        margin-right: 10px;
    }

    .last_pop {
        left: unset;
        right: -15px;
        width: 120px;
    }
</style>