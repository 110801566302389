<template>
    <div class="property">
        <property :property="property"/>

        <div class="content">
            <div v-if="property.avaliable == '1'">
                <div v-if="!property.agent_ok" >
                    <div class="bold">Waiting for property agent</div>
                    <div class="text small">
                        Waiting for property agent to agree to your inspection time and update 
                        this property availiability for a smooth inspection experience. 
                        You will be notified via email or whatsapp about any update.
                    </div>
                </div>
                <div v-else>
                    <div v-if="ready">
                        <div class="bold">Are you ready to check this property?</div>

                        <div class="text small">
                            Please ensure you are available in the axis of {{property.city}} {{property.state}}
                            before selecting one of our partners for inspection
                        </div>

                        <div class="spin" v-if="spining"></div>
                        <div class="btn" @click="get_partners()" v-else>Choose a partner</div>
                    </div>
                </div>
            </div>
            <div v-else class="not">
                <div class="bold">Property no longer available</div>
                <div class="text small">
                    This property is not longer available, please kindly cancel this appointment 
                    and book another property for inspection.
                </div>
            </div>

            <div class="partners" v-if="partners.length > 0">
                <partner v-for="partner in partners" :key="partner.user_id" 
                    :partner="partner" :appt_id="property.appt_id" 
                        :location="property.city + ' ' + property.state"/>
            </div>

            <div class="none" v-if="none">
                All our partners in {{property.city}} {{property.state}} are currently busy. 
                We will alert you once a partner is available. Please bear with Us.
            </div>

            <div class="time flex">
                <div class="text small">
                    Appointment Time
                </div>
                <div class="ready" v-if="ready">Now</div>
                <countdown :end="property.time" v-else/>
            </div>

            
            <loader :height="'37px'" v-if="loading" class="f"/>
            <div class="btn" @click="cancel()" v-else>Cancel Appointment</div>

        </div>
    </div>
</template>

<script>
import { 
    insideCircle
} from 'geolocation-utils'

import property from '../property.vue'
import countdown from '../countdown.vue'
import loader from '../loader.vue'

import partner from './partner.vue'

export default {
    components: {
        property,
        countdown,
        loader, partner
    },
    props: ['property'],
    computed: {
        url() {
            return this.$root.$data.imgurl
        }
    },
    mounted() {

        this.price = JSON.parse(this.property.price)

        this.images = JSON.parse(this.property.images)

        let now = new Date()
        let appt = new Date(this.property.time)
        if (now > appt) {
            this.ready = true
        }
    },
    data() {
        return {
            date: '',
            options: {
                slidesPerView: 1,
                // loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                    hideOnClick: true
                },
            },
            images: [],
            price: {},
            loading: false,
            spining: false,
            ready: false,
            partners: [],
            none: false,
        }
    },
    methods: {
        
        cancel() {
            this.loading = true
            let form = new FormData()
            form.append('user_id', this.$root.$data.user_id)
            form.append('appt_id', this.property.appt_id)

            this.$http.post('/appointment/cancel', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    this.loading = false
                    this.canceled()
                    this.$router.go()
                }
            })
        },
        get_partners() {
            
            this.spining = true

            const options = {
                enableHighAccuracy: true,
                timeout: 10000,
            };

            navigator.geolocation.getCurrentPosition(
                this.proceed_to_get_partners, this.errorCallback, options
            );
        },
        proceed_to_get_partners(position) {

            console.log(position.coords);

            let user_loc = {
                lat: position.coords.latitude,
                lon: position.coords.longitude
            }
            let center = {
                lat: parseFloat(this.property.latitude),
                lon: parseFloat(this.property.longitude)
            }

            console.log(user_loc);
            console.log(center);
            console.log(parseFloat(this.property.radius));

            if (insideCircle(user_loc, center, parseFloat(this.property.radius))) {

                console.log('true');

                this.none = false

                let form = new FormData()
                form.append('property', this.property.prop_id)
                form.append('state', this.property.state)
                form.append('city', this.property.city)

                this.spining = true

                this.$http.post('/partners', form)
                .then(data => {
                    let r = data.data
                    if (r.success) {
                        this.partners = r.partners
                        this.spining = false

                        if (r.partners.length == 0) {
                            this.none = true
                        }
                    }
                })
            } else {
                this.spining = false

                this.not_in_location()
            }
        },
        errorCallback() {
            this.allow_location()

            this.spining = false
        }
    },
    notifications: {
        canceled: {
            type: 'success',
            title: 'Cancelled',
            message: 'This appointment has been cancelled'
        },
        allow_location: {
            type: 'info',
            title: 'Allow Location',
            message: 'Allow Bigglandlord to get your location to ensure that you are within the property location'
        },
        not_in_location: {
            type: 'warn',
            title: 'Not In Location',
            message: 'Ensure you are within the property location'
        }
    }
}
</script>

<style scoped>
    .property {
        /* width: 300px; */
        border-radius: 5px;
        overflow: hidden;
        background-color: white;
    }

    .content {
        padding: 15px;
        border-top: 1px solid #ddd;
    }
    .flex {
        display: flex;
        align-items: center;
        margin-top: 5px;
        font-size: 14px;
    }

    .btn {
        background-color: var(--trans);
        color: var(--main);
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: 600;
        font-size: 14px;
        margin-top: 15px;
        text-align: center;
    }
    
    .ready {
        font-weight: 500;
        background-color: var(--main);
        color: white;
        padding: 5px 20px;
        border-radius: 5px;
        font-size: 14px;
    }

    
    .spin {
        margin: 70px auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: var(--main);
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .partners {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        margin-top: 20px;
        grid-gap: 15px;
    }

    .none {
        margin-top: 20px;
        font-size: 14px;
        text-align: center;
    }

    .f {
        margin-top: 15px;
    }
    .small {
        font-size: 12px;
        line-height: 18px;
    }

    .time {
        /* background-color: var(--trans); */
        /* background-color: #edeef0; */
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        margin: 0px -15px;
        margin-top: 15px;
        padding: 15px;
        justify-content: space-between;
    }
    .time .text {
        margin-bottom: 0px;
    }
    .text {
        margin-bottom: 5px;
    }

    .bold {
        font-weight: 500;
        margin-bottom: 10px;
    }
</style>