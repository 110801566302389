<template>
    <div class="appointment">
        <div class="grid">
            <div class="item time">
                <div class="icon" @click="a = !a">
                    <arrow :size="'20px'" :style="{'height': '20px'}" 
                        :class="[a ? 'a' : '']" class="arr"/>
                </div>

                {{date}}
            </div>
            <div class="item">{{appointment.user_id}}</div>
            <div class="item">{{appointment.partner}}</div>
            <div class="item">{{appointment.agent}}</div>
            <div class="item">{{appointment.property}}</div>
            <div class="item">{{appointment.appt_id}}</div>
        </div>
        <div class="main" v-if="a">
            <div class="info">
                <div class="title">Apointment Info</div>
                <div class="flex">
                    <div class="label">Property</div>
                    <div class="value">{{appointment.title}}</div>
                </div>
                <div class="flex">
                    <div class="label">Partner</div>
                    <div class="value">{{appointment.name}}</div>
                </div>
                <div class="flex">
                    <div class="label">Location</div>
                    <div class="value">{{appointment.location}}</div>
                </div>
                <div class="flex">
                    <div class="label">Code</div>
                    <div class="value">{{appointment.code}}</div>
                </div>


                <div class="text">From Partner</div>
                <div class="flex">
                    <div class="label">Success</div>
                    <div class="value">{{partner_summary.success}}</div>
                </div>
                <div class="flex">
                    <div class="label">Payment Type</div>
                    <div class="value">{{partner_summary.payment_type}}</div>
                </div>
                <div v-if="partner_summary.payment_type == 'full'">
                    <div class="flex">
                        <div class="label">Account Name</div>
                        <div class="value">{{ partner_summary.full_payment.acct_name }}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Amount Paid</div>
                        <div class="value">{{partner_summary.full_payment.amount}}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Commission Paid</div>
                        <div class="value">{{partner_summary.full_payment.commission}}</div>
                    </div>
                </div>

                <div v-if="partner_summary.payment_type == 'part'">
                    <div class="flex">
                        <div class="label">Account Name</div>
                        <div class="value">{{ partner_summary.part_payment[0].acct_name }}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Total Amount</div>
                        <div class="value">{{ partner_summary.part_payment[0].total_amount }}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Amount Paid</div>
                        <div class="value">{{ partner_summary.part_payment[0].amount_paid }}</div>
                    </div>
                </div>


                <div class="text">From User</div>
                <div class="flex">
                    <div class="label">Success</div>
                    <div class="value">{{user_summary.success}}</div>
                </div>
                <div class="flex">
                    <div class="label">Payment Type</div>
                    <div class="value">{{user_summary.payment_type}}</div>
                </div>

                <div v-if="user_summary.payment_type == 'full'">
                    <div class="flex">
                        <div class="label">Account Name</div>
                        <div class="value">{{ user_summary.full_payment.acct_name }}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Amount Paid</div>
                        <div class="value">{{user_summary.full_payment.amount}}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Commission Paid</div>
                        <div class="value">{{user_summary.full_payment.commission}}</div>
                    </div>
                </div>


                <div v-if="user_summary.payment_type == 'part'">
                    <div class="flex">
                        <div class="label">Account Name</div>
                        <div class="value">{{ user_summary.part_payment[0].acct_name }}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Total Amount</div>
                        <div class="value">{{ user_summary.part_payment[0].total_amount }}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Amount Paid</div>
                        <div class="value">{{ user_summary.part_payment[0].amount_paid }}</div>
                    </div>

                    <div v-if="user_summary.part_payment.length == 2">
                        <div class="flex">
                            <div class="value">User Second Payment</div>
                        </div>
                        <div class="flex">
                            <div class="label">Account Name</div>
                            <div class="value">{{ user_summary.part_payment[1].acct_name }}</div>
                        </div>
                        <div class="flex">
                            <div class="label">Balance Paid</div>
                            <div class="value">{{ user_summary.part_payment[1].balance }}</div>
                        </div>
                        <div class="flex">
                            <div class="label">Commission Paid</div>
                            <div class="value">{{ user_summary.part_payment[1].commission }}</div>
                        </div>
                    </div>
                </div>

                <div class="btn" @click="message = true">Message user</div>
            </div>
            
            <div>
                <div class="options" v-if="user_summary.success == 'yes'">
                    <div class="title">Appointment Options</div>
                    <div class="f">
                        <div class="t">
                            Payment confirmation ({{appointment.payment_confirmed ? 'confirmed' : 'pending'}})
                        </div>
                        
                        <loader :width="'72px'" :height="'29px'" v-if="p_load"/>
                        <div v-else>
                            <div class="btn btn2" v-if="appointment.payment_confirmed">Marked</div>
                            <div class="btn" v-else @click="confirm_payment()">Mark</div>
                        </div>
                    </div>

                    <div class="f">
                        <div class="t">
                            User details ({{appointment.details_submitted ? 'submitted' : 'pending'}})
                            {{appointment.details_rejected ? ' Rejected' : ' '}}
                        </div>
                        <div class="btn" @click="details = true" v-if="appointment.details_submitted">View</div>
                    </div>

                    <div class="f">
                        <div class="t">
                            User details confirmation ({{appointment.details_confirmed ? 'confirmed' : 'pending'}})
                        </div>

                        <loader :width="'72px'" :height="'29px'" v-if="u_load"/>
                        <div v-else>
                            <div v-if="appointment.details_submitted">
                                <div class="btn btn2" v-if="appointment.details_confirmed">Marked</div>
                                <div class="btn" v-else @click="confirm_details()">Mark</div>
                            </div>
                        </div>
                    </div>

                    <div class="f">
                        <div class="t">
                            Payment forwarded ({{appointment.payment_forwarded ? 'confirmed' : 'pending'}})
                        </div>

                        <loader :width="'72px'" :height="'29px'" v-if="pf_load"/>
                        <div v-else>
                            <div v-if="appointment.details_confirmed">
                                <div class="btn btn2" v-if="appointment.payment_forwarded">Marked</div>
                                <div class="btn" v-else @click="forward_payment()">Mark</div>
                            </div>
                        </div>
                    </div>

                    <div class="f">
                        <div class="t">
                            Partner paid ({{appointment.partner_paid ? 'paid' : 'pending'}})
                        </div>

                        <loader :width="'72px'" :height="'29px'" v-if="pp_load"/>
                        <div v-else>
                            <div v-if="appointment.payment_forwarded">
                                <div class="btn btn2" v-if="appointment.partner_paid">Marked</div>
                                <div class="btn" v-else @click="pay_partner()">Mark</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="issues">
                    <div class="title">Appointment Issues</div>
                    <div class="issue">
                        User ({{user_issues.length}})
                    </div>
                    <div class="issue">
                        Partner ({{partner_issues.length}})
                    </div>
                    <div class="issue">
                        Agent ({{agent_issues.length}})
                    </div>
                </div>
            </div>
        </div>

        <message v-if="message" @close="message = false" 
            :user_id="appointment.user_id" 
            :who="'user'"/>

        <user_details v-if="details" 
            :details="appointment.user_details" 
            :user_issues="user_issues"
            :appt_id="appointment.appt_id"
            :user_id="appointment.user_id"
            @close="details = false"
            @rejected="rejected()"
            @approve="details = false; confirm_details()"/>
    </div>
</template>

<script>
import moment from 'moment';
import arrow from '../../../icons/arrow2.vue';

import message from '../message/user.vue'
import loader from '../../loader.vue';

import user_details from './user_details.vue';
export default {
    components: {
        arrow, message,
        loader,
        user_details
    },
    props: ['appointment'],
    data() {
        return {
            a: false,
            date: '',
            partner_summary: {},
            user_summary: {},
            message: false,
            details: false,

            p_load: false,
            u_load: false,
            pf_load: false,
            pp_load: false,

            user_issues: [],
            partner_issues: [],
            agent_issues: []
        }
    },
    mounted() {
        this.date = new moment(this.appointment.created).format('D MMM YY - HH:mm')

        this.user_summary = JSON.parse(this.appointment.user_summary)
        this.partner_summary = JSON.parse(this.appointment.partner_summary)

        this.user_issues = this.appointment.user_issues == '' ? [] : JSON.parse(this.appointment.user_issues)
        this.partner_issues = this.appointment.partner_issues == '' ? [] : JSON.parse(this.appointment.partner_issues)
        this.agent_issues = this.appointment.agent_issues == '' ? [] : JSON.parse(this.appointment.agent_issues)
    },
    methods: {
        confirm_payment() {
            this.p_load = true

            let form = new FormData() 
            form.append('appt_id', this.appointment.appt_id)
            form.append('agent', this.appointment.agent)
            form.append('user', this.appointment.user_id)

            let amount = 0
            if (this.user_summary.payment_type == 'full') {
                amount = this.user_summary.full_payment.amount
            } else {
                amount = parseFloat(this.user_summary.part_payment[0].amount_paid)
                if (this.user_summary.part_payment.length == 2) {
                    amount += parseFloat(this.user_summary.part_payment[1].balance)
                }
            }


            form.append('payment', amount)

            this.$http.post('/admin/mark/payment', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    this.p_load = false
                    this.appointment.payment_confirmed = true

                    this.payment_confirmed()
                }
            })
        },
        rejected() {
            this.details = false

            this.appointment.details_rejected = true
        },
        confirm_details() {
            this.u_load = true

            let form = new FormData()
            form.append('appt_id', this.appointment.appt_id)
            form.append('user', this.appointment.user_id)

            this.$http.post('/admin/mark_details', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    this.details_confirmed()

                    this.u_load = false
                    this.appointment.details_confirmed = true
                    this.appointment.details_rejected = false
                }
            })
        },
        forward_payment() {
            this.pf_load = true

            let form = new FormData()
            form.append('user', this.appointment.user_id)
            form.append('appt_id', this.appointment.appt_id)
            form.append('partner', this.appointment.partner)
            form.append('prop_id', this.appointment.property)
            form.append('commission', this.user_summary.commission)

            this.$http.post('/admin/forward_payment', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    this.payment_forwarded()
                    
                    this.pf_load = false
                    this.appointment.payment_forwarded = true
                }
            })
        },
        pay_partner() {
            this.pp_load = true

            let form = new FormData()
            form.append('appt_id', this.appointment.appt_id)
            form.append('partner', this.appointment.partner)

            this.$http.post('/admin/pay_partner', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    this.partner_paid()

                    this.pp_load = false
                    this.appointment.partner_paid = true
                }
            })
        }
    },
    notifications: {
        payment_confirmed: {
            type: 'success',
            title: 'Confirmed',
            message: 'Client payment confirmed'
        },
        details_confirmed: {
            type: 'success',
            title: 'Confirmed',
            message: 'Client details has been confirmed'
        },
        payment_forwarded: {
            type: 'success',
            title: 'Success',
            message: 'Payment forwarded has been marked'
        },
        partner_paid: {
            type: 'success',
            title: 'Success',
            message: 'Partner payment has been confirmed'
        }
    }
}
</script>

<style scoped>
    .grid {
        display: grid;
        grid-template-columns: 20% 16% 16% 16% 16% 16%;
        padding: 0px 20px;
        border-top: 1px solid #ccc;
        padding: 0px 20px;
    }
    .item {
        padding: 10px 0px;
        display: flex;
        gap: 20px;
    }
    .time {
        font-size: 14px;
    }
    
    .icon {
        width: 25px;
        height: 25px;
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        /* border: 1px solid #ccc; */
        background-color: var(--main);
        cursor: pointer;
        fill: white;
    }
    .arr {
        transition: .2s;
    }
    .a {
        transform: rotate(90deg);
    }

    .main {
        padding: 20px;
        border-top: 1px solid #ccc;
        display: grid;
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        gap: 20px;
    }
    .title {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 20px;
    }
    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        font-size: 14px;
    }
    .flex:nth-child(even) {
        background-color: #edeef0;
    }
    .value {
        font-weight: 500;
    }

    .text {
        font-weight: 500;
        padding: 10px;
        margin-top: 10px;
    }

    .btn {
        padding: 5px 20px;
        text-align: center;
        background-color: var(--main);
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
        color: white;
        width: fit-content;
        cursor: pointer;
    }
    .btn2 {
        background-color: var(--trans);
        color: var(--main);
    }

    .f {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
    }
    .text {
        /* font-size: 14px; */
    }


    .issues {
        margin-top: 20px;
    }
    .issue {
        background-color: var(--trans);
        color: var(--main);
        font-weight: 500;
        cursor: pointer;
        padding: 15px 20px;
        border-radius: 5px;
        margin-top: 20px;
    }
</style>