import { render, staticRenderFns } from "./agents.vue?vue&type=template&id=1c244631&scoped=true&"
import script from "./agents.vue?vue&type=script&lang=js&"
export * from "./agents.vue?vue&type=script&lang=js&"
import style0 from "./agents.vue?vue&type=style&index=0&id=1c244631&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c244631",
  null
  
)

export default component.exports