<template>
    <div class="tenant">
        <div class="title">My Tenants</div>

        <div v-if="is_manager" class="grid">
            <div>
                <div class="add">
                    <div class="t">Add Property</div>
                    <div class="text">
                        Add the property you want to manage by clicking the add button
                    </div>
                    <div class="btn" @click="show_add = true">Add Property</div>
                </div>
            </div>

            <div class="house" v-for="house in houses" :key="house.house_id">
                <house :house="house" />
            </div>
        </div>
        <div v-else>
            <register v-if="show_register"/>

            <div class="box" v-else>
                <div class="t">Manage my tenants</div>
                <div class="text">
                    Are you are a property manager/caretaker or a landlord? with Bigglandlord, 
                    you can easily manage your tenants 
                    with our simple and seamless platform. click the botton below to get started
                </div>
                <div class="btn" @click="show_register = true">Get Started</div>
            </div>
        </div>

        <div class="fetching" v-if="fetching">
            <div class="spin"></div>
        </div>

        <add_house v-if="show_add" @close="show_add = false" @new_house="new_house($event)"/>

    </div>
</template>

<script>
// import plus from '../icons/plus.vue'
import register from '../components/tenant/register.vue'

import add_house from '../components/tenant/add_house.vue'
import house from '../components/tenant/house.vue'

export default {
    components: {
        // plus,
        register,
        add_house, house
    },

    computed: {
        is_manager() {
            return this.$root.$data.is_manager == 'ok'
        }
    },

    data() {
        return {
            show_register: false,
            show_add: false,
            houses: [],
            fetching: false
        }
    },
    mounted() {
        if (this.is_manager) {
            this.fetching = true

            this.$http.post('/manager/house/get')
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.houses = r.houses

                    this.fetching = false
                }
            })
        }
    },
    methods: {
        new_house(event) {
            this.houses.push(event)
            this.added()
            this.show_add = false
        }
    },
    notifications: {
        added: {
            type: 'success',
            title: 'Added',
            message: 'your property has been added'
        }
    }
}
</script>

<style scoped>
    .tenant {
        padding: 20px;
    }
    .title {
        font-weight: 500;
        font-size: 20px;
    }

    .grid {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 20px;
    }
    .add {
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        fill: #555;
        color: #555;
        /* text-align: center; */
    }

    .box {
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        max-width: 300px;
        margin: 0 auto;
        margin-top: 30px;
    }
    .t {
        font-weight: 600;
        margin-bottom: 15px;
    }
    .text {
        color: grey;
        font-size: 14px;
    }
    .btn {
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        padding: 8px;
        text-align: center;
        margin-top: 20px;
        font-weight: 500;
        cursor: pointer;
    }


    .spin {
        margin: 70px auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: var(--main);
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>