<template>
    <div class="l">
        <div class="box" @click="open = !open">
            <div class="name">{{country.name}}</div>
            <div class="flex">
                <div class="label">States:</div>
                <div class="value">{{country.states}}</div>
            </div>
            <div class="flex">
                <div class="label">Cities:</div>
                <div class="value">{{country.cities}}</div>
            </div>
            <div class="flex">
                <div class="label">Properties:</div>
                <div class="value">{{country.properties}}</div>
            </div>
            <div class="icon">
                <arrow :size="'20px'"/>
            </div>
        </div>
        <div class="content" v-if="open">
            <div class="property">
                <div class="title">Property Types</div>

                <div class="small_t">To Let</div>
                <div class="p_flex">
                    <div class="p_type" v-for="(property, i) in Let" :key="property"
                        @click="curr_type = i; edit_type = true; pop = true; curr_edit_type='Let'">{{property}}</div>
                    <div class="add_p" @click="pop = true; type = true; curr_add_type ='Let'">
                        Add Property Type
                    </div>
                </div>

                <div class="small_t">Short Let</div>
                <div class="p_flex">
                    <div class="p_type" v-for="(property, i) in Short" :key="property"
                        @click="curr_type = i; edit_type = true; pop = true; curr_edit_type='Short'">{{property}}</div>
                    <div class="add_p" @click="pop = true; type = true; curr_add_type ='Short'">
                        Add Property Type
                    </div>
                </div>

                <div class="small_t">Lease</div>
                <div class="p_flex">
                    <div class="p_type" v-for="(property, i) in Lease" :key="property"
                        @click="curr_type = i; edit_type = true; pop = true; curr_edit_type='Lease'">{{property}}</div>
                    <div class="add_p" @click="pop = true; type = true; curr_add_type ='Lease'">
                        Add Property Type
                    </div>
                </div>

                <div class="small_t">Sale</div>
                <div class="p_flex">
                    <div class="p_type" v-for="(property, i) in Sale" :key="property"
                        @click="curr_type = i; edit_type = true; pop = true; curr_edit_type='Sale'">{{property}}</div>
                    <div class="add_p" @click="pop = true; type = true; curr_add_type ='Sale'">
                        Add Property Type
                    </div>
                </div>
            </div>


            <div class="states_sec">
                <div class="flex_state">
                    <div class="title">States</div>
                    <div class="add_s" @click="pop = true; state = true">Add State</div>
                </div>

                <div class="grid">
                    <div class="states">
                        <div class="s_loading" v-if="state_loading">
                            <div class="spin"></div>
                        </div>
                        <div v-else class="state_flex">
                            <div class="state" v-for="state in states" :key="state.name"
                                :class="[curr == state.name ? 's_a' : '']" @click="select_state(state.name)"> 
                                {{state.name}}
                            </div>
                        </div>
                    </div>
                    <div class="choose" v-if="curr == ''">
                        Select a state
                    </div>
                    <div class="cities" v-else>
                        <div class="head">
                            <div class="t">Cities Under {{curr}}</div>
                            <div class="add_c" @click="pop = true; add_city = true">Add City</div>
                        </div>
                        <div class="c_loading" v-if="cities.length == 0 && city_loading">
                            <div class="spin"></div>
                        </div>
                        <div class="c_grid">
                            <city v-for="city in cities" :key="city.name" :city="city"
                                @edit="curr_city = city; pop = true; edit_city = true"
                                @delete="curr_city = city; pop = true; delete_city = true"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="popup" v-if="pop">
            <div class="dark"></div>
            <property_type v-if="type" @close="close()" @new_property="close()"
                :properties="properties" :Let="Let" :Short="Short" :Lease="Lease" :Sale="Sale" 
                :current="curr_add_type" :country="country.name"/>

            <edit_type v-if="edit_type" :Let="Let" :Short="Short" :Lease="Lease" :Sale="Sale" 
            :properties="properties" :curr="curr_type" :country="country.name" :current="curr_edit_type"
                @close="close()"/>

            <state v-if="state" :country="country.name" @new="new_state($event)" @close="close()"/>

            <add_city v-if="add_city" :state="curr" :country="country.name" 
                @close="close()" @new_city="new_city($event)"/>

            <edit_city v-if="edit_city" :city="curr_city" @close="close()"/>

            <delete_city v-if="delete_city" :city="curr_city" @close="close()"/>
        </div>
    </div>
</template>

<script>
import arrow from '../../icons/arrow_down.vue'
import city from './city.vue'
import property_type from './location/type.vue'
import edit_type from './location/edit_type.vue'
import state from './location/state.vue'
import add_city from './location/city.vue'
import edit_city from './location/edit_city.vue'
import delete_city from './location/delete_city.vue'
export default {
    components: {
        arrow, city, property_type, edit_type, state, add_city, edit_city, delete_city
    },
    props: ['country'],
    mounted() {
        this.properties = JSON.parse(this.country.property_type)
        this.Let = JSON.parse(this.country.Let)
        this.Short = JSON.parse(this.country.Short)
        this.Lease = JSON.parse(this.country.Lease)
        this.Sale = JSON.parse(this.country.Sale)

        this.state_loading = false

        let form = new FormData()
        form.append('country', this.country.name)
        this.$http.post('/location/state/get', form)
        .then(data => {
            let r = data.data
            if (r.success) {
                this.states = r.states
                this.state_loading = false
            }
        })
    },
    data() {
        return {
            open: false,
            properties: [],
            Let: [],
            Short: [],
            Lease: [],
            Sale: [],
            curr_add_type: '',
            state_loading: false,
            city_loading: false,
            states: [],
            cities: [],
            curr_city: {},
            curr: '',
            curr_type: '',
            curr_edit_type: '',
            pop: false,
            type: false,
            edit_type: false,
            state: false,
            add_city: false,
            edit_city: false,
            delete_city: false
        }
    },
    methods: {
        close() {
            this.pop = false
            this.type = false
            this.edit_type = false
            this.state = false
            this.add_city = false
            this.edit_city = false
            this.delete_city = false
        },
        new_state(event) {
            this.states.unshift({
                name: event
            })
            this.country.states++
            this.close()
        },
        select_state(state) {
            this.curr = state
            this.city_loading = true
            this.cities = []

            let form = new FormData()
            form.append('country', this.country.name)
            form.append('state', state)
            this.$http.post('/location/city/get', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.cities = r.cities
                    this.city_loading = false
                }
            })
        },
        new_city(event) {
            this.cities.unshift(event)
            this.country.cities++
            this.close()
        }
    }
}
</script>

<style scoped>
    .l {
        background-color: white;
        overflow: hidden;
        border-radius: 5px;
        margin-bottom: 20px;
    }
    .box {
        display: flex;
        padding: 20px;
        align-items: center;
        cursor: pointer;
    }
    .name {
        font-weight: 500;
        font-size: 18px;
        margin-right: 20px;
    }

    .flex {
        display: flex;
        align-items: center;
        margin-right: 10px;
        font-size: 14px;
    }
    .label {
        font-weight: 500;
        opacity: .5;
        margin-right: 5px;
    }
    .value {
        font-weight: 500;
    }

    .icon {
        margin-left: auto;
        fill: #686868;
    }

    .content {
        padding: 20px;
        border-top: 1px solid #ccc;
    }
    .title {
        font-weight: 500;
        font-size: 18px;
    }
    .small_t {
        font-weight: 500;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .p_flex {
        /* margin-top: 10px; */
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
    }
    .p_type {
        padding: 10px 20px;
        border: 1px solid #ccc;
        /* margin-right: 10px; */
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
    }
    .add_p {
        padding: 10px 20px;
        color: var(--main);
        background-color: var(--trans);
        cursor: pointer;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
    }

    .states_sec {
        margin-top: 30px;
    }
    .flex_state {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .add_s {
        padding: 8px 20px;
        color: var(--main);
        background-color: var(--trans);
        cursor: pointer;
        border-radius: 5px;
        font-weight: 500;
    }

    .grid {
        /* display: grid; */
        /* grid-template-columns: 200px auto; */
        margin-top: 10px;
        grid-gap: 20px;
    }
    .state_flex {
        display: flex;
        border-bottom: 1px solid #ddd;
        margin-bottom: 10px;
    }
    .state {
        padding: 10px 30px;
        /* border: 1px solid #ccc; */
        /* border-radius: 5px; */
        cursor: pointer;
        font-weight: 500;
        transition: .3s;
        font-size: 14px;
    }
    .s_a {
        border-color: #edeef0;
        background-color: #edeef0;
        /* background-color: white; */
        color: var(--main);
        /* margin-right: -20px; */
        /* border: none; */
        font-weight: 700;
    }
    .s_loading {
        padding: 50px 0px;
    }
    .spin {
        margin: 0 auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: var(--main);
        animation: rotate 1s infinite linear;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .choose {
        background-color: #edeef0;
        padding: 20px;
        border-radius: 5px;
        font-weight: 500;
    }
    .c_loading {
        padding: 30px 20px;
    }
    .cities {
        background-color: #edeef0;
        padding: 20px;
        border-radius: 5px;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .t {
        font-weight: 500;
    }
    .add_c {
        padding: 10px 20px;
        color: white;
        background-color: var(--main);
        cursor: pointer;
        border-radius: 5px;
        font-weight: 500;
        font-size: 12px;
    }

    .c_grid {
        margin-top: 20px;
        /* display: grid; */
        /* grid-template-columns: auto auto; */
        grid-gap: 20px;
    }


    
    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        box-sizing: border-box;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 2;
    }
</style>