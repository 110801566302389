<template>
    <div class="house">
        <div class="title">{{ house.name }}</div>
        <div class="address">{{ house.address }}</div>

        <div class="flex">
            <div class="t">Tenants ({{ house.tenants }})</div>
            <div class="btn small" @click="show_add = true">Add tenants</div>
        </div>
        <div class="tenants">
            <div class="loading" v-if="loading">
                <div class="spin"></div>
            </div>
            <div v-else>
                <div class="empty" v-if="tenants.length == 0">
                    You have not added any tenant to this property
                </div>
                <div class="grid" v-else>
                    <div class="tenant" v-for="tenant in tenants" :key="tenant.tenant_id">
                        <div class="label">Name</div>
                        <div class="value">{{tenant.name}}</div>
                        
                        <div class="label">Phone Number</div>
                        <div class="value">{{tenant.phone}}</div>

                        <div class="click" @click="view_tenant(tenant)">View Tenant</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn" @click="view_all()">View all tenants</div>

        <add_tenant :house_id="house.house_id" v-if="show_add" @close="show_add = false" 
            @new_tenant="new_tenant($event)"/>

        <tenant v-if="show_tenant" :tenant="tenant" @close="show_tenant = false"/>
    </div>
</template>

<script>

import add_tenant from './add_tenant.vue';
import tenant from './tenant.vue';

export default {
    props: ['house'],
    components: {
        add_tenant, tenant
    },
    mounted() {
        let form = new FormData() 
        form.append('house_id', this.house.house_id)

        this.$http.post('/manager/tenant/get', form)
        .then(data => {
            let r = data.data

            if (r.success) {

                this.all = r.all
                this.tenants = r.tenants.splice(0, 2)

                this.loading = false
            }
        })
    },
    data() {
        return {
            show_add: false,
            show_tenant: false,
            loading: true,
            tenants: [],
            all: [],

            tenant: {}
        }
    },
    methods: {
        new_tenant(e) {
            this.show_add = false

            this.tenants.unshift(e)
            this.all.unshift(e)

            this.house.tenants++
        },
        view_tenant(tenant) {
            this.tenant = tenant
            
            this.show_tenant = true
        },
        view_all() {
            this.tenants = this.all
        }
    }
}
</script>

<style scoped>
    .house {
        
        background-color: white;
        padding: 20px;
        border-radius: 5px;
    }
    .title {
        font-size: 20px;
        font-weight: 500;
    }
    .address {
        margin-top: 3px;
        font-size: 14px;
        color: gray;
    }
    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    }
    .t {
        font-weight: 500;
    }


    .tenants {
        padding: 20px 0px;
    }

    
    .spin {
        margin: 70px auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: var(--main);
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }


    .empty {
        text-align: center;
        color: grey;
        padding: 30px 0px;
        font-size: 14px;
    }


    .grid {
        display: grid;
        grid-template-columns: auto auto;
        gap: 20px;
    }
    .tenant {
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 10px;
    }
    .label {
        font-size: 12px;
        font-weight: 500px;
        color: gray;
    }
    .value {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 15px;
    }
    .click {
        padding: 8px;
        border-radius: 5px;
        font-weight: 500;
        font-size: 12px;
        text-align: center;
        color: var(--main);
        background-color: var(--trans);
        cursor: pointer;
    }


    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: 500;
        text-align: center;
    }
    .small {
        font-size: 12px;
        padding: 5px 10px;
    }
</style>