<template>
    <div class="current">
        <property :property="p"/>
        
        
        <div class="content">

            <div class="text" v-if="property.status == 'waiting'">
                <div>
                    <b>{{property.lastname}} {{property.firstname}} </b> has booked an appointment to 
                    view this property
                </div>
                <div v-if="property.avaliable == '0'">
                    This property is not longer avaliable kindly cancel this appointment
                </div>
            </div>

            <div class="details" v-if="property.status == 'running'">
                <div class="title">User Details</div>
                <div class="user_image" v-if="property.user_confirmed == ''">
                    <div class="label">Picture</div>
                    <div class="image">
                        <!-- <img src="../../assets/old.png"/> -->
                        <img :src="$root.$data.dpurl + property.dp"/>
                    </div>
                    <div v-if="v == ''">
                        <div class="text">
                            Please confirm, is this the person you are with?
                        </div>
                        <div class="opts">
                            <div class="btn" @click="v = 'yes'">Yes</div>
                            <div class="btn" @click="v = 'no'">No</div>
                        </div>
                    </div>
                    <div v-if="v == 'yes'">
                        <div class="text">
                            Are you sure is the same person?
                        </div>
                        <div class="opts">
                            <div class="btn" @click="v = ''">Cancel</div>

                            <loader v-if="confirming"/>
                            <div class="btn" v-else @click="confirm_user()">Yes</div>
                        </div>
                    </div>
                    <div v-if="v == 'no'">
                        <div class="text">
                            Are you sure is a different person?
                        </div>
                        <div class="opts">
                            <div class="btn" @click="v = ''">Cancel</div>
                            
                            <loader v-if="confirming"/>
                            <div class="btn" v-else @click="confirm_user()">Yes</div>
                        </div>
                    </div>
                </div>
                <div class="f">
                    <div class="label">Name:</div>
                    <div class="value">{{property.lastname}} {{property.firstname}}</div>
                </div>
                <div class="f">
                    <div class="label">Phone Number:</div>
                    <div class="value">{{property.user_phone}}</div>
                </div>
                <div class="f">
                    <div class="label">Rating:</div>
                    <div class="star">
                        <stared :size="'20px'" v-for="i in s" :key="i"/>
                        <star :size="'20px'" v-for="i in 5 - s" :key="i"/>
                    </div>
                    <!-- <div class="value">{{property.rating}}</div> -->
                </div>

                <div v-if="property.yes && !load">
                    <div class="title up">Property Details</div>
                    <div class="f">
                        <div class="label">Location:</div>
                        <div class="value">{{property.city}} {{property.state}}</div>
                    </div>
                    <div class="f">
                        <div class="label">Address:</div>
                        <div class="value">{{property.address}}</div>
                    </div>

                    <div class="title up">Agent Details</div>
                    <div class="f">
                        <div class="label">Name:</div>
                        <div class="value">{{property.agent.name}}</div>
                    </div>
                    <div class="f">
                        <div class="label">Phone Number:</div>
                        <div class="value">{{property.agent.phone}}</div>
                    </div>
                    <div class="f">
                        <div class="label">Whatsapp Number:</div>
                        <div class="value">{{property.agent.whatsapp}}</div>
                    </div>
                    <div class="f">
                        <div class="label">Address:</div>
                        <div class="value">{{property.agent.address}}</div>
                    </div>
                </div>

                <div v-if="property.user_confirmed != ''">
                    <div v-if="!property.yes">
                        <div class="spin" v-if="load"> </div>

                        <div class="input" v-else>
                            <div class="label">Appointment Code:</div>
                            <input type="text" v-model="code"
                                placeholder="Enter appointment code to see property information">
                            <div class="btn send" @click="partner_appt()">Send</div>
                        </div>
                    </div>
                </div>
            </div>

            <partner v-if="property.status == 'checked'" :appt_id="p.appt_id" :user="p.user_id"/>


            <div class="opt" v-if="property.status == 'waiting'">
                <loader v-if="c" :height="'38px'"/>
                <div class="btn" v-else @click="cancel()">Cancel</div>

                <div v-if="property.avaliable == '1'">
                    <loader v-if="loading" :height="'38px'"/>
                    <div class="btn" v-else @click="proceed()">Proceed</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import property from '../property.vue'
import partner from './rating/partner.vue'
import loader from '../loader.vue'

import stared from '../../icons/stared.vue'
import star from '../../icons/star.vue'

export default {

    components: {
        property,
        loader,
        partner,
        stared, star
    },
    props: ['p'],
    computed: {
        url() {
            return this.$root.$data.imgurl
        }
    },
    mounted() {

        this.property = this.p

        this.price = JSON.parse(this.property.price)

        this.images = JSON.parse(this.property.images)
        
        if (this.property.rating_users > 0) {
            let s = parseInt(this.property.rating) / parseInt(this.property.rating_users)
            this.s = Math.floor(s)
        }
    },
    data() {
        return {
            property: {},
            price: {},
            images: {},

            v: '',
            confirmed: false,
            confirming: false,

            c: false,
            loading: false,
            code: '',
            load: false,
            s: 0
        }
    },
    methods: {
        cancel() {
            if (this.loading) { return }

            let form = new FormData()
            form.append('appt_id', this.property.appt_id)

            this.c = true

            this.$http.post('/partner/appointment/cancel', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.canceled()
                    setTimeout(() => {
                        this.$router.go()
                    }, 500);
                }
            })
        },
        proceed() {
            if (this.c) { return }

            let form = new FormData()
            form.append('property', this.property.prop_id)
            form.append('appt_id', this.property.appt_id)

            this.loading = true
            this.$http.post('/partner/appointment/proceed', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.proceeded()
                    setTimeout(() => {
                        this.$router.go()
                    }, 500);
                }
            })
        },
        confirm_user() {
            this.confirming = true

            let form = new FormData()
            form.append('appt_id', this.property.appt_id)
            form.append('confirmation', this.v)

            this.$http.post('/partner/confirm_user', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.$router.go()
                }
            })
        },
        partner_appt() {
            this.load = true

            let form = new FormData()
            form.append('code', this.code)

            this.$http.post('/partner/appointment', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    if (!r.property.yes) { 
                        this.incorrect(); 
                        this.load = false; 
                        return 
                    }
                    this.got()
                    this.property = r.property

                    this.price = JSON.parse(this.property.price)
                    this.images = JSON.parse(this.property.images)

                    this.load = false

                }
            })
        }
    },
    notifications: {
        canceled: {
            type: 'success',
            title: 'Canceled',
            message: 'You have canceled this appointment'
        },
        proceeded: {
            type: 'success',
            title: 'Success',
            message: 'You can now proceed with the appointment'
        },
        incorrect: {
            type: 'warn',
            title: 'Incorrect code',
            message: 'The code you have entered is incorrect'
        },
        got: {
            type: 'success',
            title: 'Success',
            message: 'Property information fetched'
        }
    }
}
</script>

<style scoped>
    .current {
        background-color: white;
        border-radius: 5px;
        margin-top: 20px;
        margin-bottom: 20px;
        overflow: hidden;
    }
    .content {
        padding: 15px;
        border-top: 1px solid #ddd;
    }


    .opt {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 15px;
        margin-top: 15px;
    }
    .no {
        margin-left: 5px;
        font-weight: 500;
    }

    .btn {
        background-color: var(--trans);
        color: var(--main);
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
    }


    .text {
        font-size: 14px;
    }

    .details {
        font-size: 14px;
    }
    .title {
        font-weight: 500;
        background-color: var(--main);
        color: white;
        padding: 10px 15px;
        margin: 0px -15px;
    }
    .up {
        margin-top: 15px;
    }

    .user_image {
        margin-bottom: 20px;
        margin-top: 10px;
    }
    .user_image img {
        max-width: 300px;
    }
    .opts {
        display: grid;
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        gap: 20px;
        margin-top: 10px;
    }

    .f {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;
    }
    .label {
        font-size: 12px;
    }
    .value {
        font-weight: 500;
    }
    .star {
        display: flex;
        fill: var(--main);
    }

    .input {
        margin-top: 15px;
    }
    input {
        width: 100%;
        padding: 12px;
        border-radius: 5px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        outline: none;
        margin-top: 5px;
    }
    .send {
        margin-top: 15px;
        background-color: var(--main);
        color: white;
    }

    
    .spin {
        margin: 70px auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: var(--main);
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>