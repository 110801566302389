<template>
    <div>
        <svg style="enable-background:new 0 0 30 30;" version="1.1" viewBox="0 0 30 30" 
            xml:space="preserve" xmlns="http://www.w3.org/2000/svg" 
            xmlns:xlink="http://www.w3.org/1999/xlink" :width="size" :height="size">
            <rect height="10" width="26" x="2" y="15"/>
            <line style="fill:none;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;" x1="28" x2="28" y1="26" y2="15"/>
            <line style="fill:none;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;" x1="2" x2="2" y1="26" y2="15"/>
            <path d="M25,4H5C3.895,4,3,4.895,3,6v7h2v-2c0-0.552,0.448-1,1-1h7c0.552,0,1,0.448,1,1v2h2v-2c0-0.552,0.448-1,1-1h7  c0.552,0,1,0.448,1,1v2h2V6C27,4.895,26.105,4,25,4z"/>
        </svg>
    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>