<template>
    <div class="add_popup">
        <div class="dark" @click="close()"></div>

        <div class="box_2">
            <div class="title">Add New Payment</div>

            <div class="input">
                <div class="label">Rent Start</div>
                <input type="date" placeholder="Choose when tenant rent start" v-model="rent_start">
            </div>
            <div class="input">
                <div class="label">Rent End</div>
                <input type="date" placeholder="Chose when tenant rent end" v-model="rent_end">
            </div>
            <div class="input">
                <div class="label">Rent Price</div>
                <input type="number" placeholder="Enter rent price" v-model="rent_price">
            </div>
            <div class="input">
                <div class="label">Rent Duration</div>
                <div class="flex">
                    <input type="number" placeholder="No" v-model="rent_duration_no">
                    <select v-model="rent_duration">
                        <option value="" selected disabled>Duration</option>
                        <option value="Day">Day</option>
                        <option value="Week">Week</option>
                        <option value="Month">Month</option>
                        <option value="Year">Year</option>
                    </select>
                </div>
            </div>

            <loader :height="'41px'" v-if="loading"/>
            <div class="btn" v-else @click="add()">Add Payment</div>
        </div>
    </div>
</template>

<script>
import loader from '../loader.vue'

export default {
    components: {
        loader
    },
    props: ['tenant_id'],
    data() {
        return {
            loading: false,
            rent_start: '',
            rent_end: '',
            rent_price: '',
            rent_duration_no: '',
            rent_duration: ''
        }
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },
        add() {
            if (this.rent_start == '' || this.rent_end == '' || this.rent_price == '' || 
                this.rent_duration_no == '' || this.rent_duration == '') {
                this.empty(); return
            }
            
            this.loading = true

            let form = new FormData()
            form.append('tenant_id', this.tenant_id)
            form.append('rent_start', this.rent_start)
            form.append('rent_end', this.rent_end)
            form.append('rent_price', this.rent_price)
            form.append('rent_duration_no', this.rent_duration_no)
            form.append('rent_duration', this.rent_duration)

            this.$http.post('/manager/tenant/payment/add', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.added() 
                    
                    this.loading = false

                    this.$emit('new_payment', {
                        'tenant_id': this.tenant_id,
                        'rent_start': this.rent_start,
                        'rent_end': this.rent_end,
                        'rent_price': this.rent_price,
                        'rent_duration_no': this.rent_duration_no,
                        'rent_duration': this.rent_duration
                    })
                }
            })
        }
    },
    
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Make sure all field are filled'
        },
        added: {
            type: 'success',
            title: 'Success',
            message: 'Tenant new payment as been added'
        }
    }
}
</script>

<style scoped>

    .add_popup {
        position: fixed;
        z-index: 7;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dark {
        position: fixed;
        z-index: 8;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
    }
    .box_2 {
        background-color: white;
        border-radius: 5px;
        padding: 20px;
        z-index: 9;
        width: 300px;
        /* height: calc(100vh - 150px); */
        overflow: auto;
    }
    .title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    
    
    .input {
        margin-bottom: 25px;
        position: relative;
    }
    .label {
        position: absolute;
        background-color: white;
        padding: 0px 5px;
        top: -10px;
        left: 10px;
        transition: .2s;
        font-weight: 500;
        font-size: 14px;
    }
    input, select {
        padding: 15px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
    }
    .flex {
        display: flex;
        gap: 10px;
    }
    
    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: 500;
        text-align: center;
    }
</style>