<template>
    <div class="rate">
        <div class="text">
            The agent has marked that this property has been checked
        </div>

        
        <div class="payment">
            <div class="title">Payment Details</div>
            <div class="pay_text">
                Payment for this property should be made into Bigglandlord account 
            </div>
            <div class="f">
                <div class="label">Bank:</div>
                <div class="value">UBA</div>
            </div>
            <div class="f">
                <div class="label">Account Number:</div>
                <div class="value">1020153237</div>
            </div>
            <div class="f">
                <div class="label">Account Name:</div>
                <div class="value">Foresure Authentication Services Limited</div>
            </div>
            <div class="note">
                <b>Note:</b> Do not pay to any agent or partner
            </div>
        </div>

        <div> Was this appoinment succesful </div>
        <div class="flex">
            <div class="btn l trans" :class="[s == 'yes' ? 'a' : '']" @click="set('yes')">Yes</div>
            <div class="btn trans" :class="[s == 'undecided' ? 'a' : '']" @click="set('undecided')">Undecided</div>
        </div>

        <div class="tab" v-if="s == 'yes'">
            <div class="tab_item" :class="[tab == 'full' ? 'tab_a' : '']" @click="tab = 'full'">
                Full Payment
            </div>
            <div class="tab_item" :class="[tab == 'part' ? 'tab_a' : '']" @click="tab = 'part'">
                Part Payment
            </div>
        </div>
        <div class="form" v-if="s == 'yes'">
            <div v-if="tab == 'full'">
                <div class="input">
                    <div class="label">Bank Account Name</div>
                    <input placeholder="Enter sender bank account name"
                        type="text" v-model="acct_name">
                </div>
                <div class="input">
                    <div class="label">Total Amount</div>
                    <input placeholder="Enter the total amount you paid"
                        type="number" v-model="amount"/>
                </div>
                <div class="input">
                    <div class="label">Commission</div>
                    <input placeholder="Enter the commission you paid"
                        type="number" v-model="commission"/>
                </div>
            </div>

            <div v-if="tab == 'part'">
                <div class="input">
                    <div class="label">Bank Account Name</div>
                    <input placeholder="Enter sender bank account name"
                        type="text" v-model="acct_name">
                </div>
                <div class="input">
                    <div class="label">Amount to be paid</div>
                    <input placeholder="Enter the total amount to be paid" 
                        type="number" v-model="total_amount">
                </div>
                <div class="input">
                    <div class="label">Amount you paid</div>
                    <input placeholder="Enter the amount you paid" 
                        type="number" v-model="amount_paid">
                </div>
                <div class="input">
                    <div class="label">Balance</div>
                    <input type="text" disabled :value="total_amount - amount_paid">
                </div>
            </div>
        </div>
        

        <div class="label">Rate Property:</div>
        <star @stared="prop_star = $event"/>
        <textarea placeholder="Enter Your Review" v-model="prop_text"></textarea>

        <div class="label">Rate Partner:</div>
        <star @stared="partner_star = $event"/>
        <textarea placeholder="Enter Your Review" v-model="partner_text"></textarea>


        <loader :height="'38px'" v-if="loading"/>
        <div class="btn" v-else @click="rate()">Submit</div>


        <!-- <div class="label">Rate Property</div>
        <star/>
        <textarea placeholder="Enter Your Review"></textarea> -->
    </div>
</template>

<script>
import star from './star.vue'
import loader from '../../loader.vue'

export default {
    components: {
        star, loader
    },
    props: ['appt_id', 'partner'],
    data() {
        return {
            prop_star: 0,
            prop_text: '',

            partner_star: 0,
            partner_text: '',
            s: '',
            acct_name: '',
            commission: '',
            amount: '',

            tab: 'full',

            total_amount: '',
            amount_paid: '',
            loading: false
        }
    },
    methods: {
        rate() {
            if ( this.prop_star == 0 || this.prop_text == '' || this.partner_star == 0 || 
                this.partner_text == '' || this.s == '' ) {
                    this.emp();  return
                }
            
            if (this.s == 'yes') {
                if (this.tab == 'full') {
                    if (this.acct_name == '' || this.commission == '' || this.amount == '') {
                        this.emp(); return
                    }
                }
                if (this.tab == 'part') {
                    if (this.acct_name == '' || this.total_amount == '' || this.amount_paid == '') {
                        this.emp(); return
                    }
                }
            }

            this.loading = true
            let form = new FormData()

            form.append('appt_id', this.appt_id)
            form.append('partner', this.partner)

            form.append('partner_star', this.partner_star)
            form.append('partner_text', this.partner_text)

            form.append('prop_star', this.prop_star)
            form.append('prop_text', this.prop_text)

            form.append('success', this.s)
            form.append('payment_type', this.tab)

            form.append('acct_name', this.acct_name)

            form.append('commission', this.commission)
            form.append('amount', this.amount)

            form.append('total_amount', this.total_amount)
            form.append('amount_paid', this.amount_paid)

            this.$http.post('/user/submit_rating', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.loading = false
                    this.suc() 
                    setTimeout(() => {
                        this.$router.go()
                    }, 1000);
                }
            })
        },
        set(w) {
            this.s = w
        }
    },
    notifications: {
        emp: {
            type: 'warn',
            title: 'Check',
            message: 'Please make sure all field are filled including the star rating'
        },
        suc: {
            type: 'success',
            title: 'Success',
            message: 'Your ratings and reviews has been submitted'
        }
    }
}
</script>

<style scoped>
    .rate {
        /* margin-top: 15px; */
    }

    .text {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .label {
        /* margin-top: 10px; */
        font-weight: 500;
    }

    textarea, input {
        margin-bottom: 15px;
        width: 100%;
        padding: 12px;
        box-sizing: border-box;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

    .flex {
        display: flex;
        margin-bottom: 20px;
        margin-top: 5px;
    }
    .l {
        margin-right: 20px;
    }
    
    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
    }
    .trans {
        background-color: var(--trans);
        color: var(--main);
    }
    .a {
        background-color: var(--main);
        color: white;
    }


    .tab {
        display: flex;
        gap: 15px;
        margin-left: 15px;
        position: relative;
    }
    .tab_item {
        padding: 8px 15px;
        padding-bottom: 9px;
        font-size: 14px;
        cursor: pointer;
    }
    .tab_a {
        border-radius: 5px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border: 1px solid #ccc;
        border-bottom: none;
        z-index: 2;
        background-color: white;
        font-weight: 500;
        color: var(--main);
    }
    .form {
        padding: 15px;
        padding-bottom: 0px;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-bottom: 20px;
        margin-top: -1px;
    }

    
    .payment {
        margin-bottom: 20px;
    }
    .payment .pay_text {
        font-size: 14px;
    }
    .title {
        margin: 15px -15px;
        /* margin-top: 15px; */
        font-weight: 500;
        background-color: var(--main);
        color: white;
        padding: 10px 15px;
    }
    .f {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;
    }
    .label {
        /* font-size: 12px; */
    }
    .value {
        font-weight: 500;
        text-align: right;
    }
    .note {
        margin-top: 10px;
    }
</style>