<template>
    <div class="messages">
        <div class="head">
            <div class="title">Marked Clients</div>
            <div class="btn" @click="show_send = true">Message Clients</div>
        </div>
        
        <div class="clients">
            <div class="client" v-for="client in clients" :key="client.id">
                <div class="c_label">Name</div>
                <div class="c_value">{{client.name}}</div>

                <div class="c_label">Number</div>
                <div class="c_value">{{client.number}}</div>
                
                <div class="clicks">
                    <div class="del" @click="remove(client.id, client.city)">Delete</div>
                </div>
            </div>
        </div>
        
        <message v-if="show_send" @close="show_send = false"/>
    </div>
</template>

<script>
import message from '../components/admin/clients/message.vue'
export default {
    components: {
        message
    },
    data() {
        return {
            show_send: false,
            clients: [],
        }
    },
    mounted() {
        this.$http.post('/admin/client/get_marked')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.clients = r.clients
            }
        })
    },
    methods: {
        remove(id, city) {

            let form = new FormData()
            form.append('id', id)
            form.append('city', city)

            this.$http.post('/admin/client/delete', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    this.removed()
                    this.$router.go()
                }
            })
        }
    },
    notifications: {
        
        removed: {
            'type': 'success',
            'title': 'Removed',
            'message': 'Client has been removed'
        }
    }
}
</script>

<style scoped>

    .messages {
        padding: 30px;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .title {
        font-size: 20px;
        font-weight: 600;
    }
    .btn {
        padding: 8px 30px;
        border-radius: 5px;
        background-color: var(--main);
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        color: white;
    }


    
    .clients {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 20px;
    }
    .client {
        padding: 15px;
        border-radius: 5px;
        background-color: white;
    }
    .c_label {
        font-size: 12px;
        color: grey;
    }
    .c_value {
        font-weight: 500;
        margin-bottom: 15px;
        font-size: 14px;
    }
    
    .clicks {
        display: flex;
        gap: 20px;
    }
    .del, .click {
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: var(--main);
        cursor: pointer;
    }
</style>