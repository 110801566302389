<template>
    <div class="star_flex">
        <div class="fle" @click="star = 1; $emit('stared', 1)">
            <star :size="'25px'" v-if="star <= 0"/>
            <stared :size="'25px'" v-else/>
        </div>
        
        <div class="fle" @click="star = 2; $emit('stared', 2)">
            <star :size="'25px'" v-if="star <= 1"/>
            <stared :size="'25px'" v-else/>
        </div>
        
        <div class="fle" @click="star = 3; $emit('stared', 3)">
            <star :size="'25px'" v-if="star <= 2"/>
            <stared :size="'25px'" v-else/>
        </div>
        
        <div class="fle" @click="star = 4; $emit('stared', 4)">
            <star :size="'25px'" v-if="star <= 3"/>
            <stared :size="'25px'" v-else/>
        </div>
        
        <div class="fle" @click="star = 5; $emit('stared', 5)">
            <star :size="'25px'" v-if="star <= 4"/>
            <stared :size="'25px'" v-else/>
        </div>
    </div>
</template>

<script>
import star from '../../../icons/star.vue'
import stared from '../../../icons/stared.vue'

export default {
    components: {
        star, stared
    },
    data() {
        return {
            star: 0
        }
    }
}
</script>

<style scoped>
    .star_flex {
        display: flex;
        fill: var(--main);
    }
</style>