<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">Verify via whatsapp</div>
            <div class="input">
                <div class="label">Whatsapp number</div>
                <input type="text" placeholder="Enter your whatsapp number" 
                    v-model="whatsapp" :disabled="current == 1">
            </div>
            <div v-if="current == 0">
                <loader :height="'39px'" v-if="getting"/>
                <div class="btn" v-else @click="send_code()">Get Code</div>
            </div>
            <div v-if="current == 1">
                <div class="input">
                    <div class="label">Code</div>
                    <input type="text" placeholder="Enter code sent to your whatsapp" v-model="code">
                </div>
                <loader :height="'39px'" v-if="loading"/>
                <div class="btn" v-else @click="verify_code()">Verify Code</div>
            </div>
        </div>
    </div>
</template>

<script>
import loader from '../loader.vue';

export default {
    components: {
        loader
    },
    data() {
        return {
            getting: false,
            whatsapp: '',

            code: '',
            loading: false,
            
            current: 0
        }
    },
    methods: {
        close() {
            if (this.getting) { return }
            if (this.loading) { return }
            this.$emit('close')
        },
        send_code() {
            if (this.whatsapp.length != 11) {
                this.empty(); return
            }

            this.getting = true

            let form = new FormData()
            form.append('whatsapp', this.whatsapp)

            this.$http.post('/send_code_to_whatsapp', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.current = 1
                    this.code_sent()
                } else {
                    this.whatsapp_err()
                }

                this.getting = false
            })
        },
        verify_code() {
            if (this.code == '') {
                this.empty_code(); return
            }

            this.loading = true

            let form = new FormData()
            form.append('whatsapp', this.whatsapp)
            form.append('code', this.code)

            this.$http.post('verify_whatsapp_code', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.verified()
                    
                    localStorage.removeItem('email')

                    this.$router.push('/dashboard')
                } else {
                    this.incorrect_code()
                }
                this.loading = false
            })
            
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Your whatsapp number must be 11 digit'
        },
        whatsapp_err: {
            type: 'warn',
            title: '',
            message: 'This whatsapp number has bee used by another user'
        },
        code_sent: {
            type: 'success',
            title: 'Sent',
            message: 'Verification code has been sent to your whatsapp'
        },

        empty_code: {
            type: 'warn',
            title: '',
            message: 'Enter the code sent to your whatsapp'
        },
        incorrect_code: {
            type: 'warn',
            title: '',
            message: 'The code you entered is incorrect'
        },
        verified: {
            type: 'success',
            title: 'Verified',
            message: 'Your account has been verified'
        }
    }
}
</script>

<style scoped>
    .popup {
        position: fixed;
        width: 100%;
        height: 100%;

        top: 0px;
        left: 0px;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dark {
        position: fixed;
        width: 100%;
        height: 100%;

        top: 0px;
        left: 0px;
        background-color: rgba(0, 0, 0, 0.8);
    }
    .box {
        background-color: white;
        padding: 20px;
        border-radius: 5px;

        position: relative;
        width: 300px;
    }
    .title {
        font-weight: 700;
        margin-bottom: 30px;
    }

    .input {
        margin-bottom: 30px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -10px;
        left: 10px;
        padding: 0px 5px;
        font-size: 14px;
        font-weight: 500;
        background-color: white;
        color: gray;
    }
    input {
        padding: 12px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }
    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 10px 30px;
        border-radius: 5px;
        font-weight: 500;
        text-align: center;
    }
</style>