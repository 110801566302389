<template>
    <div class="prop">
        <property :property="property"/>
        
        <div class="content">
            
            <div class="flex" v-if="property.post_as == 'Semi-Mandate'">
                <div>Mandate: </div>
                <div>{{property.mandate_no}}</div>
            </div>

            <div class="flex av">
                <div>
                    Is this property still available
                </div>

                <loader :width="'60px'" :height="'35px'" v-if="loading"/>
                <div class="btn r" v-else @click="extend()">Yes</div>
                <div class="btn r" @click="pop = true">No</div>
            </div>

            <div v-if="unlisted" class="small f">
                This property has been unlisted, please confirm if this property is still available
            </div>

            <div class="f" v-else>
                <div class="small">
                    This property will be unlisted in
                </div>
                <div class="time">
                    <countdown :end="property.updated"/>
                </div>
            </div>
        </div>

        <div class="popup" v-if="pop">
            <div class="dark"></div>
            <div class="box">
                <div class="title">Remove Property</div>
                <div class="text">
                    Are you sure this property is no longer available?
                </div>
                <div class="options">
                    <div class="btn" @click="pop = false">Cancel</div>

                    <loader :height="'37px'" v-if="load"/>
                    <div class="btn c" @click="remove()" v-else>Yes</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import property from '../../property.vue';

import loader from '../../loader.vue';
import countdown from '../../countdown.vue';

export default {
    props: ['property'],
    components: {
        property,
        loader, countdown
    },
    data() {
        return {

            pop: false,
            loading: false,
            load: false,
            date: '',
            unlisted: false,
        }
    },
    mounted() {
        
        let updated = new Date(this.property.updated)

        let now = new Date()

        if (now > updated) {
            this.unlisted = true
        }
    },
    methods: {
        extend() {
            this.loading = true
            let form = new FormData()
            form.append('prop_id', this.property.prop_id)

            this.$http.post('/admin/agent/property/extend', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.extended()
                    setTimeout(() => {
                        this.$router.go()
                    }, 3000);
                }
            })
        },
        remove() {
            this.load = true
            let form = new FormData()
            form.append('prop_id', this.property.prop_id)

            this.$http.post('/admin/agent/property/remove', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.removed()
                    setTimeout(() => {
                        this.$router.go()
                    }, 3000);
                }
            })
        }
    },
    notifications: {
        extended: {
            'type': 'success',
            'title': 'Extended',
            'message': 'This property has been extended'
        },
        removed: {
            'type': 'success',
            'title': 'Removed',
            'message': 'This property has been removed'
        }
    }
}
</script>

<style scoped>
    .prop {
        border: 1px solid #ddd;
        border-radius: 5px;
        overflow: hidden;
        background-color: white;
    }
    
    .content {
        padding: 15px;
        border-top: 1px solid #ddd;
    }

    .flex {
        display: flex;
        align-items: center;
        font-size: 14px;
        gap: 8px;
    }

    .btn {
        background-color: var(--trans);
        color: var(--main);
        cursor: pointer;
        padding: 8px 20px;
        border-radius: 5px;
        font-weight: 500;
        margin-top: 15px;
        text-align: center;
    }
    .av {
        /* margin-top: 15px; */
    }
    .r {
        margin-left: 10px;
        margin-top: 0px;
    }

    .f {
        margin-top: 15px;
    }
    .small {
        font-size: 12px;
    }


    
    
    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        box-sizing: border-box;
        z-index: 1000;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
    }
    .box {
        background-color: white;
        position: relative;
        z-index: 1001;
        padding: 20px;
        border-radius: 10px;
    }
    .title {
        font-weight: 500;
        margin-bottom: 20px;
        font-size: 18px;
    }

    .options {
        display: grid;
        grid-template-columns: auto auto;
        gap: 20px;
        margin-top: 20px;
    }
    .options .btn {
        margin-top: 0px;
    }
    .c {
        background-color: var(--main);
        color: white;
    }
</style>