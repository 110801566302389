<template>
    <div class="agent">
        <be v-if="agent == ''"/>
        <processing v-if="agent == 'processing'"/>
        <rejected v-if="agent == 'rejected'"/>
    </div>
</template>

<script>
import be from '../components/agent/be.vue'
import processing from '../components/agent/processing.vue'
import rejected from '../components/agent/rejected.vue'
export default {
    components: {
        be,
        processing,
        rejected
    },
    computed: {
        agent() {
            return this.$root.$data.agent
        },
    },
    mounted () {
        window.scrollTo(0, 0)

        if (this.agent == 'ok') {
            this.$router.push('/dashboard/agent/submit')
        }
    },
}
</script>

<style scoped>
    .agent {
        padding: 30px;
    }
</style>