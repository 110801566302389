<template>
    <div>
        
    <svg style="enable-background:new 0 0 91 91;"  :width="size" :height="size"
        version="1.1" viewBox="0 0 91 91" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" 
        xmlns:xlink="http://www.w3.org/1999/xlink">

        <g><g><g><g>
            <path d="M39.5,30.2c3.2-2.6-1.5-7.5-4.6-4.6c-2.8,2.5-5.5,5.7-8.5,8c-2.7-2.6-9.3-11.6-11.1-8.1      c-0.9,1.8,1,3.9,2,5.2c1.6,2.2,3.3,4.2,5,6.3c1.6,2,3.6,3.7,6.1,2.1C32.4,36.7,35.9,33.1,39.5,30.2z"/>
            <path d="M72.9,25.6c-2.8,2.5-5.5,5.7-8.5,8c-2.7-2.6-9.3-11.6-11.1-8.1c-0.9,1.8,1,3.9,2,5.2c1.6,2.2,3.3,4.2,5,6.3      c1.6,2,3.6,3.7,6.1,2.1c3.9-2.5,7.5-6,11-8.9C80.7,27.6,76,22.7,72.9,25.6z"/>
            <path d="M54.9,58.8c-2.5-2.7-5.7-5.4-9.6-5.4c-3.7,0-6.8,2.3-9.3,4.9c-3.1,3.4-6.4,7.3-8.4,11.4      c-1.1,2.2,1.4,5.7,3.8,3.8c2.6-2,4.8-4.6,6.9-7.1c1.6-1.9,3.5-5.4,6.1-6.1c2.6-0.7,4.8,2.3,6.3,3.9c2.5,2.6,4.9,5.3,7,8.2      c2.5,3.4,8.3,0.1,5.8-3.4C61,65.5,58,62.1,54.9,58.8z"/></g></g></g></g>
    </svg>
    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>