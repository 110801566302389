<template>
    <div class="details">
        <div class="box">
            <div class="opt">
                <div class="btn" @click="$emit('close')">close</div>

                <loader v-if="choosing" :height="'31px'" :width="'71px'"/>
                <div class="btn btn2" v-else @click="select()">select</div>
            </div>
            <div class="image">
                <img :src="imgurl + '/avatar.svg'"/>
            </div>
            <div class="name">{{partner.firstname}} {{partner.lastname}}</div>

            <div class="flex">
                <div class="label">Star</div>
                <div class="star">
                    <stared :size="'20px'" v-for="i in s" :key="i"/>
                    <star :size="'20px'" v-for="i in (5 - s)" :key="i"/>
                </div>
            </div>
            <div class="flex">
                <div class="label">Score</div>
                <div class="value">{{partner.score}}/10</div>
            </div>
            <div class="flex">
                <div class="label">Appointments</div>
                <div class="value">{{partner.appointments}}</div>
            </div>
            <div class="flex">
                <div class="label">Successful</div>
                <div class="value">{{partner.success}}</div>
            </div>
            <div class="flex">
                <div class="label">Opening</div>
                <div class="value">{{partner.opening}}</div>
            </div>
            <div class="flex">
                <div class="label">Closing</div>
                <div class="value">{{partner.closing}}</div>
            </div>

            <div class="reviews">
                <div class="head">
                    <div class="title">Reviews</div>
                    <div class="small">Last 10</div>
                </div>
                <div class="empty" v-if="!loading && reviews.length == 0">
                    This partner has no review
                </div>

                <div class="spin" v-if="loading"></div>
                <div v-else>
                    <div class="review" v-for="(review, i) in reviews" :key="i">
                        <div class="info">
                            <div class="n">{{review.name}}</div>
                            <div class="time">
                                <timeago :date="review.date"/>
                            </div>
                        </div>
                        <div class="text">{{review.text}}</div>
                        <div class="foot">
                            <div class="f">
                                <div class="l">Star:</div>
                                <div class="star">
                                    <stared :size="'15px'" v-for="i in parseInt(review.star)" :key="i"/>
                                    <star :size="'15px'" v-for="i in ( 5 - parseInt(review.star))" :key="i"/>
                                </div>
                            </div>
                            <div class="f">
                                <div class="l">Successful:</div>
                                <div class="v">{{review.success}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import star from '../../icons/star.vue'
import stared from '../../icons/stared.vue'
import loader from '../loader.vue'
import timeago from '../time.vue'
export default {
    components: {
        star, stared, loader, timeago
    },
    props: ['partner', 'appt_id', 'location'],
    computed: {
        imgurl() {
            return this.$root.$data.dpurl
        }
    },
    data() {
        return {
            reviews: [],
            loading: true,
            s: 0,
            choosing: false,
        }
    },
    mounted() {
        
        if (parseInt(this.partner.rating) > 0) {
            let s = parseInt(this.partner.rating) / parseInt(this.partner.rating_users)
            this.s = Math.floor(s)
        } else {
            this.s = 0
        }

        let form = new FormData()
        form.append('partner', this.partner.user_id)

        this.$http.post('/partner/reviews', form)
        .then(data => {
            let r = data.data
            if (r.success) {
                this.reviews = r.reviews
                this.loading = false
            }
        })
    },
    methods: {
        select() {
            if (this.$root.$data.current_appointment != '0') {
                this.current_appt(); return
            }
            let d = this.$root.$data.dp
            let f = this.$root.$data.firstname
            let l = this.$root.$data.lastname
            let p = this.$root.$data.phone

            if (d == '' || f == '' || l == '' || p == '') {
                this.settings()
                this.$router.push('/dashboard/settings')
                return
            }
            let form = new FormData()
            form.append('partner', this.partner.user_id)
            form.append('appt_id', this.appt_id)
            form.append('location', this.location)
            form.append('name', this.partner.firstname + ' ' + this.partner.lastname)

            this.choosing = true

            this.$http.post('/partner/select', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()
                    this.$router.go()
                }
            })
        }
    },
    notifications: {
        success: {
            type: 'success',
            title: 'Success',
            message: 'you have choose this partner'
        },
        settings: {
            type: 'warn',
            title: 'Settings',
            message: 'Provide your picture, firstname, lastname and phone number before selecting a partner'
        },
        current_appt: {
            type: 'warn',
            title: 'Current Appointment',
            message: 'Please finish up with your current appoinment before proceeding'
        }
    }
}
</script>

<style scoped>
    .details {
        background-color: rgba(0, 0, 0, 0.8);
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 5;
    }
    .box {
        background-color: white;
        border-radius: 5px;
        width: 300px;
        height: 60vh;
        overflow: auto;
        padding: 15px;
        box-sizing: border-box;
        position: relative;
        animation: pop .3s;
    }
    @keyframes pop {
        from {
            transform: scale(.7);
        }
        to {
            transform: scale(1);
        }
    }
    
    .opt {
        position: absolute;
        top: 15px;
        left: 15px;
        display: flex;
        justify-content: space-between;
        width: calc(100% - 30px);
    }
    .btn {
        background-color: var(--trans);
        color: var(--main);
        cursor: pointer;
        padding: 7px 15px;
        border-radius: 5px;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
    }
    .btn2 {
        background-color: var(--main);
        color: white;
    }

    .image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    img {
        width: 50px;
    }
    .name {
        font-weight: 500;
        text-align: center;
        margin: 5px 0px;
    }

    .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0px;
        border-bottom: 1px solid #ccc;
    }
    .label {
        font-size: 12px;
    }
    .value {
        font-weight: 500;
        font-size: 14px;
    }

    .star {
        display: flex;
        fill: var(--main);
    }

    .head {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .title {
        font-weight: 500;
    }
    .small {
        font-size: 12px;
    }

    .empty {
        margin-top: 15px;
        padding: 10px;
        background-color: #edeef0;
        font-size: 12px;
        font-weight: 500;
    }



    .spin {
        margin: 70px auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: var(--main);
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    .review {
        background-color: #edeef0;
        padding: 10px;
        margin-top: 15px;
        font-size: 14px;
        border-radius: 5px;
    }
    .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: gray;
    }
    .n {
        font-weight: 500;
        font-size: 12px;
    }
    .time {
        font-size: 10px;
    }
    .text {
        padding: 10px 0px;
        font-weight: 500;
    }
    .foot {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        justify-content: space-between;
    }
    .f {
        display: flex;
        font-size: 12px;
        /* align-items: center; */
    }
    .l {
        margin-right: 5px;
        color: gray;
    }
    .v {
        font-weight: 500;
    }
    

    @media screen and (max-width: 400px) {
        .box {
            width: 100%;
            height: 100%;
            border-radius: 0px;
        }
    }

    
</style>