<template>
    <div class="pop">
        <div class="close" @click="$emit('close')">
            <close :size="'30px'"/>
        </div>
        <div class="title">
            Add a City Under {{state}}
        </div>
        <!-- <div class="image" v-if="is_image">
            <img :src="image"/>
            <div class="change" @click="upload()" >Change Photo</div>
        </div>
        <div class="select" @click="upload()" v-else>
            Click to upload an image for the city
        </div> -->
        <div class="input">
            <input placeholder="Enter City Name" v-model="input"/>
        </div>
        <loader :width="'90px'" :height="'37px'" class="l" v-if="loading"/>
        <div class="btn" v-else @click="add()">Add</div>

        <input type="file" accept="image/png, image/gif, image/jpeg, image/jpg" 
            ref="image" class="hidden" @change="handleupload($event)">
    </div>  
</template>

<script>
import close from '../../../icons/remove.vue'
import loader from '../../loader.vue'
export default {
    components: {
        close, loader
    },
    props: ['state', 'country'],
    data() {
        return {
            input: '',
            loading: false,
            image: '',
            is_image: false
        }
    },
    methods: {
        upload() {
            this.$refs.image.click()
        },
        handleupload(event) {
            let mediaType = event.target.files[0].type
            if (mediaType.includes('image')) {
                const picture = event.target.files[0]
                const reader = new FileReader()
                reader.readAsDataURL(picture)
                reader.onload = event => {
                    this.image = event.target.result
                    this.is_image = true
                }
            }
        },
        add() {
            // if (!this.is_image) { this.no_image(); return }
            if (this.input == '') { this.empty(); return }

            this.loading = true
            let form = new FormData()
            form.append('name', this.input)
            form.append('state', this.state)
            form.append('country', this.country)
            // form.append('image', this.$refs.image.files[0])

            this.$http.post('/location/city/add', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()
                    this.$emit('new_city', {
                        'home_page': '0',
                        'name': this.input,
                        'state': this.state,
                        'country': this.country,
                        'properties': 0,
                        'image': r.image,
                        'home_page': false
                    })
                    this.loading = false
                }
            })
        }
    },
    notifications: {
        no_image: {
            type: 'warn',
            title: 'No image',
            message: 'Add an image'
        },
        empty: {
            type: 'warn',
            title: 'Empty',
            message: 'City name cannot be empty'
        },
        success: {
            type: 'success',
            title: 'Added',
            message: 'A new city has been added'
        }
    }
}
</script>

<style scoped>
    .pop {
        background-color: white;
        position: relative;
        z-index: 4;
        width: 300px;
        padding: 20px;
        border-radius: 10px;
        animation: pop .3s;
        
    }
    @keyframes pop {
        from {
            transform: scale(.7);
        }
        to {
            transform: scale(1);
        }
    }
    .close {
        position: absolute;
        right: -10px;
        top: -10px;
        fill: var(--main);
        cursor: pointer;
    }

    .title {
        font-weight: 500;
    }
    .image {
        height: 150px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        position: relative;
    }
    img {
        height: 150px;
    }
    .change {
        background-color: var(--main);
        color: white;
        padding: 8px 20px;
        font-weight: 500;
        position: absolute;
        left: 0px;
        bottom: 0px;
        cursor: pointer;
    }
    .select {
        text-align: center;
        padding: 30px 20px;
        background-color: #edeef0;
        border-radius: 5px;
        margin-top: 20px;
        cursor: pointer;
        border: 1px dotted #ccc;
        font-size: 14px;
    }
    input {
        margin-top: 20px;
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 5px;
    }
    .btn {
        margin-top: 20px;
        background-color: var(--main);
        padding: 8px 30px;
        border-radius: 5px;
        margin-left: auto;
        width: fit-content;
        color: white;
        font-weight: 500;
        cursor: pointer;
    }
    .l {
        margin-top: 20px;
        margin-left: auto;
    }

    .hidden {
        display: none;
    }
</style>