<template>
    <div>
        
        <svg :width="size" :height="size" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <title/>
                <g>
                <path d="M28.71,6.29l-6-6A1,1,0,0,0,22,0H6A3,3,0,0,0,3,3V29a3,3,0,0,0,3,3H26a3,3,0,0,0,3-3V7A1,1,0,0,0,28.71,6.29ZM27,29a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V3A1,1,0,0,1,6,2H21V6a2,2,0,0,0,2,2h4Z"/>
                <path d="M19.43,16l1.27-1.27-1.41-1.41L18,14.57a4,4,0,0,0-1-.42V12H15v2.14a4,4,0,0,0-1,.42l-1.27-1.27-1.41,1.41L12.57,16a4,4,0,0,0-.42,1H10v2h2.14a4,4,0,0,0,.42,1l-1.27,1.27,1.41,1.41L14,21.43a4,4,0,0,0,1,.42V24h2V21.86a4,4,0,0,0,1-.42l1.27,1.27,1.41-1.41L19.43,20a4,4,0,0,0,.42-1H22V17H19.86A4,4,0,0,0,19.43,16ZM16,20a2,2,0,1,1,2-2A2,2,0,0,1,16,20Z"/>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>