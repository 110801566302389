<template>
    <div class="register">
        <div class="content">
            <div class="t">
                <div class="big">
                    Have a property to post?
                </div>
                <div class="text">Connect with people looking for properties to rent and buy</div>
            </div>
            <router-link to="/dashboard/agent/be" v-if="$root.$data.login">
                <div class="btn">Post Now</div>
            </router-link>
            <router-link to="/signup" v-else>
                <div class="btn">Register Now</div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .register {
        background: linear-gradient(to right, var(--main), royalblue);
    }
    .content {
        max-width: 1000px;
        margin: 0 auto;
        padding: 20px;
        gap: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        color: white;
    }

    .t {
        /* margin: 15px; */
        /* margin-left: 0px; */
    }
    .big {
        font-weight: 500;
        font-size: 30px;
    }
    .text {
        font-weight: 500;
        font-size: 14px;
    }

    .btn {
        
        background-color: white;
        color: var(--main);
        cursor: pointer;
        padding: 10px 30px;
        border-radius: 5px;
        font-weight: 500;
        text-align: center;
    }
</style>