<template>
    <div class="avaliable">
        <div class="main_title"> Set Avaliability</div>

        <div class="flex">
            <div>
                Is this property still available
            </div>

            <div class="fle">
                <loader :width="'60px'" :height="'35px'" v-if="loading"/>
                <div class="btn r" v-else @click="extend()">Yes</div>
                <div class="btn r" @click="pop = true">No</div>
            </div>
        </div>

        <div v-if="avaliable != 1" class="small f">
            This property has been unlisted, please confirm if this property is still available
        </div>
        <div v-else>
            <div v-if="unlisted" class="small f">
                This property has been unlisted, please confirm if this property is still available
            </div>

            <div class="f" v-else>
                <div class="small">
                    This property will be unlisted in
                </div>
                <div class="time">
                    <countdown :end="updated"/>
                </div>
            </div>
        </div>

        
        <div class="popup" v-if="pop">
            <div class="dark"></div>
            <div class="box">
                <div class="title">Remove Property</div>
                <div class="text">
                    Are you sure this property is no loger available
                </div>
                <div class="options">
                    <div class="btn" @click="pop = false">Cancel</div>

                    <loader :height="'37px'" v-if="load"/>
                    <div class="btn c" @click="remove()" v-else>Yes</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import loader from '../loader.vue'
import countdown from '../countdown.vue'

export default {
    props: ['prop_id', 'updated', 'avaliable'],
    components: {
        loader, countdown
    },
    data() {
        return {
            loading: false,
            load: false,

            unlisted: false,
            pop: false
        }
    },
    mounted() {
        
        let updated = new Date(this.updated)

        let now = new Date()

        if (now > updated) {
            this.unlisted = true
        }
    },
    methods: {
        extend() {
            this.loading = true
            let form = new FormData()
            form.append('prop_id', this.prop_id)

            this.$http.post('/property/extend', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.extended()
                    setTimeout(() => {
                        this.$router.go()
                    }, 3000);
                }
            })
        },
        remove() {
            this.load = true
            let form = new FormData()
            form.append('prop_id', this.prop_id)

            this.$http.post('/property/remove', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.removed()
                    setTimeout(() => {
                        this.$router.go()
                    }, 3000);
                }
            })
        }
    },
    notifications: {
        extended: {
            'type': 'success',
            'title': 'Extended',
            'message': 'This property has been extended'
        },
        removed: {
            'type': 'success',
            'title': 'Removed',
            'message': 'This property has been removed'
        }
    }
}
</script>

<style scoped>

    .avaliable {
        margin-bottom: 30px;
        background-color: white;
        padding: 20px;
        border-radius: 5px;
    }
    .main_title {
        font-weight: 500;
        font-size: 20px;
    }
    
    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        gap: 10px;
        margin-top: 20px;
    }
    .fle {
        display: flex;
        gap: 10px;
    }

    .btn {
        background-color: var(--trans);
        color: var(--main);
        cursor: pointer;
        padding: 8px 20px;
        border-radius: 5px;
        font-weight: 500;
        text-align: center;
    }

    .f {
        margin-top: 15px;
    }
    .small {
        font-size: 12px;
    }


    
    
    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        box-sizing: border-box;
        z-index: 1000;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
    }
    .box {
        background-color: white;
        position: relative;
        z-index: 1001;
        padding: 20px;
        border-radius: 10px;
    }
    .title {
        font-weight: 500;
        margin-bottom: 20px;
        font-size: 18px;
    }

    .options {
        display: grid;
        grid-template-columns: auto auto;
        gap: 20px;
        margin-top: 20px;
    }
    .options .btn {
        margin-top: 0px;
    }
    .c {
        background-color: var(--main);
        color: white;
    }
</style>