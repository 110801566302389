<template>
    <div class="s">
        <div class="p">
            <div class="pop">
                <div class="icon" :style="{'--c' : '#1877f2'}" @click="facebook()">
                    <facebook :size="'15px'" :style="{'height': '15px'}"/>
                </div>
                <div class="icon" :style="{'--c' : '#1da1f2'}" @click="twitter()">
                    <twitter :size="'15px'" :style="{'height': '15px'}"/>
                </div>
                <div class="icon" @click="telegram()">
                    <telegram :size="'15px'" :style="{'height': '15px'}"/>
                </div>
                <div class="icon" :style="{'--c' : '#25d366'}" @click="whatsapp()">
                    <whatsapp :size="'15px'" :style="{'height': '15px'}"/>
                </div>
                <div class="icon" @click="messenger()">
                    <messenger :size="'15px'" :style="{'height': '15px'}"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import share from '../icons/share.vue'

import facebook from '../icons/facebook.vue'
import twitter from '../icons/twitter.vue'
import telegram from '../icons/telegram.vue'
import whatsapp from '../icons/whatsapp.vue'
import messenger from '../icons/messenger.vue'

export default {
    components: {
        share,
        facebook, twitter, telegram, whatsapp, messenger
    },
    props: ['property'],
    data() {
        return {
            pop: false,
            sharing: {
                url: '',
                title: '',
                description: '',
                quote: ''
            }
        }
    },
    mounted() {
        this.sharing.url = 'https://www.bigglandlord.com/property/' + this.property.prop_id
        this.sharing.title = this.property.title
        this.sharing.description = this.property.description
    },
    methods: {
        facebook() {
            let u = this.sharing.url
            let t = this.sharing.title
            let d = this.sharing.description
            let link = `https://www.facebook.com/sharer/sharer.php?u=${u}&title=${t}&description=${d}`
        
            window.open(link, '_blank');
        },
        twitter() {
            let t = this.sharing.description
            let u = this.sharing.url
            let h = '#bigglandlord #realestate #property'
            let link = `https://twitter.com/intent/tweet?text=${t}&url=${u}&hashtags=${h}`

            window.open(link, '_blank');
        },
        whatsapp() {
            // let t = this.sharing.title
            let u = this.sharing.url
            let d = this.sharing.description
            let link = `https://api.whatsapp.com/send?text=${d}%0D%0A${u}`
            
            window.open(link, '_blank');
        },
        messenger() {
            let u = this.sharing.url
            let link = `fb-messenger://share/?link=${u}`
            window.open(link, '_blank');
        },
        telegram() {
            let u = this.sharing.url
            let t = this.sharing.title
            let d = this.sharing.description

            let link = `https://t.me/share/url?url=${u}&text=${t}%0D%0A${d}`
            window.open(link, '_blank');
        }
    }
}
</script>

<style scoped>
    .s {
        position: relative;
        /* z-index: 50; */
    }


    .pop {
        /* background-color: white; */
        /* margin-top: 15px; */
        border-radius: 5px;
        display: flex;
        gap: 15px;
    }
    .icon {
        cursor: pointer;
        fill: var(--c);
    }
</style>