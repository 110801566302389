<template>
    <div class="box">
        <div class="flex">
            <div class="tag">For {{a.category}}</div>
            <div>
                <div class="title">{{a.type}}</div>
                <div class="flex time">
                    <clock :size="'12px'" :style="{'heigth': '5px'}"/>
                    <div class="">
                        <timeago :date="a.date"/>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="location">
            <location :size="'12px'"/>
            {{a.city}} {{a.state}}
        </div>
        <div class="price">
            ₦{{a.min_price}} - ₦{{a.max_price}}
        </div>
    </div>
</template>

<script>
import clock from '../icons/time.vue'
import location from '../icons/location.vue'

import timeago from './time.vue'

export default {
    components: {
        clock,
        location,
        timeago
    },
    props: ['a']
}
</script>

<style scoped>
    .box {
        padding: 20px;
        background-color: white;
        border-radius: 5px;
    }
    .flex {
        display: flex;
        align-items: center;
        gap: 10px;
        fill: gray;
    }
    .tag {
        background-color: var(--main);
        color: white;
        font-weight: 500;
        padding: 5px 10px;
        font-size: 14px;
    }
    .title {
        /* font-size: 18px; */
        font-weight: 500;
    }
    .time {
        font-size: 10px;
        font-weight: 500;
        color: gray;
        line-height: 5px;
        gap: 5px;
    }
    .location {
        display: flex;
        gap: 10px;
        fill: gray;
        /* color: grey; */
        font-size: 14px;
        margin: 10px 0px;
        opacity: .8;
    }
    .price {
        font-size: 14px;
        /* color: grey; */
        opacity: .8;
        font-weight: 500;
    }
</style>