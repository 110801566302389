<template>
    <div class="properties">
        <div class="title">
            Properties
        </div>
        
        <div class="locations" v-for="city in cities" :key="city.name">
            <properties :city="city"/>
        </div>
    </div>
</template>

<script>
import properties from '../components/admin/property/properties.vue'
export default {
    components: {
        properties
    },
    data() {
        return {
            cities: [],
            show: false
        }
    },
    mounted() {
        
        this.$http.get('/properties/locations')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.cities = r.cities
            }
        })
    }
}
</script>

<style scoped>

    .properties {
        padding: 30px;
    }
    .title {
        font-size: 20px;
        font-weight: 600;
    }

</style>