<template>
    <div class="cities">
        <div class="title">
            Featured Project
        </div>
        <!-- <div v-masonry transition-duration="0.3s" 
            item-selector=".item" 
            horizontal-order="true"
            gutter="20"
            class="items">
        </div> -->
        

        <div class="loading" v-if="loading">
            <div class="spin"></div>
        </div>

        <swiper class="swiper" ref="swiper" :options="options" v-else>
            <swiper-slide v-for="banner in banners" :key="banner.name">
                <!-- <div class="grid"> -->
                    <banner  :banner="banner"/>
                <!-- </div> -->
            </swiper-slide>
            
            <div class="city-pagination" slot="pagination"></div>
        </swiper>
    </div>
</template>

<script>
import banner from './banner.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
    components: {
        banner,
        Swiper, SwiperSlide
    },
    created() {
        if (window.innerWidth < 600) {
            this.options.slidesPerView = 1; return 
        }
        if (window.innerWidth < 800) {
            this.options.slidesPerView = 2; return 
        }
    },
    mounted() {
        this.$http.post('banners')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.banners = r.banners
                this.loading = false
            }
        })
    },
    data() {
        return {
            loading: true,
            options: {
                slidesPerView: 3,
                spaceBetween: 30,
                loop: true,
                pagination: {
                    el: '.city-pagination',
                    clickable: true,
                },
                autoplay: true
            },
            banners: [],
            cities: [
                {
                    name: 'Lagos',
                    image: 'lagos.jpg',
                    properties: 100
                },
                {
                    name: 'Oyo',
                    image: 'oyo.jpg',
                    properties: 7
                },
                {
                    name: 'Osun',
                    image: 'osun.jpg',
                    properties: 10
                },
                {
                    name: 'Ogun',
                    image: 'ogun.jpg',
                    properties: 57
                }
            ]
        }
    }
}
</script>

<style scoped>


    .spin {
        margin: 70px auto;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 3px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    
    .cities {
        padding: 80px 20px;
    }
    .title {
        text-align: center;
        font-weight: 500;
        font-size: 25px;
        margin-bottom: 30px;
    }

    .grid {
        max-width: 900px;
        margin: 0 auto;
        margin-top: 50px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        grid-gap: 20px;
    }

    .swiper {
        max-width: 1000px;
    }
    .city-pagination {
        margin: 0 auto;
        margin-top: 10px;
        width: fit-content;
    }

    @media screen and (max-width: 450px) {
        .grid {
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

        }
    }
</style>