<template>
    <div class="properties">
        <div class="head">
            <div class="title">Agent Properties</div>
        </div>

        <div class="loader" v-if="loading">
            <div class="spin"></div>
        </div>
        <div class="empty" v-if="!loading && properties.length == 0">
            This agent currently have no properties
        </div>

        <div class="grid">
            <property v-for="property in properties" :key="property.prop_id"
                :property="property"/>
        </div>
    </div>
</template>

<script>
import property from './property.vue'

export default {
    components: {
        property
    },
    props: ['agent_id'],
    data() {
        return {
            loading: false,
            properties: []
        }
    },
    mounted() {
        
        this.loading = true

        let form = new FormData()
        form.append('user_id', this.agent_id)
        form.append('how', 'available')

        this.$http.post('/employee/agent/properties', form)
        .then(data => {
            let r = data.data
            if (r.success) {
                this.properties = r.properties
                this.loading = false
            }
        })
    }
}
</script>

<style scoped>

    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
    }
    .title {
        font-size: 18px;
        font-weight: 500;
    }

    

    .empty {
        text-align: center;
        font-size: 14px;
    }
    
    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-gap: 20px;
        padding: 20px;
        padding-top: 0px;
    }
    
    .spin {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 2px solid var(--main) ;
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
        margin: 50px auto;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    
</style>