<template>
    <div class="appointment">
        <property :property="property"/>
        
        
        <div class="content">

            <div class="title">Appointment Summary</div>
            <div class="f">
                <div class="label">Id</div>
                <div class="value">{{property.appt_id}}</div>
            </div>

            <div>
                <div class="t">Our Client ({{property.lastname}} {{property.firstname}}) Summary</div>
                <div class="f">
                    <div class="label">Success</div>
                    <div class="value">{{user_summary.success}}</div>
                </div>
                <div class="f">
                    <div class="label">Payment Type</div>
                    <div class="value">{{user_summary.payment_type}}</div>
                </div>
                <div v-if="user_summary.payment_type == 'full'">
                    <div class="f">
                        <div class="label">Account Name</div>
                        <div class="value">{{ user_summary.full_payment.acct_name }}</div>
                    </div>
                    <div class="f">
                        <div class="label">Amount Paid</div>
                        <div class="value">{{user_summary.full_payment.amount}}</div>
                    </div>
                    <div class="f">
                        <div class="label">Commission Paid</div>
                        <div class="value">{{user_summary.full_payment.commission}}</div>
                    </div>
                </div>
                <div v-if="user_summary.payment_type == 'part'">
                    <div class="f">
                        <div class="label">Account Name</div>
                        <div class="value">{{ user_summary.part_payment[0].acct_name }}</div>
                    </div>
                    <div class="f">
                        <div class="label">Total Amount</div>
                        <div class="value">{{ user_summary.part_payment[0].total_amount }}</div>
                    </div>
                    <div class="f">
                        <div class="label">Amount Paid</div>
                        <div class="value">{{ user_summary.part_payment[0].amount_paid }}</div>
                    </div>
                </div>
                
                <div v-if="user_summary.part_payment.length == 2">
                    <div class="f up">
                        <div class="value">Client Second Payment</div>
                    </div>
                    <div class="f">
                        <div class="label">Account Name</div>
                        <div class="value">{{ user_summary.part_payment[1].acct_name }}</div>
                    </div>
                    <div class="f">
                        <div class="label">Balance Paid</div>
                        <div class="value">{{ user_summary.part_payment[1].balance }}</div>
                    </div>
                    <div class="f">
                        <div class="label">Commission Paid</div>
                        <div class="value">{{ user_summary.part_payment[1].commission }}</div>
                    </div>
                </div>
            </div>
            

            <div class="timeline">
                <div class="each" :class="[property.payment_confirmed ? 'a' : '']">
                    <checked :size="'15px'" :style="{'height': '15px'}" v-if="property.payment_confirmed"/>
                    <loading :size="'15px'" class="spin" :style="{'height': '15px'}" v-else/>
                    <div>Payment recieved and confirmed</div>
                </div>

                <div class="each" :class="[property.details_confirmed ? 'a' : '']">
                    <checked :size="'15px'" :style="{'height': '15px'}" v-if="property.details_confirmed"/>
                    <loading :size="'15px'" class="spin" :style="{'height': '15px'}" v-else/>
                    <div>Client details submitted and confirmed</div>
                </div>

                <div class="each" :class="[property.payment_forwarded ? 'a' : '']">
                    <checked :size="'15px'" :style="{'height': '15px'}" v-if="property.payment_forwarded"/>
                    <loading :size="'15px'" class="spin" :style="{'height': '15px'}" v-else/>
                    <div>Payment and details forwarded to you</div>
                </div>
            </div>

            <div class="message">
                <div class="btn" @click="show = true">Message Bigglandlord</div>

                <div v-if="show">
                    <textarea placeholder="Enter you message" v-model="text"></textarea>

                    <div class="sent" v-if="sent">
                        Your message has been sent, Bigglandlord will respond to you via email or whatsapp
                    </div>

                    <loader :height="'37px'" v-if="loading"/>
                    <div class="btn click" v-else @click="message()">Send</div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>


import loading from '../../icons/loading.vue'
import checked from '../../icons/check.vue'

import loader from '../loader.vue'
import property from '../property.vue'

export default {
    components: {
        loading, checked,
        loader, property
    },
    props: ['property'],
    computed: {
        url() {
            return this.$root.$data.imgurl
        }
    },
    mounted() {

        this.price = JSON.parse(this.property.price)
        this.images = JSON.parse(this.property.images)

        this.user_summary = JSON.parse(this.property.user_summary)

        // if ( this.property.partner_summary != '' ) {
        //     this.partner_summary = JSON.parse(this.property.partner_summary)
        // }
    },
    data() {
        return {
            options: {
                slidesPerView: 1,
                // loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                    hideOnClick: true
                },
            },
            price: {},
            images: {},
            user_summary: {},
            partner_summary: {},
            show: false,
            sent: false,
            loading: false,
            text: ''
        }
    },
    methods: {
        message() {
            if (this.text == '') { this.empty(); return }

            this.loading = true
            this.sent = false

            let form = new FormData()
            form.append('text', this.text)
            form.append('appt_id', this.property.appt_id)
            form.append('from', 'agent')

            this.$http.post('/user/message', form)
            .then( data => {
                let r = data.data
                if (r.success) {
                    this.loading = false
                    this.sent = true
                    this.text = ''
                    this.sent_noti()
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: 'Empty',
            message: 'Please type a message'
        },
        sent_noti: {
            type: 'success',
            title: 'Sent',
            message: 'Your message has been sent, We will get in touch with you soon'
        }
    }
}
</script>

<style scoped>
    .appointment {
        background-color: white;
        border-radius: 5px;
        overflow: hidden;
    }

    .content {
        padding: 15px;
        border-top: 1px solid #ddd;
    }



    .btn {
        background-color: var(--trans);
        color: var(--main);
        cursor: pointer;
        padding: 8px 20px;
        border-radius: 5px;
        font-weight: 500;
        text-align: center;
    }

    .title {
        text-align: center;
        margin-bottom: 10px;
        font-weight: 500;
    }
    .t {
        font-size: 14px;
        font-weight: 500;
        background-color: var(--main);
        padding: 10px 15px;
        color: white;
        font-weight: 500;
        margin: 0px -15px;
        margin-top: 20px;
    }
    .f {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;
    }
    .label {
        font-size: 12px;
    }
    .value {
        font-weight: 500;
        text-align: right;
    }

    .up {
        margin-top: 20px;
    }

    
    .timeline {
        /* display: flex; */
    }
    .each {
        /* text-align: center; */
        font-size: 12px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 15px;
        background-color: #edeef0;
        margin-top: 15px;
        padding: 15px;
        border-radius: 5px;
    }
    .a {
        fill: var(--main);
        background-color: var(--trans);
        color: var(--main);
    }
    .spin {
        width: fit-content;
        /* margin: 0 auto; */
        animation: spin 2s linear infinite;
        fill: var(--main);
    }
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }


    .message {
        margin-top: 20px;
    }
    textarea {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-top: 20px;
        margin-bottom: 20px;
        box-sizing: border-box;
        width: 100%;
        height: 150px;
    }
    .click {
        background-color: var(--main);
        color: white;
    }
    .sent {
        background-color: #edeef0;
        font-weight: 500;
        font-size: 14px;
        padding: 15px;
        margin: 15px -15px;
        margin-top: 0px;
    }
</style>