<template>
    <div class="footer">
        <div class="content">
            <div class="c">
                <div class="title">About</div>
                <div class="text">
                    Bigglandlord... realty made easy.
                    An all-in-one realty solutions provider
                </div>
            </div>
            <div class="c">
                <div class="title">Links</div>
                <div class="links">
                    <router-link to="/about">
                        <div class="link">About Us</div>
                    </router-link>
                    <!-- <div class="link">Contact</div> -->
                    <router-link to="/properties">
                        <div class="link">Properties</div>
                    </router-link>
                    <!-- <div class="link">Agents</div> -->
                    <router-link to="/terms">
                        <div class="link">Terms and Conditions</div>
                    </router-link>
                </div>
            </div>
            <div class="c">
                <div class="title">Contact Us</div>
                <div class="info">
                    <div class="i">
                        <email :size="'12px'" class="icon"/>
                        info@bigglandlord.com
                    </div>
                    <div class="i">
                        <location :size="'12px'" class="icon"/>
                        Ikotun Lagos
                    </div>
                    <div class="i">
                        <phone :size="'12px'" class="icon"/>
                        08144680989 08141981870
                    </div>
                </div>
            </div>
            <div class="c">
                <div class="title">Follow Us</div>
                <div class="follow">
                    <div class="f">
                        <facebook :size="'30px'" class="icon fi"/>
                    </div>
                    <div class="f">
                        <instagram :size="'30px'" class="icon fi"/>
                    </div>
                    <div class="f">
                        <twitter :size="'30px'" class="icon fi"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="f_text">
            © 2023 Bigglandlord. Buy, Rent and Sell like never before.
        </div>
    </div>
</template>

<script>
import email from '../icons/inbox.vue'
import location from '../icons/location.vue'
import phone from '../icons/call.vue'

import facebook from '../icons/facebook.vue'
import instagram from '../icons/instagram.vue'
import twitter from '../icons/twitter.vue'
export default {
    components: {
        email, location, phone,
        facebook, instagram, twitter
    }
}
</script>

<style scoped>
    .footer {
        background-color: #232733;
        color: white;
    }

    .content {
        max-width: 1000px;
        margin: 0 auto;
        padding: 50px 20px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-gap: 30px;
    }
    .title {
        font-weight: 500;
        margin-bottom: 20px;
        font-weight: 20px;
    }

    .text {
        opacity: .5;
        line-height: 30px;
    }
    .link, .i {
        margin-bottom: 10px;
        opacity: .5;
    }
    .i {
        display: flex;
        align-items: center;
    }
    .icon {
        fill: white;
        opacity: .5;
        margin-right: 10px;
    }

    .follow {
        display: flex;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;
    }
    .fi {
        /* margin: 0; */
        cursor: pointer;
    }

    .f_text {
        padding: 20px;
        text-align: center;
        border-top: 1px solid #ccc;
        opacity: .5;
    }
</style>