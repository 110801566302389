<template>
    <div class="appoint">
        <div class="title">My Properties Appointment</div>
        
        <div class="menu">
            <div class="item" :class="[c == 0 ? 'a' : '']" @click="setTo(0)">Upcoming</div>
            <div class="item" :class="[c == 1 ? 'a' : '']" @click="setTo(1)">Current</div>
            <div class="item" :class="[c == 2 ? 'a' : '']" @click="setTo(2)">All</div>
        </div>

        <div class="spin" v-if="load"> </div>

        <div class="main" v-else>
            
            <div v-if="c == 0">
                <div class="grid">
                    <agent_upcoming v-for="a in upcoming" :key="a.appt_id" :property="a"/>
                </div>

                <div class="no" v-if="upcoming.length == 0 && !load">
                    <appointmenticon :size="'30px'"/>
                    <div>There are currently no upcoming appointments</div>
                </div>
            </div>

            <div v-if="c == 1">
                <div class="grid">
                    <agent v-for="a in current" :key="a.appt_id" :property="a"/>
                </div>
                
                <div class="no" v-if="current.length == 0 && !load">
                    <appointmenticon :size="'30px'"/>
                    <div>You don't have any appointment at the moment</div>
                </div>
            </div>

            <div v-if="c == 2">
                <div class="grid">
                    <agent_success v-for="a in success" :key="a.appt_id" :property="a"/>
                </div>
                
                <div class="no" v-if="success.length == 0 && !load">
                    <appointmenticon :size="'30px'"/>
                    <div>You don't have any appointment at the moment</div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import agent_upcoming from '../components/appointment/agent_upcoming.vue'
import agent from '../components/appointment/agent.vue'
import agent_success from '../components/appointment/agent_success.vue'

import appointmenticon from '../icons/appointment.vue'

export default {
    components: {
        agent_upcoming, agent, agent_success,
        appointmenticon
    },
    mounted() {
        if (this.$root.$data.agent != 'ok') {
            this.$router.push('/dashboard')
        }

        this.fetch_upcoming()
    },
    data() {
        return {
            c: 0,
            load: true,
            upcoming: [],
            current: [],
            success: []
        }
    },
    methods: {
        setTo(c) {
            this.c = c
            this.load = true

            if (c == 0) { this.fetch_upcoming() }
            if (c == 1) { this.fetchCurrent() }
            if (c == 2) { this.fetch_succ() }
        },

        fetch_upcoming() {
            this.$http.get('/agent/appointments/upcoming')
            .then(data => {
                let r = data.data
                this.upcoming = r.properties
                this.load = false
            })
        },
        fetchCurrent() {

            this.$http.get('/agent/appointments')
            .then(data => {
                let r = data.data
                this.current = r.properties
                this.load = false
            })
        },
        fetch_succ() {
            this.$http.get('/agent/appointments/successful')
            .then(data => {
                let r = data.data

                if (r.success) {
                    this.success = r.property

                    this.load = false
                }
            })
        }
    }
}
</script>

<style scoped>

.appoint {
        padding: 20px;
    }
    .title {
        font-weight: 500;
        font-size: 20px;
    }

    
    .menu {
        display: grid;
        grid-template-columns: 33.33% 33.33% 33.33%;
        background-color: white;
        /* border-radius: 5px; */
        overflow: hidden;

        margin: 0px -20px;
        margin-top: 20px;
        position: sticky;
        top: 0px;
        z-index: 3;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .item {
        text-align: center;
        font-weight: 500;
        padding: 15px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        font-size: 14px;
    }
    .a {
        border-bottom-color: var(--main);
        color: var(--main);
    }


    .spin {
        margin: 70px auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: var(--main);
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    
    .no {
        padding-top: 80px;
        fill: gray;
        text-align: center;
        font-size: 14px;
    }

    .grid {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-gap: 30px;
    }


</style>