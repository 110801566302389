<template>
    <div class="box">
        <div class="label">Agent</div>

        <div class="bold" v-if="agent == ''">Become an Agent</div>
        <div class="bold" v-if="agent == 'ok'">You are already an agent</div>
        <div class="bold" v-if="agent == 'processing'">Application Processing</div>
        <div class="bold" v-if="agent == 'block'">You are already an agent</div>

        <div class="flex" v-if="agent == ''">
            <div class="text">
                Are you in charge of a property, become an agent now and get the oppurtunity to 
                share your property on Bigglandlord
            </div>
            <router-link to="/dashboard/agent/be">
                <div class="btn">
                    Register
                </div>
            </router-link>
        </div>

        <div class="f" v-if="agent == 'ok'">
            <router-link to="/dashboard/agent/submit">
                <div class="btn btn2">Submit A Property</div>
            </router-link>
            <router-link to="/dashboard/agent/properties">
                <div class="btn btn2 up">View Your Properties</div>
            </router-link>
        </div>

        <div class="processing" v-if="agent == 'processing'">
            Your registration details is under review, we will send you a response shortly
        </div>

        <div class="f" v-if="agent == 'block'">
            <router-link to="/dashboard/agent/properties">
                <div class="btn btn2 up">View Your Properties</div>
            </router-link>
        </div>
        
    </div>
</template>

<script>
export default {
    props: ['agent']
}
</script>

<style scoped>

    .box {
        background-color: white;
        padding: 20px 0px ;
        border-radius: 10px;
        overflow: hidden;
    }
    .label {
        background-color: var(--main);
        padding: 10px 20px;
        color: white;
        font-weight: 500;
        width: fit-content;
    }

    .bold {
        margin: 50px 0px;
        text-align: center;
        font-weight: 500;
        font-size: 20px;
    }
    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px;
    }
    .text {
        font-size: 14px;
    }
    
    .btn {
        background-color: var(--trans);
        color: var(--main);
        padding: 8px 20px;
        text-align: center;
        cursor: pointer;
        font-weight: 500;
        border-radius: 5px;
        margin-left: 15px;
        box-sizing: border-box;
    }
    .btn2 {
        margin: 0px 20px;
    }
    .up {
        margin-top: 20px;
    }

    .processing {
        
        background-color: #edeef0;
        padding: 20px;
    }
</style>