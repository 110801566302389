<template>
    <div class="description">
        <div class="title">Description</div>
        <div class="text">
            {{description}}
        </div>
    </div>
</template>

<script>
export default {
    props: ['description']
}
</script>

<style scoped>

    .description {
        margin-bottom: 30px;
        background-color: white;
        padding: 20px;
        border-radius: 5px;
    }
    .title {
        font-weight: 500;
        font-size: 20px;
    }
    .text {
        opacity: .7;
        margin-top: 20px;
        font-size: 14px;
    }
</style>