<template>
    <div class="dashboard">
        <headerLayout class="h" :no_bg="true"/>

        <div class="loading" v-if="loading">
            <div class="spin"></div>
        </div>
        <div class="grid" v-else>
            <menuLayout class="web"/>
            
            <div class="main">
                <router-view></router-view>
            </div>

            <div class="mobile">
                <mobileMenu/>
            </div>
            
        </div>
    </div>
</template>

<script>
import headerLayout from '../components/header.vue'
import menuLayout from '../components/menu.vue'
import mobileMenu from '../components/mobile.vue'
export default {

    components: {
        headerLayout,
        menuLayout, mobileMenu
    },
    data() {
        return {
            loading: true
        }
    },
    mounted() {
        if (!this.$root.$data.login) {
            this.$router.push('/login')
            
            // this.login_first()

        } else {
            this.$http.get('/my_info')
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.$root.$data.agent = r.agent.length > 0 ? r.agent[0].status : ''
                    this.$root.$data.partner = r.partner
                    this.$root.$data.is_partner = r.is_partner
                    this.$root.$data.is_manager = r.is_manager
                    this.$root.$data.current_appointment = r.appointment

                    this.loading = false
                }
            })
        }
    },
    notifications: {
        login_first: {
            type: 'warn',
            title: 'tr',
            message: 'Login first'
        }
    }
}
</script>

<style scoped>

    .h {
        /* position: fixed; */
        /* width: 100%; */
        /* box-sizing: border-box; */
        /* top: 0px;
        left: 0px; */
        z-index: 5;
        color: white;
    }
    .loading {
        height: calc(100vh - 81px);
        background-color: #edeef0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .grid {
        display: grid;
        grid-template-columns: max-content auto;
        height: calc(100vh - 81px);
    }
    
    .spin {
        margin: 0 auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: var(--main);
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .main {
        background-color: #edeef0;
        height: 100%;
        overflow: auto;
    }

    .mobile {
        display: none;
    }
    @media screen and (max-width: 700px) {
        .grid {
            display: block;
            height: calc(100vh - 74px);
        }
        .main {
            height: calc(100% - 57px);
        }
        .web {
            display: none;
        }
        .mobile {
            display: block;
        }
    }

</style>