<template>
    <div class="home" ref="home">
        <headerLayout class="h"/>
        <div class="banner">
            <div class="dark"></div>
            <div class="content">
                <div class="big">Find Your Dream Property</div>
                <div class="small">We have almost any property you can think of</div>
                <div class="menu">
                    <div class="item f" @click="category = 'Short Let'" 
                        :class="[category == 'Short Let' ? 'a' : '']">Short Let</div>

                    <div class="item f" @click="category = 'Let'"
                        :class="[category == 'Let' ? 'a' : '']">Let</div>

                    <div class="item f" @click="category = 'Sale'"
                        :class="[category == 'Sale' ? 'a' : '']">Sale</div>

                    <div class="item" @click="category = 'Lease'"
                        :class="[category == 'Lease' ? 'a' : '']">Lease</div>

                    <!-- <div class="item">Lease</div> -->
                </div>
                <div class="box">
                    <div class="location" @click="pri = true">
                        {{price}}
                        <div class="close" @click="remove_price()" v-if="price != 'Set price range'">
                            <close :size="'15px'"/>
                        </div>
                    </div>
                    <div class="location" @click="pop = true">
                        {{place}}
                    </div>

                    <select v-model="type">
                        <option value="" disabled selected>Property Type</option>
                        <option v-for="t in types" :key="t" :value="t">{{t}}</option>
                    </select>

                    <!-- <div class="input">
                        <input type="text" placeholder="Rooms" list="rooms">
                    </div> -->
                    <div class="btn" @click="search()">Search</div>
                </div>
                <!-- <div class="p">
                    <div class="title">Popular Tags</div>
                    <div class="tags">
                        <div class="tag">Single Room</div>
                        <div class="tag">Room and Parlour</div>
                        <div class="tag">Single Room Self Contain</div>
                        <div class="tag">Room and Parlour Self Contain</div>
                        <div class="tag">Two Room Flat</div>
                        <div class="tag">Three Room Flat</div>
                    </div>
                </div> -->
            </div>
        </div>
        
        <!-- <datalist id="property">
            <option value="Apartment" />
            <option value="Condo" />
            <option value="Family House" />
            <option value="Modern Villa" />
            <option value="Town House" />
        </datalist> -->

        <datalist id="rooms">
            <option value="1" />
            <option value="2" />
            <option value="3" />
            <option value="4" />
            <option value="5" />
        </datalist>

        <banners/>

        <div class="featured">
            <div class="title">Featured Properties</div>

            
            <div v-masonry transition-duration="0.3s" 
                item-selector=".man_item" 
                horizontal-order="true"
                gutter="30"
                class="items">
                <div v-masonry-tile class="man_item" v-for="property in featured" :key="property.prop_id">
                    <property :property="property"/>
                </div>
            </div>


            <div class="loading" v-if="loading">
                <div class="spin"></div>
            </div>

            <div class="btn more" v-if="!loading" @click="more()">Load More</div>
        </div>

        <looking/>

        <!-- <div class="agents">
            <div class="title">Our Agents</div>
            <div class="grid">
                <agent/>
                <agent/>
                <agent/>
                <agent/>
                <agent/>
            </div>
        </div> -->

        <register/>
        <footerLayout/>

        <div class="popup" v-if="pop">
            <div class="dark2" @click="pop = false"></div>
            <location @close="pop = false" @save="set_location($event)"/>
        </div>

        <div class="popup" v-if="pri">
            <div class="dark2" @click="pri = false"></div>
            <prices @close="pri = false" @save="set_price($event)"/>
        </div>
    </div>
</template>

<script>
import headerLayout from '../components/header.vue'
import location from '../components/location.vue'
import prices from '../components/prices.vue'

import close from '../icons/remove.vue'

import banners from '../components/banners/banners.vue'
import property from '../components/property.vue'
import looking from '../components/looking.vue'

import agent from '../components/agent/agent.vue'
// import partners from '../components/partners.vue'
import register from '../components/register.vue'
import footerLayout from '../components/footer.vue'

export default {
    name: 'Home',
    components: {
        headerLayout,
        location, prices,
        close,
        banners, property,
        looking,
        agent, register, footerLayout
    },
    mounted () {
        window.scrollTo(0, 0)
    },
    data() {
        return {
            pop: false,
            pri: false,
            place: 'Set Location',
            price: 'Set price range',
            prices: {
                min: 0,
                max: 1000000000000
            },
            location: {},
            featured: [],
            loading: true,
            category: 'Let',
            type: '',
            properties: [],
            types: []
        }
    },
    watch: {
        category() {
            this.types = JSON.parse(this.properties[this.category])
            this.type = ''
        }
    },
    methods: {
        set_location(e) {
            this.location = e.l
            this.place = `${e.l.city + ' ' + e.l.state + ', ' + e.l.country}`
            this.pop = false

            this.properties = e.p
            this.types = JSON.parse(this.properties[this.category])

            this.type = ''
        },
        remove_price() {
            this.price = 'Set price range'
            this.prices = {
                min: 0,
                max: 1000000000000
            }
        },
        set_price(e) {
            this.price = `${e.min} - ${e.max}`

            this.prices = e

            this.pri = false
        },
        search() {
            if (this.place == 'Set Location') { this.loc_err(); return }
            if (this.type == '') { this.type_err(); return }

            this.$router.push({
                path: '/properties',
                query: {
                    'city': this.location.city,
                    'state': this.location.state,
                    'country': this.location.country,
                    'category': this.category,
                    'type': this.type,
                    'min': this.prices.min,
                    'max': this.prices.max
                }
            })

            this.place = 'Set Location'
            this.price = 'Set price range'
            this.prices = {
                min: 0,
                max: 1000000000000
            }
            this.type = ''
            this.category = 'Let'
            this.location = {}
        },
        more() {
            this.loading = true
            
            let last = this['featured'].slice(-1).pop()
            if (last == undefined) { return }
            
            let date = last.date

            let form = new FormData()
            form.append('date', date)

            this.$http.post('/properties/featured', form)
            .then( data => {
                let r = data.data
                if (r.success) {
                    this.featured = this.featured.concat(r.properties)
                    this.loading = false
                }
            })
        }
    },
    mounted() {
        let form = new FormData()
        form.append('date', '')

        this.$http.post('/properties/featured', form)
        .then( data => {
            let r = data.data
            if (r.success) {
                this.featured = r.properties
                this.loading = false
            }
        })
    },
    activated() {
        setTimeout(() => {
            this.$redrawVueMasonry()
        }, 500);
    },
    notifications: {
        loc_err: {
            type: 'warn',
            title: 'Location',
            message: 'Please select a location'
        },
        type_err: {
            type: 'warn',
            title: 'Type',
            message: 'please choose a property type'
        }
    }
}
</script>

<style scoped>
    .home {
        /* height: 100vh; */
        /* overflow: auto; */
    }
    .h {
        position: fixed;
        width: 100%;
        box-sizing: border-box;
        top: 0px;
        left: 0px;
        z-index: 5;
        color: white;
    }
    .banner {
        min-height: 150vh;
        width: 100%;
        background: url('../assets/images/bg2.jpg');
        background-position: center;
        background-size: cover;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dark {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 2;
    }

    .content {
        color: white;
        position: relative;
        z-index: 3;
        text-align: center;
        padding: 20px;
        width: 100%;
    }
    .big {
        font-size: 40px;
        font-weight: 600;
    }

    .menu {
        display: flex;
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 30px;
        width: fit-content;
    }
    .item {
        padding: 10px 30px;
        background-color: white;
        color: var(--main);
        border-radius: 5px;
        cursor: pointer;
        font-weight: 500;
        transition: .3s;
    }
    .f {
        margin-right: 20px;
    }
    .a {
        background-color: var(--main);
        color: white;
        position: relative;
    }
    .a:after {
        content: ' ';
        width: 0;
        height: 0;
        position: absolute;
        bottom: -20px;
        left: calc(50% - 10px);
        border: 10px solid;
        border-color: var(--main) transparent transparent transparent;
    }

    .box {
        background-color: white;
        padding: 15px;
        border-radius: 5px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        grid-gap: 15px;
        align-items: center;
        width: 80%;
        margin: 0 auto;
    }
    .location {
        color: black;
        border: 1px solid #ccc;
        padding: 13px;
        border-radius: 5px;
        cursor: pointer;
        text-align: left;
        font-size: 14px;
        position: relative;
        /* font-weight: 500; */
    }
    .close {
        position: absolute;
        right: 5px;
        top: 5px;
        fill: var(--main);
    }
    input, select {
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 15px;
        box-sizing: border-box;
        width: 100%;
    }
    .btn {
        padding: 12px 30px;
        color: white;
        background-color: var(--main);
        border-radius: 5px;
        cursor: pointer;
        font-weight: 500;
    }


    .p {
        margin-top: 30px;
    }
    .title {
        font-weight: 500;
        font-size: 20px;
    }
    .tags {
        max-width: 700px;
        margin: 0 auto;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .tag {
        padding: 8px 20px;
        background-color: white;
        color: black;
        border-radius: 5px;
        margin: 10px;
        /* font-weight: 500; */
        font-size: 14px;
        cursor: pointer;
        transition: .2s;
    }
    .tag:hover {
        background-color: var(--main);
        color: white;
    }

    .featured {
        background-color: #edeef0;
        padding: 50px 20px;
    }
    .title {
        text-align: center;
        font-size: 25px;
        margin-bottom: 30px;
    }

    .spin {
        margin: 70px auto;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 3px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .items {
        max-width: 1000px;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 40px;
    }
    .man_item {
        width: calc(33.33% - 20px);
        margin-bottom: 30px;
    }

    @media screen and (max-width: 850px) {
        .man_item {
            width: calc(50% - 20px);
        }
    }
    @media screen and (max-width: 650px) {
        .man_item {
            width: calc(100%);
        }
    }
    /* @media screen and (max-width: 1000px) {
        .item {
            width: calc(33.33% - 13.3px);
        }
    }
    @media screen and (max-width: 900px) {
        .item {
            width: calc(50% - 13.3px);
        }
    }
    @media screen and (max-width: 650px) {
        .item {
            width: 100%;
        }
    } */


    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        grid-gap: 30px;
        max-width: 1000px;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 40px;
    }

    .more {
        margin: 0 auto;
        width: fit-content;
    }

    .agents {
        background-color: #edeef0;
        padding: 50px 20px;
    }

    @media screen and (max-width: 450px) {
        .content{
            margin: 100px 0px;
        }
    }
    @media screen and (max-width: 530px) {
        .item {
            padding: 10px 15px;
            font-size: 14px;
        }
        .f {
            margin-right: 10px;
        }
    }

      
    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        box-sizing: border-box;
        z-index: 5;
    }
    .dark2 {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 2;
    }
</style>