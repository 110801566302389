<template>
    <div id="app" :style="{'--main': c.main, '--trans': c.trans, '--trans2': c.trans2}">
        <keep-alive max="10" :include="['Home']">
            <router-view v-if="ready"/>
        </keep-alive>
    </div>
</template>

<script>

import { getToken, onMessage } from "firebase/messaging";

import 'vue-croppa/dist/vue-croppa.css';

import Localbase from 'localbase'
export default {
    computed: {
        c() {
            return this.$root.$data.c
        }
    },
    data() {
        return {
            db: new Localbase('Bigglandlord'),
            ready: false
        }
    },
    created() {
        try {
            onMessage(this.$messaging, (payload) => {
                console.log('Message received. ', payload);
            });
        } catch (e) {
            console.error("Error : ", e);
        }
    },
    mounted() {
        
        
        console.log('Firebase cloud messaging object', this.$messaging)

        
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                console.log('Notification permission granted.');
        
                getToken(this.$messaging, { vapidKey: 'BB-Tuy5b8ucRRg3zje8EhKLb-DG4Qat3gcGdi4SJLu-MbuGWduBlkyVfa7Nc7q42lWNwnzfYlWs-wVLEpd88tmk' }).then((currentToken) => {
                if (currentToken) {
                    
                    console.log(currentToken);
                } else {
                    // Show permission request UI
                    console.log('No registration token available. Request permission to generate one.');
                    // ...
                }
                }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                })
            }
        })
        // 
        

        this.db.collection('auth').doc({ id: 1 }).get().then(document => {
            if (document == undefined) {
                this.$root.$data.login = false
            } else {
                this.$root.$data.login = true
                this.$root.$data.firstname = document.firstname
                this.$root.$data.lastname = document.lastname
                this.$root.$data.dp = document.dp == undefined ? '' : document.dp
                this.$root.$data.phone = document.phone
                this.$root.$data.whatsapp = document.whatsapp
                this.$root.$data.user_id = document.user_id
                this.$root.$data.email = document.email
                this.$http.defaults.headers.common['Auth'] = document.key
                
                let email = localStorage.getItem('email')
                
                if (email != null) { 
                    this.$router.push('/verify')
                } else {
                    localStorage.removeItem('email')
                }

                this.$tawkMessenger.$on('load', () => {
                    this.$tawkMessenger.setAttributes({
                        name : document.firstname + ' ' + document.lastname,
                        email : document.email,
                        phone: document.phone,
                        whatsapp: document.whatsapp
                    });
                });

            }
            this.ready = true
        })
    }
}
</script>

<style>

@import 'assets/css/vue-select.css';
@import 'assets/css/swiper.css';

@font-face {
    font-family: 'Montserrat', sans-serif;
    src: url('/assets/fonts/montserrat.ttf');
}

:root {
    --swiper-theme-color: rgb(255, 128, 0) !important;
}
body {
    padding: 0px;
    margin: 0px;
}
#app {
    /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
    
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
textarea, input, select {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    resize: none;
    font-weight: 500;
}
a {
    color: inherit;
    text-decoration: none;
    /* color: #ff8000; */
}
select {
    background-color: white;
}
.is-fullscreen {
    margin-top: 0px !important;
}
.flickity-enabled.is-fullscreen {
    z-index: 7 !important;
}
.flickity-button {
    /* background-color: transparent !important; */
}

.full_img {
    max-height: 100vh !important;
    width: unset !important;
    max-width: 100vw !important;

    /* max-height: 100% !important;
    width: unset !important;
    width: 100% !important; */
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

</style>
