<template>
    <div class="box">
        <div class="icon">
            <appointment :size="'40px'"/>
        </div>
        <div class="title">
            Your have booked appointment for this property
        </div>

        <div v-if="ready && appointment.status == 'ok'">
            <div class="bold">Are you ready to check this property?</div>
            <router-link to="/dashboard/appointments">
                <div class="btn">Choose a Partner</div>
            </router-link>
        </div>

        <div class="booked" v-if="!ready">
            You have successfully booked an appointment to inspect this property
        </div>

        <!-- <div class="booked">
            you have booked an appointment with one of our partner <b> {{appointment.name}} </b> to inspect 
            and <b>{{category}}</b> this property. 
            <b>{{appointment.name}}</b> contact details will be reveal once our partner <b> {{appointment.name}} </b>
            respond to this appointment.
        </div> -->

        <div class="flex">
            <div class="text">Appointment Time</div>
            <div class="ready" v-if="ready">Now</div>
            <countdown :deadline="appointment.date" v-else/>
        </div>

        <div v-if="appointment.status == 'ok'">
            <loader :height="'38px'" v-if="loading"/>
            <div class="btn" @click="cancel()" v-else>Cancel Appointment</div>
        </div>
    </div>
</template>

<script>
import appointment from '../../icons/appointment.vue'
import countdown from '../countdown.vue'
import loader from '../loader.vue'
export default {
    components: {
        appointment, countdown, loader
    },
    props: ['appointment', 'category'],
    data() {
        return {
            loading: false,
            ready: false
        }
    },
    mounted() {
        let now = new Date()

        let appt = new Date(this.appointment.date)

        if (now > appt) {
            this.ready = true
        }
    },
    methods: {
        cancel() {
            this.loading = true
            let form = new FormData()
            form.append('user_id', this.$root.$data.user_id)
            form.append('appt_id', this.appointment.appt_id)

            this.$http.post('/appointment/cancel', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    this.loading = false
                    this.$emit('canceled')
                    this.canceled()
                }
            })
        }
    },
    notifications: {
        canceled: {
            type: 'success',
            title: 'Cancelled',
            message: 'This appointment has been cancelled'
        }
    }
}
</script>

<style scoped>

    .box {
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        /* width: 250px; */
        height: fit-content;
        margin-bottom: 30px;
    }
    .icon {
        fill: var(--main);
        text-align: center;
    }
    .title {
        font-weight: 500;
        text-align: center;
        margin-top: 10px;
        /* font-size: 20px; */
    }

    .bold {
        font-size: 12px;
        font-weight: 500;
        margin-top: 20px;
        margin-bottom: 5px;
    }

    .booked {
        
        margin-top: 15px;
        font-size: 14px;
    }

    .flex {
        background-color: var(--trans);
        padding: 10px 20px;
        margin: 20px -20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .text {
        font-size: 14px;
        font-weight: 500;
    }
    .ready {
        font-weight: 500;
        background-color: var(--main);
        color: white;
        padding: 5px;
        border-radius: 5px;
        font-size: 14px;
    }
    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
        /* margin-top: 20px; */
        text-align: center;
    }
</style>