<template>
    <div class="properties">
        <div class="head">
            <div class="title">Agent Properties</div>
        </div>
        <div class="menu">
            <div class="item" :class="[c == 0 ? 'a' : '']" @click="available()">Available</div>
            <div class="item" :class="[c == 1 ? 'a' : '']" @click="all()">All</div>
            <div class="item" :class="[c == 2 ? 'a' : '']" @click="c = 2">Add Property</div>
        </div>
        
        <div class="loader" v-if="loading">
            <div class="spin"></div>
        </div>
        <div class="empty" v-if="!loading && properties.length == 0">
            This agent currently have no properties
        </div>

        <div v-if="c == 0">
            <div class="grid" v-if="!loading && properties.length != 0">
                <property v-for="property in properties" :key="property.prop_id"
                    :property="property"/>
            </div>
        </div>
        
        <div v-if="c == 1">
            <div class="grid" v-if="!loading && properties.length != 0">
                <property v-for="property in properties" :key="property.prop_id"
                    :property="property"/>
            </div>
        </div>

        <div v-if="c == 2">
            <submit :user_id="agent_id"/>
        </div>

    </div>
</template>

<script>
import property from './property.vue'
import submit from './submit.vue'

export default {
    components: {
        property, submit
    },
    props: ['agent_id'],
    data() {
        return {
            properties: [],
            loading: true,
            c: 0
        }
    },
    mounted() {
        this.fecth_propperties('available')
    },
    methods: {
        available() {
            this.c = 0
            this.fecth_propperties('available')
        },
        all() {
            this.c = 1
            this.fecth_propperties('all')
        },
        fecth_propperties(how) {
            this.loading = true

            let form = new FormData()
            form.append('user_id', this.agent_id)
            form.append('how', how)

            this.$http.post('/admin/agent/properties', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.properties = r.properties
                    this.loading = false
                }
            })

        }
    }
}
</script>

<style scoped>

    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
    }
    .title {
        font-size: 18px;
        font-weight: 500;
    }


    .menu {
        display: flex;
        padding: 0px 20px;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
    }
    .item {
        padding: 12px 30px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        border-bottom: 2px solid transparent;
    }
    .a {
        border-bottom-color: var(--main);
        color: var(--main);
    }



    .empty {
        text-align: center;
        font-size: 14px;
    }
    
    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-gap: 20px;
        padding: 20px;
    }
    
    .spin {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 2px solid var(--main) ;
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
        margin: 50px auto;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    
</style>