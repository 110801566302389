<template>
    <div class="dashboard">
        <div class="title">
            Dashboard
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .dashboard {
        padding: 30px;
    }
    .title {
        font-weight: 500;
        font-size: 18px;
    }
</style>