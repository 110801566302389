<template>
    <div class="dash">
        <div class="head">
            Hello, {{$root.$data.lastname}} {{$root.$data.firstname}}
        </div>
        <div class="loading" v-if="loading">
            <div class="spin"></div>
        </div>
        <div class="grid" v-else>
            <rating :rating="rating"/>
            <appointment :appointment="appointment" :appointments="appointments"/>
            <agent :agent="agent"/>
            <partner v-if="partner == 'ok'"/>
        </div>
    </div>
</template>

<script>
import rating from '../components/dashboard/rating.vue'
import appointment from '../components/dashboard/appointment.vue'
import agent from '../components/dashboard/agent.vue'
import partner from '../components/dashboard/partner.vue'

export default {
    components: {
        rating, appointment, agent, partner
    },
    data() {
        return {
            loading: true,
            rating: {},
            appointment: [],
            appointments: 0,
            agent: '',
            partner: ''
        }
    },
    mounted() {
        setTimeout(() => {
            this.$http.get('/dashboard')
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.rating = r.rating
                    this.appointment = r.appointment
                    this.appointments = r.appointments
                    this.agent = r.agent
                    this.partner = r.partner

                    this.$root.$data.agent = r.agent
                    this.$root.$data.is_partner = r.partner
                    this.loading = false
                }
            })
        }, 1000);
    }
}
</script>

<style scoped>
    .dash {
        padding: 20px;
    }
    .head {
        font-size: 20px;
        font-weight: 500;
    }

    .spin {
        margin: 70px auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: var(--main);
        animation: rotate 1s infinite linear;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .grid {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        grid-gap: 20px;
    }

    @media screen and (max-width: 450px) {
        .grid {
            grid-template-columns: auto;
        }
    }
</style>