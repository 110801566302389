
import {initializeApp} from 'firebase/app'
import {getMessaging } from 'firebase/messaging'

var firebaseConfig = {
    apiKey: "AIzaSyAW2qn9bI9_8rYCyNP1BXFUIxPxcgUD7yw",
    authDomain: "bigglandlord-53b13.firebaseapp.com",
    projectId: "bigglandlord-53b13",
    storageBucket: "bigglandlord-53b13.appspot.com",
    messagingSenderId: "243637691624",
    appId: "1:243637691624:web:91987781950737a8ea955d",
    measurementId: "G-8J1H6ZXNG3"
}

const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app);

export default messaging
