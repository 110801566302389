<template>
    <div class="agent">
        <div class="properties">
            Properties 10
        </div>
        <div class="image">
            <img :src="imgurl + 'agent2.jpg'"/>
        </div>
        <div class="content">
            <div class="name">Big Landlord</div>
            <div class="details">
                <div class="flex">
                    <phone :size="'12px'" class="icon"/>
                    <div class="value">09011122233, 08033344455</div>
                </div>
                <div class="flex">
                    <email :size="'12px'" class="icon"/>
                    <div class="value">bigglandlord@gmail.com</div>
                </div>
                <div class="flex">
                    <location :size="'12px'" class="icon"/>
                    <div class="value">Ikotun Lagos</div>
                </div>
                <!-- <div class="flex">
                    <appartment :size="'12px'" class="icon"/>
                    <div class="value">10</div>
                </div> -->
            </div>
            <div class="opt">
                <div class="btn">View Properties</div>
            </div>
        </div>
    </div>
</template>

<script>
import phone from '../../icons/call.vue'
import email from '../../icons/inbox.vue'
import location from '../../icons/location.vue'
// import appartment from '../../icons/house.vue'
export default {
    components: {
        phone, email, location
    },
    computed: {
        imgurl() {
            return this.$root.$data.imgurl
        }
    }
}
</script>

<style scoped>
    .agent {
        background-color: white;
        box-shadow: 0px 0px 20px #ccc;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
    }
    .properties {
        background-color: var(--main);
        position: absolute;
        top: 10px;
        right: 0px;
        padding: 5px 10px;
        color: white;
        font-size: 12px;
        font-weight: 500;
    }
    .image {
        width: 100%;
        height: 200px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
    }
    img {
        /* width: 100%; */
        max-height: 250px;
    }
    .content {
        padding: 15px;
    }
    .name {
        font-weight: 500;
    }
    .details {
        font-size: 14px;
        margin-top: 10px;
        opacity: .7;
    }
    .flex {
        display: flex;
        margin-bottom: 5px;
    }
    .icon {
        margin-right: 10px;
        fill: #686868;
    }
    .value {
        font-weight: 500;
    }
    .btn {
        background-color: var(--trans);
        color: var(--main);
        cursor: pointer;
        padding: 8px 20px;
        border-radius: 5px;
        font-weight: 500;
        margin-top: 15px;
        text-align: center;
    }
</style>