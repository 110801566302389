<template>
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 48 48">
            <path d="M10.75 41.15q-1.65 0-2.775-1.125T6.85 37.25q0-1.6 1.125-2.725T10.75 33.4q1.6 0 2.725 
            1.125T14.6 37.25q0 1.65-1.125 2.775T10.75 41.15Zm13.25 0q-1.6 0-2.725-1.125T20.15 37.25q0-1.6 
            1.1-2.725T24 33.4q1.6 0 2.725 1.125t1.125 2.725q0 1.65-1.1 2.775T24 41.15Zm13.25 0q-1.6 
            0-2.725-1.125T33.4 37.25q0-1.6 1.125-2.725T37.25 33.4q1.65 0 2.775 1.125t1.125 2.725q0 1.65-1.125 
            2.775T37.25 41.15Zm-26.5-13.3q-1.65 0-2.775-1.1T6.85 24q0-1.6 1.125-2.725t2.775-1.125q1.6 0 2.725 
            1.1T14.6 24q0 1.6-1.125 2.725T10.75 27.85Zm13.25 0q-1.6 0-2.725-1.1T20.15 24q0-1.6 1.1-2.725T24 
            20.15q1.6 0 2.725 1.1T27.85 24q0 1.6-1.1 2.725T24 27.85Zm13.25 0q-1.6 0-2.725-1.1T33.4 24q0-1.6 
            1.125-2.725t2.725-1.125q1.65 0 2.775 1.1T41.15 24q0 1.6-1.125 2.725T37.25 27.85ZM10.75 14.6q-1.65 
            0-2.775-1.125T6.85 10.75q0-1.65 1.125-2.775T10.75 6.85q1.6 0 2.725 1.125T14.6 10.75q0 1.6-1.125 
            2.725T10.75 14.6Zm13.25 0q-1.6 0-2.725-1.125T20.15 10.75q0-1.65 1.1-2.775T24 6.85q1.6 0 2.725 
            1.125t1.125 2.775q0 1.6-1.1 2.725T24 14.6Zm13.25 0q-1.6 0-2.725-1.125T33.4 10.75q0-1.65 
            1.125-2.775T37.25 6.85q1.65 0 2.775 1.125t1.125 2.775q0 1.6-1.125 2.725T37.25 14.6Z"/>
        </svg>
    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>