<template>
    <div class="head">
        <div>
            <div class="f">
                <div class="link">Home</div>
                <div class="arr">></div>
                <div class="link a">{{text}}</div>
            </div>
            <div class="title">
                {{text}}
            </div>
        </div>
        <div>
            <slot name="left"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: ['text']
}
</script>

<style scoped>
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .f {
        display: flex;
        font-size: 14px;
    }
    .arr {
        margin: 0px 5px;
    }

    .link {
        cursor: pointer;
        font-weight: 500;
    }
    .a {
        color: var(--main);
    }

    .title {
        font-weight: 500;
        font-size: 25px;
    }
</style>