<template>
    <div>
        <svg :width="size" :height="size" style="enable-background:new 0 0 30 30;" version="1.1" viewBox="0 0 30 30" xml:space="preserve" 
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path d="M19.999,2h-9.998C9.294,2,8.81,2.715,9.073,3.371L10,6h10l0.927-2.629C21.19,2.715,20.706,2,19.999,2z"/>
            <path d="M20,8H10c0,0-7,6-7,13c0,4,3,6,5,6s7,0,7,0s5,0,7,0s5-2,5-6C27,14,20,8,20,8z M16,21.898V23.5h-2v-1.597  
            c-1.471-0.276-2.396-1.18-2.45-2.501h1.732c0.083,0.694,0.783,1.139,1.792,1.139c0.931,0,1.59-0.451,1.59-1.098  
            c0-0.546-0.427-0.86-1.477-1.086l-1.115-0.237c-1.56-0.326-2.326-1.139-2.326-2.444c0-1.313,0.869-2.249,2.254-2.557V11.5h2v1.616  
            c1.35,0.302,2.252,1.222,2.296,2.465h-1.685c-0.083-0.676-0.712-1.127-1.578-1.127c-0.896,0-1.489,0.415-1.489,1.068  
            c0,0.528,0.409,0.831,1.412,1.044l1.032,0.22c1.721,0.362,2.462,1.104,2.462,2.432C18.45,20.648,17.532,21.607,16,21.898z"/>
            <path d="M18,4h-6c0-1.657,1.343-3,3-3S18,2.343,18,4z"/>
        </svg>
    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>