<template>
    <div class="user">
        <div class="grid">
            <div class="f">
                <div class="icon" @click="a = !a">
                    <arrow :size="'20px'" :style="{'height': '20px'}" 
                        :class="[a ? 'a' : '']" class="arr"/>
                </div>
                <div class="small_image">
                    <img :src="imgurl + info.image" class="small_img"/>
                </div>
                <div class="name">
                    {{info.firstname}} {{info.lastname}}
                </div>
            </div>
            <div>
                {{info.email}}
            </div>
            <div>
                {{info.user_id}}
            </div>
            <div>
                {{info.agent}}
            </div>
            <div>
                {{info.partner}}
            </div>
        </div>

        <div class="info" v-if="a">
            <div class="details">
                <div class="flex">
                    <div class="label">Firstname:</div>
                    <div class="value">{{info.firstname}}</div>
                </div>
                <div class="flex">
                    <div class="label">Last name:</div>
                    <div class="value">{{info.lastname}}</div>
                </div>
                <div class="flex">
                    <div class="label">Email:</div>
                    <div class="value">{{info.email}}</div>
                </div>
                <div class="flex">
                    <div class="label">Email Verified:</div>
                    <div class="value">{{info.email_verified == '1' ? 'Yes' : 'No'}}</div>
                </div>
                <div class="flex">
                    <div class="label">Email Verification Code:</div>
                    <div class="value">{{info.email_otp}}</div>
                </div>
                <div class="flex">
                    <div class="label">Password Reset Code:</div>
                    <div class="value">{{info.reset_code}}</div>
                </div>
                <div class="flex">
                    <div class="label">Appointments:</div>
                    <div class="value">{{info.appointments}}</div>
                </div>
                <div class="flex">
                    <div class="label">Phone Number:</div>
                    <div class="value">{{info.phone}}</div>
                </div>
                <div class="flex">
                    <div class="label">Whatsapp Number:</div>
                    <div class="value">{{info.whatsapp}}</div>
                </div>
                <div class="flex">
                    <div class="label">Rating:</div>
                    <div class="value">{{ Math.round((info.rating / info.rating_users) * 10) / 10 }}</div>
                </div>
                <div class="flex">
                    <div class="label">Agent:</div>
                    <div class="value">{{info.agent}}</div>
                </div>
                <div class="flex">
                    <div class="label">Partner:</div>
                    <div class="value">{{info.partner}}</div>
                </div>
                <div class="flex">
                    <div class="label">Registered:</div>
                    <div class="value">{{info.created}}</div>
                </div>
            </div>

            <div class="opt">
                <div class="title">Options</div>

                <div v-if="info.partner == 'block'">
                    <div >
                        <loader :height="'35px'" v-if="loading"/>
                        <div class="btn" v-else @click="make_partner()">Unblock partner</div>
                    </div>
                </div>
                <div v-else>
                    <div v-if="info.partner == ''">
                        <loader :height="'35px'" v-if="loading"/>
                        <div class="btn" v-else @click="make_partner()">Make user a partner</div>
                    </div>
                    <div v-else>
                        <loader :height="'35px'" v-if="loading"/>
                        <div class="btn" v-else @click="remove_partner()">Remove partner</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import arrow from '../../../icons/arrow2.vue'
import loader from '../../loader.vue'

export default {
    components: {
        arrow, loader
    },
    props: ['info', 'index'],
    data() {
        return {
            a: false,
            loading: false
        }
    },
    computed: {
        imgurl() {
            return this.$root.$data.imgurl
        }
    },
    methods: {
        make_partner() {
            this.loading = true

            let form = new FormData()
            form.append('user_id', this.info.user_id)

            this.$http.post('/users/make_partner', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    this.partnered()
                    this.$router.go()
                }
            })
        },
        remove_partner() {
            this.loading = true

            let form = new FormData()
            form.append('user_id', this.info.user_id)

            this.$http.post('/users/remove_partner', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    this.remove()
                    this.$router.go()
                }
            })
        }
    },
    notifications: {
        partnered: {
            type: 'success',
            title: 'Success',
            message: 'This user is now a partner'
        },
        remove: {
            type: 'success',
            title: 'Success',
            message: 'This user is no longer a partner'
        }
    }
}
</script>

<style scoped>

    .grid{
        display: grid;
        grid-template-columns: calc(40% - 10px) 30% 20% 5% 5%;
        border-top: 1px solid #ccc;
        align-items: center;
        padding: 10px 20px;
    }
    .f {
        display: flex;
        align-items: center;
    }
    .icon {
        width: 25px;
        height: 25px;
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        /* border: 1px solid #ccc; */
        background-color: var(--main);
        cursor: pointer;
        fill: white;
    }
    .arr {
        transition: .2s;
    }
    .a {
        transform: rotate(90deg);
    }
        
    .small_image {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ccc;
        margin: 0 20px;
    }
    .small_img {
        width: 30px;
    }


    .info {
        border-top: 1px solid #ccc;
        padding: 20px;
        display: grid;
        grid-template-columns: 50% calc(50% - 20px);
        grid-gap: 20px;
    }
    .details {
        /* max-width: 400px; */
        position: relative;
    }
    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
    }
    .flex:nth-child(even) {
        background-color: var(--trans);
        /* border-radius: 5px; */
    }
    .label {
        margin-right: 10px;
        font-size: 14px;
    }
    .value {
        font-weight: 500;
    }

    .title {
        font-weight: 500;
        font-size: 18px;
        text-align: center;
        margin-bottom: 20px;
    }
    .t {
        font-weight: 500;
    }
    .btn {
        background-color: var(--trans);
        color: var(--main);
        padding: 8px;
        text-align: center;
        font-weight: 500;
        cursor: pointer;
        border-radius: 5px;
    }
</style>