<template>
    <div class="agent">
        <div class="grid">
            <div class="f">
                <div class="icon" @click="show_main = !show_main">
                    <arrow :size="'20px'" :style="{'height': '20px'}" 
                        :class="[show_main ? 'rot' : '']" class="arr"/>
                </div>
                <!-- <div class="small_image">
                    <img src="../../../assets/old.png" class="small_img"/>
                </div> -->
                <div class="name">
                    {{info.name}}
                </div>
            </div>
            <div>
                {{info.phone}}
            </div>
            <div>
                {{info.city}}
            </div>
            <div>
                {{info.user_id}}
            </div>
        </div>
        <div class="main" v-if="show_main">
            <div class="menu">
                <div class="item" :class="[c == 0 ? 'a' : '']" @click="c = 0">Details</div>
                <div class="item" :class="[c == 1 ? 'a' : '']" @click="c = 1">Properties</div>
                <div class="item" :class="[c == 2 ? 'a' : '']" @click="c = 2">Apointments</div>
            </div>
            
            <div v-if="c == 0">
                <detailslayout :info="info" :index="index"
                    @move="$emit('move', $event)"/>
            </div>

            <div v-if="c == 1">
                <properties :agent_id="info.user_id"/>
            </div>

            <div v-if="c == 2">
                <appointments :user_id="info.user_id"/>
            </div>
        </div>
    </div>
</template>

<script>
import arrow from '../../../icons/arrow2.vue'
import loader from '../../loader.vue'

import detailslayout from './details.vue'
import properties from './properties.vue'
import appointments from './appointments.vue'

export default {
    components: {
        arrow, loader,
        detailslayout,
        properties, appointments
    },
    props: ['info', 'index'],
    data() {
        return {
            show_main: false,
            c: 0
        }
    },
}
</script>

<style scoped>
    .grid{
        display: grid;
        grid-template-columns: 40% 20% 20% 20%;
        border-top: 1px solid #ddd;
        align-items: center;
        padding: 10px 20px;
    }
    .f {
        display: flex;
        align-items: center;
    }
    .icon {
        width: 30px;
        height: 30px;
        border-radius: 3px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        /* border: 1px solid #ccc; */
        background-color: var(--main);
        cursor: pointer;
        fill: white;
    }
    .arr {
        transition: .2s;
    }
    .rot {
        transform: rotate(90deg);
    }
        
    .name {
        margin-left: 20px;
    }

    
    .menu {
        display: flex;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        padding: 0px 20px;
    }
    .item {
        padding: 12px 30px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        border-bottom: 2px solid transparent;
    }
    .a {
        border-bottom-color: var(--main);
        color: var(--main);
    }
    
</style>