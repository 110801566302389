<template>
    <div class="admin">
        <div class="header">
            <div class="logo">Bigglandlord Admin</div>
            <div class="logout" @click="logout()">Logout</div>
        </div>
        <div class="grid">
            <menuLayout/>
            <div class="main">
                <router-view v-if="ready"></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import Localbase from 'localbase'
import menuLayout from '../components/admin/menu.vue'
export default {
    components: {
        menuLayout
    },
    data() {
        return {
            db: new Localbase('Bigglandlord'),
            ready: false
        }
    },
    mounted() {
        this.db.collection('auth').doc({ id: 1 }).get().then( auth => {
            if ( auth == undefined ) {
                this.is_login = false
                this.$router.push('/admin/login')
            } else {
                let user = auth.key.split('-')[0]
                if (user == 'admin') {
                    this.is_login = true
                    this.$http.defaults.headers.common['Auth'] = auth.key
                    setTimeout(() => {
                        // this.$router.push('/admin/dashboard')
                        this.ready = true
                    }, 1000)
                } else {
                    this.is_login = false
                    this.$router.push('/admin/login')
                }
            }
        })
    },
    methods: {
        logout() {
            
            this.db.collection('auth').doc({ id: 1 }).delete()
            this.$router.push('/admin/login')
        }
    }
}
</script>

<style scoped>
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        box-sizing: border-box;
        border-bottom: 2px solid #edeef0;
    }
    .logo {
        color: var(--main);
        font-weight: 500;
        font-size: 18px;
    }
    .logout {
        padding: 8px 20px;
        box-shadow: 0px 0px 10px #edeef0;
        border-radius: 10px;
        font-weight: 500;
        cursor: pointer;
    }

    .grid {
        display: grid;
        grid-template-columns: max-content auto;
        height: calc(100vh - 79px);
    }
    .main {
        background-color: #edeef0;
        height: 100%;
        overflow: auto;
    }
</style>