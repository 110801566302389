<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">Send Message</div>
            <div class="small">Send a message to this {{who}} ({{user_id}})</div>

            <div class="input">
                <div class="label">Title</div>
                <input type="text" placeholder="Enter message title for email only" v-model="title">
            </div>
            <div class="input">
                <textarea placeholder="Enter your message" v-model="message"></textarea>
            </div>
            <loader :height="'37px'" v-if="loading"/>
            <div class="btn" @click="send()" v-else>Send</div>
        </div>
    </div>
</template>

<script>
import loader from '../../loader.vue'

export default {
    components: {
        loader
    },
    props: ['who', 'user_id'],
    data() {
        return {
            loading: false,
            title: '',
            message: ''
        }
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },
        send() {
            if (this.title == '' || this.message == '') {
                this.err(); return
            }

            this.loading = true

            let form = new FormData()
            form.append('title', this.title)
            form.append('message', this.message)
            form.append('user_id', this.user_id)

            this.$http.post('/admin/message/user', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    if (r.whatsapp) {
                        this.whatsapp()
                    } else { this.not_whatsapp() }

                    if (r.mail) {
                        this.email()
                    }
                    this.loading = false

                    this.close()
                }
            })
        }
    },
    notifications: {
        err: {
            type: 'warn',
            title: 'Complete',
            message: 'Enter message title and body'
        },
        whatsapp: {
            type: 'success',
            title: 'Whatsapp',
            message: 'Message sent to user whatsapp'
        },
        not_whatsapp: {
            type: 'warn',
            title: 'Not sent',
            message: "Message didn't send to user whatsapp"
        },
        email: {
            type: 'success',
            title: 'Email',
            message: 'Message sent to user email'
        }
    }
}
</script>

<style scoped>
    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dark {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
    }

    .box {
        background-color: white;
        border-radius: 5px;
        width: 350px;
        padding: 20px;
        position: relative;
        z-index: 1;
        animation: pop .2s;
    }
    @keyframes pop {
        from {
            transform: scale(.8);
        }
        to {
            transform: scale(1);
        }
    }
    .title {
        font-size: 20px;
        font-weight: 500;
    }
    .small {
        font-size: 12px;
        margin-bottom: 20px;
    }

    .input {
        margin-bottom: 20px;
    }
    .label {
        font-size: 14px;
        margin-bottom: 5px;
    }
    input, textarea {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-sizing: border-box;
        width: 100%;
    }
    textarea {
        height: 150px;
    }
    .btn {
        padding: 8px;
        text-align: center;
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        font-weight: 500;
        cursor: pointer;
    }
</style>