<template>
    <div class="agent">
        <div class="flex">
            <div class="icon" :class="[show_main ? 'rot' : '']" @click="show_main = !show_main">
                <arrow :size="'20px'" :style="{'height': '20px'}"/>
            </div>
            <div> {{ agent.name }}</div>
            <div>{{ agent.state }} {{ agent.city }}</div>
        </div>
        <div class="main" v-if="show_main">
            <div class="menu">
                <div class="item" @click="c = 0" :class="[c == 0 ? 'a' : '']">Properties</div>
                <div class="item" @click="c = 1" :class="[c == 1 ? 'a' : '']">Appointment</div>
                <div class="item" @click="c = 2" :class="[c == 2 ? 'a' : '']">Add Property</div>
            </div>

            <div v-if="c == 0">
                <properties :agent_id="agent.user_id"/>
            </div>
            <div v-if="c == 1">
                <appointments :user_id="agent.user_id"/>
            </div>
            <div v-if="c == 2">
                <submit :user_id="agent.user_id"/>
            </div>
        </div>
    </div>
</template>

<script>
import arrow from '../../../icons/arrow2.vue'

import properties from './properties.vue';
import appointments from './appointments.vue';
import submit from './submit.vue';


export default {
    components: {
        arrow,
        properties, appointments, submit
    },
    props: ['agent'],
    data() {
        return {
            show_main: false,
            c: 0
        }
    },

}
</script>

<style scoped>
    .agent {
        margin-bottom: 20px;
        border-radius: 5px;
        overflow: hidden;
        background-color: white;
    }
    .flex {
        display: flex;
        align-items: center;
        gap: 20px;
        font-size: 14px;
    }
    .icon {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--main);
        fill: white;
        cursor: pointer;
        transition: .3s;
    }
    .rot {
        transform: rotate(90deg);
    }

    .main {
        border-top: 1px solid #ddd;
    }
    .menu {
        display: flex;
        border-bottom: 1px solid #ddd;
    }
    .item {
        font-size: 12px;
        font-weight: 500;
        padding: 10px 15px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
    }
    .a {
        border-bottom-color: var(--main);
        color: var(--main);
    }
</style>