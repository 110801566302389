<template>
    <div class="box">
        <div class="title">My Statistics</div>
        <div class="rating">
            <div class="star">
                <stared :size="'20px'" v-for="i in s" :key="i"/>
                <star :size="'20px'" v-for="i in (5 - s)" :key="i"/>
            </div>
            <div>Rating {{rating}}</div>
        </div>
        <div class="item">
            <div class="label">Total Rating</div>
            <div class="value">{{d.rating}}</div>
        </div>
        <div class="item">
            <div class="label">Times Rated</div>
            <div class="value">{{d.rating_users}}</div>
        </div>
        <div class="item">
            <div class="label">Activity Score</div>
            <div class="value">{{d.score}}/10</div>
        </div>
        <div class="item">
            <div class="label">Total Appointments</div>
            <div class="value">{{d.appointments}}</div>
        </div>
        <div class="item">
            <div class="label">Successful Appointments</div>
            <div class="value">{{d.success}}</div>
        </div>
    </div>
</template>

<script>
import star from '../../icons/star.vue';
import stared from '../../icons/stared.vue';

export default {
    components: {
        star, stared
    },
    props: ['d'],
    data() {
        return {
            s: 0,
            rating: 0
        }
    },
    mounted() {
        
        if (parseInt(this.d.rating) > 0) {
            let s = parseInt(this.d.rating) / parseInt(this.d.rating_users)
            this.s = Math.floor(s)
            this.rating = (parseInt(this.d.rating) / parseInt(this.d.rating_users)).toFixed(1)
            
        } else {
            this.s = 0
        }
    }
}
</script>

<style scoped>

    .box {
        background-color: white;
        border-radius: 10px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .title {
        font-size: 18px;
        font-weight: 500;
        padding: 20px;
    }
    .rating {
        text-align: center;
        font-weight: 500;
        padding: 20px 0px;
    }
    .star {
        display: flex;
        margin: 0 auto;
        width: fit-content;
        fill: var(--main);
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        padding: 10px 20px;
    }
    .item:nth-child(even) {
        background-color: var(--trans);
    }
    .label {
        font-size: 14px;
        color: gray;
    }
    .value {
        font-weight: 500;
    }
</style>