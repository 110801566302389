<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">
                Send message to client in {{city}}
            </div>
            <div class="main">
                <textarea placeholder="Enter your message" v-model="text"></textarea>
                <div class="btn" @click="send()">Send</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['city', 'category', 'type', 'marked'],
    data() {
        return {
            text: '',
            loading: false
        }
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },
        send() {
            if (this.text == '') { this.empty(); return }

            let form = new FormData()
            form.append('message', this.text)
            form.append('city', this.city)
            form.append('category', this.category)
            form.append('type', this.type)
            form.append('marked', this.marked)

            this.$http.post('/admin/client/send_request', form)
            this.success()

            this.close()
        }
    },
    notifications: {
        empty: {
            'type': 'warn',
            'title': 'Empty message',
            'message': ''
        },
        success: {
            'type': 'success',
            'title': 'Sent',
            'message': 'Message sent, check whatsapp for delivery'
        }
    }
}
</script>

<style scoped>

    .popup {
        z-index: 10;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
    }

    .box {
        background-color: white;
        width: 500px;
        position: relative;
        z-index: 20;
        border-radius: 5px;
    }
    .title {
        padding: 15px 20px;
        font-weight: 500;
    }
    .main {
        padding: 20px;
        border-top: 1px solid #ddd;
    }
    textarea {
        padding: 15px;
        border-radius: 5px;
        border: 1px solid #ddd;
        width: 100%;
        box-sizing: border-box;
        height: 100px;
        margin-bottom: 20px;
    }
    .btn {
        padding: 8px;
        border-radius: 5px;
        cursor: pointer;
        background-color: var(--main);
        color: white;
        text-align: center;
        font-weight: 500;
    }
</style>