<template>
    <div class="video">
        <div class="date">
            {{ video.date }}
        </div>
        <div class="click">
            <a :href="tmpurl + video.video_data"  download="video">
                Download video
            </a>
        </div>
        <div class="click">
            <router-link :to="'/property/' + video.prop_id">View</router-link>
        </div>
        <div class="flex">
            <input type="text" placeholder="Enter youtube URL" v-model="youtube">

            <loader :height="'35px'" :width="'89.38px'" v-if="loading"/>
            <div class="btn" @click="save()" v-else>Save</div>
        </div>
    </div>
</template>

<script>
import loader from '../../loader.vue';
export default {
    components: {
        loader
    },
    props: ['video'],
    computed: {
        tmpurl() {
            return this.$root.$data.tmpurl
        }
    },
    data() {
        return {
            loading: false,
            youtube: ''
        }
    },

    methods: {
        save() {
            if (this.youtube == '') {
                this.empty(); return
            }
            this.loading = true

            let form = new FormData()
            form.append('prop_id', this.video.prop_id)
            form.append('video_data', this.video.video_data)
            form.append('youtube_url', this.youtube)

            this.$http.post('/admin/property/video/set', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()

                    this.loading = false
                    this.$emit('link_added')
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Please enter youtube url'
        },
        success: {
            type: 'success',
            title: 'Added',
            message: 'Youtube link has been added'
        }
    }
}
</script>

<style scoped>
    .video {
        background-color: white;
        margin-top: 20px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        gap: 20px;
        font-size: 14px;
        padding: 20px;
    }

    .date {
        font-weight: 500;
    }
    .click {
        font-weight: 500;
        cursor: pointer;
        color: var(--main);
    }

    .flex {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 20px;
    }
    input {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
    }
    .btn {
        padding: 8px 30px;
        font-weight: 500;
        color: white;
        background-color: var(--main);
        cursor: pointer;
        border-radius: 5px;
    }
</style>